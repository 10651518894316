<template>
  <div>
    <v-flex xs12>
      <v-row>
        <v-col
          cols="12"
          md
        >
          <v-select
            v-model="filter.project"
            :loading="loading"
            :items="projectList"
            label="Metas"
            item-value="id_assistencia"
            item-text="descricao"
            return-object
            hide-details
            clearable
            dark
            filled
            :rules="[v => !!v || 'Campo obrigatório']"
            @change="loadGoals"
          />
        </v-col>
        <v-col
          cols="12"
          md
        >
          <month-picker
            v-model="filter.date.input"
            disable-last-thirty
            not-clearable
            @change="onDateFilter"
          />
        </v-col>
        <v-col
          cols="12"
          md
        >
          <person-autocomplete-filter
            v-model="filter.technician"
            type="TECHNICAL"
            label="Técnico"
            dark
            hide-details
            @change="loadTechnician"
          />
        </v-col>
        <v-col
          cols="12"
          md
        >
          <routes-autocomplete-filter
            v-model="filter.routes"
            :technician="filter.technician ? filter.technician: null"
            label="Rota"
            dark
            type="visita,coleta"
            multiple
            hide-details
            @change="loadProducer"
          />
        </v-col>
        <v-col
          cols="12"
          md
        >
          <v-autocomplete
            v-model="filter.producer"
            :items="listProducer"
            :loading="loading"
            clearable
            dark
            filled
            label="Produtor"
            prepend-inner-icon="person_outline"
            item-text="name"
            item-value="id_produtor"
            @change="loadRange"
          />
        </v-col>
      </v-row>
      <average-graphic
        v-for="q in quality"
        :key="q.indicador+q.acao"
        :goal="q"
        :filter="filter"
      />
    </v-flex>
  </div>
</template>

<script>
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import AverageGraphic from "@/Domains/Projects/Dairy/MetaReport/Components/AverageGraphic.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import MonthPicker from "@/Support/Components/MonthPicker.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import moment from "moment-timezone";
import _ from "lodash";

export default {

  components: {
    AverageGraphic,
    RoutesAutocompleteFilter,
    PersonAutocompleteFilter,
    MonthPicker,
  },

  mixins: [ReportMixin],

  props: {
    type: String,
  },

  data() {
    return {
      loading: false,

      filter: {
        project: {
          id_assistencia: null,
          descricao: '',
        },

        date: {
          input: "",
          month: null,
        },

        technician: {
          id: "",
          description: ""
        },

        producer: [],

        routes: [],

      },

      listProducer: [],

    };
  },

  async mounted() {
    await this.loadProducer();
    this.loadGoals();
  },

  methods: {

    /**
     * @event array
     *
     * Evento acionado ao selecionar um filtro de data
     */
    async onDateFilter([month]) {
      this.filter.date.month = month;
      this.loadGoals();
    },

    async loadTechnician() {
      this.filter.producer = [];
      await this.loadProducer();
      this.loadRange();
    },

    async loadRange() {
      this.loading = true
      let quality = this.filter.project.quality;
      let producer = this.filter.producer;
      let technician = this.filter.technician;
      let tipo = "Dairy";
      let date = this.filter.date

      const endpointMap = {
        esd: "/relatorioIn/loadIndicatorsEsd",
        lactose: "/relatorioIn/loadIndicatorsLactose",
        solidos_totais: "/relatorioIn/loadIndicatorsST",
        caseina: "/relatorioIn/loadIndicatorsCaseina",
        ureia: "/relatorioIn/loadIndicatorsUreia",
        CCS: "/relatorioIn/loadIndicatorsCCS",
        CCS_TRIMESTRAL: "/relatorioIn/loadIndicatorsCCSTri",
        CPP: "/relatorioIn/loadIndicatorsCPP",
        CPP_TRIMESTRAL: "/relatorioIn/loadIndicatorsCPPTri",
        gordura: "/relatorioIn/loadIndicatorsGordura",
        proteina: "/relatorioIn/loadIndicatorsProteina",
      };

      const requests = Object.keys(quality).map(async (item) => {
        const endpoint = endpointMap[quality[item].indicador];
        if (!endpoint) return null; // Se não houver endpoint definido, ignora

        let { data } = await this.$axios.post(endpoint, {
          meta: Array(quality[item]), // Envia a meta do indicador específico
          producer: producer,
          technician: technician,
          tipo: tipo,
          date: date,
        });

        return { data };

      });

      const responses = await Promise.all(requests);

      this.quality = responses
        .filter(response => response !== null) // Filtra respostas nulas
        .flatMap(response => response.data);
      this.quality = this.quality.filter(item => Array.isArray(item.dados) && item.dados.length > 0);

      this.loading = false

    },

    async loadGoals() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(
          `/recomendacoesQualidade/historicoMetas`,
          {
            tipo: "Dairy",
            view: 'quality'
          }
        );

        this.projectList = data.map((proj) => {
          return {
            quality: proj.qualidade ? JSON.parse(proj.qualidade) : [],
            descricao: proj.descricao,
            id_assistencia: proj.id_assistencia,
          }
        });

        if (!_.isEmpty(this.projectList )) {
          this.filter.project = this.projectList[0];
          await this.loadRange();
        }

      } catch (error) {
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    async loadProducer() {

      try {
        this.loading = true;
        const { data } = await this.$axios.post(
          `/produtores/listaProdutorRelatorio`,
          this.$qs.stringify({
            routes: this.filter.routes.map(route => route.id),
            technician: this.filter.technician ? this.filter.technician : null
          })
        );

        this.listProducer = [];

        this.listProducer = data.map((producer) => ({
          id_produtor: producer.id_produtor,
          name: producer.nome_produtor
        }));

      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    formatMonth: (value) => _.capitalize(moment(value, 'YYYY-MM').format("MMM/YY")),
    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
    formatDate: (value, format) => !value ? "-" : moment(value).format(format),
  },
};
</script>
