var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"persistent":"","max-width":"450"},model:{value:(_setup.isVisible),callback:function ($$v) {_setup.isVisible=$$v},expression:"isVisible"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Fechamento de Carga ")]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":_setup.conclude}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Código da Carga *","prepend-inner-icon":"qr_code","rules":[v => !!v || 'Campo obrigatório'],"outlined":"","clearable":""},model:{value:(_setup.shipmentCode),callback:function ($$v) {_setup.shipmentCode=$$v},expression:"shipmentCode"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"prepend-inner-icon":"local_shipping","label":"Tipo de Veículo *","items":[
                { text: 'Bitruck', value: 'BITRUCK' },
                { text: 'Truck', value: 'TRUCK' },
                { text: 'Carreta', value: 'CARRETA' },
                { text: 'Vanderleia', value: 'VANDERLEIA' },
                { text: 'Carretinha', value: 'CARRETINHA' },
                { text: 'Sider', value: 'SIDER' },
              ],"rules":[v => !!v  || 'Campo obrigatório'],"outlined":""},model:{value:(_setup.truckType),callback:function ($$v) {_setup.truckType=$$v},expression:"truckType"}})],1)],1),_vm._l((_setup.plannedShipmentSuppliers),function({ supplier },index){return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"text-subtitle-1 font-weight-bold"},[_vm._v(" "+_vm._s(_setup.startCase(supplier.name?.toLowerCase()))+" ")]),_c(_setup.DateTimeTextField,{staticClass:"mt-2",attrs:{"input-format":"YYYY-MM-DD HH:mm:ss","label":"Previsão Carregamento *","rules":[v => !!v || 'Campo obrigatório'],"manual":"","outlined":""},model:{value:(_setup.plannedShipmentSuppliers[index].plannedTimeToArrive),callback:function ($$v) {_vm.$set(_setup.plannedShipmentSuppliers[index], "plannedTimeToArrive", $$v)},expression:"plannedShipmentSuppliers[index].plannedTimeToArrive"}})],1)],1)}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Observações","prepend-inner-icon":"note","auto-grow":"","outlined":""},model:{value:(_setup.notes),callback:function ($$v) {_setup.notes=$$v},expression:"notes"}})],1)],1)],2),_c('small',[_vm._v("* Campo obrigatório")])],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red darken-1","outlined":""},on:{"click":function($event){return _setup.close()}}},[_vm._v(" Cancelar ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","outlined":"","loading":_setup.loading},on:{"click":function($event){return _setup.conclude()}}},[_vm._v(" Concluir ")])],1)],1),_c('v-overlay',{attrs:{"value":_setup.loading,"absolute":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_setup.loading ? 'Carregando...' : 'Salvando...')+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }