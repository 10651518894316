<template>
  <v-dialog
    v-model="isVisible"
    persistent
    max-width="450"
  >
    <v-card>
      <v-card-title class="text-h5">
        Fechamento de Carga
      </v-card-title>

      <v-card-text>
        <v-form
          ref="form"
          lazy-validation
          @submit="conclude"
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="shipmentCode"
                label="Código da Carga *"
                prepend-inner-icon="qr_code"
                :rules="[v => !!v || 'Campo obrigatório']"
                outlined
                clearable
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-select
                v-model="truckType"
                prepend-inner-icon="local_shipping"
                label="Tipo de Veículo *"
                :items="[
                  { text: 'Bitruck', value: 'BITRUCK' },
                  { text: 'Truck', value: 'TRUCK' },
                  { text: 'Carreta', value: 'CARRETA' },
                  { text: 'Vanderleia', value: 'VANDERLEIA' },
                  { text: 'Carretinha', value: 'CARRETINHA' },
                  { text: 'Sider', value: 'SIDER' },
                ]"
                :rules="[v => !!v  || 'Campo obrigatório']"
                outlined
              />
            </v-col>
          </v-row>

          <v-row
            v-for="({ supplier }, index) in plannedShipmentSuppliers"
          >
            <v-col cols="12">
            <span class="text-subtitle-1 font-weight-bold">
              {{ startCase(supplier.name?.toLowerCase()) }}
            </span>

              <date-time-text-field
                v-model="plannedShipmentSuppliers[index].plannedTimeToArrive"
                class="mt-2"
                input-format="YYYY-MM-DD HH:mm:ss"
                label="Previsão Carregamento *"
                :rules="[v => !!v || 'Campo obrigatório']"
                manual
                outlined
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="notes"
                label="Observações"
                prepend-inner-icon="note"
                auto-grow
                outlined
              />
            </v-col>
          </v-row>
        </v-form>

        <small>* Campo obrigatório</small>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="red darken-1"
          outlined
          @click="close()"
        >
          Cancelar
        </v-btn>

        <v-spacer />

        <v-btn
          color="green darken-1"
          outlined
          :loading="loading"
          @click="conclude()"
        >
          Concluir
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay
      :value="loading"
      absolute
    >
      <v-card-text>
        {{ loading ? 'Carregando...' : 'Salvando...' }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-dialog>
</template>

<script setup>
import { getCurrentInstance, ref } from "vue";

import moment from "moment/moment";

import groupBy from "lodash/groupBy";

import axios from '@/Support/Resources/axios-instance.js'
import mapValues from "lodash/mapValues";
import first from "lodash/first";
import get from "lodash/get";
import values from "lodash/values";
import startCase from "lodash/startCase";
import DateTimeTextField from "@/Support/Components/DateTimeTextField.vue";

const { proxy: { $snotify, $root } } = getCurrentInstance();

const emit = defineEmits(["saved"]);

const form = ref(null);

const isVisible = ref(false);
const loading = ref(false);
const notes = ref('Observação de teste');
const shipmentCode = ref('');
const truckType = ref('');

const plannedShipmentOrders = ref([]);
const plannedShipmentSuppliers = ref([]);

function show(orderedDispatches) {
  const { suppliers } = getSuppliers(orderedDispatches);

  plannedShipmentOrders.value = orderedDispatches;
  plannedShipmentSuppliers.value = suppliers;

  const dateCode = moment().format("DDMMYY");

  isVisible.value = true;
  shipmentCode.value = `${dateCode}-01`;
}

function close() {
  isVisible.value = false;

  return reset();
}

function reset() {
  plannedShipmentOrders.value = [];
  plannedShipmentSuppliers.value = [];
  truckType.value = '';

  shipmentCode.value = '';
}

function getSuppliers(orders) {
  const products = orders.flatMap(order => {
    return order.products;
  });

  const productsBySupplier = groupBy(products, 'supplier.id');

  const suppliers = values(mapValues(productsBySupplier, (productsBySupplier) => {
    return {
      supplier: get(first(productsBySupplier), 'supplier'),
      // plannedTimeToArrive: moment().format('YYYY-MM-DD HH:mm:ss'), // @todo remover antes de subir
      plannedTimeToArrive: null,
    };
  }));

  return {
    suppliers,
  }
}

async function conclude() {
  try {
    loading.value = true;

    const payload = {
      observacoes: notes.value,
      codigo_carga: shipmentCode.value,
      tipo_veiculo_carga: truckType.value,
      expedicao_carga_unidade: plannedShipmentSuppliers.value.map(shipmentSupplier => {
        return {
          id_laticinio: shipmentSupplier.supplier.id,
          data_hora_previsao_carregamento: shipmentSupplier.plannedTimeToArrive,
        }
      }),
      pedidos: plannedShipmentOrders.value.map(shipmentOrder => {
        return {
          id_movimento: shipmentOrder.id,
          codigo_movimento: shipmentOrder.code,
          cod_pedido: shipmentOrder.purchaseOrderCode,
          observacoes: shipmentOrder.notes,
          sequencia: shipmentOrder.sequence,
          data_hora_entrega: shipmentOrder.scheduledTo,
          id_cliente: shipmentOrder.customer.id,
          produtos: shipmentOrder.products.map((product) => {
            return {
              id_movimento: shipmentOrder.id,
              id_movimento_item: product.id,
              id_item: product.item.itemId,
              id_unidade_carregamento: product.supplier.id,

              quantidade: product.quantity,
              id_unidade_medida: product.measurementId,
              unidade_medida: product.measurement,
              unidade_descricao: product.measurementDescription,

              quantidade_real: product.realQuantity,
              id_unidade_medida_real: product.unidade,
              unidade_real: product.realMeasurement,
              unidade_real_descricao: product.realMeasurementDescription,
            };
          }),
        }
      })
    }

    let { data } = await axios.post(`/order/save-shipment`, payload);

    if (!data) {
      throw new Error(data.mensagem || data);
    }

    $snotify.success("Carga montada com sucesso!", "Sucesso");

    emit('saved');

    return close();
  } catch (error) {
    $snotify.error("Erro ao gravar a expedição", "Atenção");

    console.warn(error);
  } finally {

    loading.value = false;
  }
}

defineExpose({
  show,
});
</script>
