<template>
  <div class="packing-weighing">
    <v-form ref="formRef">
      <v-row class="mt-2">
        <v-col
          cols="12"
          sm="6"
          class="py-0"
        >
          <v-select
            v-if="form.shippingId || form.orderId"
            v-model="form.item"
            :items="products"
            label="Produto *"
            prepend-inner-icon="inventory"
            item-value="id"
            item-text="description"
            return-object
            outlined
            :disabled="products.length === 0"
            :rules="[v => !!v && !!v.id || 'Campo obrigatório!']"
            @change="onShippingItemChange"
          />
          <item-autocomplete-filter
            v-else
            v-model="form.item"
            label="Produto *"
            prepend-inner-icon="inventory"
            return-object
            outlined
            :item-types="['VENDA']"
            show-other-measurements
            :rules="[v => !!v && !!v.id || 'Campo obrigatório!']"
            @change="onItemChange"
          />
        </v-col>
        <v-col
          class="py-0"
        >
          <v-autocomplete
            v-model="form.stock"
            label="Lote *"
            :items="stocks"
            :disabled="!form.item?.id"
            :rules="[v => (!!v && !!v.id) || 'Campo obrigatório!']"
            item-value="id"
            item-text="description"
            return-object
            outlined
            prepend-inner-icon="subtitles"
          >
            <template #selection="{ item }">
              {{ item.description }}
              <div
                v-if="(onlyShipped || !form.item?.manualLot) && !form.orderId"
                class="mx-1 caption font-weight-light"
              >
                (Disp: {{ formatNumber(item.availableQuantity) }})
              </div>
            </template>
            <template #item="{ item }">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.description }}
                </v-list-item-title>
                <v-list-item-subtitle class="mt-1">
                  <template v-if="item.warehouseName">
                    Depósito: <b>{{ item.warehouseName }}</b> -
                  </template>
                  <template v-if="item.manufacturingDate">
                    Fab: <b>{{ formatDate(item.manufacturingDate, 'DD/MM/YYYY') }}</b> -
                  </template>
                  <template v-if="item.expirationDate">
                    Venc: <b>{{ formatDate(item.expirationDate, 'DD/MM/YYYY') }}</b> -
                  </template>
                  <template v-if="!form.orderId">
                    Estoque: <b :class="{ 'red--text' : item.quantity < 0 }">{{ formatNumber(item.quantity) }}</b>
                  </template>
                  <template v-if="item.availableQuantity >= 0 && (onlyShipped || !form.item?.manualLot) && !form.orderId">
                    - Disp: <b>{{ formatNumber(item.availableQuantity) }}</b>
                  </template>
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
            <template
              v-if="(form.item?.id && (!form.shippingId || !onlyShipped) && hasLotRegistrationPermission) || !!form.orderId"
              #append-item
            >
              <v-divider class="mt-2" />
              <v-row no-gutters>
                <v-col>
                  <v-btn
                    block
                    outlined
                    @click="addLot"
                  >
                    Cadastrar Lote
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          class="py-0"
        >
          <masked-text-field
            v-model="form.quantity"
            label="Quantidade *"
            :mask="masks.integer"
            unmask
            outlined
            :suffix="form.item?.defaultMeasurement"
            :disabled="!!form.item?.measurementId"
            prepend-inner-icon="iso"
            :hint="form.stock && !form.orderId ? `Quantidade disponível: ${form.stock.availableQuantity}` : undefined"
            :rules="[
              v => !!v && form.quantity > 0 || 'Campo obrigatório!',
              validateQuantity(),
            ]"
          />
        </v-col>
      </v-row>

      <v-row
        v-if="!!form.item?.fixedWeight && form.grossWeight > 0"
      >
        <v-col
          cols="6"
          md="3"
          class="py-0"
        >
          <masked-text-field
            v-model="form.packingsQuantity"
            label="Quantidade Embalagens *"
            :mask="masks.integer"
            unmask
            outlined
            inputmode="numeric"
            prepend-inner-icon="iso"
            :rules="[
              v => !!v && form.packingsQuantity > 0 || 'Informe a Quantidade!',
              () => !!form.orderId || ((form.quantity * form.packingsQuantity) <= form.stock?.availableQuantity) || 'Quantidade Indisponível!',
            ]"
            validate-on-blur
            @keyup.enter="addPackings()"
          />
        </v-col>
        <v-col
          cols="6"
          md="3"
          class="py-0"
        >
          <v-btn
            color="blue"
            outlined
            text
            x-large
            block
            style="height: 44px;margin-top: -1px;"
            @click="addPackings()"
          >
            Adicionar
          </v-btn>
        </v-col>
      </v-row>

      <scale-weighing
        v-else
        v-model="form"
        :settings-id="settingsId"
        :has-weighing-input-selected="hasWeighingInputSelected"
        @weighing="emit('weighing', $event)"
        @save="save"
      >
        <template #append>
          <div
            v-if="form.stock"
            class="text-body-1"
            style="font-size: 1.1rem !important;"
          >
            Lote: {{ form.stock.description }}
            <template v-if="form.stock.manufacturingDate">
              - Fab: <b>{{ formatDate(form.stock.manufacturingDate, 'DD/MM/YYYY') }}</b>
            </template>
            <template v-if="form.stock.expirationDate">
              - Venc: <b>{{ formatDate(form.stock.expirationDate, 'DD/MM/YYYY') }}</b>
            </template>
            <template v-if="!form.orderId">
              - Estoque: <b :class="{ 'red--text' : form.stock.quantity < 0 }">{{ formatNumber(form.stock.quantity) }}</b>
            </template>
            <template v-if="form.stock.availableQuantity >= 0 && (onlyShipped || !form.item?.manualLot) && !form.orderId">
              - Disp: <b>{{ formatNumber(form.stock.availableQuantity) }}</b>
            </template>
          </div>
        </template>
      </scale-weighing>
    </v-form>

    <edit-stock-dialog
      ref="editStockDialogRef"
      hide-price
      :order-id="form.orderId"
      @save="onStockSaved"
    />
  </div>
</template>

<style lang="scss">
.packing-weighing {
  .theme--light.v-label--is-disabled, .theme--light.v-input--is-disabled {
    color: rgba(0, 0, 0, 0.8) !important;
  }

  .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
    color: rgba(0, 0, 0, 0.87) !important;
  }
}
</style>

<script setup>
import { ref, reactive, computed } from 'vue'
import { useUtils } from '@/Support/Composables/utils.js'
import { usePermissions } from '@/Support/Composables/permissions.js'
import axios from '@/Support/Resources/axios-instance.js'
import store from '@/Support/Resources/vuex.js'

import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

import ScaleWeighing from '@/Domains/Shipment/StorageControl/Components/ScaleWeighing.vue'
import ItemAutocompleteFilter from '@/Support/Components/Filters/ItemAutocompleteFilter.vue'
import MaskedTextField from '@/Support/Components/MaskedTextField.vue'
import EditStockDialog from '@/Domains/Registrations/Item/Components/EditStockDialog.vue'

import { usePacking } from '@/Domains/Shipment/Composable/Packing.js'

import ZebraPrinterGenerator from '@/Domains/Shipment/Api/ZebraPrinterGenerator.js'

const { progressBar, notify } = useUtils()

const formatNumber = (value) => !value ? 0 : new Intl.NumberFormat('pt-BR').format(value)
const formatDate = (value, format) => !value ? '-' : moment(value).format(format)

// eslint-disable-next-line no-undef
const props = defineProps({
  warehouseId: String,
  settingsId: {
    type: String,
    default: 'WEIGHING'
  },
  externalValidate: {
    type: Function,
    default: () => true
  },
  customTags: {
    type: Array,
    default: () => []
  },
  boxNumber: Number,
  palletNumber: [String, Number],
  loadNumber: [String, Number],
  loadedAt: String,
  hasWeighingInputSelected: Boolean,
  onlyShipped: Boolean,
})

const { packingSettings, printTag } = usePacking(props.settingsId)

const { hasPermission } = usePermissions()

const hasLotRegistrationPermission = computed(() => hasPermission('lot-registration'))

// eslint-disable-next-line no-undef
const emit = defineEmits([
  'weighing',
  'created'
])

const formRef = ref()
const grossWeightRef = ref()

const masks = {
  float: { mask: Number, min: 0, scale: 3, thousandsSeparator: '.', radix: ',' },
  floatSigned: { mask: Number, scale: 3, thousandsSeparator: '.', radix: ',', signed: true },
  integer: { mask: Number, min: 0, scale: 0, signed: false },
}

const form = reactive({
  id: null,
  item: null,
  stock: null,
  quantity: null,
  grossWeight: null,
  tareWeight1: null,
  tareWeight2: null,
  shippingId: null,
  orderId: null,
})

const stocks = ref([]);

const products = ref([]);

const init = (data = null) => {
  form.item = null
  form.stock = null
  form.quantity = 1
  form.grossWeight = null
  form.tareWeight1 = null
  form.tareWeight1 = null
  form.shippingId = data?.shippingId
  form.orderId = data?.orderId
  reset()

  if (form.shippingId || form.orderId) {
    products.value = data.pendingProducts.map((product) => ({
      ...product,
      stocks: product.stocks.map((stock) => ({
        ...stock,
        availableQuantity: stock.quantity - stock.packingQuantity,
      }))
    }))

    if (products.value.length === 1) {
      onShippingItemChange(products.value[0])
    }
  }

}

const reset = () => {
  formRef.value?.resetValidation()
}

const onStockSaved = (stock) => {
  if (form.orderId && stock) {
    const stockItem = {
      id: stock.id_estoque,
      description: stock.numero_lote || 'PADRÃO',
      lotNumber: stock.numero_lote,
      quantity: stock.quantidade,
      availableQuantity: stock.quantidade - (parseFloat(stock.quantidade_embalado) || 0),
      manufacturingDate: stock.data_fabricacao,
      expirationDate: stock.data_validade,
    }

    stocks.value.push(stockItem)
    form.stock = stockItem
    return
  }
  loadStocks(form.item.itemId)
}

const loadStocks = async (itemId) => {
  try {
    const { data } = await axios.get(`/stock`, { params: {
      ids: [itemId],
      id_deposito: props.warehouseId,
      exibir_embalagens: 1,
      multiplos_depositos: 1,
    } });

    stocks.value = data
      .map(stock => ({
        id: stock.id_estoque,
        description: stock.numero_lote || 'PADRÃO',
        lotNumber: stock.numero_lote,
        quantity: stock.quantidade,
        availableQuantity: stock.quantidade - (parseFloat(stock.quantidade_embalado) || 0),
        manufacturingDate: stock.data_fabricacao,
        expirationDate: stock.data_validade,
        warehouseName: stock.nome_deposito,
      }));

  } catch (e) {
    console.error(e)
    const message = e?.response?.data?.message || 'Erro ao carregar os estoques'
    notify.error(message, 'Atenção')
  }
}

const onItemChange = (item) => {
  if (item.fixedWeight) {
    form.grossWeight = item.grossWeight
  }
  form.tareWeight1 = item.tareWeight1
  form.tareWeight2 = item.tareWeight2
  form.quantity = item.conversionFactor || 1
  stocks.value = []
  if (item?.itemId) {
    loadStocks(item.itemId)
  }
}

const onShippingItemChange = (item) => {
  form.item = item
  if (item.fixedWeight) {
    form.grossWeight = item.grossWeight
  }
  form.tareWeight1 = item.tareWeight1
  form.tareWeight2 = item.tareWeight2
  form.quantity = item.conversionFactor || 1

  if (props.onlyShipped || form.orderId) {
    stocks.value = item.stocks
    if (stocks.value.length === 1) {
      form.stock = stocks.value[0]
    }
  } else {
    loadStocks(item.itemId)
  }
}

const validate = async () => {
  const isExternalValidated = await props.externalValidate()
  const isValidated = await formRef.value?.validate()

  if (!isExternalValidated || !isValidated) {
    const errors = formRef.value.inputs
      .filter(item => item.hasError)
      .map(item => item.label ? `<span><b>${item.label.replace(' *', '')}</b>: ${item.errorBucket[0]}</span>` : `<b>${item.errorBucket[0]}</b>`)

    if (errors.length) {
      notify.error('Atenção', { html: `<div class="text-h4">Atenção</div>${errors.join('\n')}` })
    }

    return false
  }

  return true
}

const save = async () => {
  if (!await validate()) {
    return
  }

  try {
    progressBar?.saving(true)

    const packing = await storePacking(form)

    emit('created', [packing])

    if (packingSettings.value.autoPrinting) {
      print(packing)
    }

    form.stock.availableQuantity -= form.quantity

    reset()
    grossWeightRef.value?.focus()

  } catch (e) {
    console.error(e)
    const message = e?.response?.data?.message || 'Erro ao salvar'
    notify.error(message, 'Atenção')
  } finally {
    progressBar?.hide()
  }
}

const storePacking = async (form) => {
  const grossWeight = form.grossWeight

  const payload = {
    id: uuidv4(),
    id_item: form.item.itemId,
    id_unidade_medida: form.item.measurementId,
    id_estoque: form.stock.id,
    quantidade: form.quantity,
    peso_tara_1: form.tareWeight1,
    peso_tara_2: form.tareWeight2,
    peso_bruto: grossWeight,
  };

  if (form.item.shippingItemId) {
    payload.id_entrega_item = form.item.shippingItemId
  }

  if (form.orderId) {
    payload.id_ordem_producao = form.orderId
  }

  payload.gerar_sscc = packingSettings.value.generateSscc

  const { data } = await axios.post(`packing`, payload)

  const packing = {
    ...form,
    id: data.id,
    description: form.item?.name,
    measurement: form.item?.defaultMeasurement,
    lotNumber: form.stock?.lotNumber,
    code: data.code,
    barcode: data.barcode,
    sscc: data.sscc,
    createdAt: data.createdAt,
    grossWeight,
    tareWeight: parseFloat(form.tareWeight1 || 0) + parseFloat(form.tareWeight2 || 0),
    netWeight: parseFloat(grossWeight || 0) - parseFloat(form.tareWeight1 || 0) - parseFloat(form.tareWeight2 || 0),
    product: `${form.item?.code || ''} - ${form.item?.name} - ${form.stock?.lotNumber || 'SEM LOTE'}`,
    boxNumber: props.boxNumber,
  }

  return packing
}

const addPackings = async () => {
  if (!await validate()) {
    return
  }

  try {
    progressBar?.saving(true)

    const forms = Array.from({ length: form.packingsQuantity }).fill(form)

    const packings = await Promise.all(forms.map(item => storePacking(item)))

    emit('created', packings.sort((a, b) => a.code - b.code))

    form.stock.availableQuantity -= form.quantity * form.packingsQuantity

    reset()
    grossWeightRef.value?.focus()

  } catch (e) {
    console.error(e)
    const message = e?.response?.data?.message || 'Erro ao salvar'
    notify.error(message, 'Atenção')
  } finally {
    progressBar?.hide()
  }

}

const validateQuantity = () => {
  // Estoque não selecionado
  if (!form.stock || !form.item) {
    return true
  }

  // Entrada de ordem de produção não valida quantidade
  if (form.orderId) {
    return true
  }

  // Se não estiver expedindo itens com lote já lançado (expedição). O lote manual não deve ser validado estoque
  if (!props.onlyShipped && form.item.manualLot) {
    return true
  }

  if (form.quantity <= form.stock.availableQuantity) {
    return true
  }

  return 'Quantidade indisponível'
}

const editStockDialogRef = ref()

const addLot = () => {
  const { item } = form

  const defaultLotNumber = item?.lotInfo?.lotNumber && !item.stocks.find((stock) => stock.lotNumber === item.lotInfo.lotNumber) ? item.lotInfo.lotNumber : null

  editStockDialogRef.value.show({
    itemId: item?.itemId,
    defaultLotNumber,
  })
}

const print = (packing) => {
  const tagModel = packingSettings.value.tagModel

  if (tagModel === 'SYSTEM_10X5') {
    const rawData = ZebraPrinterGenerator.systemTag10x5({
      barcode: packing.barcode,
      grossWeight: packing.grossWeight,
      tareWeight: packing.tareWeight,
      netWeight: packing.netWeight,
      code: packing.item?.code
    })

    return printTag(rawData)
  }

  if (tagModel === 'SIF_10X15' || tagModel === 'SISBI_10X15') {
    const dairy = store.state.settings?.laticinio || {}

    const dun = packing.item.duncodes?.[dairy.id] || (packing.item.barcode !== packing.item.defaultBarcode ? packing.item.barcode : '')

    if (!dun) {
      return notify.warning('DUN-14 não encontrado', 'Atenção')
    }

    const rawData = ZebraPrinterGenerator.industryTag10x15({
      dairyName: dairy.name,
      dairyDoc: dairy.doc,
      dairyCity: dairy.address?.city,
      dairyState: dairy.address?.state,
      dairyStreet: dairy.address?.street,
      dairyNumber: dairy.address?.number,

      grossWeight: packing.grossWeight,
      tareWeight1: packing.tareWeight1,
      tareWeight2: packing.tareWeight2,
      tareWeight: packing.tareWeight,
      netWeight: packing.netWeight,

      productName: packing.item.name,
      quantity: packing.quantity,
      expirationDate: packing.stock?.expirationDate,
      manufacturingDate: packing.stock?.manufacturingDate,
      lotNumber: packing.stock?.lotNumber,
      loadedAt: props.loadedAt || packing.createdAt,

      gtin: packing.item.barcodes?.[dairy.id] || packing.item.defaultBarcode || packing.item.barcode,
      dun,
      sscc: packing.sscc,
      sif: packing.item.sifDipoa?.[dairy.id] || packing.item.sif,

      boxNumber: packing.boxNumber,
      palletNumber: props.palletNumber,
      loadNumber: props.loadNumber,

      model: tagModel === 'SISBI_10X15' ? 'SISBI' : 'SIF'
    })

    return printTag(rawData)
  }

  if (tagModel === 'PRODUCTION_10X15') {
    const dairy = store.state.settings?.laticinio || {}

    const rawData = ZebraPrinterGenerator.productionTag10x15({
      dairyName: dairy.name,
      dairyDoc: dairy.doc,
      dairyCity: dairy.address?.city,
      dairyState: dairy.address?.state,
      dairyStreet: dairy.address?.street,
      dairyNumber: dairy.address?.number,

      grossWeight: packing.grossWeight,
      tareWeight: packing.tareWeight,
      netWeight: packing.netWeight,

      productName: packing.item.name,
      quantity: packing.quantity,
      expirationDate: packing.stock?.expirationDate,
      manufacturingDate: packing.stock?.manufacturingDate,
      lotNumber: packing.stock?.lotNumber,

      barcode: packing.barcode,

      gtin: packing.item.barcodes?.[dairy.id] || packing.item.defaultBarcode || packing.item.barcode,
      sif: packing.item.sifDipoa?.[dairy.id] || packing.item.sif,
    })

    return printTag(rawData)
  }

  const customTag = props.customTags.find((label) => label.id === tagModel)

  if (customTag) {
    const variables = {
      '[DATA_FABRICACAO]': formatDate(packing.stock?.manufacturingDate, 'DD/MM/YYYY'),
      '[DATA_VALIDADE]': formatDate(packing.stock?.expirationDate, 'DD/MM/YYYY'),
      '[QUANTIDADE]': formatNumber(packing.quantity),
      '[PESO_BRUTO]': formatNumber(packing.grossWeight),
      '[PESO_LIQUIDO]': formatNumber(packing.netWeight),
      '[PESO_TARA]': formatNumber(packing.tareWeight),
      '[NOME_PRODUTO]': packing.item.name,
      '[CODIGO_PRODUTO]': packing.item.code,
      '[NUMERO_LOTE]': packing.stock?.lotNumber,
    };

    const rawData = Object.keys(variables)
      .reduce((acc, key) => acc.replace(key, variables[key]), customTag.etiqueta)

    return printTag(rawData)
  }
}

const item = computed(() => form.item)

// eslint-disable-next-line no-undef
defineExpose({ init, save, print, item })
</script>
