<template>
  <v-row>
    <v-col cols="2">
      <v-text-field
        :value="props.sellerCommission.seller.name"
        label="Vendedor"
        prepend-inner-icon="person_outline"
        class="order-input"
        hide-details
        outlined
        filled
        disabled
      />
    </v-col>

    <v-col cols="1">
      <money-input
        :value="props.sellerCommission.commission"
        label="Comissão Vendedor"
        prefix="R$"
        class="order-input"
        persistent-hint
        hide-details
        outlined
        disabled
        filled
      />
    </v-col>
  </v-row>
</template>

<script setup>
import { computed, ref, reactive, watch, onBeforeMount } from 'vue'

import MoneyInput from "@/Support/Components/MoneyInput.vue";

import MaskedInput from "@/Support/Components/MaskedInput.vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  sellerCommission: {
    type: Object,
    required: true,
  },
  total: {
    type: Number,
    required: true,
  }
});

// eslint-disable-next-line no-undef
const emit = defineEmits(['update:sellers-commission'])

const localData = computed({
  get() {
    return props.sellerCommission;
  },
  set(newValue) {
    return emit("update:sellers-commission", newValue);
  }
});

const totalCommission = computed(() => {
  return 0;
});

const masks = {
  float: {
    mask: "num",
      blocks: {
      num: {
        mask: Number,
          scale: 4,
          min: -999999,
          max: 999999,
      },
    },
  },
}
</script>
