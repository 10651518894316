
export default class RatingService {
  constructor() {
    this.types = {
      'QUALIDADE-SIMPLES': 'Qualidade Simples',
      'INCONFORME-SIMPLES': 'Inconformes Simples',
      'QUALIDADE-PONTOS': 'Qualidade por pontuação',
      'INCONFORME-PONTOS': 'Inconformes por pontuação',
      'COMBINADAS': 'Combinada',
    }
  }
}
