<template>
  <div>
    <v-row class="pl-3">
      <v-col cols="3">
        <v-text-field
          v-model="localAction.label"
          label="Descrição"
          filled
          hide-details
          class="input-action-label"
        >
          <template #prepend>
            <icon-selector
              v-model="localAction.icon"
            />
          </template>
        </v-text-field>
      </v-col>

      <v-col cols="3">
        <v-select
          v-model="localAction.action"
          label="Ação"
          :items="actions"
          filled
          hide-details
        />
      </v-col>

      <v-col cols="3">
        <v-select
          v-model="localAction.newStatusId"
          label="Novo Status"
          :items="props.statusList"
          filled
          item-text="label"
          item-value="id"
          hide-details
        />
      </v-col>

      <v-col
        cols="1"
        offset="2"
        class="text-right"
      >
        <v-btn
          large
          color="red"
          icon
          @click="onDeleteActionClick(action)"
        >
          <v-icon>delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row
      class="pl-3"
      v-if="localAction.action === 'ALTERAR_STATUS'"
    >
      <v-col
        offset="3"
        cols="6"
      >
        <v-text-field
          v-model="localAction.dialog.title"
          label="Título"
          filled
          hide-details
          class="input-action-label"
        />
      </v-col>
      <v-col
        offset="3"
        cols="3"
      >
        <v-text-field
          v-model="localAction.dialog.confirmActionLabel"
          label="Ação de Confirmação"
          filled
          hide-details
          class="input-action-label"
        />
      </v-col>
    </v-row>
    <v-row
      class="pl-3"
      v-if="localAction.action === 'ALTERAR_STATUS'"
    >
      <v-col
        cols="3"
        offset="3"
      >
        <card-title-settings
          class="mb-0"
        >
          Campos Complementares

          <v-spacer />

          <v-btn
            fab
            dark
            x-small
            plain
            @click="onAddAdditionalFieldClick"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  add
                </v-icon>
              </template>
              <span>Inserir novo campo</span>
            </v-tooltip>
          </v-btn>
        </card-title-settings>
      </v-col>

      <v-col cols="12" class="py-0">
        <v-row
          v-for="(field, index) in localAction.dialog.additionalFields"
        >
          <v-col
            cols="3"
            offset="3"
          >
            <v-text-field
              v-model="localAction.dialog.additionalFields[index].value"
              label="Campo"
              filled
              hide-details
              class="input-action-label"
            />
          </v-col>

          <v-col
            cols="3"
          >
            <v-text-field
              v-model="localAction.dialog.additionalFields[index].description"
              label="Descrição"
              filled
              hide-details
              class="input-action-label"
            />
          </v-col>

          <v-col
            cols="2"
            class="d-flex align-center justify-center"
          >
            <v-checkbox
              v-model="localAction.dialog.additionalFields[index].required"
              label="Obrigatório"
              hide-details
            />
          </v-col>
          <v-col
            cols="1"
            class="d-flex align-center justify-center"
          >
            <v-btn
              large
              color="red"
              icon
              @click="onDeleteAdditionalFieldClick(field)"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<style>
.input-action-label > .v-input__prepend-outer {
  margin-top: 4px;
  margin-right: 20px;
}

</style>

<script setup>
import { computed, ref, reactive, watch, onMounted, defineEmits } from 'vue';

import { v4 as uuidv4 } from "uuid";

import IconSelector from "@/Support/Components/IconSelector.vue";
import CardTitleSettings from "@/Domains/Settings/Components/General/CardTitleSettings.vue";

const emit = defineEmits(['change', 'delete']);

const props = defineProps({
  action: Object,
  statusList: Array,
});

const localAction = ref({
  label: '',
  icon: null,
  action: null,
  newStatusId: null,
  dialog: {
    title: '',
    description: '',
    confirmActionLabel: '',
    additionalFields: [],
  },
});

const actions = ref([
  {
    text: 'Aprovar Pedido',
    value: 'APROVAR_PEDIDO'
  },
  {
    text: 'Cancelar Pedido',
    value: 'CANCELAR_PEDIDO'
  },
  {
    text: 'Alterar Status',
    value: 'ALTERAR_STATUS'
  },
]);

onMounted(() => {
  localAction.value = {
    ...localAction.value,
    ...props.action,
    dialog: {
      ...localAction.value.dialog,
      ...props.action.dialog,
    },
  };
});

watch(localAction, (newAction) => {
  return emit('change', newAction);
}, { deep: true });

const onDeleteActionClick = (action) => {
  return emit('delete', action);
};

const onDeleteAdditionalFieldClick = (deletedField) => {
  localAction.value.dialog.additionalFields = localAction.value.dialog.additionalFields.filter(field => field.id !== deletedField.id);
};

const onAddAdditionalFieldClick = () => {
  localAction.value.dialog.additionalFields.push({
    id: uuidv4(),
    value: '',
    description: '',
  });
}
</script>
