<template>
  <v-dialog
    v-model="showDialog"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="700px"
    max-height="850px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-avatar
        v-bind="attrs"
        color="deep-purple lighten-1"
        size="36"
        v-on="on"
      >
        <v-icon>
          {{ localValue }}
        </v-icon>
      </v-avatar>
    </template>

    <v-card>
      <v-card-title>
        Escolha o Icone
      </v-card-title>

      <v-card-text
        id="scrolling-techniques-7"
      >
        <v-row>
          <v-col cols="12">
            <v-btn
              v-for="(item, i) in allIcons"
              :key="i"
              color="white"
              class="mr-3 mb-2 "
              fab
              small
              depressed
              @click="saveIcon(item)"
            >
              <v-icon
                color="grey darken-3"
                size="28"
              >
                {{ item }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {

  props: {
    value: String,
  },

  data() {
    return {
      showDialog: false,

      localValue: '',

      allIcons: [
        'settings',
        'delete',
        'info',
        'article',
        'close',
        'check',
        'person',
        'person_outline',
        'person_off',
        'domain',
        'domain_add',
        'send',
        'edit',
      ]
    }
  },

  mounted() {
    this.localValue = this.value || 'settings';
  },

  watch: {
    value(value) {
      this.localValue = value;
    }
  },

  methods: {
    saveIcon: function(icon) {
      this.localValue = icon;
      this.showDialog = false;

      return this.$emit('input', icon);
    }
  },
}

</script>
