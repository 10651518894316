export default {
  namespaced: true,
  state: {
    connection: null,
    localConnection: null,
  },
  mutations: {
    setConnection(state, socket) {
      state.connection = socket;
    },
    setLocalConnection(state, socket) {
      state.localConnection = socket;
    },
  },
};
