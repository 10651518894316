
import colors from 'vuetify/lib/util/colors'

export default {
  data() {
    return {
      defaultRanges: {
        'CCS': {
          name: 'CCS',
          limit: 4,
          list: [ 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000 ]
        },
        'CPP': {
          name: 'CPP',
          limit: 2,
          list: [ 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000 ]
        },
        'Gordura': {
          name: 'Gordura',
          limit: 1,
          list: [ 2.5, 3, 3.5, 4, 5 ]
        },
        'Proteína': {
          name: 'Proteína',
          limit: 1,
          list: [ 2.5, 2.9, 3, 3.5, 4 ]
        },
        'Caseína': {
          name: 'Caseína',
          limit: 1,
          list: [ 2, 2.6, 3, 4, 8 ]
        },
        'Uréia': {
          name: 'Uréia',
          limit: 1,
          list: [ 13, 14, 14.5, 15, 18, 22, 24, 26]
        },
        'Lactose': {
          name: 'Lactose',
          limit: 1,
          list: [ 2, 2.6, 3, 4, 8 ]
        },
        'ESD': {
          name: 'ESD',
          limit: 1,
          list: [ 2, 2.6, 3, 4, 8, 10, 11, 12, 13 ]
        },
        'Sólidos Totais': {
          name: 'Sólidos Totais',
          limit: 1,
          list: [ 10, 11.4, 12, 13 ]
        },
        'Volume': {
          name: 'Volume (Média)',
          limit: 0,
          list: [ 500 ]
        },
        'Distância': {
          name: 'Distância',
          limit: 0,
          list: [ 100 ]
        },
        'Volume Total': {
          name: 'Volume (Mês)',
          limit: 0,
          list: [ 500 ]
        },
      },
    }
  },

  methods: {
    async getRanges() {
      let { data } = await this.$axios.get(`/configuracao/listaFaixasQualidade`);

      if (data.codigo !== 1) {
        throw new Error(data.mensagem);
      }

      const saveds = data.faixas.map(range => ({
        ...range,
        faixas: JSON.parse(range.faixas)
      }));

      const savedsItems = saveds.map(({ item }) => item);
      const defaultItems = Object.keys(this.defaultRanges);

      let ranges = {};

      /**
       * Inclui na lista os que foram recuperados do Banco de dados
       */
      saveds.forEach(range => {
        if (defaultItems.includes(range.item)) {
          ranges[range.item] = {
            id: range.id_configuracao_faixas_qualidade,
            item: range.item,
            name: this.defaultRanges[range.item].name || range.item,
            limit: range.limite,
            list: range.faixas.map(value => ({ value }))
          };
        }
      });

      /**
       * Obtém a intersecção dos nomes que não foram encontradas no BD
       */
      const notSaveds = defaultItems.filter(name => !savedsItems.includes(name));

      /**
       * Adiciona os que não foram encontrados no BD
       */
      notSaveds.forEach(item => {
        const range = this.defaultRanges[item];
        ranges[item] = {
          id: null,
          item: item,
          name: range.name || item,
          limit: range.limit,
          list: range.list.map(value => ({ value }))
        };
      });

      return ranges;
    },

    generateGraphPie(title, items, key, range, filter = '', reverse = false) {
      const colorsRange = range ? this.generateColorRanges(range, reverse) : [];

      let data = items.reduce(
        (acc, item) => {
          for (let idx in colorsRange) {
            if (item[key] <= colorsRange[idx].value) {
              acc[idx].value++;
              break;
            }
          }
          return acc;
        },
        colorsRange.map(({ name, color }) => ({
          name,
          value: 0,
          itemStyle: { color }
        }))
      );

      if (filter == 'priority' && range) {
        if (reverse) {
          data = data.slice(0, range.limit);
        }
        else {
          data = data.slice(range.limit + 1);
        }
      }
      const totalProducers = data.reduce((acc, cur) => acc + cur.value, 0);

      return {
        height: 400,
        options: {
          title: {
            text: title,
            subtext: `Produtores: ${totalProducers}`,
            textStyle: { color: '#ddd' },
            subtextStyle: { color: '#ddd' }
          },
          toolbox: {
            feature: {
              saveAsImage: {
                title: '',
                backgroundColor: '#263238',
              }
            }
          },
          tooltip: {
            trigger: 'item',
            formatter: '{b}: {c}'
          },
          legend: {
            // orient: 'horizontal',
            bottom: '0%',
            left: 'center',
            icon: 'circle',
            textStyle: {
              color: '#ddd',
            }
          },
          series: [
            {
              name: title,
              type: 'pie',
              labelLine: { show: false },
              label: {
                show: true,
                position: 'inside',
                formatter: '{c}',
                textShadowColor: '#000',
                textShadowBlur: 0.5,
              },
              top: -50,
              radius: '60%',
              data: reverse ? [...data].reverse() : data,
            }
          ],
        }
      }
    },

    generateGraphPiePercent(title, items, key, range, filter = '', reverse = false) {
      const colorsRange = range ? this.generateColorRanges(range, reverse) : [];

      let data = items.reduce(
        (acc, item) => {
          for (let idx in colorsRange) {
            if (item[key] <= colorsRange[idx].value) {
              acc[idx].value++;
              break;
            }
          }
          return acc;
        },
        colorsRange.map(({ name, color }) => ({
          name,
          value: 0,
          itemStyle: { color }
        }))
      );

      if (filter == 'priority' && range) {
        if (reverse) {
          data = data.slice(0, range.limit);
        }
        else {
          data = data.slice(range.limit + 1);
        }
      }
      const totalProducers = data.reduce((acc, cur) => acc + cur.value, 0);

      return {
        height: 400,
        options: {
          title: {
            text: title,
            subtext: `Produtores: ${totalProducers}`,
            textStyle: { color: '#ddd' },
            subtextStyle: { color: '#ddd' }
          },
          toolbox: {
            feature: {
              saveAsImage: {
                title: '',
                backgroundColor: '#263238',
              }
            }
          },
          tooltip: {
            trigger: 'item',
            formatter: '{b}: {c} ({d}%)'
          },
          legend: {
            // orient: 'horizontal',
            bottom: '0%',
            left: 'center',
            icon: 'circle',
            textStyle: {
              color: '#ddd',
            }
          },
          series: [
            {
              name: title,
              type: 'pie',
              labelLine: { show: false },
              label: {
                show: true,
                position: 'inside',
                formatter: '{c}',
                textShadowColor: '#000',
                textShadowBlur: 0.5,
              },
              top: -50,
              radius: '60%',
              data: reverse ? [...data].reverse() : data,
            }
          ],
        }
      }
    },

    generateGraphBar(title, data, key, range, subtitle = '', reverseColors = false) {
      const colorsRange = range ? this.generateColorRanges(range, reverseColors) : [];
      subtitle += `\r\nProdutores: ${data.length}`;
      return {
        height: 50 + (data.length * 25),
        options: {
          title: {
            text: title,
            subtext: subtitle.trim(),
            textStyle: { color: '#ddd' },
            subtextStyle: { color: '#ddd' }
          },
          toolbox: {
            feature: {
              saveAsImage: {
                title: '',
                backgroundColor: '#263238',
              }
            }
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            },
            formatter: (params) => {
              const averageCollect = data[params[0].dataIndex].media_coleta;
              return [
                params[0].name,
                ...params.map(param => `${param.marker} ${param.seriesName}: <b>${this.formatNumber(param.value)}</b>`),
                averageCollect ? `• Média Coleta: <b>${this.formatNumber(averageCollect)} L</b>` : ''
              ].join('<br>')
            }
          },
          grid: {
            left: '1%',
            right: '5%',
            top: '50px',
            bottom: '0%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            scale: true,
            position: 'top',
            min: 0,
            boundaryGap: false,
            splitLine: { show: false },
            axisLine: { show: true },
            axisTick: { show: false },
            axisLabel: {
              textStyle: { color: 'transparent' }
            },
          },
          yAxis: {
            type: 'category',
            inverse: true,
            data: data.map(o => [o.codigo_laticinio, o.nome_produtor, o.rota].filter(o => o).join(' - ')),
            axisLabel: {
              fontSize: 10,
              textStyle: { color: '#ddd' }
            },
          },
          series: [{
            name: title,
            type: 'bar',
            label: {
              show: true,
              position: 'right',
              color: '#fff',
              distance: -1,
              padding: [2.5, 10],
              borderRadius: [0, 30, 30, 0],
              textShadowColor: '#000',
              textShadowBlur: 3,
            },
            data: data.map(item => ({
              value: item[key],
              itemStyle: {
                normal: { color: this.getColor(colorsRange, item[key]) }
              },
              label: { backgroundColor: this.getColor(colorsRange, item[key]) },
            })),
          }],
        }
      };
    },

    getColor(colorsRange, value) {
      for (let item of colorsRange) {
        if (value <= item.value) {
          return item.color;
        }
      }
    },

    generateColorRanges(range, reverse = false) {
      let basicColors = [
        colors.blue.darken4,
        colors.blue.darken3,
        colors.blue.darken2,
        colors.blue.darken1,
      ];

      const intermediateColor = colors.yellow.darken1;

      let advancedColors = [
        colors.red.lighten4,
        colors.red.lighten3,
        colors.red.lighten2,
        colors.red.lighten1,
        colors.red.darken1,
        colors.red.darken2,
        colors.red.darken3,
        colors.red.darken4
      ];

      if (reverse) {
        let aux = advancedColors;
        advancedColors = basicColors.reverse();
        basicColors = aux.reverse();
      }

      const limitIdx = range.limit;

      const colorsRange = [
        ...basicColors.slice(0, limitIdx),
        intermediateColor,
        ...advancedColors.slice((range.list.length - limitIdx + 1) * -1)
      ]

      const maxIndex = colorsRange.length - 1;

      let prev = 0;
      let colorIdx = 0;
      let cores = range.list.map(({ value }, idx) => {
        colorIdx = (idx > maxIndex) ? maxIndex : idx;
        const name = `${prev} a ${value}`;
        prev = value;
        return {
          value,
          color: colorsRange[colorIdx],
          name
        }
      })

      cores.push({
        value: Infinity,
        color: colorsRange[colorIdx + 1],
        name: `Acima de ${prev}`
      })

      return cores;
    },

    formatNumber: (value) => new Intl.NumberFormat('pt-BR').format(value),
  }
}
