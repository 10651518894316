<template>
  <div>
    <v-row>
      <v-col
        class="mt-2"
        style="max-width: 65px;"
      >
        <v-btn disabled class="pa-0">
          <v-icon>
            reorder
          </v-icon>
          {{ index + 1 }}
        </v-btn>
      </v-col>
      <v-col
        cols="1"
        lg="2"
      >
        <person-autocomplete-filter
          v-model="localData.supplier"
          label="Fornecedor"
          :type="type === 'PEDIDO_PRODUTOR' ? 'SUPPLIER' : 'DAIRY'"
          prepend-inner-icon="add_business"
          :disabled="supplierDisabled || !editing"
          :filled="false"
          persistent-hint
          hide-details
          outlined
        />
      </v-col>
      <v-col
        cols="3"
      >
        <item-autocomplete-filter
          v-model="localData.item"
          label="Produto *"
          prepend-inner-icon="inventory"
          :item-types="['VENDA']"
          :disabled="!editing"
          :rules="[v => !!v && !!v.id || 'Campo obrigatório!']"
          return-object
          outlined
          hide-details
          hide-measurement-description
        />
      </v-col>
      <v-col cols="2">
        <masked-text-field
          v-if="localData.item.saleByKilo"
          v-model.number="localData.quantity"
          label="Peso"
          :mask="masks.float"
          prepend-inner-icon="scale"
          inputmode="numeric"
          suffix="KG"
          :rules="[
            v => !!v && localData.quantity > 0 || 'Campo obrigatório!',
          ]"
          unmask
          outlined
          validate-on-blur
          hide-details
          @input="onItemWeightChange"
        />

        <masked-text-field
          v-else
          v-model.number="localData.realQuantity"
          :label="`${getItemMeasurementDescription()} *`"
          :mask="masks.float"
          prepend-inner-icon="scale"
          inputmode="numeric"
          :suffix="getItemMeasurementUnit()"
          :rules="[
            v => !!v && localData.quantity > 0 || 'Campo obrigatório!',
          ]"
          unmask
          outlined
          validate-on-blur
          hide-details
          @input="onItemUnitChange"
        />
      </v-col>
      <v-col cols="1">
        <v-select
          v-model="localData.shippingFormat"
          label="Formato"
          placeholder=" "
          :disabled="!editing"
          :items="[
            { text: 'Caixa Solta', value: 'CAIXA_SOLTA' },
            { text: 'Paletizado', value: 'PALLET' },
          ]"
          hide-details
          outlined
        />
      </v-col>
      <v-col cols="1">
        <money-input
          v-model="localData.price"
          label="Valor unitário"
          prefix="R$"
          :rules="[() => !!localData.price && localData.price > 0 || 'Campo obrigatório!']"
          persistent-hint
          hide-details
          outlined
        />
      </v-col>
      <v-col
        class="flex-grow-1 flex-shrink-0"
      >
        <money-input
          :value="subTotalWithDiscount"
          label="Subtotal"
          prefix="R$"
          class="order-input"
          persistent-hint
          hide-details
          disabled
          outlined
          filled
        />
      </v-col>
      <v-col class="d-flex flex-column justify-center">
        <div>
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                icon
                large
                v-on="on"
                @click="onShowSellersCommissionClick"
              >
                <v-icon>support_agent</v-icon>
              </v-btn>
            </template>

            Comissões dos representantes
          </v-tooltip>

          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                icon
                large
                v-on="on"
                @click="onShowItemNotesClick"
              >
                <v-icon>note_add</v-icon>
              </v-btn>
            </template>

            Informações adicionais do item
          </v-tooltip>


          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                v-if="editing"
                icon
                large
                v-on="on"
                @click.stop="removeItem"
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </template>

            Remover o item do pedido
          </v-tooltip>


        </div>
      </v-col>

      <price-adjustment-dialog
        ref="priceAdjustment"
        @save="onSaveAdjusment"
      />
    </v-row>

    <v-row
      v-if="sellers?.length > 0 && showItemCommissions"
    >
      <v-col cols="2" style="margin-left: 65px">
        <v-select
          v-model="localData.commissionType"
          label="Tipo Comissão"
          :items="[
            { text:'Percentual', value: 'PERCENTAGE' },
            { text: `R$/${getItemMeasurementUnit()}`, value: 'AMOUNT_PER_UNIT' },
          ]"
          class="order-input"
          disabled
          filled
          hide-details
          outlined
        />
      </v-col>

      <v-col cols="1">
        <money-input
          v-model="localData.commissionAdjustment"
          label="Verba"
          :suffix="localData.commissionType === 'PERCENTAGE' ? '%' : `R$/${getItemMeasurementUnit()}`"
          hide-details
          outlined
        >
          <template #prepend-inner class="mt-0">
            <div class="mt-n2 mr-2">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                    @click="onCommissionAdjustmentFlip"
                  >
                    <v-icon v-if="isZero(localData.commissionAdjustment)">exposure</v-icon>
                    <v-icon v-else-if="isPositive(localData.commissionAdjustment)">add</v-icon>
                    <v-icon v-else>remove</v-icon>
                  </v-btn>
                </template>

                {{ getCommissionAdjustmentTooltipLabel() }}
              </v-tooltip>
            </div>
          </template>
        </money-input>
      </v-col>

      <template
        v-for="(_, index) in localData.commissions"
      >
        <order-item-seller-commission-input
          :commission="localData.commissions[index]"
          :product="localData"
          :quantity="itemQuantity"
        />
      </template>
    </v-row>


    <v-row
      v-if="localData.notes?.length > 0 || showItemNotes"
    >
      <v-col cols="11" style="margin-left: 65px">
        <v-textarea
          v-model="localData.notes"
          auto-grow
          hide-details
          label="Informações adicionais"
          persistent-hint
          prepend-inner-icon="description"
          rows="2"
          outlined
        />
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, ref, watch } from "vue";

import capitalize from "lodash/capitalize";

import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import MoneyInput from "@/Support/Components/MoneyInput.vue";
import PriceAdjustmentDialog from "../PriceAdjustmentDialog.vue";
import { percentageBy, round } from "@/Support/Resources/utils";
import ItemAutocompleteFilter from "@/Support/Components/Filters/ItemAutocompleteFilter.vue";
import OrderItemSellerCommissionInput from "@/Domains/Orders/Components/OrderItemSellerCommissionInput.vue";

const priceAdjustment = ref(null);

// eslint-disable-next-line no-undef
const props = defineProps({
  type: String,
  editing: Boolean,
  editingAmount: Boolean,
  supplierDisabled: Boolean,
  index: Number,
  priceTable: Object,
  sellers: Array,
  item: {
    type: Object,
    required: true,
  },
});

// eslint-disable-next-line no-undef
const emit = defineEmits(['update:item', 'removeItem', 'itemQuantityChanged'])

const editingItem = ref(false);
const showItemNotes = ref(false);
const showItemCommissions = ref(true);

const localData = ref({
  price: null,
  increment: null,
  adjustmentType: null,
  discountPerc: null,
  discountValue: null,
  item: {},
  supplier: {},
  commissionAdjustment: 0,

  // Quantidade sempre em KG
  quantity: null,
  measurementId: null,
  measurement: '',
  measurementDescription: '',

  // Em produtos que não são vendidos por KG, calcula com base no peso e peso bruto do item a quantidade real
  realQuantity: null,
  realMeasurementId: null,
  realMeasurement: '',
  realMeasurementDescription: '',
});

onBeforeMount(async () => {
  localData.value = props.item;
});

const item = computed(() => {
  return localData.value.item || {};
});

const itemQuantity = computed(() => {
  if (item.value.saleByKilo) {
    return parseFloat(localData.value.quantity);
  }

  return parseFloat(localData.value.realQuantity);
});

const subTotal = computed(() => itemQuantity.value * localData.value.price);

const masks = {
  float: { mask: Number, min: 0, scale: 4 },
  integer: { mask: Number, min: 0, scale: 0, signed: false },
}

const discountPerc = computed({
  get() {
    if (localData.value.adjustmentType !== 'DESCONTO_VALOR') {
      return localData.value.discountPerc || null
    }
    const subTotal = subTotal.value;
    const discountValueAmount = discountValue.value
    if (!discountValueAmount) {
      return null;
    }
    return round(percentageBy(subTotal, discountValueAmount), 2)
  },
  set(newValue) {
    localData.value.discountPerc = newValue;
  },
});

const discountValue = computed({
  get() {
    if (localData.value.adjustmentType !== 'DESCONTO_PERCENTUAL') {
      return localData.value.discountValue || null
    }
    const subTotal = subTotal.value;
    const discountPercAmount = discountPerc.value
    if (!discountPercAmount) {
      return null;
    }
    return (discountPercAmount * subTotal) / 100
  },
  set(newValue) {
    localData.value.discountValue = newValue;
  },
});

const totalCommissionAdjustment = computed(() => {
  return itemQuantity.value * localData.value.commissionAdjustment;
})

const subTotalWithDiscount = computed(() => subTotal.value - (discountValue.value || 0));

watch(discountPerc, (newValue) => {
  localData.value.discountPerc = newValue;
}, { immediate: true });

watch(discountValue, (newValue) => {
  localData.value.discountValue = newValue;
}, { immediate: true });

watch(subTotal, (newValue) => {
  localData.value.subTotal = newValue;
}, { immediate: true });

watch(subTotalWithDiscount, (newValue) => {
  localData.value.subTotalWithDiscount = newValue;
}, { immediate: true });

watch(totalCommissionAdjustment, (newValue) => {
  localData.value.totalCommissionAdjustment = newValue;
}, { immediate: true, deep: true });

watch(localData, (newValue) => {
  return emit('update:item', newValue);
}, { immediate: true, deep: true });

function removeItem() {
  return emit('removeItem');
}

function changeDiscount() {
  return priceAdjustment.show({
    editing: props.editing,
    data: localData.value
  });
}

function onSaveAdjusment(data) {
  localData.value = {
    ...localData.value,
    ...data
  }
}

function onShowSellersCommissionClick() {
  showItemCommissions.value = !showItemCommissions.value;
}

function onShowItemNotesClick() {
  if (localData.value?.notes?.length > 0 && showItemNotes.value) {
    localData.value.notes = '';
    showItemNotes.value = false;

    return;
  }

  showItemNotes.value = !showItemNotes.value;
}

const formatNumber = val => !val ? '-' : new Intl.NumberFormat('pt-BR').format(val);

const isZero = val => parseFloat(val) === 0.0;
const isPositive = val => val > 0;

const onCommissionAdjustmentFlip = () => {
  localData.value.commissionAdjustment = localData.value.commissionAdjustment * -1;
}

const getCommissionAdjustmentTooltipLabel = () => {
  if (parseFloat(localData.value.commissionAdjustment) === 0.0) {
    return `Adicione um valor e informe se a verba é positiva ou negativa`;
  }

  const adjustmentTypeLabel = localData.value.commissionAdjustment > 0 ? 'adicionado' : 'removido';

  if (localData.value?.commissionType === 'PERCENTAGE') {
    return `Será ${adjustmentTypeLabel} o percentual de ${localData.value.commissionAdjustment}% da comissão.`
  }

  return `Será ${adjustmentTypeLabel} o valor de R$ ${localData.value.commissionAdjustment} por ${getItemMeasurementDescription()} da comissão.`
}

function getItemMeasurementUnit() {
  if (item.value.saleByKilo) {
    return 'KG';
  }

  return item.value.measurement || '';
}

function getItemMeasurementDescription() {
  if (item.value.saleByKilo) {
    return 'Kilo';
  }

  return capitalize(item.value.measurementDescription);
}

function onItemWeightChange(quantity) {
  const grossWeight = item.value.grossWeight || 1; // Caso não tenha peso bruto definido, gravar a quantidade
  const realQuantity = quantity / grossWeight;

  localData.value.quantity = quantity;

  localData.value.realQuantity = realQuantity;
}

function onItemUnitChange(quantity) {
  const grossWeight = item.value.grossWeight || 1; // Caso não tenha peso bruto definido, gravar a quantidade

  localData.value.quantity = quantity * grossWeight;

  localData.value.realQuantity = quantity;
}

</script>
