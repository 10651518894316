<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-row>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Configurações de Instruções Normativas
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-tabs
      dark
      background-color="transparent"
      class="transparent"
    >
      <v-tab href="#aba-forain">
        Configurações de Fora IN
      </v-tab>
      <v-tab href="#aba-novos-produtores">
        Novos Produtores
      </v-tab>
      <v-tab href="#aba-suspender">
        Suspenser Produtores
      </v-tab>

      <v-tab href="#aba-produtores-suspensos">
        Produtores Suspensos
      </v-tab>
      <v-tab href="#aba-medias-geometricas">
        Médias Geometricas
      </v-tab>

      <v-tab href="#aba-analise-pae">
        Padrões de Analise PAE
      </v-tab>

      <v-tab-item value="aba-forain">
        <v-card
          color="transparent"
          dark
        >
          <v-card-title class="text-subtitle-2">
            Salvamentos IN 59
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <v-text-field
                  v-model.number="limit59"
                  label="Limite de salvamentos IN 59"
                  placeholder="1"
                  type="number"
                  dense
                  dark
                  outlined
                  hide-details
                  :rules="[v => !v || v >= 0 || 'obrigatório']"
                />
              </v-col>
              <v-col
                cols="12"
                md="9"
                class="text-left"
              >
                Considerações:<br>
                *Se Limite = 0 vai abrir tela para suspender/desvincular produtor. <br>
                *Se Limite > 0 deve ser configurado critérios para visitas e qualidade.
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-radio-group
                  v-model="limit59_analises"
                  label="Se Limite maior que 0, considerar Analises:"
                  dark
                >
                  <v-radio
                    label="Considerar 1 analise simples acima de 300 "
                    value="1"
                    class="mb-0"
                  />
                  <v-radio
                    label="Considerar 1 analise geometrica mensal acima de 300"
                    value="2"
                    class="mb-0"
                  />
                </v-radio-group>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-radio-group
                  v-model="limit59_visitas"
                  label="Se Limite maior que 0, considerar Visitas:"
                  dark
                >
                  <v-radio
                    label="Considerar visita dentro do mês da analise"
                    value="1"
                    class="mb-0"
                  />
                  <v-radio
                    label="Considerar visita no próximo mês da analise"
                    value="2"
                    class="mb-0"
                  />
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-card
              color="transparent"
              dark
            >
              <v-card-title class="text-subtitle-2">
                Média Geometrica
              </v-card-title>
              <v-card-text>
                <v-radio-group
                  v-model="avarage_reactive"
                >
                  <v-radio
                    label="Considerar Media Geometrica Trimestral após terceiro mês de reativação "
                    value="1"
                    class="mb-0"
                  />
                  <v-radio
                    label="Considerar todas as analises dentro do mês (RBQL)"
                    value="2"
                    class="mb-0"
                  />
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item value="aba-produtores-suspensos">
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-card
              color="transparent"
              dark
            >
              <v-card-title class="text-subtitle-2">
                Obrigatóriedade para reingresso produtor após suspensão
              </v-card-title>
              <v-card-text>
                <v-checkbox-group
                  v-model="reactivate_suspended_producer"
                >
                  <v-checkbox
                    label="Obrigatório analise de CPP dentro do padrão (RBQL)"
                    value="1"
                    class="mb-0 mt-0 pa-0"
                  />
                  <v-checkbox
                    label="Obrigatório visita tecnica com recomendação dentro do mês"
                    value="2"
                    class="mb-0 mt-0 pa-0"
                  />
                  <v-checkbox
                    label="Obrigatório aplicação de questionario (PQFL) dentro do mês"
                    value="3"
                    class="mb-0 mt-0 pa-0"
                  />
                  <v-checkbox
                    label="Obrigatório analise de CCS dentro do padrão (RBQL)"
                    value="4"
                    class="mb-0 mt-0 pa-0"
                  />
                </v-checkbox-group>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-card
              color="transparent"
              dark
            >
              <v-card-title class="text-subtitle-2">
                Reativação do produtor Suspenso
              </v-card-title>
              <v-card-text>
                <v-radio-group
                  v-model="reactivate_suspended_producer"
                >
                  <v-radio
                    label="Considerar somente analise dentro do padráo lançado pelo tecnico na reativação "
                    value="1"
                    class="mb-0"
                  />
                  <v-radio
                    label="Considerar todas as analises dentro do mes (RBQL) sem a analise lançada pelo tecnico"
                    value="2"
                    class="mb-0"
                  />
                  <v-radio
                    label="Considerar apenas a analise menor dentro do pradrao (RBQL)"
                    value="3"
                    class="mb-0"
                  />
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item value="aba-suspender">
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-card
              color="transparent"
              dark
            >
              <v-card-title class="text-subtitle-2">
                Configurações para suspender Produtores
              </v-card-title>
              <v-card-text>
                <v-checkbox-group
                  v-model="reactivate_suspended_producer"
                  label=""
                >
                  <v-checkbox
                    label="Obrigatório visita técnica com recomendação dentro do mês"
                    value="1"
                    class="mb-0  mt-0 pa-0"
                  />
                  <v-checkbox
                    label="Obrigatório preenchimento do termo de suspensão assinado pelo produtor"
                    value="2"
                    class="mb-0 mt-0 pa-0"
                  />
                </v-checkbox-group>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item value="aba-novos-produtores">
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-card
              color="transparent"
              dark
            >
              <v-card-title class="text-subtitle-2">
                Configurações para efetivar Novos PRODUTORES
              </v-card-title>
              <v-card-text>
                <v-checkbox-group
                  v-model="reactivate_suspended_producer"
                >
                  <v-checkbox
                    label="Obrigatório analise de CPP dentro do padrão (RBQL)"
                    value="1"
                    class="mb-0 mt-0 pa-0"
                  />
                  <v-checkbox
                    label="Obrigatório visita técnica com recomendação"
                    value="2"
                    class="mb-0 mt-0 pa-0"
                  />
                  <v-checkbox
                    label="Obrigatório aplicação de questionario (PQFL) "
                    value="3"
                    class="mb-0 mt-0 pa-0"
                  />
                  <v-checkbox
                    label="Obrigatório analise de CCS dentro do padrão (RBQL)"
                    value="4"
                    class="mb-0 mt-0 pa-0"
                  />
                  <v-checkbox
                    label="Obrigatório atestado de conformidade brucelose/tuberculose valido"
                    value="5"
                    class="mb-0 mt-0 pa-0"
                  />
                  <v-checkbox
                    label="Obrigatório preenchimento total do Cadastro"
                    value="6"
                    class="mb-0 mt-0 pa-0"
                  />
                </v-checkbox-group>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item value="aba-medias-geometricas">
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-card
              color="transparent"
              dark
            >
              <v-card-title class="text-subtitle-2">
                Padrões de analises de qualidade
              </v-card-title>
              <v-card-text>
                <v-radio-group
                  v-model="reactivate_suspended_producer"
                >
                  <v-radio
                    label="Considerar apenas quando tiver 2 analises geometricas trimestrais consecutivas (a cada 3 meses)"
                    value="1"
                    class="mb-0"
                  />
                  <v-radio
                    label="Considerar apenas quando tiver 2 analises geometricas trimestrais consecutivas (a cada mês)"
                    value="2"
                    class="mb-0"
                  />
                  <v-radio
                    label="Considerar geometricas trimestrais após terceiro mês da reativação "
                    value="3"
                    class="mb-0"
                  />
                  <v-radio
                    label="Obrigatório analise de CCS dentro do padrão (RBQL)"
                    value="4"
                    class="mb-0"
                  />
                  <v-radio
                    label="Obrigatório atestado de conformidade brucelose/tuberculose valido"
                    value="5"
                    class="mb-0"
                  />
                  <v-radio
                    label="Obrigatório preenchimento total do Cadastro"
                    value="6"
                    class="mb-0"
                  />
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-card
              color="transparent"
              dark
            >
              <v-card-title class="text-subtitle-2">
                Considerar para reingresso do produtor
              </v-card-title>
              <v-card-text>
                <v-radio-group
                  v-model="reactivate_suspended_producer"
                >
                  <v-radio
                    label="Obrigatório lançamento do valor da analise dentro do padrão"
                    value="1"
                    class="mb-0"
                  />
                  <v-radio
                    label="Todas as analises dentro do mês"
                    value="2"
                    class="mb-0"
                  />
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item value="aba-analise-pae">
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-card
              color="transparent"
              dark
            >
              <v-card-title class="text-subtitle-2">
                Padrões analises para configuracao do PAE
              </v-card-title>
              <v-card-text>
                <v-radio-group
                  v-model="reactivate_suspended_producer"
                >
                  <v-radio
                    label="Considerar apenas quando tiver 2 analises geometricas trimestrais consecutivas (a cada 3 meses)"
                    value="1"
                    class="mb-0"
                  />
                  <v-radio
                    label="Considerar apenas quando tiver 2 analises geometricas trimestrais consecutivas (a cada mes)"
                    value="2"
                    class="mb-0"
                  />
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="deep-purple darken-1"
        @click="showCreatingAnalysisDialog"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              save
            </v-icon>
          </template>

          Salvar
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>

import moment from "moment-timezone";
import _ from "lodash";

export default {
  components: {

  },

  data() {
    return {
      reactivate_suspended_producer: null,
      limit59: null,

    };

  },

  created() {
  },
  methods: {

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: val => !val ? '-' : new Intl.NumberFormat('pt-BR').format(val),
    parseNumber: val => +String(val) || null,
  },

}
</script>
