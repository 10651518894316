<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-row>
      <v-col cols="12">
        <h2 class="menu-header white--text">
          Pré validação - Análises
        </h2>
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>

      <analysis-import-result
        v-model="importResult.show"
        :successes="importResult.successes"
        :failures="importResult.failures"
        :show-failure-link="false"
      />

      <v-col cols="12">
        <v-card
          dark
          color="transparent"
        >
          <v-card-title>
            <v-row>
              <v-col
                md="3"
                class="py-0"
              >
                <v-date-range-picker
                  v-model="filters.dateRange"
                  dark
                  @change="load"
                />
              </v-col>
              <v-col
                md="3"
                class="py-0"
              >
                <v-select
                  v-model="filters.status"
                  dark
                  filled
                  label="Status"
                  hide-details
                  clearable
                  :items="[
                    { text:'Pendente', value: 'PENDING' },
                    { text:'Erro', value: 'ERROR' },
                    { text:'Processando', value: 'PROCESSING' },
                    { text:'Sucesso', value: 'SUCCESS' },
                  ]"
                  multiple
                  small-chips
                  @change="load"
                />
              </v-col>
              <v-col
                md="3"
                class="py-0"
              >
                <v-select
                  v-model="filters.type"
                  dark
                  filled
                  label="Tipo"
                  hide-details
                  clearable
                  :items="[
                    { text:'Produtor', value:'PRODUCER' },
                    { text:'Rota', value:'ROUTE' },
                    { text:'Silo', value:'silo' },
                    { text:'Controle Leiteiro', value:'DAIRY_CONTROL' }
                  ]"
                  @change="load"
                />
              </v-col>
              <v-col
                md="3"
                class="py-0"
              >
                <v-text-field
                  v-model="filters.search"
                  filled
                  hide-details
                  prepend-inner-icon="search"
                />
              </v-col>
            </v-row>
          </v-card-title>

          <data-table
            ref="report"
            :headers="headers"
            :items="filteredItems"
            class="elevation-1"
            disable-filtering
            multi-sort
            dark
            item-key="id_pre_analise"
            @click:row="edit"
          >
            <template #[`item.status`]="{ value }">
              <v-chip
                x-small
                :color="getStatusColor(value)"
              >
                {{ getStatusDescription(value) }}
              </v-chip>
            </template>

            <template #[`item.compoe_media`]="{ value }">
              <v-chip small>
                {{ value ? 'Sim' : 'Não' }}
              </v-chip>
            </template>

            <template #[`item.dairy.name`]="{ value }">
              <v-chip small>
                {{ value }}
              </v-chip>
            </template>

            <template #[`item.actions`]="{ item }">
              <v-menu>
                <template #activator="{ on }">
                  <v-btn
                    v-if="['PENDING', 'ERROR'].includes(item.status)"
                    dark
                    icon
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>

                <v-list v-if="hasEditAnalisisValidationAccess || hasDeleteAnalisisValidationAccess">
                  <v-list-item
                    v-if="hasEditAnalisisValidationAccess"
                    @click="edit(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>
                        edit
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      Editar
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    v-if="hasDeleteAnalisisValidationAccess"
                    @click="onAnalysisExclude(item)"
                  >
                    <v-list-item-icon>
                      <v-icon>
                        delete
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      Excluir
                    </v-list-item-title>
                  </v-list-item>
                </v-list>

                <v-list v-else>
                  <v-list-item>
                    <v-list-item-title disabled>
                      Sem acesso
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>

            <template #[`footer.prepend`]>
              <div class="text-caption text-left ml-4">
                Total de
                <v-chip
                  small
                  class="mx-2 mb-0"
                >
                  {{ items.length }}
                </v-chip>
                registros
              </div>
            </template>
          </data-table>
        </v-card>
      </v-col>
    </v-row>

    <editing-analysis-dialog
      ref="dialog"
      @onSave="onAnalysisSave"
    />

    <import-excel-dialog
      v-model="importAnalysis.show"
      title="Importar análises"
      :fields="importFields"
      show-model
      @import="onImportAnalysis"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="deep-green darken-1"
        @click="onAnalysisProcess"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              check
            </v-icon>
          </template>

          Concluir
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="deep-purple darken-1"
        @click="showCreatingAnalysisDialog"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>

          Adicionar Analise
        </v-tooltip>
      </v-btn>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="blue"
            v-on="on"
            @click="showImportAnalysis()"
          >
            <v-icon>
              system_update_alt
            </v-icon>
          </v-btn>
        </template>
        Importar Análises
      </v-tooltip>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </v-container>
</template>

<script>
import EditingAnalysisDialog from "@/Domains/Analysis/Producers/Components/EditingAnalysisDialog.vue";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import AnalysisImportMixin from '@/Domains/Analysis/Upload/Mixins/AnalysisImportMixin.js';
import ImportExcelDialog from '@/Support/Components/ImportExcelDialog.vue';
import AnalysisImportResult from '@/Domains/Analysis/Upload/Components/AnalysisImportResult.vue';

import moment from "moment-timezone";
import _ from "lodash";
import isEmpty from "lodash/isEmpty";
import { default as $socket } from "@/Support/Resources/websocket.js";

const Status = {
  'PENDING': {
    description: 'Pendente',
    color: 'blue',
  },
  'ERROR': {
    description: 'Erro',
    color: 'red',
  },
  'PROCESSING': {
    description: 'Processando',
    color: 'deep-purple',
  },
  'SUCCESS': {
    description: 'Sucesso',
    color: 'green',
  },
};

const Types = {
  'PRODUCER': 'Produtor',
  'ROUTE': 'Rota',
  'SILO': 'Silo',
  'DAIRY_CONTROL': 'Controle Leiteiro',
};

export default {

  components: {
    VDateRangePicker,
    EditingAnalysisDialog,
    AnalysisImportResult,
    ImportExcelDialog,
  },
  mixins: [AnalysisImportMixin],

  data() {
    return {
      importType: 'PRODUTOR',

      filters: {
        search: '',
        dateRange: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
        type: null,
        status: [
          'PENDING',
          'ERROR',
        ],
      },

      headers: [
        { text: 'Código Laticínio', value: 'producer.code', align: 'center' },
        { text: 'Unidade', value: 'dairy.name', align: 'start' },
        { text: 'Produtor/Rota/Resfriador', value: 'producer.name', align: 'start' },
        { text: 'Data', value: 'collectedAt', align: 'center', formatter: value => this.formatDate(value, 'DD/MM/YYYY') },
        { text: 'Compõe Média', value: 'compoe_media', align: 'center' },
        { text: 'Tipo', value: 'type', align: 'center', formatter: value => (Types[value]) },
        { text: 'Status', value: 'status', align: 'center', formatter: value => (Status[value].description) },
        { text: 'CCS', value: 'ccs', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'CPP', value: 'cbt', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Caseína', value: 'caseina', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Gordura', value: 'gordura', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Uréia', value: 'ureia', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Lactose', value: 'lactose', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Proteína', value: 'proteina', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Crioscopia', value: 'crioscopia', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'ESD', value: 'esd', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'S.T.', value: 'solidos_totais', align: 'center', formatter: value => this.formatNumber(value) },
        { text: 'Amostra', value: 'numero_amostra', align: 'center' },
        { value: 'actions', sortable: false, align: 'end', width: 20 },
      ],

      items: [],

      // Dialogs
      editing: {
        show: false,
        title: '',
        analysis: {},
      },
    };

  },

  computed: {
    filteredItems() {
      if (!this.filters.search) {
        return this.items;
      }

      const search = typeof this.filters.search === 'string' ? this.filters.search.toUpperCase().trim() : null;
      return this.items.filter(item => {
        const hasSearch = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);
        return hasSearch;
      });
    },

    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    hasEditAnalisisValidationAccess() {
      return this.$store.state.settings.user.id_cargo === 1 || this.userResources.some(o => o.recurso === "edit-analisis-validation" && o.tipo === "COMPONENTE");
    },

    hasDeleteAnalisisValidationAccess() {
      return this.$store.state.settings.user.id_cargo === 1 || this.userResources.some(o => o.recurso === "delete-analisis-validation" && o.tipo === "COMPONENTE");
    },
  },

  methods: {
    showCreatingAnalysisDialog() {
      return this.$refs.dialog.show({
        producer: {},
        analysisAt: moment().format('YYYY-MM-DD'),
        type: 'PRODUCER',
        compoe_media: true,
      });
    },

    async onAnalysisSave(analysis) {
      try {
        this.$root.$progressBar.loading();

        let analysisAt = !_.isEmpty(analysis.analyzedAt) ? moment(analysis.analyzedAt).format('YYYY-MM-DD') : null ;
        let collectedAt = !_.isEmpty(analysis.collectedAt) ? moment(analysis.collectedAt).format('YYYY-MM-DD') : null ;

        const request = {
          id_pre_analise: analysis.id,
          data_coleta: collectedAt,
          data_analise: analysisAt,
          status: 'PENDING',
          id_produtor: analysis.producer.id,
          nome: analysis.producer.name,
          cbt: analysis.cbt,
          ccs: analysis.ccs,
          gordura: analysis.gordura,
          caseina: analysis.caseina,
          ureia: analysis.ureia,
          lactose: analysis.lactose,
          proteina: analysis.proteina,
          esd: analysis.esd,
          solidos_totais: analysis.solidos_totais,
          numero_amostra: analysis.numero_amostra,
          crioscopia: analysis.crioscopia,
          compoe_media: analysis.compoe_media,
          tipo_importacao: 'MANUAL',
        };

        if (analysis.id) {
          await this.$axios.put(`/analysis/pre-check/${analysis.id}`, request);

          this.$refs.dialog.close();

          return this.load();
        }

        await this.$axios.post(`/analysis/pre-check`, {
          ...request,
          tipo: 'PRODUCER',
        });

        this.$refs.dialog.close();

        return this.load();
      } catch (e) {
        console.log(e);

        this.$snotify.error("Oops, ocorreu um erro ao salvar a analise!", "Atenção");
      } finally {
        this.$refs.dialog.close();

        this.$root.$progressBar.hide();
      }
    },

    async onAnalysisExclude(analysis) {
      try {
        if (!(await this.$root.$confirm(
          'Atenção',
          `Deseja realmente excluir a amostra <b>${analysis.numero_amostra}</b>?<br><br>Esta ação não poderá ser revertida!<br><br>`,
          { color: 'red', token: 'EXCLUIR' }
        ))
        ) {
          return;
        }

        this.$root.$progressBar.loading();

        await this.$axios.delete(`/analysis/pre-check/${analysis.id}`);

        this.$refs.dialog.close();

        await this.load();
      } catch (e) {
        console.log(e);

        this.$snotify.error("Oops, ocorreu um erro ao salvar a analise!", "Atenção");
      } finally {
        this.$refs.dialog.close();

        this.$root.$progressBar.hide();
      }
    },

    async load() {
      try {
        this.$root.$progressBar.loading();

        const [dateStart, dateEnd] = this.filters.dateRange;

        let { data } = await this.$axios.get(`/analysis/pre-check`, {
          params: {
            start_date: dateStart,
            end_date: dateEnd,
            status: this.filters.status,
            type: this.filters.type,
          }
        });

        this.items = data.map(row => {
          return {
            id: row.id_pre_analise,
            settings: {
              id: row.id_laticinio_laboratorio,
            },
            producer: {
              id: row.id_produtor,
              name: row.nome,
              code: row.codigo_laticinio,
            },
            dairy: {
              id: row.id_laticinio,
              name: row.nome_laticinio,
            },
            analyzedAt: row.data_analise,
            collectedAt: row.data_coleta,
            status: row.status,
            type: row.tipo,
            caseina: row.caseina,
            cbt: row.cbt,
            ccs: row.ccs,
            crioscopia: row.crioscopia,
            esd: row.esd,
            gordura: row.gordura,
            lactose: row.lactose,
            numero_amostra: row.numero_amostra,
            proteina: row.proteina,
            solidos_totais: row.solidos_totais,
            ureia: row.ureia,
            compoe_media: row.compoe_media,
          }
        });
      } catch (error) {
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async onAnalysisProcess() {
      try {
        this.$root.$progressBar.loading();

        const invalidAnalysis = this.items.filter(({ type, producer }) => {
          return type === 'PRODUCER' && !producer.id;
        });

        if (!_.isEmpty(invalidAnalysis)) {
          this.$snotify.error("Oops, Existem analises sem produtor vinculado! Verifique-as e tente novamente.", "Atenção");

          return;
        }

        const analysis = this.items.map(item => {
          return {
            id_pre_analise: item.id,
            id_laticinio_laboratorio: item.settings.id,
            id_produtor: item.producer.id,
            nome: item.producer.name,
            codigo_laticinio: item.producer.code,
            data_analise: item.analyzedAt,
            data_coleta: item.collectedAt,
            status: item.status,
            tipo: item.type,
            caseina: item.caseina,
            cbt: item.cbt,
            ccs: item.ccs,
            crioscopia: item.crioscopia,
            esd: item.esd,
            gordura: item.gordura,
            lactose: item.lactose,
            numero_amostra: item.numero_amostra,
            proteina: item.proteina,
            solidos_totais: item.solidos_totais,
            ureia: item.ureia,
            compoe_media: item.compoe_media,
          }
        });

        await this.$axios.put(`/analysis/pre-check/process`, analysis);

        return this.load();
      } catch (error) {
        console.warn(error);

      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async onImportAnalysis({ data, fields }) {
      try {
        this.$root.$progressBar.loading();

        this.importAnalysis.show = false;

        this.saveLastImportedFields(fields);

        const analysis = data.map(item => {
          const collectFormat = (item['Data de Coleta'] || '').includes('/') ? 'DD/MM/YYYY' : 'YYYY-MM-DD';
          const analysisFormat = (item['Data de Análise'] || '').includes('/') ? 'DD/MM/YYYY' : 'YYYY-MM-DD';

          const collectedAt = !isEmpty(item['Data de Coleta']) ? moment.tz(item['Data de Coleta'], collectFormat, 'America/Sao_Paulo').format('YYYY-MM-DD') : null;
          const analyzedAt = !isEmpty(item['Data de Análise']) ? moment.tz(item['Data de Análise'], analysisFormat, 'America/Sao_Paulo').format('YYYY-MM-DD') : null;

          return {
            data_coleta: collectedAt,
            data_analise: analyzedAt,
            numero_amostra: item['Número Amostra'],
            tipo: this.importType || item['Tipo'],
            codigo_laticinio: item['Código Latícinio'],
            cpf: String(item['CPF']).replace(/\D/g, '') || null,
            nome: item['Nome'],
            gordura: this.parseNumeric(item['Gordura (g/100g)']),
            proteina: this.parseNumeric(item['Proteína (g/100g)']),
            lactose: this.parseNumeric(item['Lactose (g/100g)']),
            solidos_totais: this.parseNumeric(item['Sólidos Totais (g/100g)']),
            esd: this.parseNumeric(item['ESD (g/100g)']),
            ccs: this.parseNumeric(item['CCS (x1000 cél/mL)']),
            cpp: this.parseNumeric(item['CPP (x1000 UFC/mL)']),
            ureia: this.parseNumeric(item['Uréia (mg/dL)']),
            caseina: this.parseNumeric(item['Caseína (g/100g)']),
          }
        });

        const data = await $socket.proxy({
          method: 'post',
          url: '/analysis/import',
          data: {
            analysis
          },
        });

        console.log('response na volta do back: ', data);

        // if (!response.codigo) {
        //   throw new Error(response.mensagem || response);
        // }

        // if (response.falhas === 0) {
        //   this.$snotify.success("Análises importadas com successo!");
        // }

        // this.importResult.successes = response.sucessos;
        // this.importResult.failures = response.falhas;
        this.importResult.show = true

      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao importar!", "Atenção");

        console.log(error);
      } finally {
        this.$root.$progressBar.hide();
        await this.load();
      }
    },

    getReportTitle() {
      const [startDate, endDate] = this.filters.dateRange;
      return `Análises - ${moment(startDate || moment()).format('DD.MM')} - ${moment(endDate || moment()).format('DD.MM')}`;
    },

    edit(row) {
      if (!this.hasEditAnalisisValidationAccess) {
        return;
      }

      if (['PENDING', 'ERROR'].includes(row.status)) {
        return;
      }

      return this.$refs.dialog.show(_.cloneDeep(row));
    },

    getStatusColor(status) {
      return Status[status].color;
    },

    getStatusDescription(status) {
      return Status[status].description;
    },

    print() {
      const title = this.getReportTitle();
      this.$refs.report.print(null, title);
    },

    exportExcel() {
      const filename = this.getReportTitle();
      this.$refs.report.exportExcel(null, filename);
    },

    formatDate: (value, format) => !value ? '-' : moment(value).format(format),
    formatNumber: val => !val ? '-' : new Intl.NumberFormat('pt-BR').format(val),
    parseNumber: val => +String(val) || null,
  },

}
</script>
