<template>
  <div>
    <v-row>
      <v-col
        cols="5"
        sm
        class="py-0"
      >
        <span class="headline">PESO BRUTO</span>
        <masked-text-field
          ref="grossWeightRef"
          v-model="form.grossWeight"
          suffix="Kg"
          :mask="masks.float"
          unmask
          inputmode="numeric"
          solo
          x-large
          height="48"
          dark
          :background-color="!hideTare ? 'black' : scale.color"
          class="scale-input"
          :rules="[v => !!v && form.grossWeight > 0 || 'Informe o Peso Bruto!']"
          validate-on-blur
          @drop.prevent
          @paste.prevent
          @keypress="(e) => !hasManualWeighingPermission && e.preventDefault()"
          @keyup.enter="save()"
        />
      </v-col>
      <v-col
        v-if="!hideTare"
        class="py-0"
      >
        <span class="headline">TARA PRIMÁRIA</span>
        <masked-text-field
          v-model="form.tareWeight1"
          suffix="Kg"
          :mask="masks.float"
          unmask
          inputmode="numeric"
          solo
          x-large
          height="48"
          dark
          background-color="grey"
          class="scale-input"
        />
      </v-col>
      <v-col
        v-if="!hideTare"
        class="py-0"
      >
        <span class="headline">TARA SECUNDÁRIA</span>
        <masked-text-field
          v-model="form.tareWeight2"
          suffix="Kg"
          :mask="masks.float"
          unmask
          inputmode="numeric"
          solo
          x-large
          height="48"
          dark
          background-color="grey"
          class="scale-input"
        />
      </v-col>
      <v-col
        v-if="!hideTare"
        cols="5"
        sm
        class="py-0"
      >
        <span class="headline">PESO LÍQUIDO</span>
        <masked-text-field
          :value="netWeight"
          suffix="Kg"
          :mask="masks.floatSigned"
          unmask
          inputmode="numeric"
          solo
          x-large
          height="48"
          dark
          :background-color="netWeight < 0 ? 'red darken-4' : scale.color"
          readonly
          class="scale-input"
        />
      </v-col>
      <v-col
        v-if="!autoWeighing && !hideSave"
        class="py-0 d-flex align-center"
      >
        <v-btn
          color="blue"
          outlined
          text
          x-large
          block
          style="height: 48px;margin-top: 2px;"
          @click="save"
        >
          Adicionar
        </v-btn>
      </v-col>
    </v-row>
    <div class="d-flex">
      <div v-if="autoWeighing">
        Status Balança:
        <v-chip
          :color="scaleStatus.color"
          label
          small
        >
          {{ scaleStatus.status }}
        </v-chip>
        <span v-if="scaleStatus.message">
          {{ scaleStatus.message }}
        </span>
      </div>
      <v-spacer />
      <slot name="append" />
    </div>
  </div>
</template>

<style lang="scss">
.scale-input input {
  font-size: 18px;
}

.scale-input.v-input--is-readonly input {
  cursor: default;
}
</style>

<script setup>
import { ref, reactive, computed, watch, onMounted, onUnmounted } from 'vue'
import { usePermissions } from '@/Support/Composables/permissions.js'
import { useUtils } from '@/Support/Composables/utils.js'

import debounce from 'lodash/debounce'

import MaskedTextField from '@/Support/Components/MaskedTextField.vue'

import { usePacking } from '@/Domains/Shipment/Composable/Packing.js'
import { useLocalWebSocket } from '@/Domains/Shipment/Composable/Socket.js'

const { notify } = useUtils()

// eslint-disable-next-line no-undef
const props = defineProps({
  value: {
    type: Object,
    default: () => ({
      grossWeight: null,
      tareWeight1: null,
      tareWeight2: null,
    }),
  },
  settingsId: {
    type: String,
    default: 'WEIGHING'
  },
  hideTare: Boolean,
  hideSave: Boolean,
  hasWeighingInputSelected: Boolean,
})

const { scaleId, packingSettings } = usePacking(props.settingsId)

const { websocket } = useLocalWebSocket(packingSettings)

const { hasPermission } = usePermissions()

const hasManualWeighingPermission = computed(() => hasPermission('manual-weighing'))

const autoWeighing = computed(() => packingSettings.value.autoWeighing || !hasManualWeighingPermission.value)

// eslint-disable-next-line no-undef
const emit = defineEmits([
  'weighing',
  'save'
])

const grossWeightRef = ref()

const masks = {
  float: { mask: Number, min: 0, scale: 3, thousandsSeparator: '.', radix: ',' },
  floatSigned: { mask: Number, scale: 3, thousandsSeparator: '.', radix: ',', signed: true },
}

const form = computed({
  get: () => props.value,
  set: (value) => emit('input', value),
})

const netWeight = computed(() => parseFloat(form.value.grossWeight || 0) - parseFloat(form.value.tareWeight1 || 0) - parseFloat(form.value.tareWeight2 || 0))

const save = async () => {
  emit('save')
}

const scale = reactive({
  id: null,
  started: false,
  color: 'secondary',
})

const isSocketConnected = computed(() => websocket.value?.connected || false)

watch(isSocketConnected, (connected) => {
  if (connected) {
    startWeighingScale()
  } else {
    stopWeighingScale()
  }
})

onMounted(() => startWeighingScale())

onUnmounted(() => stopWeighingScale())

// Refresh scale weight
watch(() => packingSettings.value.scaleDelay, () => {
  const io = websocket.value
  const id = scale.id
  if (!io || !id) {
    return
  }

  io.off('weight', onWeighing);
  io.on('weight', onWeighing);
})

const onWeighing = ({ weight, id }) => {
  const { palletScale, packingScale } = packingSettings.value

  if (props.hasWeighingInputSelected && (!id || id === palletScale)) {
    return emit('weighing', weight)
  }

  if (id && id !== packingScale) {
    return
  }

  form.value.grossWeight = weight

  if (!weight) {
    lastStableWeight.value = 0
  }

  scale.color = 'yellow darken-2'
  onWeighingFinished.value()
}

const lastStableWeight = ref(0)

const onWeighingAction = () => {
  const grossWeight = form.value.grossWeight || 0
  scale.color = grossWeight ? 'green lighten-1' : 'secondary'
  grossWeightRef.value?.resetValidation()

  const minBoxWeight = packingSettings.value.minBoxWeight || 0
  if (autoWeighing.value && grossWeight && grossWeight >= minBoxWeight && lastStableWeight.value === 0) {
    lastStableWeight.value = grossWeight
    save()
  } else if (!grossWeight) {
    lastStableWeight.value = 0
  }
}

const onWeighingFinished = ref(null);

watch(
  () => packingSettings.value.scaleDelay,
  (newDelay) => {
    onWeighingFinished.value = debounce(onWeighingAction, (newDelay || 2) * 1000);
  },
  { immediate: true }
)

const scaleStatus = ref({
  color: 'secondary',
  status: 'PENDENTE',
  message: null,
})

watch(() => packingSettings.value.packingScale, () => checkUserIntegrator())

const checkUserIntegrator = () => {
  const io = websocket.value
  const id = scaleId.value
  if (!io || !id) {
    return
  }

  io.emit('check-user-integrator', id)
}

const onUserIntegratorStatus = (integrator) => {
  const scale = integrator[packingSettings.value.packingScale] || {}

  if (!integrator.connected || !scale.active) {
    scaleStatus.value = {
      color: 'warning',
      status: !integrator.connected ? 'INTEGRADOR NÃO CONECTADO' : 'INTEGRAÇÃO NÃO ATIVADA',
    }
  } else if (scale.connected) {
    scaleStatus.value = {
      color: 'success',
      status: 'CONECTADO',
      message: null,
    }
  } else {
    scaleStatus.value = {
      color: 'error',
      status: 'DESCONECTADO',
      message: scale.message,
    }
  }
}

const onUserNotification = ({ type, message }) => {
  // if (type === 'SUCCESS') {
  //   return notify.success(message)
  // }

  if (type === 'ERROR') {
    return notify.error(message)
  }
}

const startWeighingScale = () => {
  const io = websocket.value
  const id = scaleId.value
  if (!io || !id || !isSocketConnected.value) {
    return
  }

  if (scale.started && scale.id === id) {
    return
  }

  if (scale.started && scale.id && scale.id !== id) {
    console.log('Weighing scale changed')
    io.emit('leave', scale.id)
  }

  console.log(`Weighing scale started: ${id}`)

  io.emit('join', id)

  if (!scale.started) {
    io.on('weight', onWeighing);
    io.on('user-integrator-status', onUserIntegratorStatus);
    io.on('user-notification', onUserNotification);
  }

  checkUserIntegrator()

  scale.started = true
  scale.id = id
}

const stopWeighingScale = () => {
  scale.started = false

  scaleStatus.value = {
    color: 'secondary',
    status: 'PENDENTE',
    message: null,
  }

  const io = websocket.value
  const id = scale.id
  if (!io || !id) {
    return
  }

  console.log(`Weighing scale stopped: ${id}`)

  io.emit('leave', id)
  io.off('weight', onWeighing);
  io.off('user-integrator-status', onUserIntegratorStatus);
  io.off('user-notification', onUserNotification);
}

// eslint-disable-next-line no-undef
defineExpose({ save })
</script>
