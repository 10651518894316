<template>
  <v-dialog
    v-model="dialog"
    width="480"
  >
    <v-card>
      <v-card-title>
        Atualizar Pagamento
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="4">
              <v-select
                v-model="form.installment"
                prepend-inner-icon="iso"
                label="Parcela"
                :items="installments"
                item-text="description"
                item-value="index"
                :rules="[rules.required]"
                return-object
                @change="onInstallmentChange"
              />
            </v-col>

            <v-col cols="4">
              <masked-text-field
                v-model="form.dueDate"
                label="Vencimento"
                prepend-inner-icon="event"
                placeholder="00/00/0000"
                :mask="{ mask: '00/00/0000' }"
                :rules="[rules.required, rules.date]"
              />
            </v-col>

            <v-col cols="4">
              <v-switch
                v-model="form.paid"
                :label="paidSwitchLabel"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="grey darken-1"
          outlined
          @click="close()"
        >
          Cancelar
        </v-btn>

        <v-spacer />

        <v-btn
          color="blue darken-1"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";

export default {
  components: {
    MaskedTextField
  },

  props: {
    settings: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      dialog: false,

      loading: false,

      form: {
        installment: {},

        dueDate: '',

        paid: true,
      },

      rules: {
        required: v => !!v || 'Campo obrigatório!',
        date: v =>  this.isDateValid(v, 'DD/MM/YYYY') || 'Data Inválida!',
      },
    };
  },

  computed: {
    installments() {
      if (isEmpty(this.settings)) {
        return [];
      }

      return this.settings.installments.map((installment) => {
        return {
          ...installment,
          description: `Parcela ${installment.index}`,
        }
      });
    },

    paidSwitchLabel() {
      return this.form.paid ? 'Pago' : 'Em aberto';
    },
  },

  methods: {
    show() {
      this.dialog = true;
    },

    close() {
      this.dialog = false;

      this.form = {
        installmentMethod: {},

        dueDate: '',
      }
    },

    onInstallmentChange(installment) {
      const currentYear = moment().year();

      this.form.dueDate = moment(installment.date, 'DD/MM')
        .set({ 'year': currentYear })
        .format('DD/MM/YYYY')
    },

    isDateValid(value, format) {
      if (!value || value.length !== format.length) {
        return false;
      }

      return moment(value, format).isValid();
    },

    async save() {
      try {
        if (!await this.$refs.form.validate()) {
          return;
        }

        this.$emit('save', this.form);

        return this.close();
      } catch (e) {

        console.error(e);
      }
    }
  }
};
</script>
