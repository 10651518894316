<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="orders-list"
  >
    <ComercialOrderListTable
      ref="orderListTable"
      :status="props.status"
      :selected.sync="selectedToDispatch"
      show-select
      show-expand
      @dblclick:row="showOrder"
    >
      <template #[`item.sellers`]="{ value }">
        <v-chip
          v-for="seller in value"
          small
        >
          {{ seller.name }}
        </v-chip>
      </template>

      <template #[`item.discount`]="{ item }">
          <span v-if="item.discount">
            {{ formatCurrency(item.discount) }}
            <small>
              ({{ formatNumber(item.discountPerc) }} %)
            </small>
          </span>
      </template>

      <template #[`item.action`]="{ item }">
        <v-menu
          bottom
          right
          min-width="200"
        >
          <template #activator="{ on }">
            <v-btn
              icon
              v-on="on"
            >
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              @click="showOrder(item)"
            >
              <v-list-item-icon>
                <v-icon>edit</v-icon>
              </v-list-item-icon>

              <v-list-item-title>Editar Pedido</v-list-item-title>
            </v-list-item>

            <div v-if="actions.length > 0">
              <v-subheader>
                Ações
              </v-subheader>

              <v-divider />

              <v-list-item
                v-for="action in actions"
                :key="action.id"
                @click="onActionClick(action, item)"
              >
                <v-list-item-icon>
                  <v-icon>{{ action.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-title>{{ action.label }}</v-list-item-title>
              </v-list-item>
            </div>
          </v-list>
        </v-menu>
      </template>

      <template #expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          class="pa-0 text-center"
        >
          <v-container>
            <v-row>
              <v-col cols="12">
                <template
                  v-for="(product, idx) of item.products"
                >
                  <v-row
                    :key="idx"
                  >
                    <v-col
                      cols="3"
                    >
                      <person-autocomplete-filter
                        v-model="product.supplier"
                        label="Fornecedor"
                        type="DAIRY"
                        :filled="false"
                        prepend-inner-icon="add_business"
                        persistent-hint
                        hide-details
                        disabled
                      />
                    </v-col>
                    <v-col
                      cols="4"
                    >
                      <v-text-field
                        :value="product.item.name"
                        label="Item"
                        prepend-inner-icon="reorder"
                        readonly
                        persistent-hint
                        hide-details
                        disabled
                      />
                    </v-col>
                    <v-col
                      cols="2"
                    >
                      <masked-text-field
                        v-model="product.quantity"
                        label="Quantidade"
                        prepend-inner-icon="iso"
                        persistent-hint
                        hide-details
                        unmask
                        :mask="{ mask: Number, min: 0 }"
                        disabled
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-select
                        v-model="product.shippingFormat"
                        label="Formato"
                        placeholder=" "
                        hide-details
                        disabled
                        :items="[
                            { text: 'Caixa Solta', value: 'CAIXA_SOLTA' },
                            { text: 'Paletizado', value: 'PALLET' },
                          ]"
                      />
                    </v-col>
                  </v-row>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </td>
      </template>
    </ComercialOrderListTable>

    <v-card
      class="report-card"
      color="transparent"
      dark
    >
      <v-card-title
        class="py- d-flex"
      >
        <div>
          <v-icon class="mr-3">send</v-icon> Montagem da Carga
        </div>

        <v-spacer />

        <v-btn
          outlined
          large
          dark
          :disabled="!orderedDispatches.length"
          @click="concludeShipmentPlanning"
        >
          Fechar Carga
        </v-btn>
      </v-card-title>

      <data-table
        ref="selected-to-dispatch"
        :headers="planningHeaders"
        :items.sync="orderedDispatches"
        :expanded="dispatchingExpanded"
        name="Montagem de Carga Expedição"
        class="elevation-1"
        no-data-text="Selecione pedidos para planejar a entrega"
        no-data-text-class="text-subtitle-1 pa-8"
        dark
        dense
        disable-pagination
        hide-default-footer
        draggable-rows
        show-expand
      >
        <template #[`item.sellers`]="{ value }">
          <v-chip
            v-for="seller in value"
            small
          >
            {{ seller.name }}
          </v-chip>
        </template>

        <template #[`item.discount`]="{ item }">
          <span v-if="item.discount">
            {{ formatCurrency(item.discount) }}
            <small>
              ({{ formatNumber(item.discountPerc) }} %)
            </small>
          </span>
        </template>

        <template #[`item.action`]="{ item }">
          <v-menu
            bottom
            right
            min-width="200"
          >
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                @click="showOrder(item)"
              >
                <v-list-item-icon>
                  <v-icon>edit</v-icon>
                </v-list-item-icon>

                <v-list-item-title>Editar Pedido</v-list-item-title>
              </v-list-item>

              <div v-if="actions.length > 0">
                <v-subheader>
                  Ações
                </v-subheader>

                <v-divider />

                <v-list-item
                  v-for="action in actions"
                  :key="action.id"
                  @click="onActionClick(action, item)"
                >
                  <v-list-item-icon>
                    <v-icon>{{ action.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title>{{ action.label }}</v-list-item-title>
                </v-list-item>
              </div>
            </v-list>
          </v-menu>
        </template>

        <template #expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
            class="pa-0 text-center"
          >
            <v-container>
              <v-row>
                <v-col cols="12">
                  <template
                    v-for="(item, idx) of item.items"
                  >
                    <v-row
                      :key="idx"
                    >
                      <v-col
                        cols="3"
                      >
                        <person-autocomplete-filter
                          v-model="item.supplier"
                          label="Fornecedor"
                          type="DAIRY"
                          :filled="false"
                          prepend-inner-icon="add_business"
                          persistent-hint
                          hide-details
                          disabled
                        />
                      </v-col>
                      <v-col
                        cols="4"
                      >
                        <v-text-field
                          :value="item.name"
                          label="Item"
                          prepend-inner-icon="reorder"
                          readonly
                          persistent-hint
                          hide-details
                          disabled
                        />
                      </v-col>
                      <v-col
                        cols="2"
                      >
                        <masked-text-field
                          v-model="item.quantity"
                          label="Quantidade"
                          prepend-inner-icon="iso"
                          persistent-hint
                          hide-details
                          unmask
                          :mask="{ mask: Number, min: 0 }"
                          disabled
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-select
                          v-model="item.shippingFormat"
                          label="Formato"
                          placeholder=" "
                          hide-details
                          disabled
                          :items="[
                            { text: 'Caixa Solta', value: 'CAIXA_SOLTA' },
                            { text: 'Paletizado', value: 'PALLET' },
                          ]"
                        />
                      </v-col>
                    </v-row>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </td>
        </template>
      </data-table>
    </v-card>

    <v-row
      class="text-left mt-3"
    >
      <v-col
        v-for="({ supplier, products }, index) in plannedShipmentProductsBySupplier"
        :key="index"
        cols="4"
      >
        <v-list
          style="background: rgba(0,0,0,0.4);"
          class="mb-3"
          dark
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                {{ supplier.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list
          v-for="(product, index) in products"
          :key="index"
          three-line
          dark
          style="background: rgba(0,0,0,0.4);"
          class="mb-3"
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="mb-2">
              {{ product.item.code }} - {{ product.item.name }}
              </v-list-item-title>

              <v-divider class="my-4" />

              <v-list-item-subtitle class="d-flex flex-row justify-space-between align-center">
                <span>{{ formatNumber(product.currentStock || 0, 0) }} {{ product.measurement }} Disponível</span>
                <v-spacer />
                <span>Peso Total: {{ formatNumber(product.grossWeight || 0, 0) }} KG</span>
                <span>&nbsp;({{ formatNumber(product.realQuantity || 0, 0) }} {{ product.realMeasurement }})</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <OrderApprovalDialog
      ref="comercialApprovalDialog"
      @save="fetch"
    />

    <OrderCancelDialog
      ref="comercialCancelOrderDialog"
      @save="fetch"
    />

    <OrderChangeDialog
      ref="comercialChangeStatusDialog"
      @save="fetch"
    />

    <OrdersShipmentDialog
      ref="comercialOrdersShipmentDialog"
      @saved="fetch"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="blue"
            @click="showOrder({id: null})"
            v-on="on"
          >
            <v-icon>
              add
            </v-icon>
          </v-btn>
        </template>
        Novo Pedido
      </v-tooltip>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="green darken-1"
            @click="exportExcel"
            v-on="on"
          >
            <v-icon>
              backup_table
            </v-icon>
          </v-btn>
        </template>
        Download (Excel)
      </v-tooltip>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="orange darken-1"
            @click="print"
            v-on="on"
          >
            <v-icon>
              print
            </v-icon>
          </v-btn>
        </template>
        Imprimir
      </v-tooltip>
    </v-speed-dial>

    <bill-dialog
      v-model="billDialog.show"
      :bill-id="billDialog.id"
      type="CONTA_A_RECEBER"
      @input="billDialog.id = null"
    />

    <order-dialog
      ref="orderDialog"
      @close="fetch()"
    />
  </v-container>
</template>

<style lang="scss">
.orders-list {
  .financial-badge {
    font-size: 16px;
    position: absolute;
    top: -10px;
    right: 0px;
  }

  .report-card {
    margin-top: 5px !important;

    > .v-card__title{
      background: rgba(0, 0, 0, 0.5);
      color: rgba(255, 255, 255, 0.7);;
      font-size: 1.1rem;
      align-items: baseline;
    }
  }

  .report-table {
    background: transparent !important;

    table {
      thead > tr {
        background: rgba(0, 0, 0, 0.5);
      }

      tbody {
        background: rgba(255, 255, 255, 0.08);
        color: white !important;

        tr:hover {
          background: rgba(0, 0, 0, 0.2) !important;
        }
      }
    }

    .v-data-footer {
      background: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>

<script setup>
import { computed, reactive, ref, onBeforeMount, onMounted, watch, getCurrentInstance, provide } from "vue";

import isEmpty from "lodash/fp/isEmpty";
import sumBy from "lodash/fp/sumBy";
import groupBy from "lodash/groupBy";
import mapValues from "lodash/mapValues";
import values from "lodash/values";
import get from "lodash/get";
import first from "lodash/first";
import isArray from "lodash/fp/isArray";
import debounce from "lodash/debounce";

import moment from "moment-timezone";

import { useStore } from '@/Support/Resources/vuex.js';
import { useOrders } from "@/Domains/Orders/Composable/Orders";
import axios from '@/Support/Resources/axios-instance.js'

import BillDialog from '@/Domains/Financial/Components/BillDialog.vue';
import ApprovalChip from '@/Domains/Orders/Components/ApprovalChip.vue';
import OrderApprovalDialog from '@/Domains/Orders/Components/Dialogs/OrderActions/OrderApprovalDialog.vue';
import OrderCancelDialog from '@/Domains/Orders/Components/Dialogs/OrderActions/OrderCancelDialog.vue';
import OrderChangeDialog from '@/Domains/Orders/Components/Dialogs/OrderActions/OrderChangeDialog.vue';
import OrderDialog from '@/Domains/Orders/Components/Dialogs/OrderDialog.vue';
import OrdersShipmentDialog from '@/Domains/Orders/Components/Dialogs/OrdersShipmentDialog.vue';

import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import DateTimeTextField from "@/Support/Components/DateTimeTextField.vue";

import ComercialOrderListTable from "@/Domains/Orders/Components/Tables/ComercialOrderListTable.vue";
import { sum } from "lodash/math";

moment.locale('pt');

const props = defineProps({
  status: String,
  statusIn: Array,
  statusNotIn: Array,
  settings: Object,
  workflow: Array,
});

const { proxy: { $snotify, $root } } = getCurrentInstance();

const store = useStore();
const orders = useOrders();

const orderListTable = ref(null);

const orderDialog = ref(null);
const comercialApprovalDialog = ref(null);
const comercialCancelOrderDialog = ref(null);
const comercialChangeStatusDialog = ref(null);
const comercialOrdersShipmentDialog = ref(null);

const planningHeaders = ref([
  { text: 'Ordem Entrega', value: 'sequence' },
  { text: 'Código', value: 'code' },
  { text: 'N. Pedido de Compra', value: 'purchaseOrderCode' },
  { text: 'Data de Cadastro', value: 'createdAt', formatter: value => dateFormat(value, 'DD/MM/YYYY HH:mm') },
  { text: 'Previsão de Entrega', value: 'scheduledTo', formatter: value => value ? dateFormat(value, 'DD/MM/YYYY HH:mm') : 'Imediata' },
  { text: 'Cod. Cliente', value: 'customer.code' },
  { text: 'CPF/CNPJ Cliente', value: 'customer.federalTaxNumber' },
  { text: 'Cliente', value: 'customer.name' },
  { text: 'Representante(s)', value: 'sellers' },
  { text: 'Estado Entrega', value: 'customer.state' },
  { text: 'Cidade Entrega', value: 'customer.city.name' },
]);

const selectedToDispatch = ref([]);
const currentItemsStocks = ref([]);
const dispatchingExpanded = ref([]);

const billDialog = reactive({
  show: false,
  id: null,
});

const commercialProfiles = ref({});

// Permissions
const settings = computed(() => {
  return store.state.settings || {};
});

const userResources = computed(() => {
  return settings.value.recursosUsuario || [];
});

const userProfile = computed(() => {
  return settings.value.user || {};
});

const isAdmin = computed(() => {
  return settings.value.tipoAcesso === 'admin' || settings.value.user.id_cargo === 1;
});

const hasFinancialAccess = computed(() => {
  return isAdmin.value || userResources.value.some(o => o.recurso === "orders-generate-financial" && o.tipo === "COMPONENTE");
});

const hasApprovalAccess = computed(() => {
  return isAdmin.value || (userProfile.value.id_cargo === 13 && !!userProfile.value.id_perfil_comercial);
});

const plannedShipmentProducts = computed(() => {
  return selectedToDispatch.value.flatMap(order => order.products);
});

const plannedShipmentProductsWithStocks = computed(() => {
  return plannedShipmentProducts.value.map(product => {
    const currentStock = currentItemsStocks.value.find(stock => stock.itemId === product.item.itemId && stock.supplierId === product.supplier.id);
    const stock = currentStock?.availableQuantity || 0;

    // Se o produto é vendido por kilo, não é feito conversão pelo fator
    if (product.item.saleByKilo || !product.item.conversionFactor) {
      return {
        ...product,
        currentStock: stock,
      }
    }

    return {
      ...product,
      currentStock: parseInt((stock / product.item.conversionFactor)) || 0,
    }
  });
});

const plannedShipmentProductsBySupplier = computed(() => {
  const productsBySupplier = groupBy(plannedShipmentProductsWithStocks.value, 'supplier.id');

  return values(mapValues(productsBySupplier, (productBySupplier) => {
    const supplier = get(first(productBySupplier), 'supplier');
    const items = groupBy(productBySupplier, 'item.itemId');

    const products = values(mapValues(items, (itemsFromSupplier) => {
      const product = first(itemsFromSupplier);

      return {
        ...product,
        grossWeight: sumBy(product => product.quantity, itemsFromSupplier),
      }
    }));

    return {
      supplier,
      products,
    }
  }));
});

const actions = computed(() => {
  return props.settings.actions.map(action => {
    return {
      ...action,
      newStatus: props.workflow.find(({ id }) => id === action.newStatusId),
    }
  });
});

const orderedDispatches = computed({
  get() {
    return selectedToDispatch.value.map((order, index) => ({...order, sequence: index + 1}));
  },
  set(newValue) {
    selectedToDispatch.value = newValue;
  },
});

watch(
  orderedDispatches,
  () => {
    // return orderDialog.value.show(orderedDispatches.value[0].id);
    // return comercialOrdersShipmentDialog.value.show(orderedDispatches.value);
  },
  { deep: true }
);

watch(
  selectedToDispatch,
  () => {
    return loadItemsActualStocks();
  },
  { deep: true }
);

onBeforeMount(async () => {
  // await getCommercialProfiles();
});

onMounted(() => {
  return fetch();
});

async function fetch() {
  selectedToDispatch.value = [];

  return orderListTable.value.fetch();
}

async function generateFinancial({ id }) {
  try {
    if (!hasFinancialAccess.value) {
      $snotify.info("Você não possui permissão para gerar esta conta", "Aviso");

      return;
    }

    orders.loading.value = true;

    let { data } = await axios.post(`/movimento/gerarFinanceiro`, { id_movimento: id });

    if (data.codigo !== 1) {
      throw new Error(data.mensagem);
    }

    $snotify.success("Financeiro gerado com sucesso", "Sucesso");
  } catch (error) {
    $snotify.error("Erro ao gerar financeiro", "Atenção");

    console.warn(error);
  } finally {
    orders.loading.value = false;

    await fetch();
  }
}

// async function getCommercialProfiles() {
//   try {
//     orders.loading.value = true;
//
//     const { data } = await axios.post(`/comercial/perfisComercial`);
//
//     commercialProfiles.value = data.reduce((acc, cur) => ({
//       ...acc,
//       [cur.id_perfil_comercial]: {
//         id: cur.id_perfil_comercial,
//         name: cur.nome,
//         description: `${cur.nome} | ${parseInt(cur.percentual_maximo)}%`,
//         percent: parseInt(cur.percentual_maximo)
//       }
//     }), {});
//   } catch (error) {
//     $snotify.error("Erro ao carregar os perfis comerciais", "Atenção");
//
//     console.warn(error);
//   } finally {
//     orders.loading.value = false;
//   }
// }

function getStatusColor(value) {
  const statusColors = {
    'PEDIDO': 'blue',
    'ORCAMENTO': 'orange darken-1',
    'VENDA': 'green darken-1',
  }

  return statusColors[value];
}

function canApprovalPercent(item) {
  if (!hasApprovalAccess.value) {
    return false;
  }

  if (isAdmin.value) {
    return true;
  }

  const profile = commercialProfiles.value[userProfile.value.id_perfil_comercial] || {};

  return item.discountPerc <= profile.percent;
}

function onActionClick(action, order) {
  switch (action.action) {
    case 'APROVAR_PEDIDO':
      return comercialApprovalDialog.show(order, action);
    case 'CANCELAR_PEDIDO':
      return comercialCancelOrderDialog.show(order, action);
    case 'ALTERAR_STATUS':
      return comercialChangeStatusDialog.show(order, action);
  }
}

function onApproval(item) {
  return comercialApprovalDialog.show(item.id);
}

function exportExcel() {
  return orderListTable.value.exportExcel();
}

function print() {
  return orderListTable.value.print();
}

const dateFormat = (value, format) => !value ? '-' : moment(value).format(format);
const formatCurrency = (value) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
const formatNumber = (value, decimals = 2) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: decimals }).format(value);

function getShippingFormatLabel(shippingMethod) {
  const Formats = {
    'CAIXA_SOLTA': 'Caixa Solta',
    'PALLET': 'Pallet',
  }

  return Formats[shippingMethod] || shippingMethod;
}

const loadItemsActualStocks = debounce(async () => {
  currentItemsStocks.value = [];

  const itemsBySupplier = groupBy(plannedShipmentProducts.value, ({supplier}) => supplier.id);

  for (const [supplierId, items] of Object.entries(itemsBySupplier)) {
    const { data } = await axios.get(`/stock`, {
      params: {
        ids: _.uniq(items.map(({ item }) => item.itemId)),
        exibir_embalagens: 1,
        multiplos_depositos: 1,
        id_laticinio: supplierId,
      }
    });

    const stocksByItem = groupBy(data, 'id_item');
    const itemsStockSum = values(mapValues(stocksByItem, (itemStocks) => {
      const item = first(itemStocks);

      const amounts = itemStocks.map((itemStock) => {
        const packagedQuantity = parseInt(itemStock.quantidade_embalado) || 0;
        const quantity = parseInt(itemStock.quantidade) || 0;

        return quantity - packagedQuantity;
      });

      return {
        supplierId,
        itemId: item.id_item,
        availableQuantity: sum(amounts),
      }
    }));

    currentItemsStocks.value = [
      ...currentItemsStocks.value,
      ...itemsStockSum,
    ];
  }
}, 500);

function concludeShipmentPlanning() {
  return comercialOrdersShipmentDialog.value.show(orderedDispatches.value);
}

function showOrder(order) {
  return orderDialog.value.show(order.id);
}

provide('statusIn', props.statusIn);
provide('statusNotIn', props.statusNotIn);
</script>
