<template>
  <div>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-row>
        <v-col
          cols="12"
          md="3"
          offset-md="2"
        >
          <v-menu
            v-model="dateAtMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            content-class="menu-select"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="qualityValidityStart | dateFormat('MM/YYYY')"
                label="Mês de início"
                prepend-inner-icon="event"
                :rules="[v => !!v || 'Campo obrigatório!']"
                persistent-hint
                dark
                filled
                required
                hide-selected
                hide-details
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="qualityValidityStart"
              :max="qualityValidityEnd"
              type="month"
              no-title
              scrollable
              @input="dateAtMenu = false"
            />
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-menu
            v-model="dateAtMenuEnd"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            content-class="menu-select"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="qualityValidityEnd | dateFormat('MM/YYYY')"
                label="Mês de fim"
                prepend-inner-icon="event"
                :rules="[v => !!v || 'Campo obrigatório!']"
                persistent-hint
                dark
                filled
                required
                hide-selected
                hide-details
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="qualityValidityEnd"
              :min="qualityValidityStart"
              type="month"
              no-title
              scrollable
              @input="dateAtMenuEnd = false"
            />
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="protocol"
            label="Protocolo"
            type="text"
            persistent-hint
            dark
            filled
            hide-selected
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          md="9"
          offset-md="2"
        >
          <v-autocomplete
            v-model="dairySelected"
            outlined
            chips
            small-chips
            dark
            filled
            multiple
            hide-details
            label="Unidades "
            :items="ListDairy"
            item-text="nome"
            item-value="id_pessoa"
          >
            <template #selection="data">
              <v-chip
                small
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                color="primary"
                @click="data.select"
                @click:close="removeChipDairy(data.item)"
              >
                <v-icon
                  left
                  small
                >
                  account_balance
                </v-icon>
                {{ data.item.nome }}
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="9"
          offset-md="2"
        >
          <v-text-field
            v-model="obs"
            label="Observações"
            type="text"
            persistent-hint
            dark
            filled
            hide-selected
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          md="9"
          offset-md="2"
        >
          <v-switch
            v-model="show_all_bpa_app"
            label="Mostrar no app todos produtores (visitados e não visitados) classificados nas curvas"
            dark
            hide-details
            class="ma-1 pb-1"
            color="teal accent-3"
          />
        </v-col>
        <v-col cols="12">
          <v-flex>
            <v-tabs
              v-model="tab"
              vertical
              dark
              background-color="transparent"
              class="transparent"
            >
              <v-tab href="#aba-qualidade">
                Qualidade
              </v-tab>
              <v-tab href="#aba-inconformes">
                Inconformes
              </v-tab>
              <v-tab href="#aba-combinada">
                Combinada
              </v-tab>
              <v-tab-item value="aba-qualidade">
                <quality-actions v-model="qualityGoals" />
              </v-tab-item>
              <v-tab-item value="aba-inconformes">
                <conformed-actions
                  ref="conformedActions"
                  :value="nonConformityGoals"
                />
              </v-tab-item>
              <v-tab-item value="aba-combinada">
                <combined-actions
                  v-model="combinedGoals"
                  :quality="qualityGoals"
                  :nonconformity="nonConformityGoals"
                />
              </v-tab-item>
            </v-tabs>
          </v-flex>
        </v-col>
      </v-row>
    </v-form>
    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="green darken-1"
        @click.stop="save"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              save
            </v-icon>
          </template>
          Salvar configuração
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="blue darken-1"
        @click="load()"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              refresh
            </v-icon>
          </template>

          Recarregar
        </v-tooltip>
      </v-btn>
    </v-speed-dial>
  </div>
</template>

<script>
import QualityActions from "@/Domains/Projects/PQFL/BPA/Components/QualityActions.vue";
import ConformedActions from "@/Domains/Projects/PQFL/BPA/Components/ConformedActions.vue";
import CombinedActions from "@/Domains/Projects/PQFL/BPA/Components/CombinedActions.vue";

import moment from "moment-timezone";
import _ from "lodash";

export default {

  components: {
    QualityActions,
    ConformedActions,
    CombinedActions,
  },

  filters: {
    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
  },

  props: {
    value: String
  },

  data() {
    return {

      id: null,

      tab: "aba-qualidade",
      loading: false,

      nonConformityGoals: {
        simple: [],
        points: [],
      },

      qualityGoals: {
        simple: [],
        points: [],
        settingPoints: [],
      },

      combinedGoals: {
        combined: []
      },

      saving: false,

      protocol: '',

      ListDairy: [],

      dairySelected: [],

      obs: '',

      show_all_bpa_app: false,

      qualityValidityStart: null,

      qualityValidityEnd: null,

      // Date Picker
      dateAtMenu: false,

      dateAtMenuEnd: false,

      questionnairesGoals: [],
      emergencyGoals: {},
      qualityGoalsPae: [],

    };
  },

  watch: {
    value() {
      this.id = this.value;
      this.load();
    }
  },
  created() {
    this.id = this.value;
    this.load();
    this.getDairies();
  },

  methods: {

    async getDairies() {
      if (this.ListDairy.length > 0) {
        return this.ListDairy;
      }
      const { data } = await this.$axios.post(`/pessoa/listaLaticinioJson`);
      this.ListDairy = data;
    },
    removeChipDairy(lat) {
      this.dairySelected = this.dairySelected.filter((dairyId) => dairyId !== lat.id_pessoa);
    },

    async load() {
      this.qualityValidityStart = null;
      this.qualityValidityEnd = null;
      this.protocol = null;
      this.obs = null;
      this.qualityGoals.simple = [];
      this.qualityGoals.points = [];
      this.nonConformityGoals.simple = [];
      this.nonConformityGoals.points = [];
      this.combinedGoals.combined = [];

      if (this.id) {
        await this.loadPlanning();
      }

    },

    async loadPlanning() {
      try {
        this.loading = true;
        const { data } = await this.$axios.post(`/recomendacoesQualidade/detalhe`, { id: this.id });

        if (!_.isObject(data)) {
          throw data;
        }

        await this.setState(data);

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as metas!", "Atenção");
        console.warn(err)
      } finally {
        this.loading = false;
      }

    },

    async setState(data = null) {

      if (!_.isEmpty(data)) {

        this.qualityValidityStart = !_.isEmpty(data.data) ? moment(data.data).format("YYYY-MM") : moment().format("YYYY-MM");
        this.qualityValidityEnd = !_.isEmpty(data.data_fim) ? moment(data.data_fim).format("YYYY-MM") : moment().format("YYYY-MM");
        this.protocol = data.protocolo;
        this.obs = data.descricao;
        this.show_all_bpa_app = data.mostrar_app_produtores_visitados_bpa;
        this.dairySelected = data.laticinios;
        //metas das outras abas (questionario e pae)
        // traz somente para nao sobreescrever com null na hora de salvar
        this.questionnairesGoals = !_.isEmpty(data.questionario) ? data.questionario : [];
        this.emergencyGoals      = !_.isEmpty(data.emergencial) ? data.emergencial : {};
        this.qualityGoalsPae     = !_.isEmpty(data.qualidade) ? data.qualidade : [];

        await this.loadRanges();
      }
    },
    async loadRanges() {
      try {
        const { data } = await this.$axios.post(`/recomendacoesQualidade/rangesBpa`, { id_assistencia: this.id });

        if (!_.isObject(data)) {
          throw data;
        }
        this.handleRanges(data);

      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as metas!", "Atenção");
        console.warn(err)
      }
    },
    //handle ranges
    handleRanges(data) {

      data.forEach(d => {
        let ranges =  {
          id: d.id_meta_bpa,
          index: d.ordem,
          start: d.inicio_faixa,
          end: d.fim_faixa,
          class: d.nome_faixa,
          visits: d.visitas,
          period: d.periodo,
          questionnaires: JSON.parse(d.questionarios_aplicar),
          actions: d.acoes,
          show_app: d.aplicar_campo,
          type_producer: d.tipo_produtor,
          time_init: d.dias_entrada,
          points: d.pontos,
        };
        //-------------------------------qualidade simples
        if (d.tipo == 'QUALIDADE-SIMPLES') {
          let index = this.qualityGoals.simple.findIndex(data => {
            return data.indicator.value == d.id_qualidade_consulta;
          });

          if (index > 0 || index === 0) {
            this.qualityGoals.simple[index].ranges.push(ranges);
          } else {
            let indicator = {
              text: d.indicador,
              value: d.id_qualidade_consulta
            };
            this.qualityGoals.simple.push({ indicator, ranges: [ranges] });
          }
        //----------------------------------qualidade pontos
        } else if (d.tipo == 'QUALIDADE-PONTOS') {
          let index = this.qualityGoals.points.findIndex(data => {
            return data.indicator.value == d.id_qualidade_consulta;
          });

          if (index > 0 || index === 0) {
            this.qualityGoals.points[index].ranges.push(ranges);
          } else {
            let indicator = {
              text: d.indicador,
              value: d.id_qualidade_consulta
            };
            this.qualityGoals.points.push({ indicator, ranges: [ranges] });
          }

        //-------------------------------inconformes simples
        } else if (d.tipo == 'INCONFORME-SIMPLES') {
          ranges = {
            ...ranges,
            type: d.condicional,
          }
          let index = this.nonConformityGoals.simple.findIndex(data => {
            return data.indicator.value == d.id_questionario;
          });

          if (index > 0 || index === 0) {
            this.nonConformityGoals.simple[index].ranges.push(ranges);
          } else {
            let indicator = {
              text: d.indicador,
              value: d.id_questionario
            };
            this.nonConformityGoals.simple.push({ indicator, ranges: [ranges] });
          }

          //-------------------------------inconformes pontos
        } else if (d.tipo == 'INCONFORME-PONTOS') {
          let index = this.nonConformityGoals.points.findIndex(data => {
            return data.indicator.value == d.id_questionario;
          });

          if (index > 0 || index === 0) {
            this.nonConformityGoals.points[index].ranges.push(ranges);
          } else {
            let indicator = {
              text: d.indicador,
              value: d.id_questionario
            };
            this.nonConformityGoals.points.push({ indicator, ranges: [ranges] });
          }
        //-------------------------------combinadas
        } else if (d.tipo == 'COMBINADAS') {
          ranges = {
            ...ranges,
            rangesCombined: JSON.parse(d.metas_agrupadas),
          }
          this.combinedGoals.combined.push({  ranges: ranges });
        }

      });

    },

    async handleSave() {

      let metas = [];
      //----------------------------------------qualidade simples
      this.qualityGoals.simple.forEach(q => {
        let m = {
          type: 'QUALIDADE-SIMPLES',
          indicator: q.indicator.text,
          id_qualidade_consulta: q.indicator.value,
        };
        q.ranges.forEach(range => {
          if (!_.isEmpty(range.id)) {
            metas.push({
              ...m,
              id: range.id,
              index: range.index,
              class: range.class,
              start: range.start,
              end: range.end,
              actions: range.actions,
              show_app: range.show_app,
              visits: range.visits,
              period: range.period,
              questionnaires: range.questionnaires,
              type_producer: range.type_producer,
              time_init: range.time_init,
            })
          }
        })
      });
      this.qualityGoals.points.forEach(q => {
        let m = {
          type: 'QUALIDADE-PONTOS',
          indicator: q.indicator.text,
          id_qualidade_consulta: q.indicator.value,
        };
        q.ranges.forEach(range => {
          if (!_.isEmpty(range.id)) {
            metas.push({
              ...m,
              id: range.id,
              index: range.index,
              class: range.class,
              start: range.start,
              end: range.end,
              actions: range.actions,
              visits: range.visits,
              period: range.period,
              questionnaires: range.questionnaires,
              points: range.points,
            })
          }
        })
      });
      //----------------------------------------inconformes simples
      this.nonConformityGoals.simple.forEach(q => {
        let m = {
          type: 'INCONFORME-SIMPLES',
          indicator: q.indicator.text,
          id_questionnaire: q.indicator.value,
        };
        q.ranges.forEach(range => {
          if (!_.isEmpty(range.id)) {
            metas.push({
              ...m,
              id: range.id,
              index: range.index,
              class: range.class,
              start: range.start,
              end: range.end,
              actions: range.actions,
              show_app: range.show_app,
              visits: range.visits,
              period: range.period,
              questionnaires: range.questionnaires,
              conditional: range.type,
            })
          }
        })
      });
      //----------------------------------------inconformes pontuacao
      this.nonConformityGoals.points.forEach(q => {
        let m = {
          type: 'INCONFORME-PONTOS',
          indicator: q.indicator.text,
          id_questionnaire: q.indicator.value,
        };
        q.ranges.forEach(range => {
          if (!_.isEmpty(range.id)) {
            metas.push({
              ...m,
              id: range.id,
              index: range.index,
              class: range.class,
              start: range.start,
              end: range.end,
              actions: range.actions,
              show_app: range.show_app,
              visits: range.visits,
              period: range.period,
              questionnaires: range.questionnaires,
            })
          }
        })
      });
      //----------------------------------------combinadas
      this.combinedGoals.combined.forEach(q => {
        let m = {
          type: 'COMBINADAS',
        };
        let range = q.ranges;
        if (!_.isEmpty(range.id)) {
          metas.push({
            ...m,
            id: range.id,
            class: range.class,
            index: range.index,
            actions: range.actions,
            show_app: range.show_app,
            visits: range.visits,
            period: range.period,
            questionnaires: range.questionnaires,
            rangesCombined: range.rangesCombined,
            id_combined: _.map(range.rangesCombined, (data) => { return data.indicator.id; })
          })
        }
      });
      return metas;
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        this.$snotify.warning("Campos inválidos", "Atenção");
        return false;
      }
      if (_.isEmpty(this.qualityValidityStart) || _.isEmpty(this.qualityValidityEnd)) {
        this.$snotify.warning("Mêses de inicio e fim obrigatorios", "Atenção");
        return false;
      }
      try {
        this.loading = true;
        this.saving = true;
        let metas = await this.handleSave();

        const payload = {
          id_assistencia: this.id,
          data: moment(this.qualityValidityStart).startOf("month").format('YYYY-MM-DD'),
          data_fim: moment(this.qualityValidityEnd).endOf("month").format('YYYY-MM-DD'),
          protocolo: this.protocol,
          descricao: this.obs,
          mostrar_app_produtores_visitados_bpa: this.show_all_bpa_app,
          laticinios: this.dairySelected,
          tipo: "PQFL",
          metas: metas,
          questionario: this.questionnairesGoals,
          emergencial: this.emergencyGoals,
          qualidade: this.qualityGoalsPae,
        };

        let { data } = await this.$axios.post(
          `/recomendacoesQualidade/salvaMetas`,
          payload
        );

        this.id = data.id;
        this.$emit('input', data.id);
        // this.loadPlanning();

        if (data.codigo == 0 ) {
          throw new Error(data.mensagem || data);
        } else if (data.codigo == 2 ) {
          this.$snotify.error(data.mensagem, "Atenção");
          return false;
        }

        this.$snotify.success("Metas salvas com sucesso", "Sucesso");

      } catch (error) {
        console.log(error);
        this.$snotify.error("Oops, ocorreu um erro ao salvar metas!", "Atenção");

      } finally {
        this.loading = false;
      }
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  },
};
</script>
