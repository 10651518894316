<template>
  <div>
    <v-row>
      <v-col
        cols="3"
        offset="8"
      >
        <v-text-field
          v-model="filters.search"
          label="Pesquisar"
          prepend-inner-icon="search"
          dark
          filled
          hide-details
          clearable
        />
      </v-col>

      <filter-menu
        :options="filters.options"
        :customs="headers"
        :selected-options.sync="filters.selectedOptions"
        :selected-customs.sync="filters.selectedCustoms"
        color="white"
      />
    </v-row>

    <v-card
      class="report-card mb-10"
      color="transparent"
      dark
    >
      <v-card-title>
        <filter-items
          :selected-options.sync="filters.selectedOptions"
          :selected-customs.sync="filters.selectedCustoms"
          @removed="onFilterRemoved"
        >
          <filter-item value="createdAt">
            <v-date-range-picker
              label="Data de Cadastro"
              v-model="filters.range"
              dark
              @change="fetch"
            />
          </filter-item>

          <filter-item value="customer">
            <person-autocomplete-filter
              v-model="filters.customer"
              label="Cliente/Empresa *"
              type="CUSTOMER"
              :filled="false"
              return-object
              hide-details
              persistent-hint
              filled
            />
          </filter-item>

          <filter-item value="sellers">
            <person-autocomplete-filter
              v-model="filters.seller"
              label="Representante"
              type="SELLER"
              :filled="false"
              hide-details
              chips
              filled
            />
          </filter-item>

          <filter-item value="city">
            <v-autocomplete
              v-model="filters.city"
              label="Cidade"
              :items="customerCities"
              item-text="name"
              item-value="code"
              prepend-inner-icon="map"
              hide-details
              return-object
              clearable
              filled
            />
          </filter-item>

          <filter-item value="state">
            <v-autocomplete
              v-model="filters.state"
              label="Estado"
              :items="customerStates"
              prepend-inner-icon="map"
              hide-details
              clearable
              filled
            />
          </filter-item>
        </filter-items>
      </v-card-title>

      <data-table
        v-model="selectedOrders"
        :headers="headers"
        :items="filteredOrders"
        :loading="loading"
        :name="`Pedidos Comerciais - ${props.status}`"
        dark
        dense
        :group-by="groupBy"
        :show-select="showSelect"
        :show-expand="showExpand"
        class="elevation-1 report-table"
        @dblclick:row="onRowDbClick"
      >
        <template
          v-for="(_, slotName) in $slots"
          #[slotName]
        >
          <slot :name="slotName" />
        </template>

        <template
          v-for="(_, slotName) in $scopedSlots"
          #[slotName]="props"
        >
          <slot
            :name="slotName"
            v-bind="props"
          />
        </template>
      </data-table>
    </v-card>

    <bill-dialog
      v-model="billDialog.show"
      :bill-id="billDialog.id"
      type="CONTA_A_RECEBER"
      @input="billDialog.id = null"
    />
  </div>
</template>

<style lang="scss">
.orders-list {
  .financial-badge {
    font-size: 16px;
    position: absolute;
    top: -10px;
    right: 0px;
  }

  .report-card {
    margin-top: 5px !important;

    > .v-card__title{
      background: rgba(0, 0, 0, 0.5);
      color: rgba(255, 255, 255, 0.7);;
      font-size: 1.1rem;
      align-items: baseline;
    }
  }

  .report-table {
    background: transparent !important;

    table {
      thead > tr {
        background: rgba(0, 0, 0, 0.5);
      }

      tbody {
        background: rgba(255, 255, 255, 0.08);
        color: white !important;

        tr:hover {
          background: rgba(0, 0, 0, 0.2) !important;
        }
      }
    }

    .v-data-footer {
      background: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>

<script setup>
import { computed, reactive, inject, ref, onMounted, watch } from "vue";

import isEmpty from "lodash/fp/isEmpty";
import isArray from "lodash/fp/isArray";
import uniq from "lodash/uniq"

import moment from "moment-timezone";

import { useStore } from '@/Support/Resources/vuex.js';
import { useOrders } from "@/Domains/Orders/Composable/Orders";

import BillDialog from '@/Domains/Financial/Components/BillDialog.vue';
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import OrderDialog from '@/Domains/Orders/Components/Dialogs/OrderDialog.vue';

import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";

import FilterItems from "@/Support/Components/FilterMenu/FilterItems.vue";
import FilterItem from "@/Support/Components/FilterMenu/FilterItem.vue";
import FilterMenu from "@/Support/Components/FilterMenu/FilterMenu.vue";

moment.locale('pt');

const props = defineProps({
  status: String,
  showSelect: Boolean,
  showExpand: Boolean,
  selected: Array,
  groupBy: String,
});

const statusIn = inject('statusIn');
const statusNotIn = inject('statusNotIn');

const emit = defineEmits(['update:selected', 'dblclick:row']);

const store = useStore();
const orders = useOrders();

const filters = reactive({
  selectedOptions: [],
  selectedCustoms: [],

  options: [
    { text: 'Data de Cadastro', value: 'createdAt', icon: 'event' },
    { text: 'Cliente', value: 'customer', icon: 'person' },
    { text: 'Representantes', value: 'sellers', icon: 'person_outline' },
    { text: 'Estado', value: 'state', icon: 'map' },
    { text: 'Cidade', value: 'city', icon: 'map' },
  ],

  search: '',

  customer: null,
  city: null,
  state: null,
  seller: null,

  range: [
    moment().startOf('month').format('YYYY-MM-DD'),
    moment().endOf('month').format('YYYY-MM-DD')
  ],
});

const billDialog = reactive({
  show: false,
  id: null,
});

const headers = computed(() => {
  return orders.defaultHeaders.value;
});

const loading = computed(() => {
    return orders.loading.value;
});

const selectedOrders = computed({
  get() {
    return props.selected;
  },
  set(newValue) {
    return emit('update:selected', newValue);
  }
});

// @todo temporário | remover
watch(
  () => orders.list,
  () => {
    // selectedOrders.value = orders.list.value;
  },
  { deep: true }
);

/**
 * Exibe os pedidos com base nos filtros selecionados em tela
 */
const filteredOrders = computed(() => {
  const search = typeof filters.search === 'string' ? filters.search.toUpperCase().trim() : null;

  if (
    isEmpty(filters.customer) &&
    isEmpty(filters.seller) &&
    isEmpty(filters.city) &&
    isEmpty(filters.state) &&
    isEmpty(filters.seller) &&
    !search &&
    !filters.selectedCustoms.some(filter => filter.search)
  ) {
    return orders.list.value;
  }

  const customs = filters.selectedCustoms
    .filter(filter => filter.search)
    .map(filter => ({ value: filter.value, search: filter.search.toUpperCase().trim() }));

  const customsFilter = (item) => customs.every(filter => item[filter.value]?.toString()?.toUpperCase()?.includes(filter.search))

  return orders.list.value.filter(order => {
    const matchCustoms = customs.length === 0 || customsFilter(order);

    const hasCustomer = isEmpty(filters.customer) || filters.customer.code === order.customer.code;
    const hasSeller = isEmpty(filters.seller) || order.sellers.some(seller => seller.id === filters.seller.id);
    const hasCity = isEmpty(filters.city) || filters.city.code === order.customer.city.code;
    const hasState = isEmpty(filters.state) || filters.state === order.customer.state;

    const hasSearch = !search || JSON.stringify(Object.values(order)).toUpperCase().includes(search);

    return matchCustoms && hasCustomer && hasSeller && hasCity && hasState && hasSearch;
  });
});

const customerCities = computed(() => {
  return uniq(orders.list.value.flatMap(order => order.customer.city));
});

const customerStates = computed(() => {
  return uniq(orders.list.value.flatMap(order => order.customer.state));
});

watch(
  () => filters.selectedOptions,
  (newValue) => {
    return persistFilterOnStorage('SELECTED_OPTIONS', newValue);
  },
  { deep: true }
);

watch(
  () => filters.selectedCustoms,
  (newValue) => {
    return persistFilterOnStorage('SELECTED_CUSTOMS', newValue);
  },
  { deep: true }
);

onMounted(() => {
  filters.selectedCustoms = retrieveFilterFromStorage('SELECTED_CUSTOMS');
  filters.selectedOptions = retrieveFilterFromStorage('SELECTED_OPTIONS');
});

function fetch() {
  const [startDate, endDate] = filters.range;

  return orders.fetchAll({
    tipo: 'PEDIDO_COMERCIAL',
    status: (statusIn || []).map(status => status.slug),
    data_inicio: startDate,
    data_fim: endDate
  });
}

function onRowDbClick(order) {
  return emit('dblclick:row', order);
}

function showBill(id) {
  billDialog.id = id;
  billDialog.show = true;
}

function onFilter() {
  return fetch();
}

function onFilterRemoved(value) {
  if (isEmpty(filters[value])) {
    return;
  }

  if (isArray(filters[value])) {
    filters[value] = [];

    return onFilter();
  }

  filters[value] = null;

  return onFilter();
}

function persistFilterOnStorage(filterType, newValue) {
  const storageKey = `COMERCIAL_ORDER_LIST_${props.status}_${filterType}`;
  const data = JSON.stringify(newValue);

  return localStorage.setItem(storageKey, data);
}

function retrieveFilterFromStorage(filterType) {
  const storageKey = `COMERCIAL_ORDER_LIST_${props.status}_${filterType}`;
  const data = localStorage.getItem(storageKey);

  if (isEmpty(data)) {
    return [];
  }

  return JSON.parse(data) || [];
}

function exportExcel() {
  // this.$refs.report.exportExcel();
}

function print() {
  // this.$refs.report.print();
}

defineExpose({
  fetch,
  exportExcel,
  print,
})
</script>
