<template>
  <v-dialog
    v-model="isVisible"
    :max-width="400"
    persistent
  >
    <v-card>
      <v-card-title>
        {{ dialog.id ? dialog.label : 'Novo Status' }}
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="2">
            <v-menu
              v-model="showColorPicker"
              top
              nudge-bottom="105"
              nudge-left="16"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on }">
                <div
                  :style="{
                    backgroundColor: dialog.color,
                    cursor: 'pointer',
                    height: '35px',
                    width: '35px',
                    borderRadius: '20px',
                    transition: 'border-radius 200ms ease-in-out'
                  }"
                  v-on="on"
                />
              </template>
              <v-card>
                <v-card-text class="pa-0">
                  <v-color-picker
                    v-model="dialog.color"
                    flat
                  />
                </v-card-text>
              </v-card>
            </v-menu>
          </v-col>

          <v-col cols="10">
            <v-text-field
              v-model="dialog.label"
              label="Descrição"
              outlined
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          text
          @click="close"
        >
          Fechar
        </v-btn>

        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          @click="save"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';

const emptyDialog = {
  id: null,
  color: '#1976D2FF',
  label: '',
  settings: {
    showOnList: true,
    statusToShow: [],
    statusToNotShow: [],
    actions: [],
  }
}

export default {
  data() {
    return {
      isVisible: false,

      showColorPicker: false,

      swatchStyle: {
        cursor: 'pointer',
        height: '35px',
        width: '35px',
        borderRadius: '20px',
        transition: 'border-radius 200ms ease-in-out'
      },

      dialog: { ...emptyDialog },
    }
  },

  methods: {
    show(item) {
      this.isVisible = true;

      this.dialog = {
        ...this.dialog,
        ...item,
      }
    },

    close() {
      this.isVisible = false;

      this.dialog = { ...emptyDialog };
    },

    save() {
      this.$emit('save', {
        ...this.dialog,
        slug: _.chain(this.dialog.label)
          .deburr()
          .toLower()
          .replace(/[^a-zA-Z0-9\s]/g, '')
          .trim()
          .replace(/\s+/g, '_')
          .toUpper()
          .value()
      });

      return this.close();
    },
  }
}
</script>
