<template>
  <v-col cols="2">
    <money-input
      v-model="props.commission.amount"
      :label="props.commission.seller.name"
      :prefix="localData.commissionType === 'PERCENTAGE' ? '%' : 'R$'"
      class="order-input"
      :suffix="formatCurrency(props.commission.totalCommissionAmount)"
      persistent-hint
      hide-details
      outlined
    >
    </money-input>
  </v-col>
</template>

<script setup>
import { computed, watch } from "vue";

import MoneyInput from "@/Support/Components/MoneyInput.vue";

// eslint-disable-next-line no-undef
const props = defineProps({
  commission: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  product: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  quantity: {
    type: Number,
    required: true,
  },
});

// eslint-disable-next-line no-undef
const emit = defineEmits(['update:commission'])

const localData = computed({
  get() {
    return props.commission;
  },
  set(newValue) {
    return emit("update:commission", newValue);
  }
});

watch(localData, () => {
  return calculateTotalCommissionAmount();
}, { deep: true });

watch(props.product, (newValue) => {
  return calculateTotalCommissionAmount();
}, { deep: true });

const masks = {
  float: {
    mask: "num",
      blocks: {
      num: {
        mask: Number,
          scale: 4,
          min: -999999,
          max: 999999,
      },
    },
  },
}

function calculateTotalCommissionAmount() {
  const sellersCount = props.product.commissions.length; // Total de representantes do pedido
  const commissionAdjustment = props.product?.commissionAdjustment || 0.0; // Valor de verba (Positivo ou negativo)
  const quantity = props.quantity || 0
  const totalCommissionAdjustment = quantity * commissionAdjustment;
  const commissionAdjustmentBySeller = totalCommissionAdjustment / sellersCount;
  const sellerTotalCommissionAmount = props.quantity * localData.value.amount;

  localData.value.commissionAdjustmentBySeller = commissionAdjustmentBySeller;
  localData.value.totalCommissionAmount = sellerTotalCommissionAmount + commissionAdjustmentBySeller;
}

function formatCurrency (value, decimals = 2) {
  return 'R$ ' + new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: decimals }).format(value);
}
</script>
