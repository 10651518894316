<template>
  <div>
    <v-dialog
      v-model="show"
      width="1060"
      transition="dialog-bottom-transition"
    >
      <v-card
        flat
        class="pa-0"
      >
        <v-toolbar
          flat
          color="#1976D2"
          height="45px"
          class="pa-0"
        >
          <img
            src="@/Assets/logo-transparent-dark.png"
            height="30"
          >
        </v-toolbar>
        <v-tabs
          v-model="tab"
          fixed-tabs
          background-color="#1976D2"
          dark
        >
          <v-tab>Dados Pessoais</v-tab>

          <v-tab>Endereço</v-tab>

          <v-tab v-if="isSupplier">
            Dados Bancários
          </v-tab>

          <v-tab v-if="type === 'CUSTOMER'">
            Financeiro
          </v-tab>

          <v-tab v-if="type === 'CUSTOMER'">
            Vendedores
          </v-tab>

          <v-tab v-if="type === 'CUSTOMER'">
            Hist. de Pedidos
          </v-tab>

          <v-tab v-if="type === 'CUSTOMER'">
            Anexos
          </v-tab>

          <v-tabs-items v-model="tab">
            <v-tab-item eager>
              <v-container>
                <v-form
                  ref="infoForm"
                  lazy-validation
                >
                  <v-row>
                    <v-col
                      md="12"
                      class="d-inline-flex justify-center align-center py-0"
                    >
                      <span class="pr-4">CPF</span>
                      <v-switch
                        v-model="form.tipo_pessoa"
                        inset
                        dense
                        :false-value="0"
                        :true-value="1"
                        class="mt-0 py-0"
                        hide-details
                      />
                      <span>CNPJ</span>
                    </v-col>
                    <v-col
                      md="2"
                      class="pb-1"
                    >
                      <v-text-field
                        v-model="form.codigo_laticinio"
                        prepend-inner-icon="subtitles"
                        label="Código"
                        dense
                      >
                      </v-text-field>
                    </v-col>
                    <v-col
                      md="8"
                      class="pb-1"
                    >
                      <v-text-field
                        v-model="form.nome"
                        prepend-inner-icon="person"
                        label="Nome *"
                        :rules="[validationRules.required]"
                        dense
                      >
                        <template #append>
                          <v-btn
                            v-if="type === 'COMPETING-DAIRY' && !form.id_pessoa"
                            icon
                            small
                            @click="searchDairy"
                          >
                            <v-tooltip bottom>
                              <template #activator="{ on }">
                                <v-icon v-on="on">
                                  search
                                </v-icon>
                              </template>
                              Importar laticínio
                            </v-tooltip>
                          </v-btn>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col
                      v-if="type === 'CUSTOMER' || type === 'COOPERATIVE'"
                      md="4"
                      class="pb-1"
                    >
                      <v-text-field
                        v-model.trim="form.codigo_laticinio"
                        prepend-inner-icon="subtitles"
                        label="Código laticínio"
                        dense
                      />
                    </v-col>
                    <v-col
                      v-if="type === 'DAIRY'"
                      cols="4"
                      class="pb-1"
                    >
                      <v-select
                        v-model="form.tipo_negocio"
                        :items="tradingUnitTypes"
                        label="Tipos de Negócios"
                        dense
                      />
                    </v-col>
                    <v-col
                      v-if="type === 'SUPPLIER'"
                      cols="4"
                      class="pb-1"
                    >
                      <v-select
                        v-model="form.grupo"
                        :items="supplierTypes"
                        label="Tipo de Fornecedor"
                        dense
                        :rules="[validationRules.required]"
                      />
                    </v-col>
                    <template v-if="form.tipo_pessoa">
                      <v-col
                        md="8"
                        class="pb-1"
                      >
                        <v-text-field
                          v-model="form.razao_social"
                          prepend-inner-icon="business"
                          label="Razão Social"
                          dense
                        />
                      </v-col>
                      <v-col
                        md="4"
                        class="pb-1"
                      >
                        <masked-text-field
                          ref="doc"
                          v-model.trim="form.cnpj_cpf"
                          prepend-inner-icon="assignment_ind"
                          label="CNPJ *"
                          dense
                          :mask="CpfCnpjMask"
                          :rules="type === 'COMPETING-DAIRY' ? [] : [
                            validationRules.required,
                            (v) => !!v && v.length == 18 || 'CNPJ Inválido',
                            () => isDocumentAvailable || 'CNPJ já cadastrado',
                          ]"
                          @blur="checkDocument"
                        />
                      </v-col>

                      <v-col
                        md="3"
                        class="pb-1"
                      >
                        <v-text-field
                          v-model="form.inscricao_estadual"
                          prepend-inner-icon="subtitles"
                          label="Inscrição Estadual"
                          dense
                        />
                      </v-col>
                      <v-col
                        md="3"
                        class="pb-1"
                      >
                        <v-text-field
                          v-model="form.inscricao_municipal"
                          prepend-inner-icon="subtitles"
                          label="Inscrição Municipal"
                          dense
                        />
                      </v-col>
                    </template>
                    <template v-else>
                      <v-col
                        md="8"
                        class="pb-1"
                      >
                        <v-text-field
                          v-model="form.rg"
                          prepend-inner-icon="assignment_ind"
                          label="N° de Identidade (RG)"
                          dense
                        />
                      </v-col>
                      <v-col
                        md="4"
                        class="pb-1"
                      >
                        <masked-text-field
                          ref="doc"
                          v-model.trim="form.cnpj_cpf"
                          prepend-inner-icon="assignment_ind"
                          label="CPF *"
                          dense
                          :mask="CpfCnpjMask"
                          :rules="type === 'COMPETING-DAIRY' ? [] : [
                            validationRules.required,
                            (v) => !!v && v.length == 14 || 'CPF Inválido',
                            () => isDocumentAvailable || 'CPF já cadastrado',
                          ]"
                          @blur="checkDocument"
                        />
                      </v-col>
                      <v-col
                        md="3"
                        class="pb-1"
                      >
                        <v-text-field
                          v-model="form.orgao_expedidor"
                          prepend-inner-icon="assignment_ind"
                          label="Órgão Expedidor"
                          dense
                        />
                      </v-col>
                      <v-col
                        md="3"
                        class="pb-1"
                      >
                        <v-text-field
                          v-model="form.uf_expedicao"
                          prepend-inner-icon="assignment_ind"
                          label="UF Expedição"
                          dense
                        />
                      </v-col>
                    </template>
                    <v-col
                      md="3"
                      class="pb-1"
                    >
                      <masked-text-field
                        v-model="form.telefone"
                        prepend-inner-icon="phone"
                        label="Telefone"
                        dense
                        :mask="{ mask: '(00) 00000-0000' }"
                      />
                    </v-col>
                    <v-col
                      v-if="!form.tipo_pessoa"
                      md="3"
                      class="pb-1"
                    >
                      <masked-text-field
                        v-model.trim="form.data_nascimento"
                        prepend-inner-icon="event"
                        label="Data de Nascimento"
                        dense
                        :mask="{ mask: '00/00/0000' }"
                      />
                    </v-col>
                    <v-col
                      v-if="type === 'CUSTOMER'"
                      md="3"
                      class="pb-1"
                    >
                      <v-select
                        v-model="form.tipo_classificacao_cliente"
                        :items="customerClassificationTypes"
                        dense
                        label="Tipo de classificação do cliente"
                      />
                    </v-col>
                    <template v-if="type === 'CUSTOMER'">
                      <v-col
                        md="6"
                        class="pb-1"
                      >
                        <v-text-field
                          v-model="form.email"
                          prepend-inner-icon="email"
                          label="E-mail Ordens"
                          dense
                        />
                      </v-col>
                      <v-col
                        md="6"
                        class="pb-1"
                      >
                        <v-text-field
                          v-model="form.email_cobrancas"
                          prepend-inner-icon="email"
                          label="E-mail Cobranças"
                          dense
                        />
                      </v-col>
                    </template>
                    <v-col
                      v-else
                      md="6"
                      class="pb-1"
                    >
                      <v-text-field
                        v-model="form.email"
                        prepend-inner-icon="email"
                        label="E-mail"
                        dense
                      />
                    </v-col>
                    <v-col
                      md="12"
                      class="pb-1"
                    >
                      <v-textarea
                        v-model="form.observacao_tecnica"
                        label="Observações"
                        prepend-inner-icon="description"
                        rows="3"
                        auto-grow
                        dense
                      />
                    </v-col>
                    <template v-if="type === 'COOPERATIVE'">
                      <v-col
                        cols="12"
                        class="subtitle-1"
                      >
                        <div class="d-flex justify-between">
                          Parâmetros de Cooperativa
                        </div>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        class="pb-1"
                      >
                        <v-select
                          v-model="form.ignorar_bonificacao"
                          label="Ignorar pagamento (bonificação) *"
                          item-text="text"
                          item-value="value"
                          :items="[
                            { text: 'Sim', value: 1 },
                            { text: 'Não', value: 0 },
                          ]"
                          required
                          dense
                          :rules="[validationRules.requiredInt]"
                        />
                      </v-col>
                    </template>
                  </v-row>
                </v-form>
                <small color="#A9A9A9">* Campo obrigatório</small>
              </v-container>
            </v-tab-item>

            <v-tab-item eager>
              <v-container>
                <v-form
                  ref="addressForm"
                  lazy-validation
                >
                  <div v-if="type === 'CUSTOMER'">
                    Endereço principal:
                  </div>
                  <address-tab
                    :data-address.sync="form.endereco"
                    :show-region="type === 'CUSTOMER'"
                  />
                  <template v-if="type === 'CUSTOMER'">
                    <v-divider class="my-4" />
                    <div>
                      Endereço de entrega:
                    </div>
                    <address-tab :data-address.sync="form.endereco_entrega" />
                  </template>
                </v-form>
              </v-container>
            </v-tab-item>

            <v-tab-item
              v-if="isSupplier"
              eager
            >
              <v-container>
                <v-form
                  ref="financialForm"
                  lazy-validation
                >
                  <v-row>
                    <v-col
                      cols="8"
                      class="pb-1"
                    >
                      <v-text-field
                        v-model="form.dados_forma_pagamento.nome_titular"
                        label="Nome titular da conta"
                        prepend-inner-icon="person"
                        dense
                      />
                    </v-col>
                    <v-col
                      cols="4"
                      class="pb-1"
                    >
                      <masked-text-field
                        v-model="form.dados_forma_pagamento.cnpj_cpf"
                        label="CPF/CNPJ do titular da conta"
                        dense
                        :mask="CpfCnpjMask"
                        hide-details
                      />
                    </v-col>
                    <v-col
                      cols="4"
                      class="pb-1"
                    >
                      <banks-autocomplete
                        v-model="form.dados_forma_pagamento.banco"
                        label="Banco"
                        dense
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                      class="pb-1"
                    >
                      <v-text-field
                        v-model="form.dados_forma_pagamento.agencia"
                        label="Agência"
                        prepend-inner-icon="subtitles"
                        dense
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                      class="pb-1"
                    >
                      <v-text-field
                        v-model="form.dados_forma_pagamento.conta"
                        label="N° da conta"
                        prepend-inner-icon="subtitles"
                        dense
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      md="2"
                      class="pb-1"
                    >
                      <v-select
                        v-model="form.dados_forma_pagamento.tipo_conta"
                        :items="accountTypes"
                        label="Tipo de Conta"
                        dense
                      />
                    </v-col>

                    <v-col
                      md="12"
                    >
                      <span>
                        Forma de pagamento:
                      </span>
                      <v-row style="padding-left: 15px">
                        <v-radio-group
                          v-model="form.dados_forma_pagamento.forma_pagamento"
                          row
                        >
                          <v-radio
                            label="Cheque"
                            value="Cheque"
                          />
                          <v-radio
                            label="Depósito bancário"
                            value="Deposito"
                          />
                          <v-radio
                            label="PIX"
                            value="Pix"
                          />
                        </v-radio-group>
                      </v-row>

                      <v-row>
                        <v-col
                          v-if="form.dados_forma_pagamento.forma_pagamento === 'Pix'"
                          cols="2"
                        >
                          <v-select
                            v-model="form.dados_forma_pagamento.tipo_chave"
                            :items="['CPF', 'CNPJ', 'Celular', 'E-mail', 'Aleatória']"
                            label="Tipo de Chave"
                            dense
                          />
                        </v-col>

                        <v-col
                          v-if="form.dados_forma_pagamento.forma_pagamento == 'Pix'"
                          cols="6"
                        >
                          <v-text-field
                            v-model="form.dados_forma_pagamento.chave"
                            prepend-inner-icon="subtitles"
                            label="Digite a Chave *"
                            dense
                            :disabled="!form.dados_forma_pagamento.tipo_chave"
                            hide-details
                            :rules="[validationRules.required]"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-tab-item>

            <v-tab-item
              v-if="type === 'CUSTOMER'"
              eager
            >
              <v-container>
                <v-form
                  ref="financialForm"
                  lazy-validation
                >
                  <div>
                    Dados bancários:
                  </div>
                  <v-row>
                    <v-col
                      cols="12"
                      class="pb-1"
                    >
                      <v-text-field
                        v-model="form.dados_forma_pagamento.nome_titular"
                        label="Nome titular da conta"
                        prepend-inner-icon="person"
                        dense
                      />
                    </v-col>
                    <v-col
                      cols="4"
                      class="pb-1"
                    >
                      <banks-autocomplete
                        v-model="form.dados_forma_pagamento.banco"
                        label="Banco"
                        dense
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                      class="pb-1"
                    >
                      <v-text-field
                        v-model="form.dados_forma_pagamento.agencia"
                        label="Agência"
                        prepend-inner-icon="subtitles"
                        dense
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                      class="pb-1"
                    >
                      <v-text-field
                        v-model="form.dados_forma_pagamento.conta"
                        label="N° da conta"
                        prepend-inner-icon="subtitles"
                        dense
                      />
                    </v-col>
                  </v-row>

                  <v-divider />

                  <div class="mt-3">
                    Dados financeiros:
                  </div>
                  <v-row>
                    <v-col
                      md="3"
                      class="pb-1"
                    >
                      <v-select
                        v-model="form.id_forma_pagamento"
                        :items="paymentTypes"
                        dense
                        label="Forma de Pagamento"
                      />
                    </v-col>
                    <v-col
                      md="3"
                      class="pb-1"
                    >
                      <v-select
                        v-model="form.id_forma_parcelamento"
                        :items="installmentTypes"
                        dense
                        label="Forma de Parcelamento"
                      />
                    </v-col>

                    <v-col
                      md="3"
                      class="pb-1"
                    >
                      <v-select
                        v-model="form.tipo_saldo"
                        :items="balanceTypes"
                        label="Tipo de Saldo"
                        dense
                      />
                    </v-col>

                    <v-col
                      md="3"
                      class="pb-1"
                    >
                      <v-select
                        v-model="form.associado"
                        :items="associateTypes"
                        label="Associado"
                        dense
                      />
                    </v-col>
                    <v-col
                      md="3"
                      class="pb-1"
                    >
                      <v-select
                        v-model="form.revendedor"
                        :items="resellerTypes"
                        label="Revendedor"
                        dense
                      />
                    </v-col>

                    <v-col
                      md="3"
                      class="pb-1"
                    >
                      <money-input
                        v-model="form.limite_credito"
                        label="Limite de Crédito"
                        prefix="R$"
                        dense
                      />
                    </v-col>

                    <v-col
                      md="3"
                      class="pb-1"
                    >
                      <masked-input
                        v-model="form.desconto_padrao"
                        label="Desconto Padrão"
                        prefix="%"
                        dense
                        :mask="FloatNumberMask"
                        currency
                        return-unmasked
                      />
                    </v-col>

                    <v-col
                      v-if="hasFinancialNoteAccess"
                      md="12"
                      class="pb-1"
                    >
                      <v-textarea
                        v-model="form.observacao_financeira"
                        label="Observações financeiras"
                        prepend-inner-icon="description"
                        rows="3"
                        auto-grow
                        dense
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-tab-item>

            <v-tab-item
              v-if="type === 'CUSTOMER'"
              eager
            >
              <v-col
                class="tab-item-body mt-4"
                align-self="center"
              >
                <v-form
                  ref="accessForm"
                  lazy-validation
                >
                  <v-row>
                    <v-col
                      cols="12"
                      class="pt-3"
                    >
                      <v-autocomplete
                        v-model="form.pessoa_acesso"
                        outlined
                        chips
                        small-chips
                        multiple
                        prepend-inner-icon="groups"
                        hide-details
                        label="Vendedores"
                        :items="salesPeople"
                        return-object
                        item-text="nome_usuario"
                        item-value="id_usuario"
                      >
                        <template #selection="{attrs, select, selected, item}">
                          <v-chip
                            small
                            v-bind="attrs"
                            :input-value="selected"
                            close
                            color="primary"
                            class="mt-1"
                            @click="select"
                            @click:close="removeSeller(item.id_usuario)"
                          >
                            <v-icon
                              left
                              small
                            >
                              person
                            </v-icon>
                            {{ item.nome_usuario }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-tab-item>

            <v-tab-item v-if="type === 'CUSTOMER'">
              <v-container>
                <orders-history-tab
                  v-if="hasOrdersHistoryAccess"
                  :id="editId"
                />
                <v-alert
                  v-else
                  icon="lock"
                  prominent
                  text
                  type="error"
                  outlined
                >
                  Usuário sem permissão!
                </v-alert>
              </v-container>
            </v-tab-item>

            <v-tab-item v-if="type === 'CUSTOMER'">
              <v-container>
                <attachments-tab :attachments="form.anexos" />
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
        <v-divider class="ma-0" />
        <v-card-actions class="pa-1">
          <v-spacer />
          <v-btn
            color="blue-grey darken-1"
            text
            @click="cancel"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-3"
            text
            @click="save"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>

      <competing-dairy-dialog
        ref="competing"
        @selected="onDairySelected"
      />

      <v-overlay
        :value="loading"
        absolute
      >
        <v-card-text>
          Carregando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import MoneyInput from "@/Support/Components/MoneyInput.vue";
import BanksAutocomplete from "@/Support/Components/BanksAutocomplete.vue";
import OrdersHistoryTab from "@/Domains/Registrations/Person/Components/OrdersHistoryTab.vue"
import AttachmentsTab from "@/Domains/Registrations/Person/Components/AttachmentsTab.vue";
import AddressTab from "@/Domains/Registrations/Person/Components/AddressTab.vue";
import CompetingDairyDialog from "@/Domains/Registrations/Person/Components/CompetingDairyDialog.vue";

import _ from "lodash";
import MaskedInput from "@/Support/Components/MaskedInput.vue";

const docType = { CPF: 0, CNPJ: 1 };

export default {
  name: "person-dialog",

  components: {
    MaskedInput,
    MaskedTextField,
    BanksAutocomplete,
    MoneyInput,
    OrdersHistoryTab,
    AttachmentsTab,
    AddressTab,
    CompetingDairyDialog,
  },

  props: {
    value: {
      type: Boolean,
    },
    type: {
      type: String,
      validator: (value) => ['SUPPLIER', 'CUSTOMER', 'DAIRY', 'COOPERATIVE', 'COMPETING-DAIRY'].indexOf(value) !== -1
    },
    editId: {
      type: String,
    },
  },

  data() {
    return {
      loading: false,

      tab: null,

      form: {
        tipo_pessoa: docType.CNPJ,
        endereco: {},
        endereco_entrega: {},
        dados_forma_pagamento: {},
      },

      FloatNumberMask: {
        mask: "num",
        blocks: {
          num: {
            mask: Number,
            scale: 4,
            min: -999999,
            max: 999999,
          },
        },
      },

      paymentTypes: [],

      installmentTypes: [],

      salesPeople: [],

      balanceTypes: [
        { text: 'Devedor', value: 'Devedor' },
        { text: 'Credor', value: 'Credor' },
        { text: 'Ambos', value: 'Ambos' },
      ],

      associateTypes: [
        { text: 'Sim', value: true },
        { text: 'Não', value: false },
      ],

      resellerTypes: [
        { text: 'Sim', value: true },
        { text: 'Não', value: false },
      ],

      supplierTypes: [
        { text: 'Freteiro', value: 'FRETEIRO' },
        { text: 'Agropecuária', value: 'AGROPECUARIA' },
        { text: 'Comercial', value: 'COMERCIAL' },
      ],

      tradingUnitTypes: [
        { text: 'INDÚSTRIA', value: 'INDUSTRIA' },
        { text: 'CENTRO DE DISTRIBUIÇÃO', value: 'DISTRIBUICAO' },
        { text: 'POSTO DE RESFRIAMENTO', value: 'RESFRIAMENTO' }
      ],

      accountTypes: [
        { text: 'CORRENTE', value: 'CONTA_CORRENTE' },
        { text: 'POUPANÇA', value: 'CONTA_POUPANCA' },
        { text: 'SALÁRIO', value: 'CONTA_SALARIO' }
      ],

      validationRules: {
        required: (v) => !!v || "Campo obrigatório",
        requiredInt: (v) => Number.isInteger(v) || 'Campo obrigatório!',
      },

      isDocumentAvailable: true,

      CpfCnpjMask: {
        mask: [
          {
            mask: "000.000.000-00",
            type: "CPF",
          },
          {
            mask: "00.000.000/0000-00",
            type: "CNPJ",
          },
        ],
        dispatch: function(appended, dynamicMasked) {
          const number = (dynamicMasked.value + appended).replace(/\D/g, "");
          return dynamicMasked.compiledMasks.find((mask) => number.length > 11 ? mask.type === 'CNPJ' : mask.type === 'CPF');
        },
      },
    };
  },

  computed: {
    show: {
      get() {
        if (this.value) {
          this.checkType();

          if (this.editId) {
            this.loadPerson();
          }
        }

        return this.value;
      },
      set(value) {
        if (!value) {
          if (this.$refs.infoForm) {
            this.$refs.infoForm.resetValidation();
          }
          if (this.$refs.addressForm) {
            this.$refs.addressForm.resetValidation();
          }
          this.tab = 0;
        }
        this.$emit("input", value);
      },
    },

    isSupplier() {
      return ['SUPPLIER', 'COMPETING-DAIRY'].includes(this.type);
    },

    customerClassificationTypes() {
      return this.$store.state.settings.gerais.tipos_classificacao_cliente || [];
    },

    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasFinancialNoteAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "person-financial-note-access" && o.tipo === "COMPONENTE");
    },

    hasOrdersHistoryAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "person-orders-history-access" && o.tipo === "COMPONENTE");
    },
  },

  created() {
    this.checkType();
  },

  methods: {
    checkType() {
      const groups = {
        'SUPPLIER': 'FRETEIRO',
        'COMPETING-DAIRY': 'LATICINIO',
      };

      this.form = {
        tipo_fornecedor: this.isSupplier ? 1 : 0,
        tipo_cliente: this.type === 'CUSTOMER' ? 1 : 0,
        tipo_laticinio: this.type === 'DAIRY' ? 1 : 0,
        tipo_cooperativa: this.type === 'COOPERATIVE' ? 1 : 0,
        tipo_pessoa: docType.CNPJ,
        endereco: {},
        endereco_entrega: {},
        dados_forma_pagamento: {},
        grupo: this.isSupplier ? groups[this.type] : null,
      };

      if (this.type === 'CUSTOMER') {
        this.getSalesPeople();
        this.getPaymentMethods();
        this.getInstallmentMethods();
      }
    },

    async loadPerson() {
      try {

        const payload = {
          id_pessoa: this.editId
        };

        if (this.type === 'DAIRY' || this.type === 'COMPETING-DAIRY') {
          payload.filtrarLaticinio = false;
        }

        const { data } = await this.$axios.post(`/pessoa/pessoaDetalhe`, payload);

        if (!data || !data.id_pessoa) {
          throw new Error(data);
        }

        this.form = {
          ...this.form,
          ...data,
          pessoa_acesso: data.pessoa_acesso || [],
          anexos: data.anexos || [],
          dados_forma_pagamento: {
            ...data.dados_forma_pagamento || {},
            banco:
              { code: _.get(data.dados_forma_pagamento, 'codigo_banco', ''),
                name: _.get(data.dados_forma_pagamento, "banco", '')
              },
          },
          endereco: _.pick(
            data,
            [
              'end_cep',
              'end_rua',
              'end_numero',
              'end_bairro',
              'end_estado',
              'end_cidade',
              'end_cidade_codigo',
              'id_regiao',
              'regiao',
              'end_latitude',
              'end_longitude',
            ]
          ),
          endereco_entrega: data.endereco_entrega || {},
        };

      } catch (e) {
        this.$snotify.error("Erro ao carregar dados do cadastro", "Atenção");
        console.warn(e);
      }
    },

    /**
     * Busca os vendeores para dar permissão
     */
    async getSalesPeople() {
      try {
        const { data } = await this.$axios.get(`/pessoa/listaVendedoresJson`);

        this.salesPeople = data.map((person) => {
          return {
            id_usuario: person.id_pessoa,
            nome_usuario: person.nome,
          };
        });
      } catch (error) {
        console.warn(error);
      }
    },

    cancel() {
      this.show = false;
    },

    async validateForm() {
      if (!await this.$refs.infoForm.validate()) {
        this.tab = 0;
        return false;
      }
      if (!await this.$refs.addressForm.validate()) {
        this.tab = 1;
        return false;
      }
      if (this.type === 'CUSTOMER') {
        if (!await this.$refs.financialForm.validate()) {
          this.tab = 2;
          return false;
        }
      }

      return true;
    },

    async save() {
      // Verifica se todos os campos foram preenchidos corretamente
      if (!await this.validateForm()) {
        return;
      }

      try {
        this.loading = true;

        const action = this.editId ? 'atualiza' : 'insere';

        const payload = {
          ...this.form,
          ...this.form.endereco,
          endereco: undefined,
          endereco_entrega: !_.isEmpty(this.form.endereco_entrega) ? this.form.endereco_entrega : null,
          dados_forma_pagamento: !_.isEmpty(this.form.dados_forma_pagamento)
            ? {
              ...this.form.dados_forma_pagamento,
              codigo_banco: _.get(this.form.dados_forma_pagamento.banco, 'code'),
              banco: _.get(this.form.dados_forma_pagamento.banco, 'name')
            }
            : null,
        };

        const { data } = await this.$axios.post(`/pessoa/${action}`, payload);

        if (data.codigo !== 1) {
          throw new Error(data.mensagem);
        }

        this.show = false;
        this.$emit("change");
        this.$snotify.success("Registro salvo com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error("Erro ao salvar registro", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Remove o vendedor do cliente
     */
    removeSeller(id) {
      this.form.pessoa_acesso = this.form.pessoa_acesso.filter(data => data.id_usuario !== id);
    },

    /**
     * @void
     * Buscas as formas de pagamento do sistema
     */
    async getPaymentMethods() {
      try {
        const { data } = await this.$axios.get(`/formaPagamento/listaJson`);

        if (!_.isArray(data)) {
          throw new Error(data)
        }

        this.paymentTypes = data.map(item => ({
          value: item.id_forma_pagamento,
          text: item.nome,
        }));
      } catch (error) {
        this.$snotify.error("Erro ao carregar as formas de pagamento", "Atenção");
        console.warn(error);
      }
    },

    /**
     * @void
     * Buscas as formas de parcelamento do sistema
     */
    async getInstallmentMethods() {
      try {
        const { data } = await this.$axios.get(`/formaParcelamento/listaJson`);

        if (!_.isArray(data)) {
          throw new Error(data)
        }

        this.installmentTypes = data.map(item => ({
          value: item.id_forma_parcelamento,
          text: item.descricao,
        }));
      } catch (error) {
        this.$snotify.error("Erro ao carregar as formas de parcelamento", "Atenção");
        console.warn(error);
      }
    },

    async searchDairy() {
      this.$refs.competing.search(this.form.nome);
    },

    onDairySelected(dairy) {
      this.form = {
        ...this.form,
        id_pessoa: dairy.id,
        nome: dairy.nome,
        endereco: {
          end_cep: dairy.cep,
          end_rua: dairy.rua,
          end_numero: dairy.numero,
          end_bairro: dairy.bairro,
          end_cidade: dairy.cidade,
          end_estado: dairy.uf,
        },
      }
    },

    async checkDocument(document) {
      this.isDocumentAvailable = true;

      if (!document) {
        return;
      }

      if (this.form.tipo_pessoa == docType.CPF && document.length !== 14) {
        return;
      }

      if (this.form.tipo_pessoa == docType.CNPJ && document.length !== 18) {
        return;
      }

      const fields = {
        'CUSTOMER': 'tipo_cliente',
        'DAIRY': 'tipo_laticinio',
        'COOPERATIVE': 'tipo_cooperativa',
        'SUPPLIER': 'tipo_fornecedor',
        'COMPETING-DAIRY': 'tipo_fornecedor',
      };

      const type = fields[this.type];

      if (!type) {
        return;
      }

      try {
        this.$root.$progressBar.loading();

        const { data } = await this.$axios.post(`/pessoa/verificar-documento`, {
          id_pessoa: this.form.id_pessoa,
          tipo: type,
          cnpj_cpf: document
        });

        this.isDocumentAvailable = !!data.disponivel;

        this.$refs.doc.validate();
      } catch (error) {
        this.$snotify.error('Erro ao verificar documento', 'Atenção');
        console.log(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },
  },
};
</script>
