<template>
  <v-autocomplete
    v-model="localValue"
    :items="installmentMethod"
    :loading="loading"
    v-bind="$attrs"
    item-text="description"
    item-value="id"
  />
</template>

<script>
import isArray from "lodash/fp/isArray";

export default {
  name: 'payment-method-select',

  props: {
    value: [String, Object],
  },

  data() {
    return {
      loading: false,

      installmentMethod: [],
    };
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        return this.$emit("input", value);
      },
    },
  },

  async created() {
    await this.getInstallmentMethods();
  },

  methods: {
    async getInstallmentMethods() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/formaParcelamento/listaJson`);

        if (!isArray(data)) {
          throw new Error(data);
        }

        this.installmentMethod = data.map(item => ({
          id: item.id_forma_parcelamento,
          description: item.descricao,
        }));
      }
      catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as formas de parcelamento!", "Atenção");

        console.warn(err)
      }
      finally {
        this.loading = false;
      }
    },
  },
};
</script>
