<template>
  <v-text-field
    :value="formattedValue"
    v-bind="$attrs"
    @keypress="isNumeric"
    @drop.prevent
    @paste.prevent
    @input="change"
    @click="$emit('click', $event)"
  >
    <template
      v-for="(_, slotName) in $slots"
      #[slotName]
    >
      <slot :name="slotName" />
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: 'money-input',

  props: {
    value: {
      required: false,
      type: [Number, String],
      default: 0
    },
    masked: {
      type: Boolean,
      default: false
    },
    precision: {
      type: Number,
      default: 2,
    },
    decimal: {
      type: String,
      default: ',',
    },
    thousands: {
      type: String,
      default: '.',
    }
  },

  data () {
    return {
      formattedValue: '',
      currValue: 0,
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.currValue = newValue;
        let opt = { precision: this.precision, decimal: this.decimal, thousands: this.thousands, prefix: '', suffix: '' };
        let formatted = this.format(newValue, opt);
        if (formatted !== this.formattedValue) {
          this.formattedValue = formatted;
        }
      }
    }
  },

  methods: {
    change(value) {
      if (!this.masked) {
        value = this.unformat(value, this.precision);
      }
      if (value != this.currValue) {
        this.$emit('input', value);
      }
    },

    format(input, opt) {
      if (!input) {
        return '';
      }
      if (typeof input === 'number') {
        input = input.toFixed(this.fixed(opt.precision));
      }
      let negative = input.indexOf('-') >= 0 ? '-' : '';

      let numbers = this.onlyNumbers(input);
      let currency = this.numbersToCurrency(numbers, opt.precision);
      let [integer, decimal] = this.toStr(currency).split('.');
      integer = this.addThousandSeparator(integer, opt.thousands);
      return opt.prefix + negative + this.joinIntegerAndDecimal(integer, decimal, opt.decimal) + opt.suffix;
    },

    unformat(input, precision) {
      let negative = input.indexOf('-') >= 0 ? -1 : 1;
      let numbers = this.onlyNumbers(input);
      let currency = this.numbersToCurrency(numbers, precision);
      return parseFloat(currency) * negative;
    },

    onlyNumbers(input) {
      return this.toStr(input).replace(/\D+/g, '') || '0';
    },

    fixed(precision) {
      return this.between(0, precision, 20);
    },

    between(min, n, max) {
      return Math.max(min, Math.min(n, max));
    },

    numbersToCurrency(numbers, precision) {
      let exp = Math.pow(10, precision);
      let float = parseFloat(numbers) / exp;
      return float.toFixed(this.fixed(precision));
    },

    addThousandSeparator(integer, separator) {
      return integer.replace(/(\d)(?=(?:\d{3})+\b)/gm, `$1${separator}`);
    },

    joinIntegerAndDecimal(integer, decimal, separator) {
      return decimal ? integer + separator + decimal : integer;
    },

    toStr(value) {
      return value ? value.toString() : '';
    },

    isNumeric(e) {
      if (!/[0-9]/.test(String.fromCharCode(e.charCode))) {
        e.preventDefault();
      }
    }
  }
}
</script>
