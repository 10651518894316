<template>
  <v-dialog
    v-model="isVisible"
    persistent
    max-width="450"
  >
    <v-card>
      <v-card-title class="text-h5">
        Aprovação
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col
            cols="12"
          >
            <p class="text-overline font-weight-black mb-1">
              Cliente:
            </p>
            {{ order.customer?.name }}
          </v-col>

          <v-col cols="12">
            <p class="text-overline font-weight-black mb-1">
              Representantes:
            </p>
            {{ order.sellers?.map(seller => seller.name).join(', ') }}
          </v-col>

          <v-col cols="12">
            <p class="text-overline font-weight-black mb-1">
              Agendamento:
            </p>
            {{ order.scheduledTo ? formatDate(order.scheduledTo, 'DD/MM/YYYY HH:mm') : 'Imediata' }}
          </v-col>

          <v-col cols="6">
            <p class="text-overline font-weight-black mb-1">
              Código do Pedido:
            </p>

            <b>{{ order.code }}</b>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="red darken-1"
          outlined
          @click="close()"
        >
          Cancelar
        </v-btn>

        <v-spacer />

        <v-btn
          color="green darken-1"
          outlined
          :loading="loading"
          @click="approve()"
        >
          Aprovar
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-overlay
      :value="loading"
      absolute
    >
      <v-card-text>
        {{ loading ? 'Carregando...' : 'Salvando...' }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-dialog>
</template>

<script>
import isObject from "lodash/isObject";
import moment from "moment-timezone";

const emptyForm = {
  id: null,
  customer: {
    name: null,
  },
  sellers: [],
  scheduledTo: null,
  code: null,
  invoiceNumber: null,
}

export default {

  data() {
    return {
      isVisible: false,

      loading: false,

      order: { ...emptyForm },

      newStatus: null,
    }
  },

  methods: {
    async show(order, action) {
      this.order = {
        ...this.order,
        ...order,
      };

      this.newStatus = action.newStatus.slug;

      await this.loadOrder();

      this.isVisible = true;
    },

    close() {
      this.isVisible = false;

      return this.reset();
    },

    reset() {
      this.order = { ...emptyForm };
    },

    async loadOrder() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/movimento/detalhe`, { id_movimento: this.order.id });

        if (!isObject(data)) {
          throw data;
        }

        this.order = {
          id: data.id_movimento,
          code: data.cod_pedido,
          status: data.status,
          notes: data.observacao,
          scheduledTo: data.data_hora_entrega,
          priceTable: data.id_tabela_preco,
          invoiceNumber: data.numero_nota,
          customer: {
            id: data.id_cliente,
            name: data.nome_cliente,
            region: data.id_regiao || null
          },
          supplier: {
            id: data.id_fornecedor,
            name: data.nome_fornecedor,
          },
          sellers: data.vendedores?.map(seller => ({ id: seller.id_vendedor, name: seller.nome_vendedor })),
        }
      } catch (error) {
        this.$snotify.error("Erro ao alterar aprovação", "Atenção");

        console.warn(error)

        this.close();
      } finally {

        this.loading = false;
      }
    },

    async approve() {
      try {
        this.loading = true;

        let { data } = await this.$axios.post(`/movimento/aprovacao`, {
          id_movimento: this.order.id,
          novo_status: this.newStatus,
        });

        if (!data.codigo) {
          throw new Error(data.mensagem || data);
        }

        this.$snotify.success("Pedido aprovado com sucesso!", "Sucesso");

        this.$emit('save');

        return this.close();
      } catch (error) {
        this.$snotify.error("Erro ao alterar aprovação", "Atenção");

        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    formatDate(date, format) {
      return moment(date).format(format);
    },
  }
}
</script>
