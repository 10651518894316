<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        class="pt-0"
      >
        <v-card
          dark
          color="transparent"
          flat
        >
          <v-form
            ref="form"
            lazy-validation
            @submit.prevent="save()"
          >
            <v-card-title>
              <v-row class="full-width">
                <v-col
                  cols="12"
                  sm="6"
                  md="1"
                  class="py-0"
                >
                  <v-text-field
                    v-model="code"
                    label="Código"
                    filled
                    dark
                    placeholder=" "
                    :disabled="type !== 'expedicao'"
                    :rules="type !== 'expedicao' ? [] : [v => !!v || 'Informe o código da Carga']"
                  />
                </v-col>
                <v-col
                  v-if="['coleta', 'visita', 'comercial'].includes(type)"
                  cols="12"
                  sm="6"
                  md
                  class="py-0"
                >
                  <routes-autocomplete-filter
                    v-model="route"
                    label="Rota *"
                    dark
                    :placeholder="type"
                    :type="type"
                    :hide-details="false"
                    :rules="[v => !!v && !!v.id || 'Informe a rota']"
                    @change="onRouteChange"
                  />
                </v-col>
                <v-col
                  v-if="type == 'spot'"
                  cols="12"
                  sm="6"
                  md
                  class="py-0"
                >
                  <company-autocomplete-filter
                    v-model="company"
                    label="Empresa"
                    placeholder=" "
                    :disabled="hasSpotPlanning"
                    :rules="[v => !!v && !!v.id || 'Informe a empresa']"
                  />
                </v-col>
                <v-col
                  v-if="type == 'transferencia'"
                  cols="12"
                  sm="6"
                  md
                  class="py-0"
                >
                  <dairy-autocomplete-filter
                    v-model="transferDairy"
                    label="Destino"
                    hide-current-dairy
                    placeholder=" "
                    :rules="[v => !!v && !!v.id || 'Informe o laticínio']"
                  />
                </v-col>
                <v-col
                  v-if="type == 'expedicao'"
                  cols="12"
                  sm="6"
                  md
                  class="py-0"
                >
                  <v-text-field
                    v-model="route.description"
                    label="Rota/Região"
                    filled
                    dark
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md
                  class="py-0 d-flex"
                >
                  <div
                    v-if="['spot', 'expedicao'].includes(type)"
                    class="is-third-switch"
                  >
                    <v-switch
                      v-model="isThird"
                      label="Terceiro"
                      persistent-hint
                      inset
                      dense
                    />
                  </div>
                  <v-autocomplete
                    v-if="!isThird"
                    v-model="vehicle"
                    :items="vehicles"
                    item-text="description"
                    item-value="id"
                    prepend-inner-icon="local_shipping"
                    label="Veículo *"
                    return-object
                    filled
                    dark
                    placeholder=" "
                    :rules="[v => !!v && !!v.id || 'Informe o veículo']"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.description }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <v-chip
                            x-small
                            color="gray"
                            text-color="#000000"
                          >
                            {{ item.tipo_carga.toUpperCase() }}
                          </v-chip>
                          <v-chip
                            v-if="item.status === 'ATIVO'"
                            x-small
                            color="green darken-1"
                            text-color="#FFFFFF"
                          >
                            {{ item.status }}
                          </v-chip>
                          <v-chip
                            v-if="item.status === 'DESATIVADO'"
                            x-small
                            color="red darken-1"
                            text-color="#FFFFFF"
                          >
                            {{ item.status }}
                          </v-chip>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                  <v-text-field
                    v-else
                    v-model="vehicle.plate"
                    prepend-inner-icon="local_shipping"
                    label="Veículo *"
                    filled
                    dark
                    placeholder="Digite a placa do veículo"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md
                  class="py-0"
                >
                  <person-autocomplete-filter
                    v-if="!isThird"
                    v-model="driver"
                    :label="type == 'visita' ? 'Técnico' : type == 'comercial' ? 'Vendedor' : 'Motorista'"
                    :type="type == 'visita' ? 'TECHNICIAN' : type == 'comercial' ? 'SELLER' : 'DRIVER'"
                    dark
                    placeholder=" "
                    :hide-details="false"
                    :rules="type == 'spot' ? [] : [v => !!v && !!v.id || 'Campo Obrigatório']"
                    class="text-truncate"
                  />
                  <v-text-field
                    v-else
                    v-model="driver.name"
                    :label="type == 'visita' ? 'Técnico' : type == 'comercial' ? 'Técnico' : 'Motorista'"
                    prepend-inner-icon="person"
                    filled
                    dark
                    placeholder="Digite o nome do motorista"
                    :rules="type == 'spot' ? [] : [v => !!v || 'Campo Obrigatório']"
                  />
                </v-col>

                <v-col
                  v-if="type == 'expedicao'"
                  cols="12"
                  sm="6"
                  md
                  class="py-0"
                >
                  <person-autocomplete-filter
                    v-if="!isThird"
                    v-model="carrier"
                    label="Transportadora"
                    type="CARRIER"
                    prepend-inner-icon="local_shipping"
                    :hide-details="false"
                    dense
                    class="text-truncate"
                  />
                  <v-text-field
                    v-else
                    v-model="carrier.name"
                    label="Transportadora"
                    prepend-inner-icon="local_shipping"
                    filled
                    placeholder="Digite o nome da transportadora"
                    dense
                  />
                </v-col>

                <v-col
                  v-if="['transferencia', 'spot', 'coleta'].includes(type)"
                  cols="12"
                  sm="6"
                  md
                  class="py-0"
                >
                  <raw-material-autocomplete
                    v-model="rawMaterialId"
                    label="Matéria Prima *"
                    placeholder=" "
                    filled
                    dark
                    :auto-select-raw-milk="type === 'coleta'"
                    :rules="[rules.required]"
                    :disabled="type === 'coleta' || history.length > 0 || hasSpotPlanning"
                  />
                </v-col>

                <v-col
                  v-if="['coleta', 'transferencia', 'spot'].includes(type)"
                  cols="12"
                  sm="6"
                  md
                  class="py-0"
                >
                  <v-row class="pa-0">
                    <v-col
                      :cols="type === 'coleta' ? 6 : 12"
                      class="py-0"
                    >
                      <masked-text-field
                        v-model="volume"
                        label="Volume"
                        filled
                        dark
                        placeholder=" "
                        :disabled="['coleta', 'transferencia'].includes(type) || history.length > 0"
                        :rules="type == 'spot' && spotType == 'ENTRADA' ? [v => !!v || 'Campo Obrigatório'] : []"
                        :mask="{ mask: Number, min: 0, scale: 0, signed: false }"
                        unmask
                      />
                    </v-col>
                    <v-col
                      v-if="type === 'coleta'"
                      cols="6"
                      class="py-0"
                    >
                      <v-text-field
                        :value="totalLoadTime"
                        label="Tempo de carga"
                        filled
                        dark
                        disabled
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="6"
                  md
                  class="py-0"
                  :style="{ minWidth: '160px' }"
                >
                  <masked-text-field
                    v-model="startedAt"
                    label="Início Itinerário *"
                    prepend-inner-icon="access_time"
                    placeholder=" "
                    :mask="{ mask: '00/00/0000 00:00' }"
                    :max-length="16"
                    :rules="[rules.required, rules.dateTime]"
                    filled
                    dark
                    @change="changeStartedAt"
                  />
                </v-col>

                <v-col
                  cols="6"
                  md
                  class="py-0"
                  :style="{ minWidth: '160px' }"
                >
                  <masked-text-field
                    v-model="endedAt"
                    label="Fim Itinerário"
                    prepend-inner-icon="access_time"
                    placeholder=" "
                    :mask="{ mask: '00/00/0000 00:00' }"
                    :max-length="16"
                    :rules="status === 'PENDING' || ['transferencia', 'spot', 'expedicao'].includes(type) || (type == 'coleta' && !(id)) ? [rules.dateTime, endDateRule] : [rules.required, rules.dateTime, endDateRule]"
                    :disabled="status === 'PENDING'"
                    filled
                    dark
                  />
                </v-col>

                <v-col
                  v-if="type === 'coleta'"
                  cols="12"
                  sm="6"
                  md
                  class="py-0"
                  :style="{ minWidth: '100px' }"
                >
                  <v-text-field
                    :value="itineraryTotalTime"
                    label="Tempo Itinerário"
                    filled
                    dark
                    disabled
                  />
                </v-col>

                <v-col
                  cols="6"
                  md
                  class="py-0"
                  :style="{ minWidth: '105px' }"
                >
                  <masked-text-field
                    v-model.number="distance.startedAt"
                    label="Km Inicial"
                    prepend-inner-icon="map"
                    placeholder=" "
                    :rules="(['transferencia', 'spot', 'expedicao'].includes(type) && (!distance.endedAt || distance.startedAt === 0)) ? [] : [rules.required]"
                    filled
                    dark
                    :mask="{ mask: Number, min: 0, scale: 0, signed: false }"
                    unmask
                  />
                </v-col>

                <v-col
                  cols="6"
                  md
                  class="py-0"
                  :style="{ minWidth: '105px' }"
                >
                  <masked-text-field
                    v-model.number="distance.endedAt"
                    label="Km Final"
                    prepend-inner-icon="map"
                    placeholder=" "
                    :rules="status === 'PENDING' || (['transferencia', 'spot', 'expedicao'].includes(type) && !distance.startedAt) || (type == 'coleta' && !(id)) ? [] : [rules.required]"
                    :disabled="status === 'PENDING'"
                    filled
                    dark
                    :mask="{ mask: Number, min: 0, scale: 0, signed: false }"
                    unmask
                  />
                </v-col>

                <v-col
                  v-if="['coleta', 'transferencia', 'spot'].includes(type)"
                  cols="12"
                  sm="6"
                  md
                  class="py-0 d-flex"
                  :style="{ minWidth: '160px' }"
                >
                  <masked-text-field
                    v-model.number="distance.planned"
                    label="Km Planejado"
                    prepend-inner-icon="map"
                    placeholder=" "
                    :disabled="!distance.manual"
                    filled
                    dark
                    :mask="{ mask: Number, min: 0, scale: 3 }"
                    unmask
                  />
                  <v-checkbox
                    v-model="distance.manual"
                    class="ml-2"
                    :disabled="!hasPermissionEditPlannedKM"
                  />
                </v-col>

                <v-col
                  v-if="type == 'spot'"
                  cols="12"
                  sm="6"
                  md
                  class="py-0"
                >
                  <v-select
                    v-model="spotType"
                    label="Tipo *"
                    :items="[
                      { text: 'Entrada', value: 'ENTRADA' },
                      { text: 'Saída', value: 'SAIDA' },
                    ]"
                    filled
                    dark
                    :disabled="history.length > 0 || hasSpotPlanning"
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col
                  v-if="type == 'coleta'"
                  cols="12"
                  sm="6"
                  md
                  class="py-0"
                  :style="{ minWidth: '185px' }"
                >
                  <masked-text-field
                    v-model="unloadedAt"
                    label="Efetivação"
                    prepend-inner-icon="access_time"
                    placeholder=" "
                    :mask="{ mask: '00/00/0000 00:00' }"
                    :rules="(type == 'coleta' && !(id)) ?[]: [rules.required, rules.dateTime, unloadDateRule]"
                    :max-length="16"
                    filled
                    dark
                    @change="changeUnloadedAt"
                  >
                    <template #append>
                      <v-btn
                        icon
                        small
                        @click="fillCollectUnloadDate"
                      >
                        <v-icon>keyboard_arrow_down</v-icon>
                      </v-btn>
                    </template>
                  </masked-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md
                  class="py-0"
                  :style="{ minWidth: '160px' }"
                >
                  <v-text-field
                    v-model="search"
                    label="Busca"
                    prepend-inner-icon="search"
                    filled
                    dark
                  />
                </v-col>
              </v-row>
            </v-card-title>
          </v-form>

          <collects-data-table
            v-if="type == 'coleta'"
            :collects="collects"
            :search="search"
            @editCollect="showEditingCollectDialog"
            @deleteCollect="excludeCollect"
            @showCollectLog="showCollectLog"
          >
            <template slot="actions">
              <div style="display: contents">
                <v-btn
                  v-if="hasTransfers && status !== 'PENDING' "
                  icon
                  @click="showCollectTransfers"
                >
                  <v-tooltip left>
                    <template #activator="{ on }">
                      <v-icon v-on="on">
                        mediation
                      </v-icon>
                    </template>

                    Transferências
                  </v-tooltip>
                </v-btn>

                <v-btn
                  v-if="trailerTransfers?.length > 0"
                  icon
                  @click="showTrailerTransfers"
                >
                  <v-tooltip left>
                    <template #activator="{ on }">
                      <v-icon v-on="on">
                        {{ trailerTransfers[0].model == 'REBOQUE' ? 'insert_link' : 'save_alt' }}
                      </v-icon>
                    </template>

                    {{ trailerTransfers[0].model }}
                  </v-tooltip>
                </v-btn>
              </div>
            </template>
          </collects-data-table>

          <v-card-title
            v-if="hasTransfers"
            class="d-flex justify-center text-left pb-0"
          >
            <v-alert
              dense
              text
              prominent
              colored-border
              border="left"
              elevation="2"
              type="info"
            >
              <div class="text-h6">
                Itinerário com transferências
              </div>
              <div class="body-1">
                Caso seja atualizado o volume de um tanque transferido, o sistema recalculará a transferência automaticamente.
              </div>
              <div class="body-1">
                Caso seja de um tanque que ainda não houve transferências, a transferência deve ser feita manualmente.
              </div>
            </v-alert>
          </v-card-title>

          <visits-data-table
            v-if="type == 'visita'"
            :visits="visits"
            :search="search"
            @editVisit="showEditingVisitDialog"
            @deleteVisit="excludeVisit"
          />

          <transfers-data-table
            v-if="['transferencia', 'spot'].includes(type)"
            :transfers="history"
            :search="search"
          />

          <commercial-data-table
            v-if="type == 'comercial'"
            :visits="visits"
            :search="search"
            @editVisit="showEditingCommercialVisitDialog"
            @deleteVisit="excludeCommercialVisit"
          />

          <shipping-data-table
            v-if="type == 'expedicao'"
            :items="shippings"
            :search="search"
            @edit="showEditingShippingDialog"
            @delete="excludeShipping"
          />

          <v-card-text
            v-if="type == 'coleta' && reportSettings?.itinerary?.mostrar_mensagem_rodape === true"
            class="pt-6"
          >
            <v-textarea
              v-if="hasFooterMessage"
              v-model="footerMessage"
              label="Mensagem rodapé"
              filled
              rows="3"
              auto-grow
              hide-details
            />
            <v-textarea
              v-else-if="reportSettings?.itinerary?.mostrar_mensagem_rodape"
              :value="reportSettings?.itinerary?.mensagem_padrao_rodape || ''"
              label="Mensagem rodapé"
              filled
              disabled
              rows="3"
              auto-grow
              hide-details
            >
              <template #append-outer>
                <v-icon
                  @click="hasFooterMessage = true; footerMessage = reportSettings?.itinerary?.mensagem_padrao_rodape"
                  v-text="'edit'"
                />
              </template>
            </v-textarea>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-speed-dial
      fixed
      fab
      dark
      bottom
      right
      open-on-hover
      class="mr-2"
      direction="top"
      transition="slide-y-reverse-transition"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        fab
        dark
        color="blue darken-4"
        @click="save"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              done
            </v-icon>
          </template>

          Salvar
        </v-tooltip>
      </v-btn>

      <v-btn
        v-if="type == 'coleta' && status !== 'PENDING' "
        fab
        dark
        color="deep-purple darken-1"
        @click="showCreatingCollectDialog"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>

          Adicionar Coleta
        </v-tooltip>
      </v-btn>

      <v-btn
        v-if="type == 'coleta' && status !== 'PENDING'"
        fab
        dark
        color="teal"
        :disabled="!id"
        @click="showTransferDialog"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              filter_alt
            </v-icon>
          </template>

          Transferências
        </v-tooltip>
      </v-btn>

      <v-btn
        v-if="type === 'visita'"
        fab
        dark
        color="deep-purple darken-1"
        @click="showCreatingVisitDialog"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>

          Adicionar Visita
        </v-tooltip>
      </v-btn>

      <v-btn
        v-if="type === 'comercial'"
        fab
        dark
        color="deep-purple darken-1"
        @click="showCreatingCommercialVisitDialog"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>

          Adicionar Visita
        </v-tooltip>
      </v-btn>

      <v-btn
        v-if="type === 'expedicao'"
        fab
        dark
        color="deep-purple darken-1"
        @click="showCreatingShippingDialog"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>

          Adicionar Entrega
        </v-tooltip>
      </v-btn>

      <v-btn
        v-if="type == 'coleta' && id"
        fab
        dark
        color="orange darken-1"
        @click="onItineraryPrint"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <editing-collect-dialog
      ref="collectDialog"
      @onSave="onCollectSave"
    />

    <editing-visit-dialog
      ref="visitDialog"
      @onSave="onVisitSave"
    />

    <editing-commercial-visit-dialog
      ref="commercialVisitDialog"
      @onSave="onCommercialVisitSave"
    />

    <editing-shipping-dialog
      ref="shippingDialog"
      @save="onShippingSave"
    />

    <trailer-transfers-dialog
      ref="trailerTransfersDialog"
    />

    <v-dialog
      v-model="transferDialog.show"
      max-width="400px"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <v-card>
        <v-card-title class="text-h5 justify-center">
          Transferências
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-if="trailerTransfers.length === 0">
              <v-btn
                height="80"
                color="purple darken-3"
                dark
                block
                @click="showTrailerDialog"
              >
                <div class="d-flex flex-column">
                  <v-icon x-large>
                    mediation
                  </v-icon>
                  <span>
                    TRANSFERIR
                  </span>
                </div>
              </v-btn>
            </v-col>
            <v-col v-if="collectTransfers.length === 0">
              <v-btn
                height="80"
                color="indigo darken-2"
                dark
                block
                @click="showAttachDialog"
              >
                <div class="d-flex flex-column">
                  <v-icon x-large>
                    insert_link
                  </v-icon>
                  <span>
                    VINCULAR
                  </span>
                </div>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <editing-trailer-transfers-dialog
      ref="transfersDialog"
      @save="loadCollects"
    />

    <attach-trailer-dialog
      ref="attachTrailerDialog"
      @save="loadCollects"
    />

    <collect-log-dialog
      ref="collectLogDialog"
    />

    <v-overlay :value="loading">
      <v-card-text>
        Carregando...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </div>
</template>

<style lang="scss">
.is-third-switch {
  margin-right: 8px;
  .v-input {
    margin-top: 0;
    .v-input__slot {
      flex-direction: column-reverse;
      align-items: center;
      > div {
        margin: 0;
      }
      .v-label {
        font-size: 13px;
      }
    }
  }
}
</style>

<script>
import _, { isNull } from "lodash";
import moment from "moment";

import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import RoutesAutocompleteFilter from "@/Support/Components/Filters/RoutesAutocompleteFilter.vue";
import EditingCollectDialog from "@/Domains/Itineraries/Components/Form/EditingCollectDialog.vue";
import EditingVisitDialog from "@/Domains/Itineraries/Components/Form/EditingVisitDialog.vue";
import EditingCommercialVisitDialog from "@/Domains/Itineraries/Components/Form/EditingCommercialVisitDialog.vue";
import EditingShippingDialog from "@/Domains/Itineraries/Components/Form/EditingShippingDialog.vue";
import CompanyAutocompleteFilter from '@/Domains/Itineraries/Components/CompanyAutocompleteFilter.vue';
import DairyAutocompleteFilter from '@/Domains/Itineraries/Components/DairyAutocompleteFilter.vue';
import RawMaterialAutocomplete from '@/Domains/Itineraries/Components/RawMaterialAutocomplete.vue';
import CollectsDataTable from "@/Domains/Itineraries/Components/Form/CollectsDataTable.vue";
import VisitsDataTable from "@/Domains/Itineraries/Components/Form/VisitsDataTable.vue";
import TransfersDataTable from "@/Domains/Itineraries/Components/Form/TransfersDataTable.vue";
import CommercialDataTable from "@/Domains/Itineraries/Components/Form/CommercialDataTable.vue";
import ShippingDataTable from "@/Domains/Itineraries/Components/Form/ShippingDataTable.vue";
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import TrailerTransfersDialog from "@/Domains/Itineraries/Components/TrailerTransfersDialog.vue";
import EditingTrailerTransfersDialog from "@/Domains/Itineraries/Components/Form/EditingTrailerTransfersDialog.vue";
import AttachTrailerDialog from "@/Domains/Itineraries/Components/Form/AttachTrailerDialog.vue";
import CollectLogDialog from "@/Domains/Itineraries/Components/CollectLogDialog.vue";

import VisitsService from "@/Domains/Visits/Services/VisitsService.js";

const visitsService = new VisitsService();

export default {

  components: {
    PersonAutocompleteFilter,
    RoutesAutocompleteFilter,
    EditingCollectDialog,
    EditingVisitDialog,
    EditingCommercialVisitDialog,
    EditingShippingDialog,
    CollectsDataTable,
    VisitsDataTable,
    TransfersDataTable,
    CommercialDataTable,
    ShippingDataTable,
    CompanyAutocompleteFilter,
    DairyAutocompleteFilter,
    RawMaterialAutocomplete,
    MaskedTextField,
    TrailerTransfersDialog,
    EditingTrailerTransfersDialog,
    AttachTrailerDialog,
    CollectLogDialog,
  },

  props: {
    itinerary: {
      type: Object,
      default: () => ({
        id: null,
        route: {},
        driver: {},
        vehicle: {},
        carrier: {},
        time: {},
        distance: {},
        device: null,
        collect: {
          tanks: [],
          totalVol: 0,
          temp: 0,
          condominium: [],
        },
      })
    },

    status: {
      type: String,
    },

    type: {
      type: String,
      default: 'coleta'
    },

    reportSettings: {
      type: Object,
      default: () => ({})
    },
  },

  data() {
    return {
      // Loader
      loading: false,

      vehicles: [],
      trailers: [],
      types: visitsService.types,

      collects: [],
      visits: [],
      history: [],
      shippings: [],

      id: null,

      route: {},
      vehicle: {},
      driver: {},
      carrier: {},

      transferDairy: {},
      company: {},
      spotType: 'ENTRADA',
      hasSpotPlanning: false,

      rawMaterialId: null,

      startedAt: moment().format('DD/MM/YYYY HH:mm'),
      endedAt: null,
      unloadedAt: null,

      itineraryStarted: null,
      itineraryEnded: null,

      volume: null,

      code: null,

      distance: {
        planned: null,
        manual: false,
      },

      search: null,

      hasFooterMessage: false,
      footerMessage: null,

      pictures: [],

      totalLoadTime: '00:00',

      collectTransfers: [],
      trailerTransfers: [],

      // Masks
      IntNumberMask: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            scale: 0,
            thousandsSeparator: '.',
            min: -999999,
            max: 999999,
          },
        },
      },

      canChangeCollect: true,

      rules: {
        required: v => !!v || 'Campo obrigatório!',
        dateTime: v =>  !v || !!v && v.length == 16 && this.dateValidate(v, 'DD/MM/YYYY HH:mm') || 'Data Inválida!'
      },

      // Campo usados para spot
      isThird: false,

      transferDialog: {
        show: false
      },

      dataInicio: null
    };
  },

  computed: {

    itineraryTotalTime() {
      const startedAt = moment(this.itineraryStarted);
      const endedAt = moment(this.itineraryEnded);

      if (!isNull(this.itineraryStarted) && !isNull(this.itineraryEnded)) {
        return  moment.utc(endedAt.diff(startedAt)).format('HH:mm');
      }

      return '00:00';
    },

    vehicleTanks() {
      if (_.isEmpty(this.vehicle)) {
        return [];
      }

      return this.vehicle.tanks.map((tank, index) => {
        const key = ++index;

        return {
          key: `tanque${key}`,
          label: `Tanque ${key}`,
          capacity: tank,
          vol: null,
        };
      });
    },

    hasTransfers() {
      return this.collectTransfers && this.collectTransfers.length > 0
    },

    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    hasPermissionEditPlannedKM() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "itinerary-edit-planned-km" && o.tipo === "COMPONENTE");
    },

  },

  watch: {
    history() {
      if (this.type == 'transferencia') {
        this.volume = Math.abs(_.sumBy(this.history, 'transferredVol'));
      }
    },
    collects() {
      this.volume = _.sumBy(this.collects, 'vol');
    },
  },

  async mounted() {

    await this.loadVehicles();

    if (this.itinerary.id) {
      this.id = this.itinerary.id;
      if (this.type === 'coleta') {
        this.loadCollects();
      }
      else if (this.type === 'visita') {
        this.loadVisits();
      }
      else if (this.type === 'comercial') {
        this.loadCommercialVisits();
      }
      else if (this.type === 'expedicao') {
        this.loadShippings();
      }
      else if (['transferencia', 'spot'].includes(this.type)) {
        this.loadSiloTransfers();
      }
    }

    if (this.itinerary.id) {

      this.itineraryStarted = this.itinerary.time.startedAt;
      this.itineraryEnded = this.itinerary.time.endedAt;

      this.startedAt = !_.isEmpty(this.itinerary.time.startedAt) ? moment(this.itinerary.time.startedAt).format('DD/MM/YYYY HH:mm') : null;
      this.endedAt = !_.isEmpty(this.itinerary.time.endedAt) ? moment(this.itinerary.time.endedAt).format('DD/MM/YYYY HH:mm') : null;
      this.unloadedAt = !_.isEmpty(this.itinerary.time.unloadedAt) ? moment(this.itinerary.time.unloadedAt).format('DD/MM/YYYY HH:mm') : null;
      this.distance = { ...this.itinerary.distance };
      this.code = this.itinerary.code;

      this.route = { ...this.itinerary.route };
      this.driver = { ...this.itinerary.driver };
      this.carrier = { ...this.itinerary.carrier };
      this.vehicle =  !this.itinerary.vehicle.id ? { ...this.itinerary.vehicle } : this.vehicles.find(vehicle => this.itinerary.vehicle.id === vehicle.id);

      this.hasFooterMessage = !!this.itinerary.footerMessage;
      this.footerMessage = this.itinerary.footerMessage;

      if (this.type == 'coleta') {
        const dataBlock = this.$store.state.settings.gerais.data_bloqueio_edicao_coleta;
        if ( moment(this.itinerary.time.unloadedAt) < moment(dataBlock)) {
          this.canChangeCollect = false;
        }
      }

      if (this.type == 'transferencia') {
        this.transferDairy = { ...this.itinerary.transferDairy };
      }

      if (this.type == 'spot') {
        this.company = { ...this.itinerary.company };
        this.spotType = this.itinerary.spotType;

        this.isThird = !this.driver.id && !!this.driver.name;
        this.volume = this.itinerary.volume;
        this.hasSpotPlanning = this.itinerary.hasSpotPlanning;
      }

      if (this.type == 'expedicao') {
        this.isThird = !this.driver.id && !!this.driver.name;
      }

      if (['transferencia', 'spot', 'coleta'].includes(this.type)) {
        this.rawMaterialId = this.itinerary.rawMaterialId;
      }
    }
    this.$refs.form && this.$refs.form.resetValidation();
  },

  methods: {

    changeStartedAt() {
      if (this.type == 'coleta') {
        this.dataInicio = moment(this.startedAt, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const dataBlock = this.$store.state.settings.gerais.data_bloqueio_edicao_coleta;
        const dateStart = moment(this.startedAt, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss');
        if ( moment(dateStart) < moment(dataBlock)) {
          this.$snotify.warning(
            "Não é possivel editar/inserir coletas anteriores a " + moment(dataBlock).format("DD/MM/YY"),
            "Atenção"
          );
          this.startedAt = '';
        }
      }
    },

    changeUnloadedAt() {
      if (this.type == 'coleta') {
        const dataBlock = this.$store.state.settings.gerais.data_bloqueio_edicao_coleta;
        const dateUnload = moment(this.unloadedAt, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss');
        if ( moment(dateUnload) < moment(dataBlock)) {
          this.$snotify.warning(
            "Não é possivel editar/inserir coletas anteriores a " + moment(dataBlock).format("DD/MM/YY"),
            "Atenção"
          );
          this.unloadedAt = '';
        }
      }
      if (this.unloadedAt) {
        this.endedAt = this.unloadedAt
      }
    },

    onRouteChange(route) {
      if (this.type !== 'coleta') {
        return;
      }

      this.rawMaterialId = route.rawMaterialId;
    },

    async loadVehicles() {
      try {
        this.loading = true;

        var tipoCarga = '';

        switch (this.type) {
          case 'coleta':
            tipoCarga = ['leite', 'reboque'].join(',');
            break;
          case 'visita':
            tipoCarga = ['consultoria'].join(',');
            break;
          default:
            tipoCarga = Array.isArray(this.carga) ? this.carga.join(',') : this.carga;
            break;
        }

        let { data } = await this.$axios.post(
          `/equipamento/listaJson`,
          this.$qs.stringify({
            tipo_carga: tipoCarga,
            type: this.type
          })
        );

        if (!_.isArray(data)) {
          throw new Error(data);
        }

        this.vehicles = data.map(eqp => {
          return {
            id: eqp.id_equipamento,
            description: (eqp.placa || "").toUpperCase(),
            capacity: parseInt(eqp.capacidade) || 0,
            tanks: JSON.parse(eqp.tanque),
            plate: (eqp.placa || "").toUpperCase(),
            status: (eqp.ativo == 1 ? 'ATIVO' : 'DESATIVADO'),
            tipo_carga: eqp.tipo_carga,
          };
        });

        if (this.type == 'coleta') {
          const { data } = await this.$axios.post(
            `/equipamento/listaJson`,
            this.$qs.stringify({
              tipo_carga: 'leite,reboque',
            })
          );

          this.trailers = data.map(eqp => {
            return {
              id: eqp.id_equipamento,
              description: (eqp.placa || "").toUpperCase(),
              capacity: parseInt(eqp.capacidade) || 0,
              tanks: JSON.parse(eqp.tanque),
              plate: (eqp.placa || "").toUpperCase(),
              type: eqp.tipo_carga?.toUpperCase(),
            };
          });
        }
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar os veículos!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    async loadCollects() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/coleta/getItineraryCollect`,
          this.$qs.stringify({
            id_itinerario: this.id,
          }),
        );

        if (!_.isArray(data.coletas)) {
          throw 'Error loadCollects';
        }

        this.distance.planned = parseFloat(data.km_planejado);

        this.collects = data.coletas.map(collect => {

          const collectDate =  moment(collect.data).format('YYYY-MM-DD');

          const coolers = JSON.parse(collect.resfriadores) || [{
            temperatura: parseFloat(collect.temperatura),
            rejeitada: collect.rejeitada,
            quantidade_rejeitada: parseInt(collect.quantidade_rejeitada) || null,
            alizarol: collect.alizarol,
          }];

          return {
            id: collect.id_coleta,
            collectedAt: collect.data ? collectDate : null,
            collectStartedAt: moment(collect.hora_chegada, 'HH:mm:ss').format('HH:mm'),
            collectEndedAt: moment(collect.hora_saida, 'HH:mm:ss').format('HH:mm'),
            unloadedAt: collect.data_hora_descarga ? collect.data_hora_descarga : null,
            arrivalDateTime: moment(`${collectDate} ${collect.hora_chegada}`).format('YYYY-MM-DD HH:mm'),
            vol: parseFloat(collect.quantidade_coleta),
            originalVol: collect.quantidade_original ? parseFloat(collect.quantidade_original) : null,
            temp: parseFloat(collect.temperatura),
            sample: collect.numero_amostra,
            alizarol: collect.alizarol,
            rejected: collect.rejeitada,
            rejectedVol: parseInt(collect.quantidade_rejeitada) || null,
            coolers: coolers.map((cooler, index) => ({
              position: index + 1,
              temperature: cooler.temperatura,
              temperaturePhoto: cooler.url_temperatura,
              sampleNumber: !cooler.ignorar ? (cooler.numero_amostra || collect.numero_amostra) : null,
              ruler: cooler.valor_regua,
              rulerPhoto: cooler.url_regua,
              justification: cooler.justificativa,
              rejected: cooler.rejeitada,
              rejectedVolume: cooler.quantidade_rejeitada,
              alizarol: !cooler.ignorar ? (cooler.alizarol || collect.alizarol) : null,
              ignore: cooler.ignorar,
            })),
            obs: collect.observacao,
            tanks: this.vehicleTanks.map(tank => {
              return {
                ...tank,
                vol: parseInt(_.get(collect, tank.key)) || null,
              };
            }),
            producer: {
              id: collect.id_produtor,
              code: collect.codigo_produtor,
              name: collect.nome_produtor,
              pos: {
                lat: parseFloat(collect.latitude),
                lng: parseFloat(collect.longitude),
              },
              coolersQuantity: coolers.length,
            },
            lastEdit: {
              userName: collect.nome_pessoa_registro,
              updatedAt: collect.data_hora_registro,
            },
            logs: (collect.logs ? JSON.parse(collect.logs) : []).map(log => ({
              type: log.tipo,
              previous: log.anterior,
              new: log.novo,
              userName: log.nome_pessoa,
              updatedAt: log.data_hora,
            })).filter(log => log.type !== 'QUANTIDADE_ORIGINAL'),
            condominium: collect.condominio ? JSON.parse(collect.condominio) : [],
            insertionType: collect.tipo_lancamento,
            milkQualityUnload: collect.qualidade_leite_entrega,
          };
        });

        if (this.collects.length > 0) {
          const orderedCollects = this.collects.sort((first, next) => {
            return moment(first.arrivalDateTime).diff(moment(next.arrivalDateTime));
          });

          const firstLoadTime = _.get(_.first(orderedCollects), 'arrivalDateTime');

          const unloadTime = moment(this.unloadedAt, 'DD/MM/YYYY HH:mm');
          this.totalLoadTime = moment.utc(unloadTime.diff(firstLoadTime)).format('HH:mm');
        }

        this.collectTransfers = data.transferencias.map(transfer => ({
          model: this.itinerary.hasTransshipment ? 'TRANSBORDO' : 'TRANSFERÊNCIA',
          id: transfer.id,
          date: transfer.data_hora_atualizacao || transfer.data_hora,
          equipmentOrigin: {
            id: transfer.id_equipamento_origem,
            plate: transfer.placa_origem,
          },
          equipment: {
            id: transfer.id_equipamento_destino,
            plate: transfer.placa_destino,
          },
          volume: transfer.transferencias.reduce((acc, cur) => acc + (parseInt(cur.volume)), 0),
          transfers: transfer.transferencias
        }));

        this.trailerTransfers = data.transferencias_recebidas.map(transfer => ({
          model: transfer.id_itinerario_solicitante ? 'TRANSBORDO' : 'REBOQUE',
          id: transfer.id,
          groupId: transfer.id_agrupamento || transfer.id,
          itineraryId: transfer.id_itinerario_solicitante || transfer.id_itinerario,
          date: transfer.data_hora_atualizacao || transfer.data_hora,
          equipmentOrigin: {
            id: transfer.id_equipamento_origem,
            plate: transfer.placa_origem,
          },
          equipment: {
            id: transfer.id_equipamento_destino,
            plate: transfer.placa_destino,
          },
          volume: transfer.transferencias.reduce((acc, cur) => acc + (parseInt(cur.volume)), 0),
          transfers: transfer.transferencias
        }));
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar o coletas!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    fillCollectUnloadDate() {
      this.collects = this.collects.map(collect => {
        return {
          ...collect,
          unloadedAt: moment(this.unloadedAt, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
        };
      });
    },

    async showCreatingCollectDialog() {
      if (!this.canChangeCollect) {

        const dataBlock = this.$store.state.settings.gerais.data_bloqueio_edicao_coleta;
        this.$snotify.warning(
          "Não é possivel inserir coletas anteriores a " + moment(dataBlock).format("DD/MM/YY"),
          "Atenção"
        );
        return;
      }

      if (!this.$refs.form.validate()) {
        return;
      }

      return this.$refs.collectDialog.show({
        producer: {},
        collectedAt: moment(this.startedAt, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD'),
        collectStartedAt: moment(this.startedAt, 'DD/MM/YYYY HH:mm').format('HH:mm'),
        tanks: [ ...this.vehicleTanks ].map(tank => {
          return {
            ...tank,
            vol: null,
          };
        }),
        coolers: [],
        condominium: [],
      });
    },

    endDateRule(v) {
      if (!v || v.length !== 16) {
        return true;
      }

      const endedAt = moment(v, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm');
      const startedAt = moment(this.startedAt, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm');

      if (endedAt < startedAt) {
        return 'A data final é menor que a inicial!';
      }

      return true;
    },

    unloadDateRule(v) {
      if (!v || v.length !== 16) {
        return true;
      }

      const unloadedAt = moment(v, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm');
      const startedAt = moment(this.startedAt, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm');

      if (unloadedAt < startedAt) {
        return 'A data de efetivação é menor que a inicial!';
      }

      return true;
    },

    showEditingCollectDialog(collect) {
      let dataInicio = null;

      if (this.dataInicio) {
        dataInicio = this.dataInicio; //Se a data Inicio Itinerario for editada, a data minima de coleta usa ela
      } else {
        dataInicio = moment(this.startedAt, 'DD/MM/YYYY').format('YYYY-MM-DD'); //Se não, utiliza a data ja cadastrada startedAt como minima
      }

      if (this.status == 'PENDING') {
        this.$snotify.warning(
          "Não é possivel Editar coletas na fila de importação ",
          "Atenção"
        );
        return;
      }
      if (!this.canChangeCollect) {

        const dataBlock = this.$store.state.settings.gerais.data_bloqueio_edicao_coleta;
        this.$snotify.warning(
          "Não é possivel editar coletas anteriores a " + moment(dataBlock).format("DD/MM/YY"),
          "Atenção"
        );
        return;
      }
      return this.$refs.collectDialog.show(collect, dataInicio);
    },

    async onCollectSave(collect) {
      if (!this.canChangeCollect) {

        const dataBlock = this.$store.state.settings.gerais.data_bloqueio_edicao_coleta;
        this.$snotify.warning(
          "Não é possivel editar coletas anteriores a " + moment(dataBlock).format("DD/MM/YY"),
          "Atenção"
        );
        return;
      }
      if (!this.id) {
        await this.save();
      }

      if (!this.id) {
        return;
      }
      try {
        this.loading = true;

        let unloadedAt = !_.isEmpty(collect.unloadedAt) ? moment(collect.unloadedAt).format('YYYY-MM-DD HH:mm:ss') : null ;

        if (_.isEmpty(unloadedAt) && !_.isEmpty(this.unloadedAt)) {
          unloadedAt = moment(this.unloadedAt, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss')
        }

        const request = {
          id_itinerario: this.id,
          id_coleta: collect.id,
          data: moment(collect.collectedAt).format('YYYY-MM-DD'),
          data_hora_descarga: this.unloadedAt ? moment(this.unloadedAt, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss') : null,
          hora_chegada: moment(collect.collectStartedAt, 'HH:mm').format('HH:mm:ss'),
          hora_saida: moment(collect.collectEndedAt, 'HH:mm').format('HH:mm:ss'),
          rejeitada: collect.rejected,
          quantidade_rejeitada: collect.rejectedVol,
          codigo_produtor: collect.producer.code,
          id_produtor: collect.producer.id,
          nome_produtor: collect.producer.name,
          id_usuario_coleta: this.driver.id,
          nome_usuario_coleta: this.driver.name,
          placa: this.vehicle.plate,
          id_equipamento: this.vehicle.id,
          veiculo: this.vehicle.description,
          id_rota: this.route.id,
          nome_rota: this.route.description,
          temperatura: collect.temp,
          numero_amostra: collect.sample,
          alizarol: collect.alizarol,
          observacao: collect.obs,
          quantidade_coleta: collect.totalVol,
          resfriadores: JSON.stringify(collect.coolers.map(cooler => ({
            numero_resfriador: cooler.position,
            temperatura: parseFloat(cooler.temperature),
            url_temperatura: cooler.temperaturePhoto,
            numero_amostra: cooler.sampleNumber,
            valor_regua: cooler.ruler,
            url_regua: cooler.rulerPhoto,
            justificativa: cooler.justification,
            rejeitada: !cooler.ignore && cooler.rejected,
            quantidade_rejeitada: !cooler.ignore ? cooler.rejectedVolume : 0,
            alizarol: cooler.alizarol,
            ignorar: cooler.ignore,
          }))),
          condominio: !_.isEmpty(collect.condominium) ? JSON.stringify(collect.condominium) : '[]',
          tipo_lancamento: collect.insertionType === 'APP' ? 'APP_WEB' : collect.insertionType,
        };

        if (this.status === 'PENDING') {
          request.sync_type = 2;
        }

        collect.tanks.forEach(tank => {
          return _.set(request, tank.key, tank.vol);
        });

        const { data } = await this.$axios.post(
          `/coleta/salva`,
          this.$qs.stringify({
            ...request,
          }),
        );

        if (!data.codigo) {
          throw data;
        }

      } catch (e) {
        console.log(e);

        this.$snotify.error("Oops, ocorreu um erro ao salvar a coleta!", "Atenção");
      } finally {
        this.$refs.collectDialog.close();
        // Aguarda carregar as coletas, para continuar exibindo o loading na tela
        // Pois os usuários disseram que tinha que editar "três vezes" para salvar
        await this.loadCollects();
      }
    },

    async excludeCollect(collect) {

      if (this.status == 'PENDING') {
        this.$snotify.warning(
          "Não é possivel Excluir coletas na fila de importação ",
          "Atenção"
        );
        return;
      }
      if (!this.canChangeCollect) {

        const dataBlock = this.$store.state.settings.gerais.data_bloqueio_edicao_coleta;
        this.$snotify.warning(
          "Não é possivel editar coletas anteriores a " + moment(dataBlock).format("DD/MM/YY"),
          "Atenção"
        );
        return;
      }
      if (!(await this.$root.$confirm(
        'Atenção',
        `Deseja realmente excluir a coleta do produtor <b>${collect.producer.name}</b>?<br><br>Esta ação não poderá ser revertida!<br><br>`,
        { color: 'red', token: 'EXCLUIR' }
      ))
      ) {
        return;
      }
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/coleta/inativa`,
          this.$qs.stringify({
            id_coleta: collect.id,
          }),
        );

        if (!_.isArray(data)) {
          throw data;
        }

        const [ { codigo } ] = data;

        if (codigo !== 1) {
          throw data;
        }

        this.collects = this.collects.filter(o => o.id !== collect.id);
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao excluir a coleta!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    async loadVisits() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/visita/getItineraryVisit`,
          { id_itinerario: this.id }
        );

        if (!_.isArray(data)) {
          throw new Error(data);
        }

        this.visits = data.map(visit => {
          return {
            id: visit.id_visita,
            visitedAt: visit.data_visita ? moment(visit.data_visita).format('YYYY-MM-DD') : null,
            startedAt: moment(visit.hora_chegada, 'HH:mm:ss').format('HH:mm'),
            endedAt: moment(visit.hora_saida, 'HH:mm:ss').format('HH:mm'),
            obs: visit.observacao,
            types: visit.tipo,
            pictures: _(visit.fotos).map((value, key) => ({
              src: key,
              thumb: key,
              caption: value,
            })).filter(picture => picture.src).value(),
            historical: visit.historico,
            producer: {
              id: visit.id_produtor,
              code: visit.codigo_laticinio,
              name: visit.nome,
              pos: {
                lat: parseFloat(visit.end_lat_ini),
                lng: parseFloat(visit.end_lng_ini),
              },
            },
          };
        });
      } catch (e) {
        console.error(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar o visitas!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    async showCreatingVisitDialog() {
      if (!this.$refs.form.validate()) {
        return;
      }
      return this.$refs.visitDialog.show({
        producer: {},
        visitedAt: moment().format('YYYY-MM-DD')
      });
    },

    showEditingVisitDialog(visit) {
      return this.$refs.visitDialog.show(visit);
    },

    async onVisitSave(visit) {
      if (!this.id) {
        await this.save();
      }

      if (!this.id) {
        return;
      }
      try {
        this.loading = true;

        const payload = {
          id_itinerario: this.id,
          id_visita: visit.id,
          data_visita: visit.visitedAt,
          hora_chegada: moment(visit.startedAt, 'HH:mm').format('HH:mm:ss'),
          hora_saida: moment(visit.endedAt, 'HH:mm').format('HH:mm:ss'),
          codigo_produtor: visit.producer.code,
          id_produtor: visit.producer.id,
          nome_produtor: visit.producer.name,
          tipo: visit.types,
          observacao: visit.obs,
          id_tecnico: this.driver.id,
        };

        const { data } = await this.$axios.post(
          `/visita/salva`,
          payload,
        );

        if (!data.codigo) {
          throw 'Erro ao salvar';
        }

        this.loadVisits();
      } catch (e) {
        console.log(e);

        this.$snotify.error("Oops, ocorreu um erro ao salvar a visita!", "Atenção");
      } finally {
        this.loading = false;

        this.$refs.visitDialog.close();
      }
    },

    async excludeVisit(visit) {
      if (!(await this.$root.$confirm(
        'Atenção',
        `Deseja realmente excluir a visita do produtor <b>${visit.producer.name}</b>?<br><br>Esta ação não poderá ser revertida!<br><br>`,
        { color: 'red', token: 'EXCLUIR' }
      ))
      ) {
        return;
      }
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/visita/excluir`,
          this.$qs.stringify({
            id_visita: visit.id,
          }),
        );

        if (!_.isArray(data)) {
          throw data;
        }

        const [ { codigo } ] = data;

        if (codigo !== 1) {
          throw data;
        }

        this.visits = this.visits.filter(o => o.id !== visit.id);
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao excluir a coleta!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    async loadCommercialVisits() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/comercial/getItineraryVisit`,
          { id_itinerario: this.id }
        );

        if (!_.isArray(data)) {
          throw new Error(data);
        }

        this.visits = data.map(visit => {
          return {
            id: visit.id_visita,
            visitedAt: visit.data_visita ? moment(visit.data_visita).format('YYYY-MM-DD') : null,
            startedAt: moment(visit.hora_chegada, 'HH:mm:ss').format('HH:mm'),
            endedAt: moment(visit.hora_saida, 'HH:mm:ss').format('HH:mm'),
            obs: visit.observacao,
            types: visit.tipo,
            pictures: _(visit.fotos).map((value, key) => ({
              src: key,
              thumb: key,
              caption: value,
            })).filter(picture => picture.src).value(),
            customer: {
              id: visit.id_cliente,
              name: visit.nome_cliente,
              pos: {
                lat: parseFloat(visit.end_lat_ini),
                lng: parseFloat(visit.end_lng_ini),
              },
            },
          };
        });
      } catch (e) {
        console.error(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar as visitas!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    async showCreatingCommercialVisitDialog() {
      if (!this.$refs.form.validate()) {
        return;
      }
      return this.$refs.commercialVisitDialog.show({
        customer: {},
        visitedAt: moment().format('YYYY-MM-DD')
      });
    },

    showEditingCommercialVisitDialog(visit) {
      return this.$refs.commercialVisitDialog.show(visit);
    },

    async onCommercialVisitSave(visit) {
      if (!this.id) {
        await this.save();
      }

      if (!this.id) {
        return;
      }
      try {
        this.loading = true;

        const payload = {
          id_itinerario: this.id,
          id_visita: visit.id,
          data_visita: visit.visitedAt,
          hora_chegada: moment(visit.startedAt, 'HH:mm').format('HH:mm:ss'),
          hora_saida: moment(visit.endedAt, 'HH:mm').format('HH:mm:ss'),
          id_cliente: visit.customer.id,
          nome_cliente: visit.customer.name,
          tipo: visit.types,
          observacao: visit.obs,
          id_vendedor: this.driver.id,
        };

        const { data } = await this.$axios.post(
          `/comercial/salvarVisita`,
          payload,
        );

        if (!data.codigo) {
          throw 'Erro ao salvar';
        }

        this.loadCommercialVisits();
      } catch (e) {
        console.log(e);

        this.$snotify.error("Oops, ocorreu um erro ao salvar a visita!", "Atenção");
      } finally {
        this.loading = false;

        this.$refs.commercialVisitDialog.close();
      }
    },

    async excludeCommercialVisit(visit) {
      if (!(await this.$root.$confirm(
        'Atenção',
        `Deseja realmente excluir a visita do cliente <b>${visit.customer.name}</b>?<br><br>Esta ação não poderá ser revertida!<br><br>`,
        { color: 'red', token: 'EXCLUIR' }
      ))
      ) {
        return;
      }
      try {
        this.loading = true;

        const { data } = await this.$axios.post(`/comercial/excluirVisita`, {
          id_visita: visit.id
        });

        if (!_.isObject(data)) {
          throw data;
        }

        if (data.codigo !== 1) {
          throw data;
        }

        this.visits = this.visits.filter(o => o.id !== visit.id);
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao excluir a coleta!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    async loadShippings() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/shipping`, { params: { id_itinerario: this.id } });

        this.shippings = data.map(shipping => {
          return {
            id: shipping.id,
            dateTime: shipping.data_hora ? moment(shipping.data_hora).format('YYYY-MM-DD HH:mm') : null,
            code: shipping.codigo,
            invoiceNumber: shipping.numero_nota,
            notes: shipping.observacao,
            customer: {
              id: shipping.id_cliente,
              name: shipping.cliente?.nome,
            },
            products: (shipping.produtos || []).map(product => ({
              id: product.id,
              item: {
                id: product.id_unidade_medida || product.id_item,
                itemId: product.id_item,
                name: product.item?.nome,
                code: product.unidade?.codigo || product.item?.cod_item,
                description: `${product.item?.nome} ${product.unidade_descricao ? `(${product.unidade_descricao})` : ''}`,
                type: product.item?.tipo,
                measurement: product.unidade_medida,
                measurementId: product.id_unidade_medida,
                measurementDescription: product.unidade_descricao,
                conversionFactor: product.fator_conversao || 1,
                defaultMeasurement: product.item?.unidade,
              },
              quantity: parseFloat(product.quantidade),
              shipped: parseFloat(product.quantidade_expedida) / product.fator_conversao,
            })),
          };
        });
      } catch (e) {
        console.error(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar as visitas!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    async showCreatingShippingDialog() {
      if (!this.$refs.form.validate()) {
        return;
      }
      return this.$refs.shippingDialog.show({
        customer: {},
        dateTime: null,
      });
    },

    showEditingShippingDialog(shipping) {
      return this.$refs.shippingDialog.show(shipping);
    },

    async onShippingSave(shipping) {
      if (!this.id) {
        await this.save();
      }

      if (!this.id) {
        return;
      }

      try {
        this.loading = true;

        const payload = {
          id_itinerario: this.id,
          data_hora: shipping.dateTime,
          codigo: shipping.code,
          numero_nota: shipping.invoiceNumber,
          id_cliente: shipping.customer.id,
          observacao: shipping.notes,
          produtos: shipping.products.map(product => ({
            id: product.id,
            id_item: product.item.itemId,
            quantidade: product.quantity,
            unidade_medida: product.item.measurement,
            id_unidade_medida: product.item.measurementId,
            unidade_descricao: product.item.measurementDescription,
            quantidade_real: product.quantity * (product.item.conversionFactor || 1),
            unidade_real: product.item.defaultMeasurement,
          }))
        };

        const { data } = !shipping.id
          ? await this.$axios.post(`/shipping`, payload)
          : await this.$axios.put(`/shipping/${shipping.id}`, payload);

        if (shipping.id) {
          this.shippings = this.shippings.map(item => {
            if (item.id === shipping.id) {
              return { ...shipping };
            }
            return item;
          });
        } else {
          this.shippings.push({
            ...shipping,
            id: data.id
          });
        }
      } catch (e) {
        console.log(e);

        this.$snotify.error("Oops, ocorreu um erro ao salvar a entrega!", "Atenção");
      } finally {
        this.loading = false;

        this.$refs.shippingDialog.close();
      }
    },

    async excludeShipping(shipping) {
      if (shipping.products.some(item => item.shipped > 0)) {
        this.$snotify.warning("Esta entrega não pode ser excluída, pois já foram expedidas!", "Atenção");
        return
      }
      if (!(await this.$root.$confirm(
        'Atenção',
        `Deseja realmente excluir a entrega do cliente <b>${shipping.customer.name}</b>?<br><br>Esta ação não poderá ser revertida!<br><br>`,
        { color: 'red', token: 'EXCLUIR' }
      ))
      ) {
        return;
      }
      try {
        this.loading = true;

        await this.$axios.delete(`/shipping/${shipping.id}`);

        this.shippings = this.shippings.filter(o => o.id !== shipping.id);
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao excluir a entrega!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    async loadSiloTransfers() {
      try {
        const { data } = await this.$axios.post(
          `/silo/detalheTransferencia`,
          { tipo: this.type == 'transferencia' ? 'unidade' : 'spot', id_itinerario: this.id }
        );

        if (!_.isArray(data)) {
          throw "PHP Error";
        }

        this.history = data.map(history => {
          return {
            silo: {
              id: history.id_silo,
              description: history.descricao,
            },
            transferredVol: parseInt(history.volume),
            transferredAt: history.data,
            obs: history.observacao,
            inOut: history.entrada_saida === 1 ? 'IN' : 'OUT',
          };
        })
      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar as trasnferências!",
          "Atenção"
        );
      }
    },

    async onItineraryPrint() {
      return this.$emit('onItineraryPrint', this.itinerary);
    },

    dateValidate(value, format) {
      if (_.isEmpty(value)) {
        return '';
      }

      return moment(value, format).isValid();
    },

    async save() {
      try {
        const valid = this.$refs.form.validate();

        if (!valid) {
          return;
        }

        if (!this.canChangeCollect) {

          const dataBlock = this.$store.state.settings.gerais.data_bloqueio_edicao_coleta;
          this.$snotify.warning(
            "Não é possivel editar coletas anteriores a " + moment(dataBlock).format("DD/MM/YY"),
            "Atenção"
          );
          return;
        }

        this.loading = true;

        const request = {
          id_itinerario: this.id,
          data_hora_inicio: this.startedAt ? moment(this.startedAt, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss') : null,
          data_hora_fim: this.endedAt ? moment(this.endedAt, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss') : null,
          km_inicial: this.distance.startedAt || null,
          km_final: this.distance.endedAt || null,
          km_planejado: this.distance.planned || null,
          km_ajuste_manual: this.distance.manual || false,
          id_equipamento: this.vehicle.id,
          placa: this.vehicle.plate,
          veiculo: this.vehicle.description,
          id_pessoa: this.driver.id || null,
          nome_pessoa: this.driver.name || null,
          tipo: this.type, // coleta, visita, spot, transferencia, comercial
        };

        if (['coleta', 'visita', 'comercial'].includes(this.type)) {
          request.id_rota = this.route.id;
          request.descricao_rota = this.route.description;
        }

        if (this.type == 'coleta') {
          request.volume_total = this.volume;
          request.data_hora_descarga = this.unloadedAt ? moment(this.unloadedAt, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss') : null;
          request.mensagem_rodape = this.footerMessage;
        }

        if (this.type == 'transferencia') {
          request.id_laticinio_transferencia = this.transferDairy.id;
          request.nome_laticinio_transferencia = this.transferDairy.name;
          request.volume_total = this.volume;
        }

        if (this.type == 'spot') {
          request.id_empresa = this.company.id;
          request.nome_empresa = this.company.name;
          request.tipo_spot = this.spotType;
          request.volume_total = this.volume;
        }

        if (this.type == 'expedicao') {
          request.codigo = this.code;
          request.descricao_rota = this.route.description;

          request.id_transportadora = this.carrier.id;
          request.nome_transportadora = this.carrier.name;
        }

        if (['spot', 'expedicao'].includes(this.type) && this.isThird) {
          request.id_equipamento = undefined;
          request.id_pessoa = undefined;
        }

        if (['transferencia', 'spot', 'coleta'].includes(this.type)) {
          request.id_materia_prima = this.rawMaterialId;
        }

        if (this.status === 'PENDING') {
          request.status = 'APROVADO';
        }

        const { data } = await this.$axios.post(
          `/itinerario/salva`,
          this.$qs.stringify(request),
        );

        if (!_.isObject(data)) {
          throw data;
        }

        if (!data.codigo) {
          throw data;
        }

        if (!this.id) {
          this.id = data.id_itinerario;
          this.$snotify.success("Itinerário salvo com sucesso", "Sucesso");
        }
        else {
          return this.$emit('onItinerarySave');
        }

      } catch (e) {
        console.log(e);

        this.$snotify.error(
          "Oops, ocorreu um erro ao salvar o itinerario!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    showCollectTransfers() {
      this.$refs.trailerTransfersDialog.show(this.collectTransfers);
    },

    showTrailerTransfers() {
      this.$refs.trailerTransfersDialog.show(this.trailerTransfers);
    },

    showTransferDialog() {
      if (!this.canChangeCollect) {
        const dataBlock = this.$store.state.settings.gerais.data_bloqueio_edicao_coleta;
        this.$snotify.warning(
          "Não é possivel editar coletas anteriores a " + moment(dataBlock).format("DD/MM/YY"),
          "Atenção"
        );
        return;
      }
      this.transferDialog.show = true;
    },

    showTrailerDialog() {
      this.transferDialog.show = false;
      this.$refs.transfersDialog.show({
        itineraryId: this.id,
        vehicle: this.vehicle,
        transfers: this.collectTransfers,
        trailers: this.trailers.map(trailer => {
          return {
            ...trailer,
            tanks: trailer.tanks.map((tank, index) => {
              const key = ++index;

              return {
                key: `tanque${key}`,
                label: `Tanque ${key}`,
                capacity: tank,
                vol: null,
              };
            })
          }
        }),
        tanks: this.vehicleTanks.map(tank => {

          const totalVol = this.collects
            .map(collect => collect.tanks.find(({ key }) => key === tank.key))
            .reduce((acc, tank) => acc + tank.vol, 0);

          const totalTransferred = this.collectTransfers
            .flatMap(transfer => transfer.transfers)
            .filter(transfer => `tanque${transfer.origem}` === tank.key)
            .reduce((acc, tank) => acc + tank.volume, 0);

          return {
            ...tank,
            vol: totalVol - totalTransferred
          }
        })
      });
    },

    showAttachDialog() {
      this.transferDialog.show = false;
      this.$refs.attachTrailerDialog.show({
        itineraryId: this.id,
        trailerTransfers: this.trailerTransfers || [],
        vehicle: this.vehicle,
        trailers: this.trailers.filter(item => item.type === 'REBOQUE'),
      });
    },

    showCollectLog(collect) {
      return this.$refs.collectLogDialog.show(collect);
    },

  },

}
</script>
