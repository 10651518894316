<template>
  <tr
    :class="getClass"
  >
    <td
      v-if="props.draggableRows"
    >
      <v-icon
        small
      >
        drag_indicator
      </v-icon>
    </td>

    <td
      v-for="(header, index) in headers" :key="index"
      :style="getContentStyle(header)"
    >
      <template v-if="hasItemSlot(header)">
        <slot-render-wrapper
          :render-fn="() => renderSlot(header)"
        />
      </template>
      <template v-else>
        <div >
          {{ getNonSlotValue(header) }}
        </div>
      </template>
    </td>
  </tr>
</template>

<style lang="scss">
.draggable-row {
  cursor: move !important;
}
</style>

<script setup>
import { computed, ref, reactive, watch, onBeforeMount } from 'vue'
import get from 'lodash/get';

const SlotRenderWrapper = {
  functional: true,
  props: {
    renderFn: {
      type: Function,
      required: true,
    },
  },
  render(h, { props }) {
    return props.renderFn();
  },
};

const props = defineProps({
  item: {
    type: Object,
    default: () => ({}),
  },
  headers: {
    type: Array,
    default: () => [],
  },
  customSlots: {
    type: Object,
    default: () => ({})
  },
  draggableRows: {
    type: Boolean,
  }
});

const getClass = computed(() => {
  if (!props.draggableRows) {
    return {};
  }

  return {
    'draggable-row': true,
  };
});

function getItemSlotName(header) {
  return `item.${header.value}`;
}

function hasItemSlot(header) {
  const slotName = getItemSlotName(header);

  return !!props.customSlots[slotName]
}

function renderSlot(header) {
  const slotName = getItemSlotName(header);
  const slotRenderFunction = props.customSlots[slotName];
  const value = get(props.item, header.value);

  if (!slotRenderFunction) {
    return;
  }

  return slotRenderFunction({ item: props.item, value: value });
}

function getContentStyle(header) {
  const align = header.align ? header.align : "left";

  return `text-align: ${align}`;
}

function getNonSlotValue(header) {
  const value = get(props.item, header.value);
  const formatter = header.formatter;

  if (formatter) {
    return formatter(value);
  }

  return value || '';
}
</script>
