<template>
  <v-autocomplete
    v-model="input"
    :loading="loading"
    :items="items"
    :search-input.sync="search"
    item-value="id"
    item-text="description"
    :return-object="returnObject"
    :filter="() => true"
    hide-no-data
    v-bind="$attrs"
    @change="onChange"
  />
</template>

<script>
import { debounce, isString, capitalize } from 'lodash';

export default {
  name: 'item-autocomplete-filter',

  props: {
    value: {
      type: [ String, Object, Array ],
    },

    types: {
      type: Array,
      default: () => [ 'PRODUTO_ACABADO' ]
    },

    hideMeasurementDescription: {
      type: Boolean,
      default: false,
    },

    itemTypes: Array,

    showOtherMeasurements: Boolean,
    returnObject: Boolean,
    clearOnSelection: Boolean,
  },

  data() {
    return {
      input: null,
      loading: false,
      items: [],
      search: ''
    }
  },

  watch: {
    async value() {
      this.input = this.value;
      if (this.value && this.value.name) {
        await this.fetch(this.value.name);
      }
    },

    search(val) {
      if (val && val !== this.input) {
        this.queryProducts(val);
      }
    },
  },

  created() {
    this.input = this.value;
  },

  async mounted() {
    if (this.value) {
      if (isString(this.value)) {
        await this.loadProductById(this.value);
      } else if (this.value.itemId) {
        await this.loadProductById(this.value.itemId);
      }
      else if (this.value.name) {
        await this.fetch(this.value.name);
      }
    }
  },

  methods: {
    queryProducts: debounce(async function(busca) {
      await this.fetch(busca);
    }, 1000),

    async fetch(busca) {
      try {
        if (!busca || this.items.find(item => item.description.includes(busca))) {
          return;
        }

        this.loading = true;

        const { data } = await this.$axios.get(`/item`, { params: {
            exibir_outras_unidades: this.showOtherMeasurements ? 1 : 0,
            tipos: this.types,
            tipos_item: this.itemTypes,
            busca
          } });

        this.items = data.map(o => this.parseProduct(o));

      } catch (error) {
        console.log(error);

        this.$snotify.error(
          "Oops, ocorreu um erro ao carregar os produtos!",
          "Atenção"
        );
      } finally {
        this.loading = false;
      }
    },

    async loadProductById(id) {
      try {
        const { data } = await this.$axios.get(`/item/${id}`);

        const item = this.parseProduct(data);

        this.items.push(item);

        this.$emit("input", item);
      } catch (error) {
        console.warn(error);
      }
    },

    parseProduct(data) {
      return {
        id: data.id_unidade_medida || data.id_item,
        itemId: data.id_item,
        measurementId: data.id_unidade_medida,
        measurement: data.unidade,
        measurementDescription: data.unidade_descricao,
        defaultMeasurement: data.unidade_padrao,
        tareWeight1: parseFloat(data.peso_tara_1) || 0,
        grossWeight: parseFloat(data.peso_bruto) || 0,
        tareWeight2: parseFloat(data.peso_tara) || 0,
        code: data.cod_item,
        name: data.nome.trim(),
        sif: data.sif,
        sifDipoa: data.sif_dipoa,
        barcodes: data.codigos_barras,
        duncodes: data.codigos_dun,
        barcode: data.codigo_barras,
        defaultBarcode: data.cod_barras_padrao,
        description: `${data.cod_item} - ${data.nome.trim()}` + (!this.hideMeasurementDescription && data.unidade_descricao ? ` (${data.unidade_descricao})` : ''),
        type: data.tipo,

        conversionFactor: parseFloat(data.fator_conversao) || 1,
        price: data.valor ? parseFloat(data.valor) : null,
        requiresLot: data.controlar_lotes || false,
        manualLot: data.lote_manual || false,
        stockControl: data.controle_estoque,
        fixedWeight: data.peso_fixo,
        saleByKilo: data.venda_por_kilo,
        group: {
          value: data.id_item_grupo,
          text: data.nome_item_grupo,
        },
      };
    },

    onChange(event) {
      if (this.clearOnSelection) {
        this.input = null;
        this.items = [];
        this.$emit("input", null);
      }

      // atualiza o v-model do componente
      this.$emit("input", event || {});

      this.$emit("change", event);
    },
  }
}
</script>
