<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="orders-list"
  >
    <ComercialOrderListTable
      ref="orderListTable"
      :status="props.status"
      group-by="shipping.shipment.code"
      show-expand
      @dblclick:row="showOrder"
    >
      <template #[`item.sellers`]="{ value }">
        <v-chip
          v-for="seller in value"
          small
        >
          {{ seller.name }}
        </v-chip>
      </template>

      <template #[`item.discount`]="{ item }">
          <span v-if="item.discount">
            {{ formatCurrency(item.discount) }}
            <small>
              ({{ formatNumber(item.discountPerc) }} %)
            </small>
          </span>
      </template>

      <template #[`item.action`]="{ item }">
        <v-menu
          bottom
          right
          min-width="200"
        >
          <template #activator="{ on }">
            <v-btn
              icon
              v-on="on"
            >
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              @click="showOrder(item)"
            >
              <v-list-item-icon>
                <v-icon>edit</v-icon>
              </v-list-item-icon>

              <v-list-item-title>Editar Pedido</v-list-item-title>
            </v-list-item>

            <div v-if="actions.length > 0">
              <v-subheader>
                Ações
              </v-subheader>

              <v-divider />

              <v-list-item
                v-for="action in actions"
                :key="action.id"
                @click="onActionClick(action, item)"
              >
                <v-list-item-icon>
                  <v-icon>{{ action.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-title>{{ action.label }}</v-list-item-title>
              </v-list-item>
            </div>
          </v-list>
        </v-menu>
      </template>

      <template #expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          class="pa-0 text-center"
        >
          <v-container>
            <v-row>
              <v-col cols="12">
                <template
                  v-for="(product, idx) of item.products"
                >
                  <v-row
                    :key="idx"
                  >
                    <v-col
                      cols="3"
                    >
                      <person-autocomplete-filter
                        v-model="product.supplier"
                        label="Fornecedor"
                        type="DAIRY"
                        :filled="false"
                        prepend-inner-icon="add_business"
                        persistent-hint
                        hide-details
                        disabled
                      />
                    </v-col>
                    <v-col
                      cols="4"
                    >
                      <v-text-field
                        :value="product.item.name"
                        label="Item"
                        prepend-inner-icon="reorder"
                        readonly
                        persistent-hint
                        hide-details
                        disabled
                      />
                    </v-col>
                    <v-col
                      cols="2"
                    >
                      <masked-text-field
                        v-model="product.quantity"
                        label="Quantidade"
                        prepend-inner-icon="iso"
                        persistent-hint
                        hide-details
                        unmask
                        :mask="{ mask: Number, min: 0 }"
                        disabled
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-select
                        v-model="product.shippingFormat"
                        label="Formato"
                        placeholder=" "
                        hide-details
                        disabled
                        :items="[
                            { text: 'Caixa Solta', value: 'CAIXA_SOLTA' },
                            { text: 'Paletizado', value: 'PALLET' },
                          ]"
                      />
                    </v-col>
                  </v-row>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </td>
      </template>
    </ComercialOrderListTable>

    <OrderApprovalDialog
      ref="comercialApprovalDialog"
      @save="fetch"
    />

    <OrderCancelDialog
      ref="comercialCancelOrderDialog"
      @save="fetch"
    />

    <OrderChangeDialog
      ref="comercialChangeStatusDialog"
      @save="fetch"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="blue"
            @click="showOrder({id: null})"
            v-on="on"
          >
            <v-icon>
              add
            </v-icon>
          </v-btn>
        </template>
        Novo Pedido
      </v-tooltip>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="green darken-1"
            @click="exportExcel"
            v-on="on"
          >
            <v-icon>
              backup_table
            </v-icon>
          </v-btn>
        </template>
        Download (Excel)
      </v-tooltip>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="orange darken-1"
            @click="print"
            v-on="on"
          >
            <v-icon>
              print
            </v-icon>
          </v-btn>
        </template>
        Imprimir
      </v-tooltip>
    </v-speed-dial>

    <order-dialog
      ref="orderDialog"
      @close="fetch()"
    />
  </v-container>
</template>

<style lang="scss">
.orders-list {
  .financial-badge {
    font-size: 16px;
    position: absolute;
    top: -10px;
    right: 0px;
  }

  .report-card {
    margin-top: 5px !important;

    > .v-card__title{
      background: rgba(0, 0, 0, 0.5);
      color: rgba(255, 255, 255, 0.7);;
      font-size: 1.1rem;
      align-items: baseline;
    }
  }

  .report-table {
    background: transparent !important;

    table {
      thead > tr {
        background: rgba(0, 0, 0, 0.5);
      }

      tbody {
        background: rgba(255, 255, 255, 0.08);
        color: white !important;

        tr:hover {
          background: rgba(0, 0, 0, 0.2) !important;
        }
      }
    }

    .v-data-footer {
      background: rgba(0, 0, 0, 0.5);
    }
  }
}
</style>

<script setup>
import { computed, reactive, ref, onMounted, getCurrentInstance, provide } from "vue";

import moment from "moment-timezone";

import { useStore } from '@/Support/Resources/vuex.js';
import { useOrders } from "@/Domains/Orders/Composable/Orders";

import OrderApprovalDialog from '@/Domains/Orders/Components/Dialogs/OrderActions/OrderApprovalDialog.vue';
import OrderCancelDialog from '@/Domains/Orders/Components/Dialogs/OrderActions/OrderCancelDialog.vue';
import OrderChangeDialog from '@/Domains/Orders/Components/Dialogs/OrderActions/OrderChangeDialog.vue';
import OrderDialog from '@/Domains/Orders/Components/Dialogs/OrderDialog.vue';

import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";

import ComercialOrderListTable from "@/Domains/Orders/Components/Tables/ComercialOrderListTable.vue";

moment.locale('pt');

const props = defineProps({
  status: String,
  statusIn: Array,
  statusNotIn: Array,
  settings: Object,
  workflow: Array,
});

const { proxy: { $snotify, $root } } = getCurrentInstance();

const store = useStore();
const orders = useOrders();

const orderListTable = ref(null);

const orderDialog = ref(null);
const comercialApprovalDialog = ref(null);
const comercialCancelOrderDialog = ref(null);
const comercialChangeStatusDialog = ref(null);

const billDialog = reactive({
  show: false,
  id: null,
});

// Permissions
const settings = computed(() => {
  return store.state.settings || {};
});

const userResources = computed(() => {
  return settings.value.recursosUsuario || [];
});

const userProfile = computed(() => {
  return settings.value.user || {};
});

const isAdmin = computed(() => {
  return settings.value.tipoAcesso === 'admin' || settings.value.user.id_cargo === 1;
});

const hasFinancialAccess = computed(() => {
  return isAdmin.value || userResources.value.some(o => o.recurso === "orders-generate-financial" && o.tipo === "COMPONENTE");
});

const hasApprovalAccess = computed(() => {
  return isAdmin.value || (userProfile.value.id_cargo === 13 && !!userProfile.value.id_perfil_comercial);
});

const actions = computed(() => {
  return props.settings.actions.map(action => {
    return {
      ...action,
      newStatus: props.workflow.find(({ id }) => id === action.newStatusId),
    }
  });
});

onMounted(() => {
  return fetch();
});

async function fetch() {
  return orderListTable.value.fetch();
}

function onActionClick(action, order) {
  switch (action.action) {
    case 'APROVAR_PEDIDO':
      return comercialApprovalDialog.show(order, action);
    case 'CANCELAR_PEDIDO':
      return comercialCancelOrderDialog.show(order, action);
    case 'ALTERAR_STATUS':
      return comercialChangeStatusDialog.show(order, action);
  }
}

function exportExcel() {
  return orderListTable.value.exportExcel();
}

function print() {
  return orderListTable.value.print();
}

const dateFormat = (value, format) => !value ? '-' : moment(value).format(format);
const formatCurrency = (value) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
const formatNumber = (value) => new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 }).format(value);

function showOrder(order) {
  return orderDialog.value.show(order.id);
}

provide('statusIn', props.statusIn);
provide('statusNotIn', props.statusNotIn);
</script>
