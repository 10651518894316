<template>
  <div>
    <v-dialog
      v-model="visible"
      persistent
      scrollable
      max-width="900px"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <v-card class="card-dialog">
        <v-card-title>
          <span class="text-h6">{{ collect?.producer?.name || 'Coleta' }}</span>
          <v-chip
            v-if="rejected"
            dark
            small
            color="red"
            class="ml-3"
          >
            REJEITADA
          </v-chip>
        </v-card-title>
        <v-card-subtitle>{{ title }}</v-card-subtitle>

        <v-card-text>
          <div>
            <v-form
              v-if="collect"
              ref="form"
              lazy-validation
              @submit.prevent="save()"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                  class="pb-0"
                >
                  <v-menu
                    v-model="collectedAtMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    content-class="menu-select"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="collectedAt"
                        label="Data *"
                        placeholder=" "
                        prepend-inner-icon="event"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="collect.collectedAt"
                      :min="dateMin"
                      @input="collectedAtMenu = false"
                    />
                  </v-menu>
                </v-col>

                <v-col
                  cols="6"
                  md="4"
                  class="pb-0"
                >
                  <masked-input
                    v-model="collect.collectStartedAt"
                    label="Hora Chegada *"
                    placeholder=" "
                    prepend-inner-icon="schedule"
                    :mask="{
                      mask: '00:00',
                    }"
                    :rules="[
                      v => !!v || 'Campo obrigatório!',
                      v => /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/.test(v) || 'Hora Incorreta!'
                    ]"
                    :max-length="16"
                  />
                </v-col>

                <v-col
                  cols="6"
                  md="4"
                  class="pb-0"
                >
                  <masked-input
                    v-model="collect.collectEndedAt"
                    label="Hora Saída *"
                    placeholder=" "
                    prepend-inner-icon="schedule"
                    :mask="{
                      mask: '00:00',
                    }"
                    :rules="[
                      v => !!v || 'Campo obrigatório!',
                      v => /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/.test(v) || 'Hora Incorreta!'
                    ]"
                    :max-length="16"
                  />
                </v-col>

                <v-col
                  cols="12"
                  class="pb-0"
                >
                  <v-autocomplete
                    v-model="collect.producer"
                    label="Produtor *"
                    prepend-inner-icon="person_outline"
                    placeholder=" "
                    :items="producers"
                    :rules="[v => !!v.id || 'Campo obrigatório!']"
                    item-value="id"
                    item-text="code_name"
                    return-object
                    required
                    @change="onProducerChange()"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  v-for="tank in collect.tanks"
                  :key="tank.key"
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    v-if="!rejected"
                    v-model.number="tank.vol"
                    :label="tank.label"
                    prepend-inner-icon="opacity"
                    placeholder=" "
                    :rules="[() => totalVol > 0 || '']"
                    required
                    @keypress="disableDotAndComma"
                  />
                  <v-text-field
                    v-else
                    :label="tank.label"
                    prepend-inner-icon="opacity"
                    placeholder=" "
                    disabled
                  />
                </v-col>
              </v-row>

              <v-row
                v-for="cooler of collect.coolers"
                :key="cooler.position"
                class="mt-0"
              >
                <v-col
                  class="py-0"
                  cols="12"
                >
                  <div class="d-flex justify-space-between">
                    <div class="subtitle-1">
                      Resfriador #{{ cooler.position }}
                    </div>

                    <div v-if="cooler.position > 1">
                      <v-checkbox
                        v-model="cooler.ignore"
                        label="Ignorar"
                        class="mt-0 pt-0"
                        hide-details
                      />
                    </div>
                  </div>

                  <v-divider />
                </v-col>
                <v-col
                  class="py-0 flex-grow-1"
                >
                  <v-row>
                    <v-col
                      style="min-width: 120px"
                      class="pb-0"
                    >
                      <v-text-field
                        v-model="cooler.temperature"
                        label="Temperatura *"
                        type="number"
                        :rules="cooler.ignore ? [] : [
                          v => !isNaN(parseFloat(v)) || 'Campo obrigatório!',
                          v => v <= 16 || 'Temperatura Inválida!',
                        ]"
                        prepend-inner-icon="ac_unit"
                        placeholder=" "
                        :disabled="cooler.ignore"
                        @keypress="disableDot"
                      />
                    </v-col>

                    <v-col
                      style="min-width: 120px"
                      class="pb-0"
                    >
                      <v-text-field
                        v-model="cooler.sampleNumber"
                        label="Amostra"
                        prepend-inner-icon="qr_code"
                        placeholder=" "
                        :disabled="cooler.ignore"
                      />
                    </v-col>
                    <v-col
                      style="min-width: 120px"
                      class="pb-0"
                    >
                      <v-select
                        v-model="cooler.alizarol"
                        label="Alizarol *"
                        :rules="cooler.ignore ? [] : [v => !!v || 'Campo obrigatório!']"
                        prepend-inner-icon="biotech"
                        placeholder=" "
                        :items="alizarolOptions"
                        :disabled="cooler.ignore"
                        @change="onAlizarolChange(cooler)"
                      />
                    </v-col>

                    <v-col
                      style="min-width: 170px"
                      class="pb-0"
                    >
                      <v-checkbox
                        v-model="cooler.rejected"
                        color="red"
                        label="Resfriador Rejeitado?"
                        :disabled="cooler.ignore"
                      />
                    </v-col>
                    <v-col
                      style="min-width: 120px"
                      class="pb-0"
                    >
                      <v-text-field
                        v-if="cooler.rejected"
                        v-model.number="cooler.rejectedVolume"
                        label="Volume Rejeitado *"
                        suffix="L"
                        prepend-inner-icon="opacity"
                        placeholder=" "
                        :disabled="cooler.ignore"
                        :rules="cooler.ignore ? [] : [v => !!v || 'Campo obrigatório!']"
                        @keypress="disableDotAndComma"
                      />
                      <v-text-field
                        v-else
                        label="Volume Rejeitado"
                        suffix="L"
                        prepend-inner-icon="opacity"
                        placeholder=" "
                        disabled
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-divider />

              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    label="Volume Total"
                    :value="totalVol"
                    prepend-inner-icon="opacity"
                    suffix="L"
                    placeholder=" "
                    disabled
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="temperature"
                    label="Temperatura"
                    type="number"
                    prepend-inner-icon="ac_unit"
                    placeholder=" "
                    disabled
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-select
                    v-model="alizarol"
                    label="Alizarol"
                    prepend-inner-icon="biotech"
                    placeholder=" "
                    :items="alizarolOptions"
                    disabled
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    :value="rejectedVol"
                    label="Total Rejeitado"
                    prepend-inner-icon="opacity"
                    suffix="L"
                    placeholder=" "
                    disabled
                  />
                </v-col>
              </v-row>

              <v-row v-if="collect.condominium.length>0">
                <v-col md="12">
                  Volume dos produtores (condomínio)
                </v-col>
                <v-col
                  v-for="cond in collect.condominium "
                  :key="cond.id_pessoa"
                  md="4"
                >
                  <v-text-field
                    v-model="cond.volume"
                    prepend-inner-icon="opacity"
                    :label="cond.nome"
                    placeholder=" "
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="collect.obs"
                    label="Observação"
                    placeholder=" "
                  />
                </v-col>
              </v-row>
            </v-form>
            <small>* Campo obrigatório</small>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey darken-1"
            text
            @click="close()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save()"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-overlay
        :value="loading"
        absolute
      >
        <v-card-text>
          Carregando...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";

import MaskedInput from "@/Support/Components/MaskedInput.vue";

export default {

  components: {
    MaskedInput,
  },

  data() {
    return {
      loading: false,

      // Date Picker
      collectedAtMenu: false,

      visible: false,
      title: '',
      collect: null,
      producers: [],
      dateMin: null,
    };
  },

  computed: {

    totalVol() {
      if (_.isEmpty(this.collect) || this.rejected) {
        return 0;
      }

      return _.sumBy(this.collect.tanks, tank => tank.vol || 0);
    },

    collectedAt() {
      if (_.isEmpty(this.collect)) {
        return moment().format('DD/MM/YYYY');
      }

      return moment(this.collect.collectedAt).format('DD/MM/YYYY');
    },

    collectStartedAt() {
      return moment(this.collect.collectStartedAt).format('HH:mm');
    },

    usesAlizarolGrade() {
      return this.$store.state.settings.gerais.utiliza_graduacao_de_alizarol || false
    },

    alizarolOptions() {
      if (this.usesAlizarolGrade) {
        const grades = this.$store.state.settings.gerais.graduacoes_alizarol || []

        if (grades.length > 0) {
          return [
            { value: '72', text: 'Graduação 72 (Rejeitado)' },
            ...grades.map(value => ({ value, text: `Graduação ${value}` }))
          ]
        }

        return [
          { value: '72', text: 'Graduação 72 (Rejeitado)' },
          { value: '74', text: 'Graduação 74' },
          { value: '76', text: 'Graduação 76' },
          { value: '78', text: 'Graduação 78' },
          { value: '80', text: 'Graduação 80' },
        ]
      }

      return [
        { value: 'Positivo', text: 'Positivo' },
        { value: 'Negativo', text: 'Negativo' },
      ]
    },

    temperature() {
      return _.mean(this.collect.coolers.map(cooler => parseFloat(cooler.temperature) || 0))
    },

    alizarol() {
      const cooler = this.collect.coolers.find(cooler => !cooler.rejected && !cooler.ignore)

      if (cooler) {
        return cooler.alizarol
      }

      return this.collect.coolers[0]?.alizarol
    },

    rejected() {
      if (!this.collect) {
        return false
      }

      return this.collect.coolers.every(cooler => cooler.rejected || cooler.ignore)
    },

    rejectedVol() {
      return this.collect.coolers.reduce((acc, cooler) => acc + (cooler.rejected && !cooler.ignore ? cooler.rejectedVolume : 0), 0)
    },

  },

  async mounted() {
    await this.loadProducers();
  },

  methods: {

    async loadProducers() {
      try {
        const { data } = await this.$axios.post(`/pessoa/allProdutores`);

        if (_.isString(data)) {
          throw data;
        }

        this.producers = data.map(producer => {
          return {
            id: producer.id_pessoa,
            code: producer.codigo_laticinio,
            name: producer.nome,
            code_name: producer.codigo_laticinio + ' - ' + producer.nome,
            ccs: parseInt(producer.ccs) || 0,
            cbt: parseInt(producer.cbt) || 0,
            vol: parseInt(producer.litros) || 0,
            condominium: producer.condominio ? JSON.parse(producer.condominio) : [],
            coolersQuantity: parseInt(producer.quantidade_tanques) || 1,
          };
        });
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar os produtores!", "Atenção");
      }
    },

    show(collect, dataInicio) {
      // Verifica se já existe um formulário carregado em memória e o reseta
      if (this.$refs.form) {
        this.$refs.form.reset();
      }

      if (!collect.id) {
        this.title = `Nova Coleta`;
      } else {
        this.title = `Editando Coleta`;
      }

      this.collect = _.cloneDeep(collect);
      this.visible = true;

      this.dateMin = moment(dataInicio).format("YYYY-MM-DD");
    },

    close() {
      this.loading = false;
      this.title = ``;
      this.visible = false;
      this.collect = null;
    },

    onAlizarolChange(cooler) {
      cooler.rejected = ['72', 'Positivo'].includes(cooler.alizarol)
    },

    save() {
      const valid = this.$refs.form.validate();

      if (!valid) {
        return;
      }

      if (this.collect.condominium.length > 0) { //se o volume nao bater mostrar na tela
        const vol_condominium =  _.sumBy(this.collect.condominium, condominio => parseFloat(condominio.volume) || 0);
        if (vol_condominium != this.totalVol && !this.rejected) {
          this.$snotify.error(
            "Volume total diferente da soma dos condôminos",
            "Atenção"
          );
        }
      }

      this.loading = true;

      const tanks = this.collect.tanks.map(tank => ({
        ...tank,
        vol: !this.rejected ? parseFloat(tank.vol) || null : null
      }))

      const collect = {
        ...this.collect,
        tanks,
        temp: this.temperature,
        sample: this.collect.coolers[0].sampleNumber,
        alizarol: this.alizarol,
        rejected: this.rejected,
        rejectedVol: this.rejectedVol,
        totalVol: this.totalVol,
      }

      return this.$emit('onSave', collect);
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),

    onProducerChange() {
      if (this.collect.producer.condominium) {
        this.collect.condominium = this.collect.producer.condominium;
      }

      const coolersQuantity = this.collect.producer.coolersQuantity || 1;

      const coolers = [];

      for (let i = 0; i < coolersQuantity; i++) {
        const cooler = this.collect.coolers[i] || {
          position: i + 1,
          temperature: null,
          sampleNumber: null,
          rejected: false,
          rejectedVolume: null,
          alizarol: null,
        };

        coolers.push(cooler);
      }

      this.collect.coolers = coolers;
    },
  },

}
</script>
