<template>
  <v-overlay
    v-model="dialog"
    absolute
    z-index="500"
  >
    <div
      class="d-flex flex-column align-center justify-center"
      style="min-width: 120px"
    >
      <div :class="large ? 'text-h4 font-weight-medium mb-3' : 'mb-2'">
        {{ message }}
      </div>
      <v-progress-linear
        indeterminate
        rounded
        :height="large ? '8' : undefined"
        :color="color"
        class="mb-0"
      />
    </div>
  </v-overlay>
</template>

<script>

export default {
  data: () => ({
    dialog: false,
    large: false,
    color: 'white',
    message: ''
  }),
  methods: {
    loading(large = false) {
      this.message = 'Carregando...'
      this.dialog = true
      this.large = large
    },
    saving(large = false) {
      this.message = 'Salvando...'
      this.dialog = true
      this.large = large
      if (large) {
        this.color = 'light-blue accent-2'
      }
    },
    saved(large = false) {
      this.message = 'Salvo!'
      this.dialog = true
      this.large = large
      if (large) {
        this.color = 'green accent-2'
      }
    },
    hide() {
      this.dialog = false
      this.large = false
      this.message = ''
      this.color = 'white'
    }
  }
}

</script>
