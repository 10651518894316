<template>
  <div class="mx-5">
    <v-flex>
      <v-tabs
        v-model="tab"
        vertical
        dark
        background-color="transparent"
        class="transparent"
      >
        <v-tab href="#tab-pedido">
          Pedido
        </v-tab>

        <v-tab-item value="tab-pedido">
          <comercial-order-settings />
        </v-tab-item>
      </v-tabs>
    </v-flex>
  </div>
</template>

<script>
import GeneralAppSettings from "@/Domains/Settings/Components/GeneralAppSettings.vue";
import CollectAppSettingsTab from "@/Domains/Settings/Components/CollectAppSettingsTab.vue";
import VisitAppSettings from "@/Domains/Settings/Components/VisitAppSettings.vue";
import ComercialAppSettings from "@/Domains/Settings/Components/ComercialAppSettings.vue";
import ProducerAppSettings from "@/Domains/Settings/Components/ProducerAppSettings.vue";
import ComercialOrderSettings from "@/Domains/Settings/Components/Comercial/ComercialOrderSettings.vue";

export default {

  components: {
    GeneralAppSettings,
    CollectAppSettingsTab,
    ProducerAppSettings,
    ComercialAppSettings,
    VisitAppSettings,
    ComercialOrderSettings,
  },

  data() {
    return {
      tab: "tab-geral",
    };
  },
};
</script>
