var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":400,"persistent":""},model:{value:(_vm.isVisible),callback:function ($$v) {_vm.isVisible=$$v},expression:"isVisible"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.dialog.id ? _vm.dialog.label : 'Novo Status')+" ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"top":"","nudge-bottom":"105","nudge-left":"16","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({style:({
                  backgroundColor: _vm.dialog.color,
                  cursor: 'pointer',
                  height: '35px',
                  width: '35px',
                  borderRadius: '20px',
                  transition: 'border-radius 200ms ease-in-out'
                })},on))]}}]),model:{value:(_vm.showColorPicker),callback:function ($$v) {_vm.showColorPicker=$$v},expression:"showColorPicker"}},[_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-color-picker',{attrs:{"flat":""},model:{value:(_vm.dialog.color),callback:function ($$v) {_vm.$set(_vm.dialog, "color", $$v)},expression:"dialog.color"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"label":"Descrição","outlined":"","hide-details":""},model:{value:(_vm.dialog.label),callback:function ($$v) {_vm.$set(_vm.dialog, "label", $$v)},expression:"dialog.label"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(" Fechar ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Salvar ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }