<template>
  <div>
    <div class="row">
      <v-col cols="3">
        <card-title-settings class="mb-3">
          Status

          <v-spacer />

          <v-btn
            fab
            dark
            x-small
            plain
            @click="onAddStatusClick"
          >
            <v-tooltip left>
              <template #activator="{ on }">
                <v-icon v-on="on">
                  add
                </v-icon>
              </template>
              <span>Inserir Novo Status</span>
            </v-tooltip>
          </v-btn>
        </card-title-settings>

        <v-card style="background: transparent;">
          <draggable
            v-model="statusList"
          >
            <transition-group type="transition">
              <template v-for="(status, index) in statusList">
                <v-hover
                  :key="status.id"
                  v-slot:default="{ hover }"
                >
                  <v-list-item class="mb-2 white rounded">
                    <v-list-item-avatar size="32">
                      <div
                        :style="{
                          backgroundColor: status.color,
                          cursor: 'pointer',
                          height: '30px',
                          width: '30px',
                          borderRadius: '20px',
                          transition: 'border-radius 200ms ease-in-out'
                        }"
                      />
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{ status.label }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-menu>
                        <template #activator="{ on }">
                          <v-btn
                            dark
                            icon
                            v-on="on"
                          >
                            <v-icon color="grey darken-1">
                              more_vert
                            </v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            @click="onEditStatusClick(status)"
                          >
                            <v-list-item-icon>
                              <v-icon>edit</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Editar
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            @click="onDeleteStatusClick(status)"
                          >
                            <v-list-item-icon>
                              <v-icon>delete</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                              Excluir
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item-action>
                  </v-list-item>
                </v-hover>
              </template>
            </transition-group>
          </draggable>
        </v-card>
      </v-col>

      <v-col cols="9">
        <card-title-settings>
          Fluxo de Pedidos
        </card-title-settings>

        <div class="row">
          <div class="col-12">
            <v-card
              dark
              outlined
              color="rgba(255, 255, 255, 0.08)"
              class="mb-3"
            >
              <v-card-text>
                <v-row>
                  <v-col
                    cols="3"
                  >
                    <v-select
                      v-model="initialStatusId"
                      :items="statusList"
                      class="settings-select"
                      label="Status Inicial"
                      persistent-placeholder
                      hint="Status padrão para a criação de um novo pedido"
                      item-text="label"
                      item-value="id"
                      chips
                      filled
                      persistent-hint
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip class="mt-4">
                          <v-avatar
                            :color="item.color"
                            class="mr-3"
                          />
                          <span>{{ item.label }}</span>
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col
                    cols="3"
                  >
                    <v-select
                      v-model="dispatchPlanningStatusId"
                      :items="statusList"
                      class="settings-select"
                      label="Status Planejamento"
                      persistent-placeholder
                      hint="Status para a planejamento de cargas"
                      item-text="label"
                      item-value="id"
                      chips
                      filled
                      persistent-hint
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip class="mt-4">
                          <v-avatar
                            :color="item.color"
                            class="mr-3"
                          />
                          <span>{{ item.label }}</span>
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col
                    cols="3"
                  >
                    <v-select
                      v-model="shipmentStatusId"
                      :items="statusList"
                      class="settings-select"
                      label="Status Expedição"
                      persistent-placeholder
                      hint="Status para a cargas em processo de expedição"
                      item-text="label"
                      item-value="id"
                      chips
                      filled
                      persistent-hint
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip class="mt-4">
                          <v-avatar
                            :color="item.color"
                            class="mr-3"
                          />
                          <span>{{ item.label }}</span>
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col
                    cols="3"
                  >
                    <v-select
                      v-model="concludedStatusId"
                      :items="statusList"
                      class="settings-select"
                      label="Status Concluído"
                      persistent-placeholder
                      hint="Último status do fluxo de pedidos"
                      item-text="label"
                      item-value="id"
                      chips
                      filled
                      persistent-hint
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip class="mt-4">
                          <v-avatar
                            :color="item.color"
                            class="mr-3"
                          />
                          <span>{{ item.label }}</span>
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="3">
                    <v-select
                      v-model="defaultCommissionPerItem"
                      :items="[
                        { text:'Percentual', value: 'PERCENTAGE' },
                        { text:'R$/Unidade de Venda', value: 'AMOUNT_PER_UNIT' },
                      ]"
                      class="settings-select"
                      label="Comissão por Item"
                      persistent-placeholder
                      hint="Comissão padrão por item"
                      filled
                      persistent-hint
                    />
                  </v-col>
                </v-row>

                <v-row
                  v-for="status in statusList"
                  :key="status.id"
                >
                  <v-col cols="12" class="py-0">
                    <comercial-status-item-settings
                      :status-list="statusList"
                      :status="status"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </v-col>
    </div>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="green"
            v-on="on"
            @click.stop="saveSettings"
          >
            <v-icon>save</v-icon>
          </v-btn>
        </template>

        <span>Salvar fluxo de pedidos</span>
      </v-tooltip>

      <v-tooltip left>
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="red"
            v-on="on"
            @click.stop="replicateSettingsOnAllDairies"
          >
            <v-icon>sync</v-icon>
          </v-btn>
        </template>

        <span>Replicar configuração em todas as unidades</span>
      </v-tooltip>
    </v-speed-dial>

    <comercial-status-dialog
      ref="comercialStatusDialog"
      @save="onStatusSave"
    />
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import omit from "lodash/omit";
import { v4 as uuidv4 } from "uuid";

import CardTitleSettings from "@/Domains/Settings/Components/General/CardTitleSettings.vue";
import ComercialStatusItemSettings from "@/Domains/Settings/Components/Comercial/ComercialStatusItemSettings.vue";
import ComercialStatusDialog from "@/Domains/Settings/Components/Comercial/ComercialStatusDialog.vue";
import shipment from "@/Domains/Shipment/Api/Shipment";

export default {
  computed: {
    shipment() {
      return shipment
    }
  },
  components: {
    Draggable,
    CardTitleSettings,
    ComercialStatusItemSettings,
    ComercialStatusDialog,
  },

  data() {
    return {
      initialStatusId: null,
      dispatchPlanningStatusId: null,
      shipmentStatusId: null,
      concludedStatusId: null,

      defaultCommissionPerItem: null,

      swatchStyle: {
        cursor: 'pointer',
        height: '35px',
        width: '35px',
        borderRadius: '20px',
        transition: 'border-radius 200ms ease-in-out'
      },

      statusList: [],
    };
  },

  async mounted() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      const dairyId = this.$store.state.settings?.laticinio?.id;

      if (!dairyId) {
        this.$snotify.error('Escolha o laticínio!', 'Atenção');

        return;
      }

      const { data } = await this.$axios.get(`/settings/dairy/${dairyId}`);

      const { fluxo_pedidos = {} } = data || {};

      this.initialStatusId = fluxo_pedidos.initialStatusId;
      this.dispatchPlanningStatusId = fluxo_pedidos.dispatchPlanningStatusId;
      this.shipmentStatusId = fluxo_pedidos.shipmentStatusId;
      this.concludedStatusId = fluxo_pedidos.concludedStatusId;

      this.defaultCommissionPerItem = fluxo_pedidos.defaultCommissionPerItem;

      this.statusList = fluxo_pedidos.workflow;
    },

    onStatusSave(changedItem) {
      if (changedItem.id) {
        this.statusList = this.statusList.map(status => {
          if (status.id === changedItem.id) {
            return changedItem;
          }

          return status;
        });

        return;
      }

      this.statusList = [
        ...this.statusList,
        {
          ...changedItem,
          id: uuidv4(),
        },
      ];
    },

    onDeleteStatusClick(deletedStatus) {
      this.statusList = this.statusList.filter(status => {
        return status.id !== deletedStatus.id;
      });
    },

    onEditStatusClick(status) {
      return this.$refs.comercialStatusDialog.show(status);
    },

    onAddStatusClick() {
      return this.$refs.comercialStatusDialog.show();
    },

    /**
     *
     * @param replicate Replica as configurações em todas as unidades
     * @returns {Promise<void>}
     */
    async saveSettings(replicate = false) {
      try {
        this.$root.$progressBar.saving();

        const dairyId = this.$store.state.settings?.laticinio?.id;

        if (!dairyId) {
          this.$snotify.error('Escolha o laticínio!', 'Atenção');
          return;
        }

        const { data } = await this.$axios.put(`/settings/dairy/${dairyId}`, {
          replicar_todas_unidades: replicate,
          fluxo_pedidos: {
            initialStatusId: this.initialStatusId,
            dispatchPlanningStatusId: this.dispatchPlanningStatusId,
            shipmentStatusId: this.shipmentStatusId,
            concludedStatusId: this.concludedStatusId,
            defaultCommissionPerItem: this.defaultCommissionPerItem,
            workflow: this.statusList.map(status => {
              const { settings: { statusToShow = [], statusToNotShow = [] } } = status;

              return {
                ...status,
                settings: {
                  ...status.settings,
                  statusToShow: statusToShow.map(item => _.omit(item, ['settings'])),
                  statusToNotShow: statusToNotShow.map(item => _.omit(item, ['settings'])),
                },
              }
            }),
          },
        });

        this.$snotify.success('Configurações salvas com sucesso', 'Sucesso');
      } catch (error) {
        this.$snotify.error('Erro ao salvar as configurações', 'Atenção');
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    replicateSettingsOnAllDairies() {
      return this.saveSettings(true);
    }
  },
};
</script>
