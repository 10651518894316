<template>
  <v-dialog
    v-if="isVisible"
    v-model="isVisible"
    fullscreen
    class="order-dialog"
  >
    <v-card class="d-flex flex-column">
      <v-card-title class="text-h5">
        Pedido de Venda
      </v-card-title>

      <v-divider class="pb-4" />

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-tabs
              slider
              style="border-bottom: 1px #CCD5E0 solid"
            >
              <v-tab class="text--black">Dados do Cliente</v-tab>
            </v-tabs>
          </v-col>
        </v-row>

        <v-form
          ref="form"
          lazy-validation
          @submit.prevent="save"
        >
          <v-row>
            <v-col
              cols="4"
              class="pb-0"
            >
              <person-autocomplete-filter
                v-model="form.customer"
                label="Cliente/Empresa *"
                type="CUSTOMER"
                :hint="getCustomerHint()"
                :filled="false"
                :disabled="!editing"
                :hide-details="false"
                :rules="[v => !!v && !!v.id || 'Campo obrigatório!']"
                persistent-hint
                outlined
                @input="onCustomerSelect"
              />
            </v-col>

            <v-col
              cols="4"
              class="pb-0"
            >
              <v-autocomplete
                v-model="form.sellers"
                label="Representantes"
                :disabled="!editing"
                :items="form.customer?.sellers"
                item-value="id"
                item-text="name"
                return-object
                hide-details
                multiple
                persistent-hint
                chips
                small-chips
                outlined
                clearable
                deletable-chips
                @input="onSeller"
              />
            </v-col>

            <v-col
              v-if="false"
              cols="3"
            >
              <v-select
                v-model="form.priceTable"
                :disabled="!form.customer || !form.customer.id || !editing"
                :items="priceTableList"
                hide-details
                item-text="description"
                item-value="id"
                label="Tabela de Preços"
                persistent-hint
                prepend-inner-icon="rule"
                @change="onPriceTableSelected"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="pb-0 pt-1">
              <v-tabs
                slider
                style="border-bottom: 1px #CCD5E0 solid"
              >
                <v-tab class="text--black">Itens</v-tab>
              </v-tabs>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <template
                v-for="(data, idx) of form.products"
              >
                <order-item
                  v-if="data.active"
                  :key="idx"
                  :index="idx"
                  ref="orderItem"
                  :editing="editing"
                  :item.sync="form.products[idx]"
                  :type="type"
                  :sellers="form.sellers"
                  @removeItem="onRemoveItem(idx)"
                />
              </template>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              v-if="editing"
              cols="12"
            >
              <item-autocomplete-filter
                placeholder="Busque por código ou nome do produto..."
                prepend-inner-icon="inventory"
                :item-types="['VENDA']"
                return-object
                outlined
                hide-details
                clear-on-selection
                @change="addItem"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-tabs
                slider
                style="border-bottom: 1px #CCD5E0 solid"
              >
                <v-tab class="text--black">Detalhes da Venda</v-tab>
              </v-tabs>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              v-if="form.code"
              cols="2"
            >
              <v-text-field
                v-model="form.code"
                label="Número do pedido"
                prepend-inner-icon="qr_code"
                class="order-input"
                filled
                disabled
                hide-details
                outlined
              />
            </v-col>

            <v-col
              cols="2"
            >
              <date-time-text-field
                :value="form.createdAt"
                class="order-input"
                input-format="YYYY-MM-DD HH:mm:ss"
                label="Data da venda"
                prepend-inner-icon="event"
                :disabled="!editing"
                manual
                hide-details
                outlined
              >
              </date-time-text-field>
            </v-col>

            <v-col
              cols="2"
            >
              <date-time-text-field
                v-model="form.scheduledTo"
                input-format="YYYY-MM-DD HH:mm:ss"
                label="Previsão entrega"
                prepend-inner-icon="event"
                :disabled="!editing"
                manual
                hide-details
                outlined
              >
              </date-time-text-field>
            </v-col>

            <v-col
              cols="2"
            >
              <v-text-field
                v-model="form.purchaseOrderCode"
                label="Código do pedido de compra *"
                prepend-inner-icon="qr_code"
                class="order-input"
                :rules="[v => !!v || 'Campo obrigatório!']"
                :disabled="!editing"
                hide-details
                outlined
              />
            </v-col>

            <v-col
              cols="2"
            >
              <v-text-field
                v-model="form.invoiceNumber"
                label="Número da NF"
                placeholder=" "
                prepend-inner-icon="qr_code"
                filled
                outlined
                disabled
                hide-details
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-tabs
                slider
                style="border-bottom: 1px #CCD5E0 solid"
              >
                <v-tab class="text--black">Pagamento</v-tab>
              </v-tabs>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="3">
              <payment-method-select
                v-model="form.paymentSettings.paymentMethodId"
                label="Forma de Pagamento"
                outlined
              />
            </v-col>

            <v-col cols="3">
              <installment-method-select
                v-model="form.paymentSettings.installmentMethodId"
                label="Forma de Parcelamento"
                outlined
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="pt-0">
              <v-tabs
                slider
                style="border-bottom: 1px #CCD5E0 solid"
              >
                <v-tab class="text--black">Totais</v-tab>
              </v-tabs>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="2">
              <v-text-field
                :value="form.products?.length || 0"
                label="Nº de itens"
                prepend-inner-icon="exposure"
                class="order-input"
                hide-details
                outlined
                filled
                disabled
              />
            </v-col>

            <v-col cols="2">
              <v-text-field
                :value="totalQuantityAmount"
                label="Soma das quantidades (KG)"
                prepend-inner-icon="scale"
                class="order-input"
                hide-details
                outlined
                filled
                disabled
              />
            </v-col>

            <v-col cols="2">
              <masked-input
                v-model.number="form.discountPerc"
                label="Desconto"
                :mask="FloatNumberMask"
                prepend-inner-icon="percent"
                class="order-input"
                hide-details
                outlined
                return-unmasked
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="2"
            >
              <money-input
                :value="totalDiscount"
                label="Desconto total"
                prefix="R$"
                class="order-input"
                persistent-hint
                hide-details
                outlined
                disabled
                filled
              />
            </v-col>

            <v-col
              cols="2"
            >
              <money-input
                :value="totalCommissionAdjustment"
                label="Ajuste de Comissão"
                prefix="R$"
                class="order-input"
                persistent-hint
                hide-details
                outlined
                disabled
                filled
              />
            </v-col>

            <v-col
              cols="3"
            >
              <money-input
                :value="subtotal"
                label="Total dos itens"
                prefix="R$"
                class="order-input"
                persistent-hint
                hide-details
                outlined
                disabled
                filled
              />
            </v-col>

            <v-col
              cols="3"
            >
              <money-input
                :value="total"
                label="Valor total da venda"
                prefix="R$"
                class="order-input"
                persistent-hint
                hide-details
                outlined
                disabled
                filled
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-tabs
                slider
                style="border-bottom: 1px #CCD5E0 solid"
              >
                <v-tab class="text--black">Dados adicionais</v-tab>
              </v-tabs>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="form.notes"
                :disabled="!editing"
                auto-grow
                hide-details
                label="Informações adicionais"
                persistent-hint
                prepend-inner-icon="description"
                rows="3"
                outlined
              />
            </v-col>
          </v-row>

          <v-row
            v-if="commissions.length > 0"
          >
            <v-col cols="12">
              <v-tabs
                slider
                style="border-bottom: 1px #CCD5E0 solid"
              >
                <v-tab class="text--black">Comissões</v-tab>
              </v-tabs>
            </v-col>
          </v-row>

          <v-row
            v-for="(commission, index) in commissions"
          >
            <order-seller-commission-item
              :key="index"
              :seller-commission.sync="commission"
              :total="total"
            />
          </v-row>
        </v-form>

        <v-overlay :value="loading || saving">
          <v-card-text>
            {{ loading ? "Carregando..." : "Salvando..." }}
            <v-progress-linear
              class="mb-0"
              color="white"
              indeterminate
            />
          </v-card-text>
        </v-overlay>
      </v-card-text>

      <v-spacer />

      <v-card-actions>
        <v-btn
          outlined
          large
          @click="close"
        >
          Fechar
        </v-btn>

        <v-spacer />

        <v-btn
          large
          outlined
          @click="saveOrder"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.order-input {

  .v-label.v-label--active {
    font-size: 1.2rem !important;
    font-weight: 700 !important;
    padding-right: 8px !important;
    background-color: white !important;
  }

  .v-text-field__prefix {
    margin-top: 0 !important;
    font-weight: 700 !important;
  }

  input {
    font-size: 1.2rem !important;
    margin-top: 0 !important;
  }

  &.v-input--is-disabled {
    input {
      font-weight: 700 !important;
    }

    .v-label.v-label--active {
      background-color: transparent !important;
    }
  }
}
</style>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import MoneyInput from "@/Support/Components/MoneyInput.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import PaymentSettingsDialog from "@/Domains/Orders/Components/PaymentSettingsDialog.vue";
import OrderDialogItem from "@/Domains/Orders/Components/Dialogs/OrderDialogItem.vue";
import ItemAutocompleteFilter from "@/Support/Components/Filters/ItemAutocompleteFilter.vue";
import PaymentMethodSelect from "@/Support/Components/Comercial/PaymentMethodSelect.vue";
import InstallmentMethodSelect from "@/Support/Components/Comercial/InstallmentMethodSelect.vue";

import moment from "moment-timezone";
import DateTimeTextField from "@/Support/Components/DateTimeTextField.vue";
import IMask from "imask";
import MaskedTextField from "@/Support/Components/MaskedTextField.vue";
import MaskedInput from "@/Support/Components/MaskedInput.vue";
import OrderSellerCommissionItem from "@/Domains/Orders/Components/OrderSellerCommissionItem.vue";
import sumBy from "lodash/sumBy";
import first from "lodash/first";

const emptyForm = {
  code: null,
  priceTable: null,
  status: "PENDENTE",
  products: [],
  approval: { status: "PENDENTE" },
  paymentSettings: {},
  sellers: [],
  discountPerc: 0,
  createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
}

export default {
  name: "order-dialog",

  components: {
    OrderSellerCommissionItem,
    MaskedInput,
    MaskedTextField,
    DateTimeTextField,
    PersonAutocompleteFilter,
    MoneyInput,
    PaymentSettingsDialog,
    OrderItem: OrderDialogItem,
    ItemAutocompleteFilter,
    PaymentMethodSelect,
    InstallmentMethodSelect,
  },

  mixins: [ReportMixin],

  props: {
    type: String,
  },

  data() {
    return {
      isVisible: false,

      loading: false,

      saving: false,

      showPaymentSettings: false,

      statusList: [
        { value: "ORCAMENTO", text: "ORÇAMENTO", color: "orange darken-1" },
        { value: "PEDIDO", text: "PEDIDO", color: "blue" },
        { value: "VENDA", text: "VENDA", color: "green darken-1" },
      ],

      form: { ...emptyForm },

      editing: true,

      priceTableList: [
        { id: null, description: "PADRÃO" },
      ],

      priceTable: [],

      FloatNumberMask: {
        mask: "num",
        blocks: {
          num: {
            mask: Number,
            scale: 4,
            min: -999999,
            max: 999999,
          },
        },
      },
    };
  },

  computed: {
    settings() {
      const generalSettings = this.$store.state.settings.gerais || {};

      return generalSettings.fluxo_pedidos || {};
    },

    total() {
      return this.subtotal - this.totalDiscount;
    },
    subtotal() {
      return this.form.products
        .filter(item => !!item.active)
        .reduce((acc, item) => acc + (item.subTotal || 0), 0);
    },
    totalQuantityAmount() {
      return sumBy(this.form.products, 'quantity');
    },
    totalCommissionAdjustment() {
      return this.form.products
        .filter(item => !!item.active)
        .reduce((acc, item) => acc + (item.totalCommissionAdjustment || 0), 0);
    },
    totalDiscount() {
      if (!this.form.discountPerc) {
        return 0.0;
      }

      return this.subtotal * (this.form.discountPerc / 100);
    },
    totalDiscountPerc() {
      return this.form.products.reduce((acc, item) => acc + (item.discountPerc || 0), 0);
    },

    commissions() {
      if (_.isEmpty(this.form.sellers)) {
        return [];
      }

      return this.form.sellers.flatMap(seller => {
        const commissions = this.form.products.flatMap(product => {
          const sellerCommission = product.commissions.find(commission => commission.seller.id === seller.id) || {};

          if (!sellerCommission) {
            return 0;
          }

          return sellerCommission.totalCommissionAmount;
        });

        return {
          seller,
          commission: _.sum(commissions),
        };
      });
    },
  },

  mounted() {
    // return this.show(null);
  },

  methods: {
    async show(orderId = null) {
      this.form = { ...emptyForm };

      this.isVisible = true;
      this.editing = true;

      if (orderId) {
        await this.loadOrder(orderId);
      }
    },

    close() {
      this.reset();

      this.isVisible = false;

      return this.$emit("close");
    },

    async addItem(item) {
      const measurement = await this.getMeasurementByUnit('KG');

      let product = {
        quantity: null,
        measurementId: measurement.id,
        measurement: measurement.unit,
        measurementDescription: measurement.description,

        realQuantity: null,
        realMeasurementId: item.measurementId,
        realMeasurement: item.measurement,
        realMeasurementDescription: item.measurementDescription,

        shippingFormat: null,
        supplier: this.form.supplier || {},
        notes: '',
        price: null,
        item,
        active: 1,
        commissionType: this.settings.defaultCommissionPerItem || 'AMOUNT_PER_UNIT',
        commissionAdjustment: 0.0,
        commissions: [],
      };

      product.commissions = this.fillProductSellersCommissions(product);

      this.form.products.push(product);
    },

    async getMeasurementByUnit(unit) {
      try {
        const { data } = await this.$axios.get(`/unidadeMedida/listaJson`, { params: { nome: unit } });

        const measurement = first(data);

        return {
          id: measurement.id_unidade_medida,
          description: measurement.descricao,
          unit: measurement.sigla,
        }
      } catch (error) {
        console.warn(error);
      }
    },

    onRemoveItem(idx) {
      const { item } = this.form.products[idx];
      /**
       * Se possui ID então é uma edição,
       * marca como active = 0 para inativar no BD
       */
      if (item.id) {
        this.form.products[idx].active = 0;
      } else {
        this.form.products.splice(idx, 1);
      }
    },

    reset() {
      this.$refs.form.resetValidation();

      this.form = { ...emptyForm };
    },

    saveOrder() {
      // this.form.status = 'PEDIDO';
      this.save();
    },

    saveBudget() {
      // this.form.status = 'ORCAMENTO';
      this.save();
    },

    saveSale() {
      // this.form.status = 'VENDA';
      this.save();
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }

      this.saving = true;
      try {

        const { paymentMethodId, installmentMethodId } = this.form.paymentSettings || {};

        let payload = {
          id_movimento: this.form.id,
          cod_pedido: this.form.purchaseOrderCode,
          tipo: 'PEDIDO_COMERCIAL',
          status: this.form.status,
          data_hora_cadastro: this.form.createdAt,
          data_hora_entrega: this.form.scheduledTo,
          observacao: this.form.notes || "",
          desconto: this.totalDiscount,
          desconto_percentual: this.form.discountPerc,
          valor: this.total,
          id_cliente: this.form.customer?.id,
          nome_cliente: this.form.customer?.name,
          id_fornecedor: this.form.supplier?.id || null,
          nome_fornecedor: this.form.supplier?.name || null,
          vendedores: this.form.sellers.map(seller => ({ nome_vendedor: seller.name, id_vendedor: seller.id })),
          id_tabela_preco: this.form.priceTable,
          numero_nota: this.form.invoiceNumber,
          forma_pagamento: {
            id_forma_pagamento: paymentMethodId || null,
            id_forma_parcelamento: installmentMethodId || null,
          },
          itens: this.form.products.map(product => {
            return {
              id_movimento_item: product.id || null,
              id_item: product.item.itemId,
              nome_item: product.item.name,
              tipo: product.item.type,

              quantidade: parseFloat(product.quantity),
              id_unidade_medida: product.measurementId,
              unidade: product.measurement,
              unidade_descricao: product.measurementDescription,

              quantidade_real: product.realQuantity,
              id_unidade_medida_real: product.realMeasurementId,
              unidade_real: product.realMeasurement,
              unidade_real_descricao: product.realMeasurementDescription,

              valor: parseFloat(product.price),
              desconto_percentual: parseFloat(product.discountPerc) || null,
              desconto: parseFloat(product.discountValue) || null,
              valor_incremento: parseFloat(product.increment) || null,
              tipo_ajuste_preco: product.adjustmentType,
              valor_final: parseFloat(product.subTotalWithDiscount),
              id_fornecedor: product.supplier?.id || null,
              nome_fornecedor: product.supplier?.name || null,
              ativo: product.active,
              formato_entrega: product.shippingFormat,
              observacao: product.notes,
              tipo_calculo_comissao: product.commissionType,
              ajuste_comissao_valor: product.commissionAdjustment,
              commissoes: product.commissions.map(commission => ({
                id_vendedor: commission.seller.id,
                nome_vendedor: commission.seller.name,
                verba_valor: commission.commissionAdjustmentBySeller,
                comissao_valor: commission.amount,
                comissao_valor_total: commission.totalCommissionAmount,
              })),
            };
          }),
        };

        const { data } = this.form.id ? await this.$axios.put(`/order/${payload.id_movimento}`, payload) : await this.$axios.post(`/order`, payload);

        if (data.codigo != 1) {
          throw new Error(data.mensagem || data);
        }
        this.form.id = data.id;
        this.form.emitDate = moment().format("DD/MM/YYYY HH:MM");

        this.$snotify.success("Movimento salvo com sucesso!", "Sucesso");

        this.close();
        // @todo Implementar a nova impressão do pedido
        // this.print();
      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao salvar o movimento!", "Atenção");
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    async onCustomerSelect() {
      await this.loadCustomerData(this.form.customer.id);

      this.loadAndVerifyPriceTable();
    },

    getCustomerHint() {
      if (!this.form?.customer?.federalTaxNumber) {
        return '';
      }

      return `CNPJ: ${this.cnpjFormat(this.form?.customer?.federalTaxNumber)}`;
    },

    onSeller() {
      this.form.products = this.form.products.map(product => {
        product.commissions = this.fillProductSellersCommissions(product);

        return product;
      });
    },

    fillProductSellersCommissions(product) {
      return this.form.sellers.map(seller => {
        const hasCommissionAlready = product.commissions.find(commission => commission.seller.id === seller.id);

        if (hasCommissionAlready) {
          return hasCommissionAlready;
        }

        return {
          seller,
          amount: null,
          totalCommissionAmount: null,
        }
      });
    },

    onSupplier(supplier) {
      this.form.products.forEach((item, idx) => {
        this.form.products[idx].item.supplier = supplier;
      });
    },

    async loadOrder(orderId) {
      this.loading = true;
      try {
        const { data } = await this.$axios.get(`/order/${orderId}`);

        if (!data || !data.id_movimento) {
          return;
        }

        const { id_forma_pagamento, id_forma_parcelamento } = data.forma_pagamento || {};

        this.form = {
          id: data.id_movimento,
          code: data.cod_movimento,
          purchaseOrderCode: data.cod_pedido,
          status: data.status,
          notes: data.observacao,
          invoiceNumber: data.numero_nota,
          scheduledTo: data.data_hora_entrega,
          emitDate: moment(data.data_hora_registro).format("DD/MM/YYYY HH:MM"),
          createdAt: data.data_hora_cadastro,
          priceTable: data.id_tabela_preco,
          discount: data.desconto,
          discountPerc: data.desconto_percentual,
          paymentSettings: {
            paymentMethodId: id_forma_pagamento || null,
            installmentMethodId: id_forma_parcelamento || null,
          },
          customer: {
            id: data.cliente.id_pessoa,
            name: data.cliente.nome,
            region: data.cliente.id_regiao || null,
            sellers: data.cliente.vendedores.map(seller => ({ id: seller.id_usuario, name: seller.nome_usuario })),
          },
          supplier: {
            id: data.fornecedor?.id_fornecedor,
            name: data.fornecedor?.nome_fornecedor,
          },
          sellers: data.vendedores.map(seller => ({
            id: seller.id_vendedor,
            name: seller.nome_vendedor,
            commissionPerc: seller.comissao_percentual,
            additionalCommissionAmount: seller.comissao_extra,
            totalOrderCommission: seller.comissao_total_movimento,
          })),
          approval: {
            date: data.data_hora_aprovacao,
          },
          products: data.produtos.map(product => ({
            id: product.id_movimento_item,
            shippingFormat: product.formato_entrega,
            supplier: {
              id: product.id_fornecedor,
              name: product.nome_fornecedor,
            },
            notes: product.observacao,
            price: parseFloat(product.valor) - parseFloat(product.valor_incremento || 0),
            discountPerc: parseFloat(product.desconto_percentual) || null,
            discountValue: parseFloat(product.desconto) || null,
            increment: parseFloat(product.valor_incremento) || null,
            subTotal: parseFloat(product.valor_final) + parseFloat(product.desconto || 0),
            subTotalWithDiscount: parseFloat(product.valor_final),

            // Quantidade sempre em KG
            quantity: parseFloat(product.quantidade),
            measurementId: product.id_unidade_medida,
            measurement: product.unidade,
            measurementDescription: product.unidade_descricao,

            // Em produtos que não são vendidos por KG, calcula com base no peso e peso bruto do item a quantidade real
            realQuantity: parseFloat(product.quantidade_real),
            realMeasurementId: product.id_unidade_medida_real,
            realMeasurement: product.unidade_real,
            realMeasurementDescription: product.unidade_real,

            item: {
              id: product.id_unidade_medida || product.id_item,
              itemId: product.id_item,

              measurement: product.item.unidade,
              measurementDescription: product.item.unidade_descricao,

              name: product.item.nome,
              code: product.item.cod_item,
              type: product.item.tipo,

              grossWeight: product.item.peso_bruto,
            },
            commissionType: product.tipo_calculo_comissao,
            commissions: product.comissoes.map(comissao => ({
              seller: {
                id: comissao.id_vendedor,
                name: comissao.nome_vendedor,
              },
              amount: comissao.comissao_valor,
              commissionAdjustmentBySeller: comissao.verba_valor,
              totalCommissionAmount: comissao.comissao_valor_total,
            })),
            commissionAdjustment: parseFloat(product.ajuste_comissao_valor) || 0.0,
            active: 1,
          })),
        };

        await this.loadPriceTables();

        /**
         * Em uma edição se o id da tabela não estiver na lista,
         * é porque é uma tabela antiga ou que foi removida
         */
        if (this.form.priceTable && !this.priceTableList.find(table => table.id === this.form.priceTable)) {
          this.priceTableList.push({
            id: this.form.priceTable,
            description: `${data.nome_tabela_preco} (${this.dateFormat(data.vigencia_tabela_preco, "DD/MM/YY")})`,
            disabled: true,
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async cancel() {
      if (!(await this.$root.$confirm("Remover pedido", "Tem certeza que deseja remover este pedido?", { color: "red" }))) {
        return;
      }
      try {
        this.loading = true;

        let { data } = await this.$axios.post(`/movimento/remove`, { id_movimento: this.form.id });

        if (data.codigo !== 1) {
          throw new Error(data.mensagem);
        }

        this.reset();
        this.$emit("newOrder");
        this.$snotify.success("Registro excluído com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error(error, "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Carrega a tabela de preço conforme os dados selecionados do formulário
     * e verifica se a tabela seleciona ainda é válida
     */
    async loadAndVerifyPriceTable() {

      await this.loadPriceTables();

      if (this.form.priceTable && !this.priceTableList.find(table => table.id === this.form.priceTable)) {
        this.form.priceTable = null;
        this.onPriceTableSelected();
      }
    },

    /**
     * Carrega a tabela de preço conforme os dados selecionados do formulário
     */
    async loadPriceTables() {
      try {
        this.priceTableList = [{ id: null, description: "PADRÃO" }];

        const customerRegion = this.form.customer ? this.form.customer.region || null : null;
        if (!customerRegion) {
          return;
        }

        const { data } = await this.$axios.post(`/item/tabelasDePrecoPedido`, {
          id_regiao: customerRegion,
          id_vendedor: this.form.seller ? this.form.seller.id : null,
        });

        const priceTableList = data.map(p => ({
          id: p.id_tabela_preco,
          description: `${p.codigo_tabela_preco} - ${p.nome} (${this.dateFormat(p.data_inicio, "DD/MM/YY")})`,
          region: p.id_regiao,
        }));

        this.priceTableList.push(...priceTableList);

      } catch (error) {
        this.$snotify.error("Erro ao carregar as tabelas de preço", "Atenção");
        console.warn(error);
      }
    },

    /**
     * Ao alterar a tabela de preço,
     * percorre os itens para aplicar o valor de acordo com a tabela
     */
    async onPriceTableSelected() {
      for (let item of this.form.products) {
        // this.onItemChanged(item.item);
      }
    },

    /**
     * Consulta os dados do cliente referente às configurações comerciais
     */
    async loadCustomerData(id) {
      try {
        if (!id) {
          this.form.paymentSettings = {};
          return;
        }

        this.loading = true;

        const { data } = await this.$axios.post(`/movimento/dadosCliente`, { id_cliente: id });

        if (!data || !data.id_cliente) {
          throw new Error(data);
        }

        this.form.paymentSettings = {
          paymentMethodId: data.id_forma_pagamento || null,
          installmentMethodId: data.id_forma_parcelamento || null,
        };

        this.form.sellers = this.form.customer?.sellers || [];
        this.form.discountPerc = parseFloat(data.desconto_padrao) || 0.0;
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os dados do cliente!", "Atenção");
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Imprime o Pedido
     */
    print() {
      const nomeLaticinio = this.$store.state.laticinio ? this.$store.state.laticinio.nome : this.$store.state.settings.sistema;
      const html = `
      <div class="container">
        <div class="row">
          <div class="col">
            <h5 class="text-${this.form.status == "VENDA" ? "success" : this.form.status == "PEDIDO" ? "info" : "warning"}">
              ${this.form.status}
            </h5>
            <p>Emitido em ${this.form.emitDate}</p>
          </div>
          <div class="col text-right">
           <h1 class="text-capitalize">${nomeLaticinio}</h1>
          </div>
        </div>
        <hr />
        <div class="row my-2">
          <div class="col">
            <p>${this.type == "PEDIDO_PRODUTOR" ? "Produtor" : "Cliente"}</p>
            <h5>${this.form.customer?.name}</h5>
          </div>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Item</th>
              <th scope="col" class="text-center">Quantidade</th>
              <th scope="col" class="text-center">Valor unitário</th>
              <th scope="col" class="text-center">Desconto</th>
              <th scope="col" class="text-right">Subtotal</th>
            </tr>
          </thead>
          <tbody>
            ${this.form.products.filter(o => o.active).map(({ item }) => `<tr>
              <th scope="row">${item.name}</th>
              <td class="text-center">${item.quantity}</td>
              <td class="text-center">${this.formatCurrency(item.amount)}</td>
              <td class="text-center">${this.formatCurrency(item.discountValue)}</td>
              <td class="text-right">${this.formatCurrency(item.subTotalWithDiscount)}</td>
            </tr>`)}
          </tbody>
        </table>
        <div class="row mb-5">
          <div class="col">
            <div class="align-self-end text-right">
              <span class="mr-2">Sub-total</span>
              <span class="text-info" style="font-size:1.3em">${this.formatCurrency(this.subtotal)}</span>
            </div>
            <div class="align-self-end text-right">
              <span class="mr-2">Desconto</span>
              <span class="text-danger" style="font-size:1.3em">${this.formatCurrency(this.totalDiscount)}</span>
            </div>
            <div class="align-self-end text-right">
              <span class="mr-2">Valor total</span>
              <span class="text-success" style="font-size:2em">${this.formatCurrency(this.total)}</span>
            </div>
          </div>
        </div>
        <hr />
        <p class="text-center text-muted">MilkUp - Gestão de Leite</p>
      </div>
      `;

      return this.printJS({
        documentTitle: this.form.status,
        printable: html,
        type: "raw-html",
        css: ["https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css"],
      });
    },

    dateFormat: (value, format) => !value ? "-" : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(value),
    cnpjFormat(cnpj) {
      if (!cnpj) {
        return '-';
      }

      const masked = IMask.createMask({
        mask: '00.000.000/0000-00',
      });

      masked.resolve(cnpj);

      return masked.value;
    }

  },
};
</script>
