<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="pt-0"
  >
    <v-card
      dark
      color="transparent diff-list"
      style="margin-top:20px"
    >
      <v-card-title>
        <v-col
          cols="3"
        >
          <v-autocomplete
            v-model="filters.dairies"
            :items="dairies"
            label="Laticínio"
            item-text="description"
            item-value="id"
            filled
            hide-details
            return-object
            multiple
            chips
            small-chips
            clearable
          />
        </v-col>

        <v-col
          cols="3"
        >
          <v-select
            v-model="filters.labs"
            :items="labs"
            label="Laboratorio"
            item-text="description"
            item-value="id"
            filled
            hide-details
            return-object
            multiple
            chips
            small-chips
            clearable
          />
        </v-col>

        <v-col
          cols="3"
        >
          <v-autocomplete
            v-model="filters.status"
            :items="status"
            label="Status"
            filled
            return-object
            hide-details
            multiple
            chips
            small-chips
            clearable
          />
        </v-col>

        <v-col
          cols="3"
        >
          <v-text-field
            v-model="filters.search"
            append-icon="search"
            label="Busca"
            filled
            single-line
            hide-details
          />
        </v-col>
      </v-card-title>
      <data-table
        dark
        dense
        :headers="headers"
        :items="dairiesConfigsFiltered"
        :search="filters.search"
        disable-drag
        disable-sort
        @click:row="showDairySettingsDialog"
      >
        <template #[`item.actions`]="{ item }">
          <v-menu
            bottom
            right
          >
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="showDairySettingsDialog(item);">
                <v-list-item-icon>
                  <v-icon>edit</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Editar</v-list-item-title>
              </v-list-item>

              <v-list-item
                small
                @click="destroy(item)"
              >
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Excluir</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template #[`item.status`]="{value}">
          <template v-if="value === 'RUNNING'">
            <v-progress-linear
              indeterminate
              color="blue-grey lighten-4"
            />
          </template>
          <v-chip
            v-else
            :color="getStatus(value).color"
            small
          >
            {{ getStatus(value).label }}
          </v-chip>
        </template>
      </data-table>
    </v-card>

    <lab-dairy-dialog
      ref="labDairyDialog"
      :labs="labs"
      @reload="load"
    />

    <labs-parameter-dialog
      ref="labsParametersDialog"
    />

    <lab-dairy-include-dialog
      ref="labDairyIncludeDialog"
      :labs="labs"
      :dairies="dairies"
      @onDairyConfigInclude="onDairyConfigInclude"
    />

    <v-speed-dial
      fixed
      fab
      dark
      bottom
      right
      open-on-hover
      class="mr-2"
      direction="top"
      transition="slide-y-reverse-transition"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>

      <v-btn
        color="blue"
        dark
        fab
        @click="showSettingIncludeDialog"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>
          Incluir Configuração
        </v-tooltip>
      </v-btn>

      <v-btn
        color="purple"
        dark
        fab
        @click="showLabsParametersDialog"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">
              content_paste_search
            </v-icon>
          </template>
          Laboratórios
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <v-overlay
      v-model="loading"
    >
      Carregando...
    </v-overlay>
  </v-container>
</template>

<style lang="scss">

.diff-list > .v-card__title {
  background: rgba(0, 0, 0, 0.5);
}

.diff-list-table {
  background: transparent !important;

  table {
    thead > tr {
      background: rgba(0, 0, 0, 0.5);
    }

    tbody {
      background: rgba(255, 255, 255, 0.08);
      color: white !important;

      tr:hover {
        background: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }

  .v-data-footer {
    background: rgba(0, 0, 0, 0.5);
  }
}

</style>

<script>
import startCase from "lodash/fp/startCase";
import camelCase from "lodash/fp/camelCase";
import { sortBy } from "lodash/collection";
import isEmpty from "lodash/fp/isEmpty";

import LabDairyDialog from "@/Domains/SettingsMilkup/Labs/Components/LabDairyDialog/MenuDialog.vue";
import LabsParameterDialog from "@/Domains/SettingsMilkup/Labs/Components/LabParameters/LabParameterDialog.vue";
import LabDairyIncludeDialog from "@/Domains/SettingsMilkup/Labs/Components/LabDairyDialog/NewDialog.vue";

const Status = {
  PENDING: {
    color: 'grey darken-4',
    label: 'Teste Pendente',
  },
  ERROR: {
    color: 'red',
    label: 'Falha na Integração',
  },
  SUCCESS: {
    color: 'green',
    label: 'Sucesso'
  },
};

export default {

  components: {
    LabDairyDialog,
    LabsParameterDialog,
    LabDairyIncludeDialog
  },

  data() {
    return {
      filters: {
        search: "",
        labs: [],
        dairies: [],
        status: [],
      },

      loading: false,

      dairiesConfigs: [],

      labs: [],

      dairies: [],

      status: [
        {
          text: 'Pendente',
          value: 'PENDING',
        },
        {
          text: 'Erro',
          value: 'ERROR',
        },
        {
          text: 'Sucesso',
          value: 'SUCCESS',
        },
      ],

      headers: [
        {
          text: "Laticínio",
          value: "dairy.description",
        },
        {
          text: "Laboratório",
          value: "lab.description",
        },
        {
          text: "Última Análise",
          value: "lastSyncedAt",
        },
        {
          text: "Último Status",
          value: "status",
          align: 'end',
          width: 80,
        },
        {
          text: '',
          altText: 'Opções',
          value: 'actions',
          sortable: false,
          align: 'end',
        },
      ],

    };
  },

  computed: {

    dairiesConfigsFiltered() {
      return this.dairiesConfigs.filter(dairyConfig => {
        if (isEmpty(this.filters.dairies) && isEmpty(this.filters.labs) && isEmpty(this.filters.status)) {
          return true;
        }

        const hasDairy = this.filters.dairies.some(({ id }) => id === dairyConfig.dairy.id);
        const hasLab = this.filters.labs.some(({ id }) => id === dairyConfig.lab.id);
        const hasStatus = this.filters.status.some(({ value }) => value === dairyConfig.status);

        return hasDairy || hasLab || hasStatus;
      });
    }

  },

  async mounted() {
    await this.loadLabs();
    await this.loadDairies();
    await this.load();
  },

  methods: {

    async load() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/settings/labs/dairy`);

        this.dairiesConfigs = data.map(dairySetting => {
          const tenantLabel = startCase(camelCase(dairySetting.system));

          const parameters = (dairySetting.parametros || []).map(parameter => {
            return {
              id: parameter.id,
              key: parameter.chave,
              value: parameter.valor,
              description: parameter.descricao,
              type: parameter.tipo,
              options: parameter.opcoes,
            }
          });

          return {
            id: dairySetting.id_laticinio_laboratorio,
            system: {
              key: dairySetting.system,
              name: tenantLabel,
            },
            dairy: {
              id: dairySetting.id_laticinio,
              name: dairySetting.nome_laticinio,
              description: `${tenantLabel} - ${dairySetting.nome_laticinio}`,
            },
            lab: {
              id: dairySetting.id_laboratorio,
              description: dairySetting.nome_laboratorio,
              integrationType: dairySetting.tipo_integracao,
              dateRangeSearch: dairySetting.intervalo_busca,
              integrationMethod: dairySetting.metodo_integracao,
              apiUrl: dairySetting.endpoint_integracao,
            },
            lastSyncedAt: dairySetting.data_hora_ultima_integracao,
            status: dairySetting.status,
            lastChecksum: dairySetting.ultimo_checksum,
            producerSearchType: dairySetting.tipo_busca_produtor,
            parser: {
              code: dairySetting.regex_codigo_laticinio,
              document: dairySetting.regex_cpf_cnpj,
            },
            parameters,
            importAnalysisAboveLimit: dairySetting.importa_analises_acima_do_limite
          }
        });
      } catch (error) {
        console.error(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar a lista de configurações!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    async loadLabs() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/settings/labs`);

        this.labs = data.map(lab => {
          const parameters = (lab.parametros || []).map(parameter => {
            return {
              id: parameter.id,
              key: parameter.chave,
              value: parameter.value || null,
              description: parameter.descricao,
              type: parameter.tipo,
              options: parameter.opcoes,
            }
          });

          return {
            id: lab.id_laboratorio,
            description: lab.nome,
            identifier: lab.identificador,
            integrationType: lab.tipo_integracao,
            dateRangeSearch: parseInt(lab.intervalo_busca) || null,
            apiUrl: lab.endpoint_integracao,
            producerSearchType: lab.tipo_busca_produtor,
            parser: {
              code: lab.regex_codigo_laticinio,
              document: lab.regex_cpf_cnpj,
            },
            parameters,
          }
        });
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar a lista de laboratórios!", "Atenção");

        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async loadDairies() {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/configuracaoMilkup/listaLaticinio`);

        let dairies = data.map(dairy => {
          const tenantLabel = startCase(camelCase(dairy.system));

          return {
            id: dairy.id_pessoa,
            name: dairy.nome,
            description: `${tenantLabel} - ${dairy.nome}`,
            tenant: dairy.system,
          }
        });

        dairies = dairies.filter(dairy => {
          const hide = [
            'laboratorios',
            'demo',
            'lactobom',
          ];

          return !hide.some(hidden => hidden === dairy.tenant)
        });

        dairies = sortBy(dairies, 'tenant');

        this.dairies = dairies;
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar a lista de laboratórios!", "Atenção");

        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Trata evento de clique na linha..
     */
    showDairySettingsDialog(config) {
      return this.$refs.labDairyDialog.show(config)
    },

    showLabsParametersDialog() {
      return this.$refs.labsParametersDialog.show();
    },

    showSettingIncludeDialog() {
      return this.$refs.labDairyIncludeDialog.show();
    },

    async onDairyConfigInclude(dairyConfigId) {
      await this.load();

      const config = this.dairiesConfigs.find(dairyConfig => dairyConfig.id === dairyConfigId);

      return this.showDairySettingsDialog(config);
    },

    async destroy(config) {
      try {
        this.loading = true;

        if (!(await this.$root.$confirm('Remover registro', 'Tem certeza que deseja remover este registro?', { color: 'red' }))) {
          return;
        }

        await this.$axios.delete(`/settings/labs/dairy/${config.id}`);

        await this.load();
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um excluir o laboratórios!", "Atenção");

        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    getStatus(status) {
      return Status[status] || {}
    },

  },
}
</script>

