<template>
  <div>
    <v-menu
      ref="menu"
      v-model="show"
      :close-on-content-click="false"
      transition="slide-y-transition"
      offset-y
      content-class="menu-select"
      :disabled="readonly"
    >
      <template #activator="{ on, attrs }">
        <masked-text-field
          v-if="manual"
          v-model="input"
          :label="label"
          :clearable="clearable"
          placeholder="00/00/0000"
          :mask="{ mask: '00/00/0000' }"
          append-icon="event"
          :readonly="readonly"
          v-bind="$attrs"
          @click:append="show = true"
          @click:clear="onClear"
          @blur="onManualChange"
        />
        <v-text-field
          v-else
          :value="input"
          :label="label"
          readonly
          :clearable="clearable"
          v-bind="{...attrs, ...$attrs}"
          v-on="on"
          @click:clear="onClear"
        >
          <template
            v-for="(_, slotName) in $slots"
            #[slotName]
          >
            <slot :name="slotName" />
          </template>
        </v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        :min="min"
        :max="max"
        :no-title="noTitle"
        scrollable
        @change="onChange"
      />
    </v-menu>
  </div>
</template>

<script>
import MaskedTextField from './MaskedTextField.vue';
import moment from 'moment/moment';

export default {
  components: {
    MaskedTextField
  },

  props: {
    value: String,
    label: String,
    max: {
      type: String,
      default: moment().format('YYYY-MM-DD')
    },
    min: String,
    noTitle: Boolean,
    clearable: Boolean,
    manual: Boolean,
    readonly: Boolean,
  },

  data() {
    return {
      show: false,

      input: null,
      date: moment().format('YYYY-MM-DD'),
    };
  },

  watch: {
    value() {
      return this.$nextTick(() => {
        this.date = this.value ? moment(this.value).format('YYYY-MM-DD') : null;
        this.updateInput();
      });
    },
  },

  created() {
    if (this.value) {
      this.date = this.value ? moment(this.value).format('YYYY-MM-DD') : null;
      this.updateInput();
    }
  },

  methods: {
    onClear() {
      this.date = null;
      this.onChange();
    },

    onChange() {
      this.show = false;
      this.updateInput();

      this.$emit('input', this.date);
    },

    updateInput() {
      this.input = this.date ? moment(this.date).format('DD/MM/YYYY') : null
    },

    onManualChange(value) {
      if (!this.isDateValid(value, 'DD/MM/YYYY')) {
        this.input = null;
      }

      this.date = this.input ? moment(this.input, 'DD/MM/YYYY').format('YYYY-MM-DD') : null

      if (!!this.date && !!this.max && this.date > this.max) {
        this.input = null;
        this.date = null;
      }

      this.$emit('input', this.date);
    },

    isDateValid(value, format) {
      if (!value || value.length !== format.length) {
        return false;
      }

      return moment(value, format).isValid();
    },
  },

};
</script>

<style scoped></style>
