<template>
  <div class="mx-5 px-6">
    <v-row>
      <v-col
        cols="12"
        class="text-center py-0"
      >
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row justify="space-around">
      <v-col
        cols="10"
      >
        <v-tabs
          v-model="tab"
          dark
          centered
          background-color="transparent"
          class="transparent"
        >
          <v-tab
            v-if="hasAppAccess"
            href="#tab-qualidade"
          >
            APP
          </v-tab>
          <v-tab
            v-if="hasPlataformaAccess"
            href="#tab-plataforma"
          >
            Plataforma
          </v-tab>
          <v-tab
            v-if="hasFaixasDeQualidadeAccess"
            href="#tab-faixas-qualidade"
          >
            Faixas de Qualidade
          </v-tab>
          <v-tab
            v-if="hasSoretioDeAnalisesAccess"
            href="#tab-sorteio"
          >
            Sorteio de Análises
          </v-tab>
          <v-tab
            v-if="hasRevisoesDeFormulariosAccess"
            href="#tab-formularios"
          >
            Revisões de formulários
          </v-tab>
          <!-- <v-tab href="#tab-classificacao-produtor">
            Curva ABC
          </v-tab> -->
          <v-tab
            v-if="hasDescontosTaxasAccess"
            href="#tab-taxas"
          >
            Descontos e taxas
          </v-tab>
          <v-tab
            v-if="hasCadastroProdutorAccess"
            href="#tab-cadastro-produtor"
          >
            Cadastro Produtor
          </v-tab>
          <v-tab
            v-if="hasRelatoriosAccess"
            href="#tab-relatorios"
          >
            Relatórios
          </v-tab>
          <v-tab href="#tab-fiscal">
            Fiscal
          </v-tab>
          <v-tab href="#tab-industria">
            Indústria
          </v-tab>
          <v-tab href="#tab-api-sistemas">
            API's
          </v-tab>
          <v-tab href="#tab-parametros-analise-rotina">
            Análise de Rotina
          </v-tab>
          <v-tab href="#tab-comercial">
            Comercial
          </v-tab>

          <v-tab-item
            v-if="hasAppAccess"
            value="tab-qualidade"
          >
            <quality-app-settings />
          </v-tab-item>
          <v-tab-item
            v-if="hasPlataformaAccess"
            value="tab-plataforma"
          >
            <plataform-settings />
          </v-tab-item>
          <v-tab-item
            v-if="hasFaixasDeQualidadeAccess"
            value="tab-faixas-qualidade"
          >
            <quality-settings />
          </v-tab-item>
          <v-tab-item
            v-if="hasSoretioDeAnalisesAccess"
            value="tab-sorteio"
          >
            <sort-settings />
          </v-tab-item>
          <v-tab-item
            v-if="hasRevisoesDeFormulariosAccess"
            value="tab-formularios"
          >
            <form-traceability />
          </v-tab-item>
          <!-- <v-tab-item value="tab-classificacao-produtor">
            <producer-rating />
          </v-tab-item> -->
          <v-tab-item
            v-if="hasDescontosTaxasAccess"
            value="tab-taxas"
          >
            <discounts-and-tax v-if="tab === 'tab-taxas'" />
          </v-tab-item>
          <v-tab-item
            v-if="hasCadastroProdutorAccess"
            value="tab-cadastro-produtor"
          >
            <producer-form-settings />
          </v-tab-item>
          <v-tab-item
            v-if="hasRelatoriosAccess"
            value="tab-relatorios"
          >
            <report-settings v-if="tab === 'tab-relatorios'" />
          </v-tab-item>
          <v-tab-item value="tab-fiscal">
            <fiscal-settings v-if="tab === 'tab-fiscal'" />
          </v-tab-item>
          <v-tab-item value="tab-industria">
            <industry-settings v-if="tab === 'tab-industria'" />
          </v-tab-item>
          <v-tab-item value="tab-api-sistemas">
            <api-systems />
          </v-tab-item>
          <v-tab-item value="tab-parametros-analise-rotina">
            <routine-analysis-parameters />
          </v-tab-item>
          <v-tab-item value="tab-comercial">
            <comercial-settings />
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import QualityAppSettings from "@/Domains/Settings/Views/QualityAppSettings.vue";
import PlataformSettings from "@/Domains/Settings/Views/PlataformSettings.vue";
import QualitySettings from "@/Domains/Settings/Views/QualitySettings.vue";
import SortSettings from "@/Domains/Settings/Views/SortSettings.vue";
import DiscountsAndTax from "@/Domains/Settings/Views/DiscountsAndTax.vue";
import ProducerFormSettings from "@/Domains/Settings/Views/ProducerFormSettings.vue";

import FormTraceability from "@/Domains/Settings/Components/FormTraceability.vue";
// import ProducerRating from "@/Domains/Settings/Components/ProducerRating.vue";
import ReportSettings from "@/Domains/Settings/Views/ReportSettings.vue";
import FiscalSettings from "@/Domains/Settings/Views/Fiscal/Index.vue";
import IndustrySettings from "@/Domains/Settings/Views/IndustrySettings.vue";
import ApiSystems from "@/Domains/Settings/Views/ApiSystems.vue";
import RoutineAnalysisParameters from "@/Domains/Settings/Views/RoutineAnalysisParameters.vue";
import ComercialSettings from "@/Domains/Settings/Views/ComercialSettings.vue";

export default {
  name: "settings",

  components: {
    ReportSettings,
    QualityAppSettings,
    PlataformSettings,
    QualitySettings,
    SortSettings,
    FormTraceability,
    // ProducerRating,
    DiscountsAndTax,
    ProducerFormSettings,
    FiscalSettings,
    IndustrySettings,
    ApiSystems,
    RoutineAnalysisParameters,
    ComercialSettings,
  },

  data() {
    return {
      tab: "tab-qualidade",
    };
  },

  computed: {
    // Permissions
    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },
    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 12;
    },
    hasAppAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "config-app" && o.tipo === "COMPONENTE");
    },
    hasPlataformaAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "config-plataforma" && o.tipo === "COMPONENTE");
    },
    hasFaixasDeQualidadeAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "config-faixas-de-qualidade" && o.tipo === "COMPONENTE");
    },
    hasSoretioDeAnalisesAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "config-sorteio-de-analises" && o.tipo === "COMPONENTE");
    },
    hasRevisoesDeFormulariosAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "config-revisoes-de-formularios" && o.tipo === "COMPONENTE");
    },
    hasDescontosTaxasAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "config-descontos-e-taxas" && o.tipo === "COMPONENTE");
    },
    hasCadastroProdutorAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "config-cadastro-produtor" && o.tipo === "COMPONENTE");
    },
    hasRelatoriosAccess() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "config-relatorios" && o.tipo === "COMPONENTE");
    },
    hasAccessApis() {
      return this.isAdmin || this.userResources.some(o => o.recurso === "config-apis" && o.tipo === "COMPONENTE");
    },
  },
};
</script>
