<template>
  <v-card
    elevation="1"
    outlined
    dark
    color="transparent"
  >
    <v-card-title class="d-flex flex-column">
      Ações de Qualidade
    </v-card-title>
    <v-card-text>
      <v-row class="justify-center">
        <v-col
          v-if="items.length > 0"
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-model="itemSelected"
            :items="items"
            item-value="value"
            item-text="text"
            dark
            hide-selected
            hide-details
            label="Inserir item"
            prepend-inner-icon="search"
            filled
            @change="addItem"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="goals"
      hide-default-footer
      disable-sort
    >
      <template #[`item.indicator`]="{ item }">
        <v-select
          v-model="item.indicator"
          :items="entries"
          dense
          outlined
          hide-details
          disabled
        />
      </template>
      <template #[`item.media`]="{ item }">
        <v-text-field
          v-if="item.action==='ACIMA' || item.action==='ABAIXO'"
          v-model.number="item.media"
          placeholder="0"
          type="number"
          dense
          outlined
          hide-details
          :rules="[v => !!v || 'Valor inválido!']"
          :mask="FloatNumberMask"
          return-unmasked
        />
      </template>
      <template #[`item.action`]="{ item }">
        <v-select
          v-model="item.action"
          :items="options"
          dense
          outlined
          hide-details
          :rules="[v => !!v || 'Campo obrigatório!']"
        />
      </template>
      <template #[`item.percent`]="{ item }">
        <v-text-field
          v-model.number="item.percent"
          placeholder="0"
          type="number"
          dense
          outlined
          hide-details
          :rules="[v => !!v || 'Valor inválido!']"
          @keypress="disableDotAndComma"
        />
      </template>
      <!-- <template #[`item.time_init`]="{ item }">
        <v-text-field
          v-if="item.action==='ACIMA' || item.action==='ABAIXO'"
          v-model.number="item.time_init"
          placeholder="0"
          type="number"
          suffix="dias"
          dense
          outlined
          hide-details
          :rules="[v => !!v || 'Valor inválido!']"
          @keypress="disableDotAndComma"
        />
      </template> -->
      <template #[`item.type`]="{ item }">
        <v-select
          v-model="item.type"
          :items="tipos"
          item-value="value"
          item-text="text"
          dense
          hide-details
          :rules="[v => !!v || 'Campo obrigatório!']"
        />
      </template>
      <template #[`item.dateRange`]="{ item }">
        <v-date-range-picker
          v-model="item.dateRange"
          label=""
          dark
        />
      </template>
      <template #[`item.questionnaires`]="{ item }">
        <v-select
          v-model="item.questionnaires"
          label="Questionários"
          :items="questionnaires"
          :loading="loading"
          prepend-inner-icon="icon-cadastros"
          item-value="id"
          item-text="title"
          multiple
          outlined
          small-chips
          hide-details
          dense
        />
      </template>
      <template #[`item.actions`]="{ item }">
        <v-btn
          icon
          color="white"
        >
          <v-icon
            @click="removeItem(item)"
          >
            delete_outline
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";

export default {

  components: {
    VDateRangePicker,
  },

  props: {
    value: Array,
  },

  data() {
    return {

      FloatNumberMask: {
        mask: "num",
        blocks: {
          num: {
            mask: Number,
            scale: 4,
            min: -999999,
            max: 999999,
          },
        },
      },

      loading: false,

      headers: [
        { text: "Indicador", value: "indicator" },
        { text: "Ação", value: "action", width: 250 },
        { text: "Média", value: "media", width: 80 },
        { text: "Valor", value: "percent", width: 80 },
        { text: 'tipo', value: "type", width: 30  },
        // { text: 'Tempo de entrada', value: "time_init", width: 100  },
        { text: 'Período', value: "dateRange", width: 180  },
        { text: 'Questionários', value: "questionnaires", width: 200 },
        { text: "", value: "actions", width: 30 },
      ],

      tipos: [
        { text: '%', value: 'PERCENT' },
        { text: 'UN', value: 'UN' }
      ],
      options: [
        { text: 'Reduzir média', value: 'REDUZIR' },
        { text: 'Aumentar média', value: 'AUMENTAR' },
        { text: 'Adequar Produtores com media acima de:', value: 'ACIMA' },
        { text: 'Adequar Produtores com media abaixo de:', value: 'ABAIXO' }
      ],

      entries: [
        { text: 'CCS Geo Mensal', value: 'CCS' },
        { text: 'CPP Geo Mensal', value: 'CPP' },
        { text: 'CCS Geo Trimestral', value: 'CCS_TRIMESTRAL' },
        { text: 'CPP Geo Trimestral', value: 'CPP_TRIMESTRAL' },
        { text: 'Caseína', value: 'caseina' },
        { text: 'Gordura', value: 'gordura' },
        { text: 'Uréia', value: 'ureia' },
        { text: 'Lactose', value: 'lactose' },
        { text: 'Proteína', value: 'proteina' },
        { text: 'ESD', value: 'esd' },
        { text: 'Solídos Totais', value: 'solidos_totais' },

      ],
      itemSelected: '',
      questionnaires: [],
    };
  },

  computed: {
    goals: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    items() {
      // const goals = this.goals.map(({ indicator }) => indicator);
      // return this.entries.filter((item) => !goals.includes(item.value));
      return this.entries;
    },
  },

  mounted() {
    this.loadQuestionnaires();
  },

  methods: {
    async loadQuestionnaires() {
      try {
        this.loading = true;

        const { data } = await this.$axios.post(
          `/formularioCustomizado/listaFormularioProjeto`,
          {
            tipos: ['VISITA_PADRAO', 'VISITA_IN77', 'VISITA_IN59', 'VISITA_CPP', 'VISITA_CCS'],
            ativo: 1,
          }
        );

        this.questionnaires = data.map(item => {
          let text = item.titulo ? item.titulo : ``;
          return {
            id: item.id,
            title: text,
            types: item.tipo,
          }});
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os questionários!", "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    addItem(indicator) {
      this.goals.push({ indicator, action: 'AUMENTAR', media_produtor: 'MEDIA' })
      this.$nextTick(() => {
        this.itemSelected = "";
      });
    },

    removeItem(item) {
      const idx = this.goals.findIndex(o => o.indicator === item.indicator);
      this.goals.splice(idx, 1);
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  },
};
</script>
