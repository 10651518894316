<template>
  <v-card
    style="background: transparent !important;"
    flat
  >
    <v-card-title
      class="pa-0"
      style="background: transparent;"
    >
      <v-row>
        <v-col
          cols="12"
          class="pb-0"
        >
          <card-title-settings
            class="mb-3"
          >
            {{ status.label }}
          </card-title-settings>
        </v-col>
      </v-row>
    </v-card-title>

    <v-card-text class="pl-0">
      <v-container class="py-0">
        <v-row>
          <v-col
            cols="4"
          >
            <v-switch
              v-model="status.settings.showOnList"
              inset
              label="Exibir Lista"
              class="mt-0"
              hide-details
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            v-if="status.settings.showOnList"
            cols="3"
          >
            <v-select
              v-model="status.settings.statusToShow"
              :items="statusList"
              label="Exibir Status"
              persistent-placeholder
              hint="Status que serão exibidos nesta lista"
              item-text="label"
              item-value="id"
              multiple
              chips
              filled
              clearable
              persistent-hint
              return-object
            >
              <template v-slot:selection="{ item, index }">
                <v-chip class="mt-4">
                  <v-avatar
                    :color="item.color"
                    class="mr-3"
                  />
                  <span>{{ item.label }}</span>
                </v-chip>
              </template>
            </v-select>
          </v-col>

          <v-col
            v-if="status.settings.showOnList"
            cols="3"
          >
            <v-select
              v-model="status.settings.statusToNotShow"
              :items="statusList"
              label="Não Exibir Status"
              persistent-placeholder
              hint="Status que não serão exibidos nesta lista"
              item-text="label"
              item-value="id"
              multiple
              chips
              filled
              persistent-hint
              clearable
              return-object
            >
              <template v-slot:selection="{ item, index }">
                <v-chip class="mt-4">
                  <v-avatar
                    :color="item.color"
                    class="mr-3"
                  />
                  <span>{{ item.label }}</span>
                </v-chip>
              </template>
            </v-select>
          </v-col>
        </v-row>

        <v-row
          v-if="settings.showOnList"
        >
          <v-col cols="3">
            <card-title-settings
              class="mb-0"
            >
              Ações

              <v-spacer />

              <v-btn
                fab
                dark
                x-small
                plain
                @click="onAddActionClick"
              >
                <v-tooltip left>
                  <template #activator="{ on }">
                    <v-icon v-on="on">
                      add
                    </v-icon>
                  </template>
                  <span>Inserir nova ação</span>
                </v-tooltip>
              </v-btn>
            </card-title-settings>
          </v-col>

          <v-col cols="12" class="py-0">
            <template
              v-for="action in status.settings.actions"
              class="pl-3"
            >
              <comercial-status-action-item
                :key="action.id"
                :action.sync="action"
                :status-list="statusList"
                @change="onActionChange"
                @delete="onDeleteAction"
              />
            </template>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import CardTitleSettings from "@/Domains/Settings/Components/General/CardTitleSettings.vue";
import ComercialStatusActionItem from "@/Domains/Settings/Components/Comercial/ComercialStatusActionItem.vue";

import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    CardTitleSettings,
    ComercialStatusActionItem,
  },

  props: {
    statusList: {
      type: Array,
      default: () => ([]),
    },

    status: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {

    }
  },

  computed: {
    settings: {
      get() {
        return this.status.settings;
      },
      set(newSettings) {
        return this.$emit('update:status', {
          ...this.status,
          settings: newSettings,
        });
      },
    }
  },

  methods: {
    onAddActionClick() {
      this.settings.actions = [
        ...(this.settings.actions || []),
        {
          id: uuidv4(),
          label: '',
          icon: null,
          action: null,
          newStatusId: null,
        },
      ]
    },
    onActionChange(changedAction) {
      this.settings.actions = this.settings.actions.map(action => {
        if (action.id === changedAction.id) {
          return changedAction;
        }

        return action;
      });
    },

    onDeleteAction(deletedActon) {
      this.settings.actions = this.settings.actions.filter(action => action.id !== deletedActon.id);
    },
  },

}
</script>
