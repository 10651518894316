var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"orders",attrs:{"text-xs-center":"","grid-list-lg":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',[_vm._v("arrow_left")]),_c('div',{staticClass:"pr-3"},[_vm._v(" Voltar ")])],1)],1)],1),_c('v-row',[_c('v-tabs',{staticClass:"transparent text-left",attrs:{"dark":"","centered":"","background-color":"transparent"},model:{value:(_setup.tab),callback:function ($$v) {_setup.tab=$$v},expression:"tab"}},[_vm._l((_setup.settings.workflow),function(status){return [(status.settings.showOnList)?_c('v-tab',{key:status.id,attrs:{"tab-value":status.slug}},[_c('div',{staticClass:"mr-4",style:({
              backgroundColor: status.color,
              cursor: 'pointer',
              height: '14px',
              width: '14px',
              borderRadius: '8px',
              transition: 'border-radius 200ms ease-in-out'
            })}),_vm._v(" "+_vm._s(status.label)+" ")]):_vm._e()]})],2),_c('v-tabs-items',{staticStyle:{"width":"100%","background":"transparent !important"},model:{value:(_setup.tab),callback:function ($$v) {_setup.tab=$$v},expression:"tab"}},[_vm._l((_setup.settings.workflow),function(status){return [(_setup.tab === status.slug)?_c('v-tab-item',{key:status.id,attrs:{"value":status.slug,"eager":""}},[(status.id === _setup.settings.dispatchPlanningStatusId)?_c(_setup.ComercialDispatchingPlanning,{attrs:{"status":status.slug,"status-in":status.settings.statusToShow,"status-not-in":status.settings.statusToNotShow,"settings":status.settings,"workflow":_setup.settings.workflow}}):(status.id === _setup.settings.shipmentStatusId)?_c(_setup.ComercialShipmentList,{attrs:{"status":status.slug,"status-in":status.settings.statusToShow,"status-not-in":status.settings.statusToNotShow,"settings":status.settings,"workflow":_setup.settings.workflow}}):_c(_setup.ComercialOrdersList,{attrs:{"status":status.slug,"status-in":status.settings.statusToShow,"status-not-in":status.settings.statusToNotShow,"settings":status.settings,"workflow":_setup.settings.workflow}})],1):_vm._e()]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }