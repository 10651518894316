<template>
  <v-card class="producer-analysis">
    <v-card-title>
      Inconformidades
      <v-spacer />
      <v-btn
        icon
        small
        depressed
        @click="close()"
      >
        <v-icon small>
          close
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="py-0">
      <unload-data-info
        :unload="unload"
      />

      <div class="mt-6">
        <div>
          <div class="px-0">
            <v-row>
              <v-col
                cols="4"
                sm="3"
                lg="2"
                class="py-0"
                style="border-right: rgba(0, 0, 0, 0.07) 1px solid;"
              >
                <v-row
                  v-for="tank of tanks"
                  :key="tank.index"
                  class="pa-1 text-overline ma-0 mb-2"
                  style="border: 1px solid #ccc; font-size: 10px !important; line-height: 20px !important;"
                >
                  <v-col
                    cols="12"
                    class="pa-0 ma-o"
                  >
                    {{ tank.label }}
                    <span
                      v-if="tank.plate"
                      class="text-subtitle-2"
                    >
                      ({{ tank.plate }})
                    </span>
                  </v-col>
                  <v-col
                    cols="5"
                    class="pa-0 ma-o"
                  >
                    &nbsp;- Vale
                  </v-col>
                  <v-col
                    cols="7"
                    class="pa-0 ma-o text-right"
                  >
                    <span>{{ tank.vol }} L</span>
                  </v-col>

                  <v-col
                    v-if="tank.measured"
                    cols="6"
                    class="pa-0 ma-o"
                  >
                    &nbsp;- Medidos
                  </v-col>
                  <v-col
                    v-if="tank.measured"
                    cols="6"
                    class="pa-0 ma-o text-right"
                  >
                    {{ tank.measured }} L
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                cols="8"
                sm="9"
                lg="10"
                class="pt-0"
              >
                <v-tabs>
                  <v-tab>Tanques</v-tab>
                  <v-tab>Lançamento de Análise</v-tab>
                  <v-tab-item>
                    <v-row class="ma-1">
                      <v-col
                        cols="5"
                        sm="4"
                        lg="3"
                        class="pt-0"
                      >
                        <v-checkbox
                          v-model="showAllParams"
                          label="Exibir todos os parâmetros"
                          single-line
                          hide-details
                        />
                      </v-col>
                      <v-col
                        offset="1"
                        offset-lg="3"
                        class="pt-0"
                      >
                        <v-text-field
                          ref="searchIn"
                          v-model="searchIn"
                          prepend-inner-icon="search"
                          label="Pesquisar"
                          single-line
                          hide-details
                          @input="onFocusPersist"
                        />
                      </v-col>
                    </v-row>

                    <v-card>
                      <v-card-title>
                        Análises de Produtor
                      </v-card-title>

                      <v-data-table
                        :headers="[
                          { text: 'Código', value: 'code', align: 'left', width: 150 },
                          { text: 'Nome', value: 'name' },
                          { text: 'Nº Amostra', value: 'sampleNumber', width: 120 },
                          { text: 'Vol. (L)', value: 'vol', width: 120 },
                          { text: 'Vincular Descarte', value: 'select', width: 300 },
                          { text: 'Status', value: 'hasError', width: 100 },
                        ]"
                        :items="producers"
                        :search="search"
                        group-by="tank.index"
                        item-key="key"
                        hide-default-footer
                        disable-pagination
                        show-expand
                        single-expand
                      >
                        <template #[`group.header`]="{ group, items, headers, isOpen, toggle }">
                          <td
                            :colspan="headers.length - 2"
                            :style="tanksByIdx[group].discard ? 'background-color: #FFEBEE' : undefined"
                          >
                            <v-layout
                              row
                              class="pa-0 align-center"
                            >
                              <v-flex class="pa-0 text-left">
                                <v-btn
                                  icon
                                  @click="toggle"
                                >
                                  <v-icon v-if="isOpen">
                                    remove
                                  </v-icon>
                                  <v-icon v-else>
                                    add
                                  </v-icon>
                                </v-btn>
                                <span class="text-overline">
                                  {{ items[0].tank.label }}
                                </span>
                                <template v-if="items[0].tank.plate">
                                  ({{ items[0].tank.plate }})
                                </template>
                              </v-flex>
                            </v-layout>
                          </td>
                          <td
                            :style="tanksByIdx[group].discard ? 'background-color: #FFEBEE' : undefined"
                          >
                            <v-checkbox
                              v-if="!['UNLOADED', 'UNLOADED_RESTRICT'].includes(tanksByIdx[group].status)"
                              :input-value="tanksToDiscardComputed"
                              :value="`${group}-driver`"
                              label="Transportador"
                              color="yellow darken-4"
                              hide-details
                              dense
                              class="mt-0 pa-0"
                              :disabled="!canEditConcludedProducerAnalysis || !tanksByIdx[group].discard"
                              @change="onTankSelect({index: group, linkId: 'driver' })"
                            />
                          </td>
                          <td
                            :style="tanksByIdx[group].discard ? 'background-color: #FFEBEE' : undefined"
                          >
                            <v-checkbox
                              :input-value="tanksByIdx[group].discard"
                              color="red"
                              class="mt-0 pa-0 discard-checkbox"
                              hide-details
                              label="Descartar"
                              :disabled="['UNLOADED', 'UNLOADED_RESTRICT'].includes(tanksByIdx[group].status)"
                              @click.prevent="toDiscardTank(tanksByIdx[group])"
                            />
                          </td>
                        </template>

                        <template #[`item.name`]="{ item }">
                          {{ item.name }}
                          <v-icon
                            v-if="(
                              isReplicatorDisabled(item.sampleGroupKey, item.analysisId) ||
                              item?.additionalAnalysis?.some(a => isReplicatorDisabled(a.sampleGroupKey, a.analysisId))
                            )"
                            color="orange"
                          >
                            content_copy
                          </v-icon>
                        </template>

                        <template #[`item.sampleNumber`]="{ item }">
                          {{ item.samples || item.sampleNumber }}
                        </template>

                        <template #[`item.vol`]="{ value }">
                          {{ value | Mask(FloatNumberMask) }}
                        </template>

                        <template #[`item.select`]="{ item }">
                          <v-checkbox
                            v-if="!['UNLOADED', 'UNLOADED_RESTRICT'].includes(tanksByIdx[item.tank.index].status)"
                            :input-value="tanksToDiscardComputed"
                            :value="`${item.tank.index}-${item.id}`"
                            :disabled="!tanksByIdx[item.tank.index].discard"
                            label="Produtor"
                            color="red"
                            hide-details
                            dense
                            class="mt-0 pa-0"
                            @change="onTankSelect({index: item.tank.index, linkId: item.id })"
                          />
                        </template>

                        <template #[`item.hasError`]="{ value }">
                          <v-icon
                            v-if="value"
                            color="orange"
                          >
                            warning
                          </v-icon>
                        </template>

                        <template #expanded-item="{ headers, item }">
                          <td :colspan="headers.length">
                            <v-tabs class="my-2">
                              <v-tab>
                                <v-badge
                                  :value="item.hasError"
                                  color="red"
                                  dot
                                >
                                  Resfriador #1
                                </v-badge>
                              </v-tab>
                              <v-tab
                                v-for="additional in item?.additionalAnalysis"
                                :key="additional.analysisId"
                              >
                                <v-badge
                                  :value="additional.hasError"
                                  color="red"
                                  dot
                                >
                                  {{ additional.type === 'RESFRIADOR' ? `Resfriador #${additional.cooler}	` : additional.type }}
                                </v-badge>
                              </v-tab>
                              <v-tab-item>
                                <div
                                  v-if="item.sampleNumber"
                                  class="d-flex align-center px-4 pt-3"
                                >
                                  <div class="text-body-1 grey--text text--darken-2 mr-6">
                                    Amostra {{ item.sampleNumber }}
                                  </div>

                                  <v-checkbox
                                    v-if="form.groupedAnalysis[item.sampleGroupKey]"
                                    :input-value="item.analysisId === form.groupedAnalysis[item.sampleGroupKey].replicatorId"
                                    :label="`Replicar análise ${form.groupedAnalysis[item.sampleGroupKey].labels}`"
                                    class="mt-0 pt-0"
                                    hide-details
                                    :disabled="isReplicatorDisabled(item.sampleGroupKey, item.analysisId)"
                                    @change="value => form.groupedAnalysis[item.sampleGroupKey].replicatorId = value ? item.analysisId : null"
                                  />
                                </div>

                                <analysis
                                  v-if="showAllParams || item.problems.length > 0"
                                  :key="item.id"
                                  :value="item.analysis"
                                  :visible="!showAllParams ? item.problems : visibleAnalysisParams"
                                  :vol="item.vol"
                                  :cryoscopys="cryoscopys"
                                  :disabled="!canEditConcludedProducerAnalysis || isReplicatorDisabled(item.sampleGroupKey, item.analysisId)"
                                  alt-checkbox
                                  @input="onProducerUpdate(item, $event)"
                                />
                              </v-tab-item>
                              <v-tab-item
                                v-for="additional in item?.additionalAnalysis"
                                :key="additional.analysisId"
                              >
                                <div
                                  v-if="additional.type === 'RESFRIADOR'"
                                  class="d-flex align-center px-4 pt-3"
                                >
                                  <div class="text-body-1 grey--text text--darken-2 mr-6">
                                    Amostra {{ additional.sampleNumber }}
                                  </div>

                                  <v-checkbox
                                    v-if="form.groupedAnalysis[additional.sampleGroupKey]"
                                    :input-value="additional.analysisId === form.groupedAnalysis[additional.sampleGroupKey].replicatorId"
                                    :label="`Replicar análise ${form.groupedAnalysis[additional.sampleGroupKey].labels}`"
                                    class="mt-0 pt-0"
                                    hide-details
                                    :disabled="isReplicatorDisabled(additional.sampleGroupKey, additional.analysisId)"
                                    @change="value => form.groupedAnalysis[additional.sampleGroupKey].replicatorId = value ? additional.analysisId : null"
                                  />
                                </div>
                                <analysis
                                  v-if="showAllParams || item.problems.length > 0"
                                  :key="item.id"
                                  :value="additional.analysis"
                                  :visible="!showAllParams ? item.problems : visibleAnalysisParams"
                                  :vol="item.vol"
                                  :disabled="!canEditConcludedProducerAnalysis || isReplicatorDisabled(additional.sampleGroupKey, additional.analysisId)"
                                  alt-checkbox
                                  @input="onProducerUpdate(item, $event, additional.type, additional.analysisId, additional.sampleGroupKey)"
                                />
                              </v-tab-item>
                            </v-tabs>
                          </td>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-row class="ma-1">
                      <v-col
                        cols="5"
                        sm="4"
                        lg="3"
                        class="pt-0"
                      >
                        <v-checkbox
                          v-model="showAllParams"
                          label="Exibir todos os parâmetros"
                          single-line
                          hide-details
                        />
                      </v-col>
                    </v-row>
                    <v-card>
                      <v-card-text>
                        <v-row>
                          <v-col
                            md="3"
                          >
                            <v-autocomplete
                              ref="producer"
                              v-model="form.producer"
                              :items="producers"
                              label="Produtor"
                              item-text="description"
                              item-value="key"
                              prepend-inner-icon="person_outline"
                              filled
                              return-object
                              auto-select-first
                              clearable
                              :disabled="!canEditConcludedProducerAnalysis"
                              @change="onAnalysisSelectChange()"
                            />
                          </v-col>
                          <v-col
                            md="3"
                          >
                            <v-select
                              v-model="form.type"
                              :items="[
                                { value: 'PADRAO', text: 'Resfriador' },
                                { value: 'REANALISE', text: 'Reanálise' },
                                { value: 'ADICIONAL', text: 'Adicional' },
                              ]"
                              label="Tipo"
                              filled
                              auto-select-first
                              :disabled="!canEditConcludedProducerAnalysis"
                              @change="onAnalysisSelectChange()"
                            />
                          </v-col>
                          <v-col
                            v-if="form.type=== 'REANALISE' && form.otherIds.length > 0"
                            md="6"
                          >
                            <v-btn
                              v-for="(otherId, idx) of form.otherIds"
                              :key="otherId"
                              color="cyan darken-1"
                              outlined
                              large
                              class="mt-2 mr-2"
                              :disabled="otherId === form.id"
                              @click="onAnalysisSelectChange(false, otherId)"
                            >
                              Reanálise {{ idx + 1 }}
                            </v-btn>
                            <v-btn
                              color="orange darken-1"
                              outlined
                              large
                              class="mt-2"
                              :disabled="!form.id"
                              @click="onAnalysisSelectChange(true)"
                            >
                              Nova Reanálise
                            </v-btn>
                          </v-col>
                          <v-col
                            v-else-if="['PADRAO', 'RESFRIADOR'].includes(form.type) && form.otherCoolers.length > 1"
                            md="6"
                          >
                            <v-btn
                              v-for="cooler of form.otherCoolers"
                              :key="cooler.id"
                              color="cyan darken-1"
                              outlined
                              large
                              class="mt-2 mr-2"
                              :disabled="cooler.id === form.id"
                              @click="onAnalysisSelectChange(false, cooler.id)"
                            >
                              {{ cooler.description }}
                            </v-btn>
                          </v-col>
                        </v-row>

                        <div
                          v-if="['PADRAO', 'RESFRIADOR'].includes(form.type)"
                          class="d-flex align-center px-4 pt-3"
                        >
                          <div class="text-body-1 grey--text text--darken-2 mr-6">
                            Amostra {{ form.sampleNumber }}
                          </div>

                          <v-checkbox
                            v-if="form.groupedAnalysis[form.sampleGroupKey]"
                            :input-value="form.id === form.groupedAnalysis[form.sampleGroupKey].replicatorId"
                            :label="`Replicar análise ${form.groupedAnalysis[form.sampleGroupKey].labels}`"
                            class="mt-0 pt-0"
                            hide-details
                            :disabled="isReplicatorDisabled(form.sampleGroupKey, form.id)"
                            @change="value => form.groupedAnalysis[form.sampleGroupKey].replicatorId = value ? form.id : null"
                          />
                        </div>

                        <analysis
                          v-if="form.producer?.key"
                          ref="analysisForm"
                          :key="`${form.type}-${form.id}-${form.producer.key}`"
                          v-model="form.analysis"
                          :visible="!showAllParams ? form.producer.problems : visibleAnalysisParams"
                          :vol="form.producer.vol"
                          :cryoscopys="cryoscopys"
                          :disabled="['PADRAO', 'RESFRIADOR'].includes(form.type) && isReplicatorDisabled(form.sampleGroupKey, form.id)"
                          alt-checkbox
                        />
                        <v-divider class="my-2" />
                        <div class="d-flex">
                          <div v-if="form.pending.length">
                            <span class="d-block grey--text">Análises pendentes para salvar: </span>
                            <v-chip
                              v-for="producer in form.pending"
                              :key="producer.id"
                              small
                              dark
                              color="orange"
                              class="mr-1"
                            >
                              {{ producer.description }}
                            </v-chip>
                          </div>
                          <v-spacer />
                          <v-btn
                            v-if="form.producer"
                            color="blue darken-1"
                            outlined
                            class="mt-3"
                            @click="nextAnalysis"
                          >
                            Concluir
                          </v-btn>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-btn
        color="grey darken-3"
        text
        @click="close()"
      >
        VOLTAR
      </v-btn>

      <v-spacer />

      <v-btn
        v-if="tanks.some(tank => tank.discard)"
        color="red darken-1"
        text
        outlined
        @click="saveAndClose"
      >
        SALVAR E DESCARTAR
      </v-btn>

      <v-btn
        v-else
        color="blue darken-1"
        class="ml-3"
        text
        outlined
        :disabled="!showSaveButton"
        @click="saveAndClose"
      >
        SALVAR
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import * as _ from "lodash";
import Analysis from "@/Domains/Platform/Unload/Components/Analysis.vue";
import UnloadDataInfo from "@/Domains/Platform/Unload/Components/UnloadDataInfo.vue";
import AnalysisPatternValidator from "@/Domains/Platform/Unload/Services/AnalysisPatternValidator.js";
import IMask from "imask";

export default {

  components: {
    Analysis,
    UnloadDataInfo,
  },

  filters: {

    Mask(value, mask) {
      return IMask.pipe((value || '').toString(), mask, IMask.PIPE_TYPE.UNMASKED, IMask.PIPE_TYPE.MASKED);
    },

  },

  props: {

    unload: {
      type: Object,
      default: () => ({}),
    },

    tanks: {
      type: Array,
      default: () => ([]),
    },

    loading: {
      type: Boolean,
    },

    visibleAnalysisParams: {
      type: Array,
      default: () => ([]),
    },
  },

  data() {
    return {
      searchIn: '',

      // Mascara usada nos campos numéricos float
      FloatNumberMask: {
        mask: 'num',
        blocks: {
          num: {
            mask: Number,
            scale: 4,
            min: -999999,
            max: 999999,
          },
        },
      },

      // Tanques para descartar vinculado ao produtor ou motorista
      tanksToDiscard: [],

      // Lista de produtores para análise
      producers: [],

      // Checkbox para exibir todos os parâmetros
      showAllParams: true,

      // Lista de crioscopias
      cryoscopys: [],

      form: {
        type: 'PADRAO',

        producer: null,

        id: null,

        analysis: null,

        sampleNumber: null,

        sampleGroupKey: null,

        pending: [],

        otherIds: [],

        otherCoolers: [],

        groupedAnalysis: {},
      }

    };
  },

  computed: {

    search: {
      get() {
        return this.searchIn;
      },
      set(newValue) {
        return newValue;
      },
    },

    isLoading: {
      get() {
        return this.loading;
      },
      set(newValue) {
        return this.$emit('update:loading', newValue)
      },
    },

    invalidTanks() {
      return this.tanks.filter(tank => !_.isEmpty(tank.problems));
    },

    hasUnloadCompleted() {
      return ['LOADED', 'UNLOADED'].includes(this.unload.status);
    },

    showSaveButton() {
      if (this.form.analysis) {
        return false;
      }

      if (this.hasUnloadCompleted) {
        return this.canEditConcludedProducerAnalysis;
      }

      return this.showAllParams || this.producers.some(producer => producer.problems.length > 0);
    },

    tanksToDiscardComputed() {
      return this.tanksToDiscard.map(tank => `${tank.index}-${tank.linkId}`);
    },

    tanksByIdx() {
      return this.tanks
        .reduce((acc, tank) => ({ ...acc, [tank.index]: tank }), {});
    },

    /**
     * Recupera o role do usuário
     * @returns {String}
     */

    userResources() {
      return this.$store.state.settings.recursosUsuario || [];
    },

    isAdmin() {
      return this.$store.state.settings.tipoAcesso === 'admin' || this.$store.state.settings.user.id_cargo === 1;
    },

    canEditConcludedProducerAnalysis() {
      if (!this.hasUnloadCompleted) {
        return true;
      }

      return this.isAdmin || this.userResources.some(o => o.recurso === 'edit-concluded-producer-analysis' && o.tipo === 'COMPONENTE');
    },

  },

  watch: {

    invalidTanks: {
      deep: true,
      immediate: true,

      handler(newValue) {

        if (_.isEmpty(newValue)) {
          this.producers = [];
        }

        const producers = this.tanks.map(tank => {
          return tank.producers
            .map(producer => ({
              ...producer,
              tank,
              problems: tank.problems,
              key: `t${tank.index}-${producer.id}`
            }));
        }).flat();

        this.producers = _.cloneDeep(producers);

      },
    },

  },

  created() {
    this.loadCryoscopys();
  },

  async mounted() {
    await this.$nextTick();

    await this.checkAllProducerAnalysis()

    this.tanksToDiscard = this.tanks
      .filter(tank => tank.status === 'DISCARDED')
      .reduce((acc, cur) => {
        if (cur.driverNonconformityLink) {
          acc.push({ index: cur.index, linkId: 'driver' });
        }

        cur.producers.forEach(producer => {
          if (producer.status === 'DISCARDED') {
            acc.push({ index: cur.index, linkId: producer.id });
          }
        });

        return acc
      }, []);

    const groupedAnalysis = this.tanks.reduce((acc, tank) => {
      const allProducersAnalysis = tank.producers
        .flatMap(producer => [
          producer,
          ...(producer.additionalAnalysis?.map((additional) => ({ ...producer, ...additional })) || []),
        ])

      for (let producer of allProducersAnalysis) {
        const { sampleGroupKey } = producer;

        if (!acc[sampleGroupKey]) {
          acc[sampleGroupKey] = {
            groupKey: producer.groupKey,
            sampleNumber: producer.sampleNumber,
            replicatorId: null,
            analysis: [],
            analysisIds: [],
          };
        }

        acc[sampleGroupKey].analysis.push({
          analysisId: producer.analysisId,
          producerName: producer.name,
          tankLabel: producer.tank.label,
        });

        acc[sampleGroupKey].analysisIds.push(producer.analysisId);
      }

      return acc;
    }, {})

    for (let key in groupedAnalysis) {
      const group = groupedAnalysis[key];

      if (group.analysis.length < 2) {
        delete groupedAnalysis[key];
      } else {
        const tanks = [...new Set(group.analysis.map(analysis => analysis.tankLabel))]
        const producers = [...new Set(group.analysis.map(analysis => analysis.producerName))];

        group.labels = `(${tanks.join(', ')})`;

        if (producers.length > 1) {
          group.labels += ` - ${producers.join(', ')}`;
        }
      }
    }

    this.form.groupedAnalysis = groupedAnalysis;
  },

  methods: {
    onFocusPersist() {
      this.$refs.searchIn.focus();
    },
    onTankSelect(item) {
      const alreadySelectedProducerIndex = this.tanksToDiscard.findIndex(tank => tank.linkId === item.linkId && tank.index === item.index);

      if (alreadySelectedProducerIndex !== -1) {
        this.tanksToDiscard.splice(alreadySelectedProducerIndex, 1);
        return;
      }

      this.tanksToDiscard.push(item);
    },

    replicateAnalysis(type, sampleGroupKey, analysisId, analysis) {
      // Verifica se há amostras agrupadas
      if (!sampleGroupKey || !this.form.groupedAnalysis[sampleGroupKey]) {
        return
      }

      // Verifica se é um resfriador
      if (type && !['PADRAO', 'RESFRIADOR'].includes(type)) {
        return
      }

      const { replicatorId, groupKey, analysisIds } = this.form.groupedAnalysis[sampleGroupKey];

      // Verifica se está marcado para replicar
      if (replicatorId !== analysisId) {
        return
      }

      const producers = this.producers.filter(producer => producer.groupKey === groupKey)

      for (let producer of producers) {
        if (type === 'RESFRIADOR') {
          const additional = producer.additionalAnalysis
            .find((additional) => additional.analysisId !== replicatorId && analysisIds.includes(additional.analysisId));

          if (additional) {
            this.onProducerAnalysisUpdate(producer, _.cloneDeep(analysis), type, additional.analysisId)
          }

          continue;
        }

        if (producer.analysisId !== replicatorId) {
          this.onProducerAnalysisUpdate(producer, _.cloneDeep(analysis), type, producer.analysisId)
        }
      }
    },

    onProducerUpdate(updatedProducer, analysis, type = null, id = null, groupKey = null) {
      this.onProducerAnalysisUpdate(updatedProducer, analysis, type, id)

      const sampleGroupKey = groupKey || updatedProducer.sampleGroupKey
      const analysisId = id || updatedProducer.analysisId

      this.replicateAnalysis(
        type,
        sampleGroupKey,
        analysisId,
        analysis
      );
    },

    async onProducerAnalysisUpdate(updatedProducer, analysis, type = null, id = null) {
      this.producers = this.producers.map(producer => {
        if (producer.tank.index !== updatedProducer.tank.index) {
          return producer;
        }

        if (producer.id !== updatedProducer.id) {
          return producer;
        }

        if (['REANALISE', 'ADICIONAL', 'RESFRIADOR'].includes(type)) {
          const additionalAnalysis = producer.additionalAnalysis || []

          const idx = additionalAnalysis.findIndex(additional => additional.type == type && additional.analysisId == id)

          if (idx > -1) {
            additionalAnalysis[idx].analysis = analysis
          } else {
            additionalAnalysis.push({ type, analysis, analysisId: id })
          }

          return {
            ...updatedProducer,
            additionalAnalysis,
          }
        }

        return {
          ...updatedProducer,
          analysis,
        };
      });

      await this.$nextTick();

      return this.checkAllProducerAnalysis();
    },

    async checkAllProducerAnalysis() {
      return this.tanks.forEach(tank => {

        const producers = tank.producers.map(producer => {
          const updatedProducer = this.producers.find(updatedProducerRow => {
            if (updatedProducerRow.tank.index !== tank.index) {
              return false;
            }

            return updatedProducerRow.id === producer.id;
          });

          if (!updatedProducer) {
            return producer;
          }

          if (updatedProducer.additionalAnalysis?.length) {
            updatedProducer.additionalAnalysis = updatedProducer.additionalAnalysis.map(additional => ({
              ...additional,
              hasError: this.hasProducerError(updatedProducer.problems, additional.analysis)
            }))
          }

          const hasError = this.hasProducerError(updatedProducer.problems, updatedProducer.analysis)

          return  {
            ...updatedProducer,
            hasError,
          }
        });

        return this.onTankUpdated({
          ...tank,
          producers,
        });
      });
    },

    hasProducerError(visible, analysis) {
      const problems = _.map(analysis, ({ value, rule }, key) => {

        if (!visible.includes(key)) {
          return false;
        }

        return !(new AnalysisPatternValidator().fromRule(rule).validate(value));
      });

      return !(problems.every(result => !result));
    },

    async saveAllProducerAnalysis() {
      try {
        this.isLoading = true;

        let producers = this.producers
          .flatMap(producer => {

            if (producer.additionalAnalysis?.length) {
              return [
                producer,
                ...producer.additionalAnalysis
                  .map((additional) => ({
                    ...producer,
                    ...additional,
                  })),
              ];
            }

            return producer;
          });

        if (!_.isEmpty(this.form.groupedAnalysis)) {
          producers = producers.map(producer => {
            const replicatorId = this.form.groupedAnalysis[producer.sampleGroupKey]?.replicatorId;

            if (replicatorId && replicatorId !== producer.analysisId) {
              const analysis = producers.find(p => p.analysisId === replicatorId)?.analysis;

              producer.analysis = _.cloneDeep(analysis);
            }

            return producer;
          })
        }

        const req = {
          id_descarga_coletas: this.unload.id,
          id_itinerario: this.unload.itinerary.id,
          tanques: this.tanks.map(tank => {

            const producerAnalysis = producers.filter(producer => producer.tank.index === tank.index);

            if (_.isEmpty(producerAnalysis)) {
              return;
            }

            return {
              tanque: `tanque${tank.index}`,
              volume_liquido: tank.vol,
              volume_total: tank.capacity,
              analises: producerAnalysis.map(({ id, unloadId, vol, sampleNumber, name, analysis, analysisId, type }) => {

                const cryoscopy = analysis.cryoscopy.value !== -0.5 ? analysis.cryoscopy.value : null;

                return {
                  tanque: `tanque${tank.index}`,
                  id_descarga_analise: analysisId,
                  id_produtor: id,
                  id_coleta: unloadId,
                  nome_produtor: name,
                  tipo: type,
                  temperatura: analysis.temp.value,
                  acidez: analysis.acidity.value,
                  alizarol: analysis.alizarol.value,
                  crioscopia: cryoscopy,
                  volume_liquido: vol,
                  numero_amostra: sampleNumber,
                  porcentagem_agua: analysis.waterPercentage.value,
                  volume_agua: analysis.waterLiters.value,
                  densidade: analysis.density.value,
                  esd: analysis.esd.value,
                  gordura: analysis.fat.value,
                  proteina: analysis.protein.value,
                  mastite: analysis.mastitis.value,
                  sensorial: analysis.sensorial.value,
                  fosfatase: analysis.alkalinePhosphatase.value,
                  solidos_totais: analysis.totalSolids.value,
                  brix: analysis.brix.value,
                  est: analysis.est.value,
                  relacao_acidez: analysis.acidityRatio.value,
                  alizarol_qualit: analysis.alizarolQualit.value,
                  alcool: analysis.alcohol.value,
                  lactose: analysis.lactose.value,
                  grumos: analysis.clumps.value,
                  coagulos: analysis.clots.value,
                  redutase: analysis.reductase.value,
                  peroxidase: analysis.peroxidase.value,
                  outras_especies: analysis.otherSpecies.value,
                  solubilidade: analysis.solubilidade.value,
                  base_seca: analysis.base_seca.value,
                  ph: analysis.ph.value,
                  cefalosporina: analysis.cefalosporina.value,
                  tianfenicol: analysis.tianfenicol.value,
                  florfenicol: analysis.florfenicol.value,
                  outros: analysis.others.value,

                  // Antibióticos
                  antibiotico: analysis.antibiotic.value,
                  betalactamico: analysis.betaLactams.value,
                  tetraciclina: analysis.tetracycline.value,
                  sulfonamida: analysis.sulphonamide.value,
                  quinolonas: analysis.quinolones.value,
                  aminoglicosideos: analysis.aminoglykosides.value,
                  macrolideos: analysis.macrolides.value,
                  anfenicois: analysis.anfenicois.value,
                  neomicina: analysis.neomicina.value,

                  // Neutralizantes
                  neutralizantes: analysis.neutralising.value,
                  soda: analysis.sodiumHydroxide.value,
                  bicarbonato: analysis.bicarbonateOfSoda.value,
                  alcalino: analysis.alkali.value,

                  // Reconstituintes
                  reconstituintes: analysis.restoratives.value,
                  sacarose: analysis.sucrose.value,
                  amido: analysis.starch.value,
                  cloretos: analysis.chlorides.value,
                  cloro: analysis.chlorine.value,
                  ureia: analysis.ureia.value,

                  // Conservantes
                  conservantes: analysis.conservatives.value,
                  peroxido: analysis.peroxide.value,
                  formol: analysis.formol.value,
                }
              }),
            };
          }).filter(n => n),
        }

        const { data } = await this.$axios.post(`/descargaColeta/salvaAnalise`, req);

        if (!data.codigo) {
          throw 'Erro ao salvar';
        }
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao atualizar as análises!", "Atenção");
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },

    async updateDiscardedTanks() {
      try {
        this.isLoading = true;

        const tankStatus = {
          'PENDING': 'PENDENTE',
          'TO_DISCARD': 'DESCARTAR',
          'DISCARDED': 'DESCARTADO',
          'TO_UNLOAD': 'DESCARREGAR',
          'UNLOADED': 'DESCARREGADO',
          'LOADED': 'CARREGADO',
          'TO_UNLOAD_RESTRICT': 'DESCARREGAR_RESTRITO',
          'UNLOADED_RESTRICT': 'DESCARREGADO_RESTRITO',
        };

        const request = {
          id_descarga_coletas: this.unload.id,
          tanques: this.tanks.map(tank => {

            let status = tank.status

            if (tank.discard && !['UNLOADED', 'UNLOADED_RESTRICT'].includes(tank.status)) {
              status = 'DISCARDED';
            }

            if (!tank.discard && tank.status === 'DISCARDED') {
              status = 'TO_UNLOAD';
            }

            let driverNonconformityLink = false;
            let discardedProducers = [];

            const tanksToDiscard = this.tanksToDiscard.filter(t => t.index === tank.index);
            if (tanksToDiscard.length > 0) {
              driverNonconformityLink = tanksToDiscard.some(t => t.linkId === 'driver');
              discardedProducers = tanksToDiscard
                .filter(t => t.linkId !== 'driver')
                .map(t => ({
                  id_produtor: t.linkId,
                  status: 'DESCARTADO',
                }));
            }

            return {
              tanque: `tanque${tank.index}`,
              status: tankStatus[status],
              produtores: discardedProducers,
              vincula_inconformidade_transportador: driverNonconformityLink,
              observacao: '',
              anexos: [],
            }
          }),
        }

        const { data } = await this.$axios.post(
          `/descargaColeta/salvaDescarte`,
          request,
        );

        const { codigo } = data;

        if (!codigo) {
          throw 'Erro ao salvar';
        }

        return this.$emit('onTanksDiscarded', this.tanksToDiscard);
      } catch (err) {
        this.$snotify.error("Oops, ocorreu um erro ao atualizar as análises!", "Atenção");
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },

    onTankUpdated(updatedTank) {
      return this.$emit('onTankUpdated', updatedTank);
    },

    toDiscardTank(tank) {
      if (['UNLOADED', 'UNLOADED_RESTRICT'].includes(tank.status)) {
        return;
      }

      const discard = !tank.discard

      if (!discard) {
        this.tanksToDiscard = this.tanksToDiscard.filter(item => item.index !== tank.index);
      }

      return this.onTankUpdated({
        ...tank,
        measureTankWeight: discard ? true : tank.measureTankWeight,
        discard,
      });
    },

    async saveAndClose() {
      await this.saveAllProducerAnalysis();

      await this.updateDiscardedTanks();

      return this.close();
    },

    close() {
      return this.$emit('close');
    },

    async loadCryoscopys() {
      try {
        const { data } = await this.$axios.get('registrations/cryoscopy');
        this.cryoscopys = data;

      } catch (err) {
        this.cryoscopys = [];
      }
    },

    async onAnalysisSelectChange(isNew = false,  otherId = null) {
      this.form.id = null;
      this.form.analysis = null;
      this.form.sampleNumber = null;
      this.form.sampleGroupKey = null;
      this.form.otherIds = [];
      this.form.otherCoolers = [];

      if (!this.form.producer) {
        return;
      }

      let id = this.form.producer.analysisId;
      let analysis = { ...this.form.producer.analysis };
      let sampleNumber = this.form.producer.sampleNumber;
      let sampleGroupKey = this.form.producer.sampleGroupKey;

      if (['REANALISE', 'ADICIONAL'].includes(this.form.type)) {
        const additionals = this.form.producer?.additionalAnalysis?.filter(item => item.type === this.form.type) || [];

        this.form.otherIds = additionals.filter(item => item.analysisId).map(item => item.analysisId);

        // Cria uma nova analise
        if (additionals.length === 0 || isNew) {
          id = null;
          analysis = Object.keys(analysis).reduce((acc, key) => ({
            ...acc,
            [key]: { ...analysis[key], value: null }
          }), {})
        } else {
          // Busca a análise sem ID (aberta recentemente) ou a primeira análise
          let additionalAnalysis = additionals.find(item => !item.analysisId) || additionals[0];

          // Edição de análise adicional
          if (otherId) {
            additionalAnalysis = additionals.find(item => item.analysisId === otherId)
          }

          id = additionalAnalysis.analysisId;
          analysis = { ...additionalAnalysis.analysis };
          sampleNumber = null;
          sampleGroupKey = null;
        }
      } else {
        const coolers = this.form.producer?.additionalAnalysis?.filter(item => item.type === 'RESFRIADOR') || [];

        this.form.otherCoolers = [
          {
            id,
            description: `Resfriador #1`,
          },
          ...coolers.map(cooler => ({
            id: cooler.analysisId,
            description: `Resfriador #${cooler.cooler}`,
          }))
        ];

        // RESFRIADOR
        if (otherId && otherId !== id) {
          id = otherId;
          const cooler = coolers.find(item => item.analysisId === otherId)
          analysis = { ...cooler.analysis };
          sampleNumber = cooler.sampleNumber;
          sampleGroupKey = cooler.sampleGroupKey;
        }
      }

      this.form.id = id;
      this.form.analysis = analysis;
      this.form.sampleNumber = sampleNumber;
      this.form.sampleGroupKey = sampleGroupKey;

      await this.$nextTick();
      this.$refs.analysisForm?.focusFirst()
    },

    async nextAnalysis() {
      this.onProducerUpdate(this.form.producer, this.form.analysis, this.form.type, this.form.id, this.form.sampleGroupKey);

      const { key, description } = this.form.producer;

      if (!this.form.pending.find(p => p.key === key)) {
        this.form.pending.push({ key, description });
      }

      this.form.producer = null;
      this.form.id = null;
      this.form.otherIds = [];
      this.form.otherCoolers = [];
      this.form.analysis = null;
      this.form.sampleNumber = null;
      this.form.sampleGroupKey = null;

      await this.$nextTick();

      return this.$refs.producer?.focus();
    },

    isReplicatorDisabled(sampleGroupKey, analysisId) {
      const replicatorId = this.form.groupedAnalysis[sampleGroupKey]?.replicatorId;

      return replicatorId && replicatorId !== analysisId;
    },
  },

}
</script>
