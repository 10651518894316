<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="order"
  >
    <v-form
      ref="form"
      lazy-validation
      @submit.prevent="save"
    >
      <v-row>
        <v-col>
          <v-select
            v-model="form.status"
            :items="statusList"
            label="Status"
            prepend-inner-icon="rule"
            background-color="rgba(0,0,0,.3)"
            dark
            filled
            persistent-hint
            readonly
            hide-details
            :rules="[v => !!v || 'Campo obrigatório!']"
          >
            <template #selection="{ item }">
              <v-chip
                :color="item.color"
                small
              >
                <span>{{ item.text }}</span>
              </v-chip>
            </template>
          </v-select>
        </v-col>
        <v-col>
          <template v-if="type == 'PEDIDO_PRODUTOR'">
            <person-autocomplete-filter
              v-model="form.customer"
              label="Produtor *"
              type="PRODUCER"
              background-color="rgba(0,0,0,.3)"
              dark
              persistent-hint
              hide-details
              :rules="[v => !!v && !!v.id || 'Campo obrigatório!']"
            />
          </template>
          <template v-else-if="type == 'PEDIDO_COMERCIAL'">
            <person-autocomplete-filter
              v-model="form.customer"
              label="Cliente/Empresa *"
              type="CUSTOMER"
              background-color="rgba(0,0,0,.3)"
              dark
              persistent-hint
              hide-details
              :disabled="!editing"
              :rules="[v => !!v && !!v.id || 'Campo obrigatório!']"
              @input="onCustomer"
            />
          </template>
        </v-col>
        <v-col v-if="type == 'PEDIDO_PRODUTOR'">
          <person-autocomplete-filter
            v-model="form.supplier"
            label="Fornecedor/Agropecuária"
            type="SUPPLIER"
            background-color="rgba(0,0,0,.3)"
            prepend-inner-icon="add_business"
            dark
            persistent-hint
            hide-details
            :disabled="!editing"
            @input="onSupplier"
          />
        </v-col>
        <v-col v-if="type == 'PEDIDO_COMERCIAL'">
          <person-autocomplete-filter
            v-model="form.seller"
            label="Vendedor"
            type="SELLER"
            background-color="rgba(0,0,0,.3)"
            dark
            persistent-hint
            hide-details
            :disabled="!editing"
            @input="onSeller"
          />
        </v-col>
        <v-col>
          <v-select
            v-model="form.priceTable"
            :items="priceTableList"
            label="Tabela de Preços"
            prepend-inner-icon="rule"
            background-color="rgba(0,0,0,.3)"
            :disabled="!form.customer || !form.customer.id || !editing"
            item-value="id"
            item-text="description"
            dark
            filled
            persistent-hint
            hide-details
            @change="onPriceTableSelected"
          />
        </v-col>
        <v-col>
          <money-input
            :value="total"
            label="Valor total"
            prefix="R$"
            persistent-hint
            hide-details
            dark
            filled
            readonly
            background-color="rgba(0,0,0,.3)"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="form.notes"
            label="Informações adicionais"
            prepend-inner-icon="description"
            rows="3"
            auto-grow
            background-color="rgba(0,0,0,.3)"
            dark
            filled
            persistent-hint
            :disabled="!editing"
            hide-details
          />
        </v-col>
      </v-row>
      <v-card
        dark
        color="transparent"
        class="mt-3"
      >
        <v-card-text
          style="background: rgba(0, 0, 0, 0.3);"
          class="white--text"
        >
          <span class="text-h6 d-inline-flex">
            Itens
          </span>
          <v-divider class="mb-2" />
          <template
            v-for="(data, idx) of form.items"
          >
            <order-item
              v-if="data.active"
              ref="orderItem"
              :key="idx"
              :type="type"
              :editing="editing"
              :supplier-disabled="!!form.supplier && !!form.supplier.id"
              :item.sync="data.item"
              @removeItem="onRemoveItem(idx)"
              @itemQuantityChanged="onItemQuantityChanged"
            />
          </template>
          <v-row v-if="editing">
            <v-col cols="12">
              <v-autocomplete
                v-model="products.item"
                :items="products.list"
                :loading="products.loading"
                :search-input.sync="products.search"
                hide-no-data
                hide-selected
                item-text="description"
                item-value="id"
                placeholder="Informe o nome do produto"
                return-object
                :rules="[() => form.items.filter(o => o.active).length > 0 || 'Adicione um item ao pedido']"
                @change="addItem"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text
          style="background: rgba(0, 0, 0, 0.3);"
          class="white--text"
        >
          <v-row no-gutters>
            <v-col class="pa-0 align-self-end text-start">
              <v-col class="px-0">
                <v-tooltip
                  top
                  attach
                >
                  <template #activator="{ on }">
                    <v-btn
                      icon
                      dark
                      @click="showPaymentSettings = true"
                      v-on="on"
                    >
                      <v-icon>price_change</v-icon>
                    </v-btn>
                  </template>

                  <span>Pagamento</span>
                </v-tooltip>
              </v-col>
              <v-col class="pa-0">
                <small>* Campo obrigatório</small>
              </v-col>
            </v-col>
            <v-spacer />
            <v-col cols="7">
              <v-col
                cols="12"
                class="align-self-end text-end pa-0 ma-0"
              >
                <small class="pr-1">
                  Sub-total
                </small>
                <span class="light-blue--text text--accent-2 text-subtitle-1">
                  <b>{{ formatCurrency(subtotal) }}</b>
                </span>
              </v-col>
              <v-col
                cols="12"
                class="align-self-end text-end pa-0 ma-0"
              >
                <small class="pr-1">
                  Desconto
                </small>
                <span :class="['text-subtitle-1', {'red--text text--accent-2': totalDiscount > 0}]">
                  <b>{{ formatCurrency(totalDiscount) }}</b>
                </span>
              </v-col>
              <v-col
                cols="12"
                class="align-self-end text-end pa-0 ma-0"
              >
                <small class="pr-1">
                  Valor total
                </small>
                <span class="green--text text--accent-3 text-h5">
                  <b>{{ formatCurrency(total) }}</b>
                </span>
              </v-col>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>

    <payment-settings-dialog
      v-model="showPaymentSettings"
      :readonly="!editing"
      :data-payment.sync="form.paymentSettings"
    />

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          v-if="!editing"
          color="blue darken-1"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
        <v-tooltip
          v-else-if="form.status == 'PEDIDO'"
          left
        >
          <template #activator="{ on }">
            <v-btn
              color="blue"
              dark
              large
              fab
              v-on="on"
              @click="saveOrder"
            >
              <v-icon>check</v-icon>
            </v-btn>
          </template>

          <span>Salvar Pedido</span>
        </v-tooltip>
        <v-tooltip
          v-else-if="form.status == 'ORCAMENTO'"
          left
        >
          <template #activator="{ on }">
            <v-btn
              color="orange darken-1"
              dark
              large
              fab
              v-on="on"
              @click="saveBudget"
            >
              <v-icon>task</v-icon>
            </v-btn>
          </template>

          <span>Salvar orçamento</span>
        </v-tooltip>
      </template>

      <v-tooltip
        v-if="!editing && form.status != 'VENDA'"
        left
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="blue"
            @click="editing = true"
            v-on="on"
          >
            <v-icon>
              edit
            </v-icon>
          </v-btn>
        </template>
        Editar
      </v-tooltip>

      <v-tooltip
        v-if="editing && form.approval.status === 'APROVADO'"
        left
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="green darken-1"
            @click="saveSale"
            v-on="on"
          >
            <v-icon>
              attach_money
            </v-icon>
          </v-btn>
        </template>
        Salvar Venda
      </v-tooltip>

      <v-tooltip
        v-if="editing && form.status != 'PEDIDO'"
        left
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="blue"
            @click="saveOrder"
            v-on="on"
          >
            <v-icon>
              check
            </v-icon>
          </v-btn>
        </template>
        Salvar Pedido
      </v-tooltip>

      <v-tooltip
        v-if="editing && form.status != 'ORCAMENTO'"
        left
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="orange darken-1"
            @click="saveBudget"
            v-on="on"
          >
            <v-icon>
              task
            </v-icon>
          </v-btn>
        </template>
        Salvar Orçamento
      </v-tooltip>

      <v-tooltip
        v-if="!editing && form.id"
        left
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="teal darken-1"
            @click="print"
            v-on="on"
          >
            <v-icon>
              print
            </v-icon>
          </v-btn>
        </template>
        Imprimir
      </v-tooltip>

      <v-tooltip
        v-if="form.id"
        left
      >
        <template #activator="{ on }">
          <v-btn
            fab
            dark
            color="red darken-1"
            @click="cancel"
            v-on="on"
          >
            <v-icon>
              delete
            </v-icon>
          </v-btn>
        </template>
        Cancelar
      </v-tooltip>
    </v-speed-dial>

    <v-overlay :value="loading || saving">
      <v-card-text>
        {{ loading ? 'Carregando...' : 'Salvando...' }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-container>
</template>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import MoneyInput from "@/Support/Components/MoneyInput.vue";
import ReportMixin from "@/Support/Mixins/ReportMixin.js";
import PaymentSettingsDialog from "@/Domains/Orders/Components/PaymentSettingsDialog.vue"
import OrderDialogItem from "@/Domains/Orders/Components/Dialogs/OrderDialogItem.vue"

import moment from "moment-timezone";
import _ from "lodash";

export default {
  name: 'order',

  components: {
    PersonAutocompleteFilter,
    MoneyInput,
    PaymentSettingsDialog,
    OrderItem: OrderDialogItem,
  },

  mixins: [ReportMixin],

  props: {
    type: String,
    orderId: String,
  },

  data() {
    return {
      loading: false,
      saving: false,

      showPaymentSettings: false,

      statusList: [
        { value: 'ORCAMENTO', text: 'ORÇAMENTO', color: 'orange darken-1' },
        { value: 'PEDIDO', text: 'PEDIDO', color: 'blue' },
        { value: 'VENDA', text: 'VENDA', color: 'green darken-1' }
      ],
      form: {
        priceTable: null,
        status: 'PEDIDO',
        items: [],
        approval: { status: 'PENDENTE' },
        paymentSettings: {},
      },
      products: {
        types: null,
        item: null,
        search: null,
        loading: false,
        list: [],
      },
      editing: true,

      priceTableList: [
        { id: null, description: 'PADRÃO' }
      ],

      priceTable: []
    }
  },

  computed: {
    total() {
      return this.subtotal - this.totalDiscount
    },
    subtotal() {
      return this.form.items
        .filter(item => !!item.active)
        .reduce((acc, { item }) => acc + item.subTotal, 0)
    },
    totalDiscount() {
      return this.form.items.reduce((acc, { item }) => acc + (item.discountValue || 0), 0)
    },
    totalDiscountPerc() {
      return this.form.items.reduce((acc, { item }) => acc + (item.discountPerc || 0), 0)
    },
  },

  watch: {
    'products.search' (val) {
      if (val && val.trim().length >= 3) {
        this.loadProducts();
      }
    },
    orderId(value) {
      if (value) {
        this.editing = false;
        this.loadOrder();
      }
      else {
        this.editing = true;
        this.reset();
      }
    }
  },

  created() {
    if (this.type == 'PEDIDO_PRODUTOR') {
      this.products.types = ['PRODUTO_ACABADO', 'INSUMO_AGRICOLA'];
    }
    else if (this.type == 'PEDIDO_COMERCIAL') {
      this.products.types = ['PRODUTO_ACABADO'];
    }
    else {
      this.products.types = [];
    }
  },

  mounted() {
    if (this.orderId) {
      this.editing = false;
      this.loadOrder();
    }
    else {
      this.reset();
    }
    this.loadProducts();
  },

  methods: {
    addItem(product) {
      this.$nextTick(() => {
        this.products.item = null;
      })

      const item = {
        item: {
          ...product,
          quantity: 1,
          supplier: this.form.supplier || {}
        },
        active: 1,
      }

      this.form.items.push(item)

      this.onItemChanged(item.item)
    },

    onRemoveItem(idx) {
      const { item } = this.form.items[idx];
      /**
       * Se possui ID então é uma edição,
       * marca como active = 0 para inativar no BD
       */
      if (item.id) {
        this.form.items[idx].active = 0;
      }
      else {
        this.form.items.splice(idx, 1);
      }
    },

    reset() {
      this.$refs.form.resetValidation();
      this.form = {
        priceTable: null,
        status: 'PEDIDO',
        items: [],
        approval: { status: 'PENDENTE' },
        paymentSettings: {},
      };
    },

    saveOrder() {
      this.form.status = 'PEDIDO';
      this.save();
    },

    saveBudget() {
      this.form.status = 'ORCAMENTO';
      this.save();
    },

    saveSale() {
      this.form.status = 'VENDA';
      this.save();
    },

    async save() {
      if (!await this.$refs.form.validate()) {
        return;
      }

      this.saving = true;
      this.editing = false;
      try {

        const { paymentMethod, installmentMethod } = this.form.paymentSettings || {};

        let payload = {
          id_movimento: this.form.id,
          tipo: this.type,
          status: this.form.status,
          observacao: this.form.notes || '',
          desconto: this.totalDiscount,
          desconto_percentual: this.totalDiscountPerc,
          valor: this.total,
          id_cliente: this.form.customer.id,
          nome_cliente: this.form.customer.name,
          id_fornecedor: this.form.supplier ? this.form.supplier.id : null,
          nome_fornecedor: this.form.supplier ? this.form.supplier.name : null,
          id_vendedor: this.form.seller ? this.form.seller.id : null,
          nome_vendedor: this.form.seller ? this.form.seller.name : null,
          id_tabela_preco: this.form.priceTable,
          forma_pagamento: {
            forma_pagamento: paymentMethod ? {
              id: paymentMethod.id,
              descricao: paymentMethod.description,
            } : null,
            forma_parcelamento: installmentMethod ? {
              id: installmentMethod.id,
              descricao: installmentMethod.description,
            } : null
          },
          itens: this.form.items.map(({ item, active }) => {
            return {
              id_movimento_item: item.id || null,
              id_item: item.itemID,
              nome_item: item.name,
              tipo: item.type,
              unidade: item.measurement,
              valor: parseFloat(item.amount),
              quantidade: parseFloat(item.quantity),
              desconto_percentual: parseFloat(item.discountPerc) || null,
              desconto: parseFloat(item.discountValue) || null,
              valor_incremento: parseFloat(item.increment) || null,
              tipo_ajuste_preco: item.adjustmentType,
              valor_final: parseFloat(item.subTotalWithDiscount),
              id_item_grupo: item.group.value,
              nome_item_grupo: item.group.text,
              id_fornecedor: item.supplier ? item.supplier.id : null,
              nome_fornecedor: item.supplier ? item.supplier.name : null,
              ativo: active,
            }
          }),
        };

        const action = this.form.id ? 'atualiza' : 'insere';

        const { data } = await this.$axios.post(`/movimento/${action}`, payload);

        if (data.codigo != 1) {
          throw new Error(data.mensagem || data);
        }
        this.form.id = data.id;
        this.form.emitDate = moment().format('DD/MM/YYYY HH:MM');

        this.$emit('newOrder', data.id);
        this.$snotify.success("Movimento salvo com sucesso!", "Sucesso");
        this.print();
      } catch (error) {
        this.$snotify.error("Oops, ocorreu um erro ao salvar o movimento!", "Atenção");
        this.editing = true;
        console.warn(error);
      } finally {
        this.saving = false;
      }
    },

    async onCustomer() {

      await this.loadCustomerData(this.form.customer.id);

      this.loadAndVerifyPriceTable();
    },

    onSeller() {
      this.loadAndVerifyPriceTable();
    },

    onSupplier(supplier) {
      this.form.items.forEach((item, idx) => {
        this.form.items[idx].item.supplier = supplier;
      });
    },

    async loadOrder() {
      this.loading = true;
      try {
        const { data } = await this.$axios.post(`/movimento/detalhe`, { id_movimento: this.orderId });

        if (!data || !data.id_movimento) {
          return;
        }

        const { forma_pagamento, forma_parcelamento } = data.forma_pagamento || {};

        this.form = {
          id: data.id_movimento,
          status: data.status,
          notes: data.observacao,
          emitDate: moment(data.data_hora_registro).format('DD/MM/YYYY HH:MM'),
          priceTable: data.id_tabela_preco,
          paymentSettings: {
            paymentMethod: forma_pagamento ? {
              id: forma_pagamento.id,
              description: forma_pagamento.descricao,
            } : null,
            installmentMethod: forma_parcelamento ? {
              id: forma_parcelamento.id,
              description: forma_parcelamento.descricao,
            } : null
          },
          customer: {
            id: data.id_cliente,
            name: data.nome_cliente,
            region: data.id_regiao || null
          },
          supplier: {
            id: data.id_fornecedor,
            name: data.nome_fornecedor,
          },
          seller: {
            id: data.id_vendedor,
            name: data.nome_vendedor,
          },
          approval: {
            status: data.aprovacao || 'PENDENTE',
            date: data.data_hora_aprovacao
          },
          items: data.itens.map(item => ({
            item: {
              id: item.id_movimento_item,
              itemID: item.id_item,
              name: item.nome_item,
              type: item.tipo,
              measurement: item.unidade,
              price: parseFloat(item.valor) - parseFloat(item.valor_incremento || 0),
              quantity: parseFloat(item.quantidade),
              adjustmentType: item.tipo_ajuste_preco,
              discountPerc: parseFloat(item.desconto_percentual) || null,
              discountValue: parseFloat(item.desconto) || null,
              increment: parseFloat(item.valor_incremento) || null,
              subTotal: parseFloat(item.valor_final) + parseFloat(item.desconto || 0),
              subTotalWithDiscount: parseFloat(item.valor_final),
              group: {
                value: item.id_item_grupo,
                text: item.nome_item_grupo,
              },
              supplier: {
                id: item.id_fornecedor,
                name: item.nome_fornecedor,
              }
            },
            active: 1,
          })),
        };

        await this.loadPriceTables();

        /**
         * Em uma edição se o id da tabela não estiver na lista,
         * é porque é uma tabela antiga ou que foi removida
         */
        if (this.form.priceTable && !this.priceTableList.find(table => table.id === this.form.priceTable)) {
          this.priceTableList.push({ id: this.form.priceTable, description: `${data.nome_tabela_preco} (${this.dateFormat(data.vigencia_tabela_preco, 'DD/MM/YY')})`, disabled: true });
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    loadProducts: _.debounce(async function() {
      const search = this.products.search;

      try {
        this.products.loading = true;

        const { data } = await this.$axios.get(`/item`, { params: {
          tipos: this.products.types,
          busca: search
        } });

        this.products.list = data.map(item => {
          return {
            itemID: item.id_item,
            code: item.cod_item,
            name: item.nome.trim(),
            description: `${item.cod_item} - ${item.nome.trim()}`,
            type: item.tipo,
            measurement: item.unidade,
            price: item.valor ? parseFloat(item.valor) : null,
            group: {
              value: item.id_item_grupo,
              text: item.nome_item_grupo,
            },
          };
        });
      }
      catch (err) {
        console.warn(err)
      }
      finally {
        this.products.loading = false;
      }
    }, 500),

    async cancel() {
      if (!(await this.$root.$confirm('Remover pedido', 'Tem certeza que deseja remover este pedido?', { color: 'red' }))) {
        return;
      }
      try {
        this.loading = true;

        let { data } = await this.$axios.post(`/movimento/remove`, { id_movimento: this.form.id });

        if (data.codigo !== 1) {
          throw new Error(data.mensagem);
        }

        this.reset();
        this.$emit('newOrder');
        this.$snotify.success("Registro excluído com sucesso", "Sucesso");
      } catch (error) {
        this.$snotify.error(error, "Atenção");
        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    onItemQuantityChanged(item) {
      if (!this.form.priceTable) {
        return;
      }
      this.onItemChanged(item)
    },

    /**
     * Ao adicionar ou alterar o item
     * Obtém o preço de acordo com a tabela e quantidade
     */
    async onItemChanged(item) {
      try {
        const { data } = await this.$axios.post(`/item/precoItem`, {
          id_item: item.itemID,
          id_tabela_preco: this.form.priceTable,
          quantidade: item.quantity
        });

        if (!data.codigo || !data.valor) {
          throw new Error(data.mensagem || data)
        }

        item.price = data.valor

      } catch (error) {
        this.$snotify.error("Erro ao carregar o preço do item", "Atenção");
        console.warn(error);
      }
    },

    /**
     * Carrega a tabela de preço conforme os dados selecionados do formulário
     * e verifica se a tabela seleciona ainda é válida
     */
    async loadAndVerifyPriceTable() {

      await this.loadPriceTables()

      if (this.form.priceTable && !this.priceTableList.find(table => table.id === this.form.priceTable)) {
        this.form.priceTable = null
        this.onPriceTableSelected()
      }
    },

    /**
     * Carrega a tabela de preço conforme os dados selecionados do formulário
     */
    async loadPriceTables() {
      try {
        this.priceTableList = [{ id: null, description: 'PADRÃO' }];

        const customerRegion = this.form.customer ? this.form.customer.region || null : null;
        if (!customerRegion) {
          return
        }

        const { data } = await this.$axios.post(`/item/tabelasDePrecoPedido`, {
          id_regiao: customerRegion,
          id_vendedor: this.form.seller ? this.form.seller.id : null
        });

        const priceTableList = data.map(p => ({
          id: p.id_tabela_preco,
          description: `${p.codigo_tabela_preco} - ${p.nome} (${this.dateFormat(p.data_inicio, 'DD/MM/YY')})`,
          region: p.id_regiao,
        }))

        this.priceTableList.push(...priceTableList)

      } catch (error) {
        this.$snotify.error("Erro ao carregar as tabelas de preço", "Atenção");
        console.warn(error);
      }
    },

    /**
     * Ao alterar a tabela de preço,
     * percorre os itens para aplicar o valor de acordo com a tabela
     */
    async onPriceTableSelected() {
      for (let item of this.form.items) {
        this.onItemChanged(item.item)
      }
    },

    /**
     * Consulta os dados do cliente referente às configurações comerciais
     */
    async loadCustomerData(id) {
      try {
        if (!id) {
          this.form.paymentSettings = {};
          return;
        }

        this.loading = true;

        const { data } = await this.$axios.post(`/movimento/dadosCliente`, { id_cliente: id });

        if (!data || !data.id_cliente) {
          throw new Error(data);
        }

        this.form.paymentSettings = {
          paymentMethod: data.id_forma_pagamento ? {
            id: data.id_forma_pagamento,
            description: data.nome_forma_pagamento,
          } : null,
          installmentMethod: data.id_forma_parcelamento ? {
            id: data.id_forma_parcelamento,
            description: data.nome_forma_parcelamento,
          } : null,
        }
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar os dados do cliente!", "Atenção");
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    /**
     * Imprime o Pedido
     */
    print() {
      const nomeLaticinio = this.$store.state.laticinio ? this.$store.state.laticinio.nome : this.$store.state.settings.sistema;
      const html = `
      <div class="container">
        <div class="row">
          <div class="col">
            <h5 class="text-${this.form.status == 'VENDA' ? 'success' : this.form.status == 'PEDIDO' ? 'info' : 'warning'}">
              ${this.form.status}
            </h5>
            <p>Emitido em ${this.form.emitDate}</p>
          </div>
          <div class="col text-right">
           <h1 class="text-capitalize">${nomeLaticinio}</h1>
          </div>
        </div>
        <hr />
        <div class="row my-2">
          <div class="col">
            <p>${this.type == 'PEDIDO_PRODUTOR' ? 'Produtor' : 'Cliente'}</p>
            <h5>${this.form.customer.name}</h5>
          </div>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Item</th>
              <th scope="col" class="text-center">Quantidade</th>
              <th scope="col" class="text-center">Valor unitário</th>
              <th scope="col" class="text-center">Desconto</th>
              <th scope="col" class="text-right">Subtotal</th>
            </tr>
          </thead>
          <tbody>
            ${this.form.items.filter(o => o.active).map(({ item }) => `<tr>
              <th scope="row">${item.name}</th>
              <td class="text-center">${item.quantity}</td>
              <td class="text-center">${this.formatCurrency(item.amount)}</td>
              <td class="text-center">${this.formatCurrency(item.discountValue)}</td>
              <td class="text-right">${this.formatCurrency(item.subTotalWithDiscount)}</td>
            </tr>`)}
          </tbody>
        </table>
        <div class="row mb-5">
          <div class="col">
            <div class="align-self-end text-right">
              <span class="mr-2">Sub-total</span>
              <span class="text-info" style="font-size:1.3em">${this.formatCurrency(this.subtotal)}</span>
            </div>
            <div class="align-self-end text-right">
              <span class="mr-2">Desconto</span>
              <span class="text-danger" style="font-size:1.3em">${this.formatCurrency(this.totalDiscount)}</span>
            </div>
            <div class="align-self-end text-right">
              <span class="mr-2">Valor total</span>
              <span class="text-success" style="font-size:2em">${this.formatCurrency(this.total)}</span>
            </div>
          </div>
        </div>
        <hr />
        <p class="text-center text-muted">MilkUp - Gestão de Leite</p>
      </div>
      `;

      return this.printJS({
        documentTitle: this.form.status,
        printable: html,
        type: 'raw-html',
        css: ['https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css'],
      });
    },

    dateFormat: (value, format) => !value ? '-' : moment(value).format(format),
    formatCurrency: (value) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value),
  }
}
</script>
