import { ref, getCurrentInstance } from 'vue'

import axios from '@/Support/Resources/axios-instance.js'
import moment from "moment-timezone";

import isEmpty from 'lodash/isEmpty'

export function useOrders() {
  const { proxy: { $snotify, $root } } = getCurrentInstance();

  const dateFormat = (value, format) => !value ? '-' : moment(value).format(format);
  const formatCurrency = (value) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);

  const loading = ref(false);
  const list = ref([]);

  const defaultHeaders = ref([
    { text: 'Código', value: 'code' },
    { text: 'N. Pedido de Compra', value: 'purchaseOrderCode' },
    { text: 'Data de Cadastro', value: 'createdAt', formatter: value => dateFormat(value, 'DD/MM/YYYY HH:mm') },
    { text: 'Previsão de Entrega', value: 'scheduledTo', formatter: value => value ? dateFormat(value, 'DD/MM/YYYY HH:mm') : 'Imediata' },
    { text: 'Cod. Cliente', value: 'customer.code' },
    { text: 'CPF/CNPJ Cliente', value: 'customer.federalTaxNumber' },
    { text: 'Cliente', value: 'customer.name' },
    { text: 'Representante(s)', value: 'sellers' },
    { text: 'Estado Entrega', value: 'customer.state' },
    { text: 'Cidade Entrega', value: 'customer.city.name' },
    { text: 'Desconto', value: 'discount', formatter: value => formatCurrency(value) },
    { text: 'Valor', value: 'total', formatter: value => formatCurrency(value) },
    // { text: 'Aprovação', value: 'approval.status', align: 'center', width: 150,  },
    { text: 'TESTE', value: 'shipping.shipment.code' },
    { text: '', altText: 'Opções', value: 'action', width: 30, sortable: false, drag: false }
  ]);

  async function findById(orderId) {
    try {
      loading.value = true;

      const { data } = await axios.get(`/order/${orderId}`);

      return parseOrder(data);
    } catch (error) {
      $snotify.error("Erro ao carregar os pedidos", "Atenção");

      console.warn(error);
    } finally {
      loading.value = false;
    }
  }

  async function fetchAll(params) {
    try {
      loading.value = true;

      const { data } = await axios.get(`/order`, { params });

      list.value = data.map(order => parseOrder(order));
    } catch (error) {
      $snotify.error("Erro ao carregar os pedidos", "Atenção");

      console.warn(error);
    } finally {
      loading.value = false;
    }
  }

  function parseOrder(order) {
    const { id_forma_pagamento, id_forma_parcelamento } = order.forma_pagamento || {};

    let parsedOrder = {
      id: order.id_movimento,
      code: order.cod_movimento,
      purchaseOrderCode: order.cod_pedido,
      status: order.status,
      notes: order.observacao,
      invoiceNumber: order.numero_nota,
      scheduledTo: order.data_hora_entrega,
      emitDate: moment(order.data_hora_registro).format("DD/MM/YYYY HH:MM"),
      createdAt: order.data_hora_cadastro,
      priceTable: order.id_tabela_preco,
      discount: order.desconto,
      discountPerc: order.desconto_percentual,
      total: order.valor,
      paymentSettings: {
        paymentMethodId: id_forma_pagamento || null,
        installmentMethodId: id_forma_parcelamento || null,
      },
      customer: {
        id: order.cliente.id_pessoa,
        name: order.cliente.nome,
        code: order.cliente.codigo_laticinio,
        federalTaxNumber: order.cliente.cnpj_cpf,
        region: order.cliente.id_regiao || null,
        sellers: order.cliente?.vendedores?.map(seller => ({ id: seller.id_usuario, name: seller.nome_usuario })),
        state: order.cliente.end_estado,
        city: {
          code: order.cliente.end_cidade_codigo,
          name: order.cliente.end_cidade,
        }
      },
      supplier: {
        id: order.fornecedor?.id_fornecedor,
        name: order.fornecedor?.nome_fornecedor,
      },
      sellers: order.vendedores.map(seller => ({
        id: seller.id_vendedor,
        name: seller.nome_vendedor,
        commissionPerc: seller.comissao_percentual,
        additionalCommissionAmount: seller.comissao_extra,
        totalOrderCommission: seller.comissao_total_movimento,
      })),
      approval: {
        date: order.data_hora_aprovacao,
      },
      products: order.produtos.map(product => ({
        id: product.id_movimento_item,

        // Quantidade sempre em KG
        quantity: parseFloat(product.quantidade),
        measurementId: product.id_unidade_medida,
        measurement: product.unidade,
        measurementDescription: product.unidade_descricao,

        // Em produtos que não são vendidos por KG, calcula com base no peso e peso bruto do item a quantidade real
        realQuantity: parseFloat(product.quantidade_real),
        realMeasurementId: product.id_unidade_medida_real,
        realMeasurement: product.unidade_real,
        realMeasurementDescription: product.unidade_real,

        shippingFormat: product.formato_entrega,
        supplier: {
          id: product.id_fornecedor,
          name: product.nome_fornecedor,
        },
        notes: product.observacao,
        price: parseFloat(product.valor) - parseFloat(product.valor_incremento || 0),
        discountPerc: parseFloat(product.desconto_percentual) || null,
        discountValue: parseFloat(product.desconto) || null,
        increment: parseFloat(product.valor_incremento) || null,
        subTotal: parseFloat(product.valor_final) + parseFloat(product.desconto || 0),
        subTotalWithDiscount: parseFloat(product.valor_final),
        item: {
          id: product.id_unidade_medida || product.id_item,
          itemId: product.id_item,

          measurement: product.unidade,
          measurementDescription: product.unidade_descricao,

          conversionFactor: product.fator_conversao || 1,

          name: product.item.nome,
          code: product.unidade?.codigo || product.item.cod_item,
          sif: product.item.sif,
          sifDipoa: product.item.sif_dipoa,
          barcodes: product.item.codigos_barras,
          duncodes: product.item.codigos_dun,
          barcode: product.unidade?.codigo_barras || product.item.codigo_barras,
          defaultBarcode: product.item.codigo_barras,
          description: product.item.nome,
          type: product.item.tipo,
          requiresLot: product.item.controlar_lotes || false,
          manualLot: product.item.lote_manual || false,

          saleByKilo: product.item.venda_por_kilo,

          group: {
            id: product.item.id_item_grupo,
            name: product.item.nome_item_grupo,
          },

          grossWeight: ((product.item.peso_bruto * (product.fator_conversao || 1)) + (product.unidade?.peso_tara || 0)) || null,
          tareWeight1: product.unidade?.peso_tara,
          tareWeight2: (product.item.peso_tara * (product.fator_conversao || 1)) || null,
        },
        commissionType: product.tipo_calculo_comissao,
        commissions: product.comissoes.map(comissao => ({
          seller: {
            id: comissao.id_vendedor,
            name: comissao.nome_vendedor,
          },
          amount: comissao.comissao_valor,
          commissionAdjustmentBySeller: comissao.verba_valor,
          totalCommissionAmount: comissao.comissao_valor_total,
        })),
        commissionAdjustment: parseFloat(product.ajuste_comissao_valor) || 0.0,
        active: 1,
      })),
    }

    if (!isEmpty(order.entrega)) {
      parsedOrder = {
        ...parsedOrder,
        shipping: {
          id: order.entrega.id,
          shipment: {
            code: order.entrega.expedicao.codigo_carga,
          }
        }
      }
    }

    return parsedOrder;
  }

  return {
    findById,
    fetchAll,
    loading,
    list,
    defaultHeaders,
  }
}
