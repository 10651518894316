<template>
  <v-card
    elevation="1"
    outlined
    dark
    color="transparent"
  >
    <v-card-title class="d-flex flex-column">
      Ações de Qualidade BPA
    </v-card-title>

    <v-tabs
      v-model="tab"
      dark
      centered
      background-color="transparent"
      class="transparent"
    >
      <v-tab href="#simple">
        Faixa Simples
      </v-tab>
      <v-tab href="#points">
        Pontuação
      </v-tab>
      <v-tab-item value="simple">
        <v-row class="justify-center">
          <v-col
            cols="12"
            md="4"
          >
            <v-autocomplete
              v-model="itemSelected"
              :items="entries"
              :loading="loading"
              item-value="value"
              item-text="text"
              dark
              hide-selected
              hide-details
              label="Inserir item"
              prepend-inner-icon="search"
              filled
              return-object
              @change="addItem"
            />
          </v-col>
        </v-row>
        <ranges
          :value="goals.simple"
          type-range="simple"
        />
      </v-tab-item>
      <v-tab-item value="points">
        <v-row class="justify-center">
          <v-col
            cols="12"
            md="4"
          >
            <v-autocomplete
              v-model="itemSelected"
              :items="entries"
              :loading="loading"
              item-value="value"
              item-text="text"
              dark
              hide-selected
              hide-details
              label="Inserir item"
              prepend-inner-icon="search"
              filled
              return-object
              @change="addItem"
            />
          </v-col>
        </v-row>
        <ranges
          :value="goals.points"
          type-range="points"
        />
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>

import Ranges from "@/Domains/Projects/PQFL/BPA/Components/Ranges.vue";
import _ from "lodash";

import { v4 as uuidv4 } from "uuid";

export default {

  components: {
    Ranges,
  },

  props: {
    value: {
      type: Object,
    },
  },

  data() {
    return {

      tab: null,

      entries: [],

      itemSelected: '',

      itemType: '',

      loading: false,
    };
  },

  computed: {
    goals: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  mounted() {
    this.loadItens();
  },

  methods: {

    async loadItens() {
      try {
        this.loading = true;

        const { data } = await this.$queries.post(`/qualidade/classificacaoListaJson`);

        this.entries = data.map(item => {
          return {
            value: item.id_qualidade,
            text: item.item,
          }
        });

      } catch (error) {
        console.log(error);

        this.$snotify.error("Oops, ocorreu um erro ao carregar grupos!", "Atenção");
      } finally {
        this.loading = false;
      }
    },

    addItem(indicator) {

      if (this.tab === 'simple') {
        let ranges =  [{
          id: uuidv4(),
          index: 1,
          start: 0,
          end: null,
          class: 'A',
          visits: null,
          period: 'mensal',
          questionnaires: [],
          actions: '',
          show_app: true,
          type_producer: '',
          time_init: '',
        }]

        this.goals.simple.push({ indicator, ranges })
      } else {

        let ranges =  [{
          id: uuidv4(),
          index: 1,
          start: 0,
          end: null,
          class: 'A',
          visits: null,
          period: 'mensal',
          questionnaires: [],
          actions: '',
          show_app: true,
          type_producer: '',
          time_init: '',
          points: '',
        }]

        this.goals.points.push({ indicator, ranges })
      }
      this.$nextTick(() => {
        this.itemSelected = "";
      });

    },

    removeItem(item) {
      const idx = this.goals.findIndex(o => o.indicator === item.indicator);
      this.goals.splice(idx, 1);
    },

    disableDot: (e) => [46, 101].includes(e.charCode) && e.preventDefault(),
    disableDotAndComma: (e) => [44, 46, 101].includes(e.charCode) && e.preventDefault(),
  },
};
</script>
