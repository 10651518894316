
import { ref, computed, watch, onMounted, onUnmounted } from 'vue'
import { io } from 'socket.io-client'
import store from '@/Support/Resources/vuex.js'

export function useLocalWebSocket(settings) {

  const websocket = ref(null)

  const localSocket = computed({
    get: () => store.state.socket.localConnection,
    set: (value) => store.commit('socket/setLocalConnection', value)
  })

  const start = () => {
    const port = settings.value.connectionPort || '9001'

    const connection = io(`ws://localhost:${port}`)
    connection.on('connect', onConnect)
    connection.on('disconnect', onDisconnect)

    localSocket.value = connection
    websocket.value = connection
  }

  const onConnect = () => {
    console.log('Local WS connected')

    localSocket.value.emit('user', {
      id: store.state?.settings?.user?.id,
      name: store.state?.settings?.user?.nome,
    })
  }

  const onDisconnect = (reason) => {
    console.log('Local WS disconnected', { reason })

    if (reason === 'io server disconnect') {
      // the disconnection was initiated by the server, we need to reconnect manually
      localSocket.value.connect()
    }
    // else the socket will automatically try to reconnect
  }

  const closeConnection = () => {
    if (localSocket.value) {
      localSocket.value.removeAllListeners()
      localSocket.value.disconnect()
      localSocket.value.io.opts.reconnection = false
      localSocket.value = null
    }

    websocket.value = null
  }

  const startConnection = () => {
    const connectionType = settings.value.connectionType
    // Disconnect previous connection
    closeConnection()

    setTimeout(() => {
      if (connectionType === 'LOCAL') {
        start()
      } else {
        websocket.value = store.state.socket.connection
      }
    }, 1000)
  }

  watch(() => settings.value.connectionType, () => startConnection())

  onMounted(() => {
    startConnection()
  })

  onUnmounted(() => {
    closeConnection()
  })

  return { websocket }
}
