<template>
  <v-container
    text-xs-center
    grid-list-lg
    class="orders"
  >
    <v-row>
      <v-col cols="12">
        <v-btn
          text
          @click="$router.back()"
        >
          <v-icon>arrow_left</v-icon>
          <div class="pr-3">
            Voltar
          </div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-tabs
        v-model="tab"
        dark
        centered
        background-color="transparent"
        class="transparent text-left"
      >
        <template
          v-for="status in settings.workflow"
        >
          <v-tab
            v-if="status.settings.showOnList"
            :key="status.id"
            :tab-value="status.slug"
          >
            <div
              class="mr-4"
              :style="{
                backgroundColor: status.color,
                cursor: 'pointer',
                height: '14px',
                width: '14px',
                borderRadius: '8px',
                transition: 'border-radius 200ms ease-in-out'
              }"
            />

            {{ status.label }}
          </v-tab>
        </template>
      </v-tabs>

      <v-tabs-items
        v-model="tab"
        style="width: 100%; background: transparent !important;"
      >
        <template
          v-for="status in settings.workflow"
        >
          <v-tab-item
            v-if="tab === status.slug"
            :key="status.id"
            :value="status.slug"
            eager
          >
            <ComercialDispatchingPlanning
              v-if="status.id === settings.dispatchPlanningStatusId"
              :status="status.slug"
              :status-in="status.settings.statusToShow"
              :status-not-in="status.settings.statusToNotShow"
              :settings="status.settings"
              :workflow="settings.workflow"
            />

            <ComercialShipmentList
              v-else-if="status.id === settings.shipmentStatusId"
              :status="status.slug"
              :status-in="status.settings.statusToShow"
              :status-not-in="status.settings.statusToNotShow"
              :settings="status.settings"
              :workflow="settings.workflow"
            />

            <ComercialOrdersList
              v-else
              :status="status.slug"
              :status-in="status.settings.statusToShow"
              :status-not-in="status.settings.statusToNotShow"
              :settings="status.settings"
              :workflow="settings.workflow"
            />
          </v-tab-item>
        </template>
      </v-tabs-items>
    </v-row>
  </v-container>
</template>

<script setup>
import { computed, ref } from "vue";

import ComercialOrdersList from '@/Domains/Orders/Components/Lists/ComercialOrdersList.vue'
import ComercialDispatchingPlanning from '@/Domains/Orders/Components/Lists/ComercialDispatchingPlanning.vue'

import { useStore } from '@/Support/Resources/vuex.js';
import ComercialShipmentList from "@/Domains/Orders/Components/Lists/ComercialShipmentList.vue";

const tab = ref(null);

const store = useStore();

const settings = computed(() => {
  const generalSettings = store.state.settings?.gerais || {};

  return generalSettings.fluxo_pedidos || {};
});
</script>
