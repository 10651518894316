<template>
  <v-dialog
    v-model="isVisible"
    persistent
    max-width="450"
  >
    <v-form
      ref="form"
      lazy-validation
      @submit.prevent="changeStatus()"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ action.dialog?.title }}
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col
              cols="12"
            >
              <p class="text-overline font-weight-black mb-1">
                Cliente:
              </p>
              {{ order.cliente?.nome }}
            </v-col>

            <v-col cols="12">
              <p class="text-overline font-weight-black mb-1">
                Representantes:
              </p>
              {{ order.vendedores?.map(seller => seller.nome_vendedor).join(', ') }}
            </v-col>

            <v-col cols="12">
              <p class="text-overline font-weight-black mb-1">
                Agendamento:
              </p>
              {{ order.data_hora_entrega ? formatDate(order.data_hora_entrega, 'DD/MM/YYYY HH:mm') : 'Imediata' }}
            </v-col>

            <v-col cols="6">
              <p class="text-overline font-weight-black mb-1">
                Código do Pedido:
              </p>

              <b>{{ order.cod_pedido }}</b>
            </v-col>
          </v-row>

          <v-row
            v-if="action.dialog?.additionalFields?.length > 0"
          >
            <v-col cols="12">
              <p class="text-overline font-weight-black mb-1">
                Campos Adicionais:
              </p>

              <v-divider />
            </v-col>
          </v-row>

          <v-row
            v-for="({ value, description, required }) in action.dialog?.additionalFields"
          >
            <v-col cols="12">
              <v-textarea
                v-if="(value || '').includes('observacao')"
                v-model="order[value]"
                :label="description"
                placeholder=" "
                persistent-placeholder
                :rules="required ? [v => !!v || 'Campo obrigatório!'] : null"
              />

              <v-text-field
                v-else
                v-model="order[value]"
                :label="description"
                placeholder=" "
                persistent-placeholder
                :rules="required ? [v => !!v || 'Campo obrigatório!'] : null"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="red darken-1"
            outlined
            @click="close()"
          >
            Cancelar
          </v-btn>

          <v-spacer />

          <v-btn
            color="green darken-1"
            outlined
            :loading="loading"
            @click="changeStatus()"
          >
            {{ action.dialog?.confirmActionLabel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

    <v-overlay
      :value="loading"
      absolute
    >
      <v-card-text>
        {{ loading ? 'Carregando...' : 'Salvando...' }}
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        />
      </v-card-text>
    </v-overlay>
  </v-dialog>
</template>

<script>
import isObject from "lodash/isObject";
import moment from "moment-timezone";

const emptyForm = {
  id: null,
  customer: {
    name: null,
  },
  sellers: [],
  scheduledTo: null,
  code: null,
  invoiceNumber: null,
}

export default {

  data() {
    return {
      isVisible: false,

      loading: false,

      order: { ...emptyForm },

      action: {},
    }
  },

  methods: {
    async show(order, action) {
      this.action = action;

      await this.loadOrder(order.id);

      this.isVisible = true;
    },

    close() {
      this.isVisible = false;

      return this.reset();
    },

    reset() {
      this.order = { ...emptyForm };
    },

    async loadOrder(id) {
      try {
        this.loading = true;

        const { data } = await this.$axios.get(`/order/${id}`);

        if (!isObject(data)) {
          throw data;
        }

        this.order = data;
      } catch (error) {
        this.$snotify.error("Erro ao alterar aprovação", "Atenção");

        console.warn(error)

        this.close();
      } finally {

        this.loading = false;
      }
    },

    async changeStatus() {
      try {
        this.loading = true;

        const payload = {
          id_movimento: this.order.id_movimento,
          status: this.action.newStatus.slug,
        };

        for (const { value } of this.action.dialog?.additionalFields) {
          payload[value] = this.order[value];
        }

        let { data } = await this.$axios.post(`/order/change-status`, payload);

        if (!data.codigo) {
          throw new Error(data.mensagem || data);
        }

        this.$snotify.success("Pedido aprovado com sucesso!", "Sucesso");

        this.$emit('save');

        return this.close();
      } catch (error) {
        this.$snotify.error("Erro ao alterar aprovação", "Atenção");

        console.warn(error);
      } finally {
        this.loading = false;
      }
    },

    formatDate(date, format) {
      return moment(date).format(format);
    },
  }
}
</script>
